<template>
  <v-container>
    <v-row class="py-3 px-2">
      <h1 class="display-1">Voucher Types</h1>
      <v-spacer></v-spacer>
      <v-text-field
        v-model="search"
        append-icon="search"
        label="Search"
        single-line
        hide-details
      ></v-text-field>
      <v-spacer></v-spacer>
      <v-dialog v-model="showAddVoucherType" width="500">
        <template v-slot:activator="{ on, attrs }">
          <v-btn color="primary" dark v-bind="attrs" v-on="on">
            Add Voucher Type
          </v-btn>
        </template>
        <add-voucher-type
          @handleSubmit="handleSubmit"
          @closeModal="showAddVoucherType = false"
        ></add-voucher-type>
      </v-dialog>
    </v-row>
    <v-data-table
      :items="voucherTypes"
      :headers="headers"
      :options.sync="options"
      :search="search"
    >
      <template v-slot:item="{ item }">
        <tr>
          <td>{{ item.type }}</td>
          <td>
            <v-checkbox
              color="primary"
              v-model="item['new_starter']"
              primary
              hide-details
              @change="updateVoucherType(item.id)"
            ></v-checkbox>
          </td>
          <td>
            <v-checkbox
              color="primary"
              v-model="item['agent']"
              primary
              hide-details
              @change="updateVoucherType(item.id)"
            ></v-checkbox>
          </td>
          <td>
            <v-checkbox
              color="primary"
              v-model="item['senior_agent']"
              primary
              hide-details
              @change="updateVoucherType(item.id)"
            ></v-checkbox>
          </td>
          <td>
            <v-checkbox
              color="primary"
              v-model="item['team_leader']"
              primary
              hide-details
              @change="updateVoucherType(item.id)"
            ></v-checkbox>
          </td>
          <td>
            <v-checkbox
              color="primary"
              v-model="item['is_leadership_code']"
              primary
              hide-details
              @change="updateVoucherType(item.id)"
            ></v-checkbox>
          </td>
          <td>
            <v-icon
              color="error"
              @click="showDeleteVoucherTypeModal(item.id, item.type)"
              >delete</v-icon
            >
          </td>
        </tr>
      </template>
    </v-data-table>
    <v-dialog v-model="showDeleteVoucherType" width="500">
      <delete-voucher-type
        @closeModal="showDeleteVoucherType = false"
        @handleSubmit="handleSubmit"
        :selectedVoucherToDelete="selectedVoucherToDelete"
      ></delete-voucher-type>
    </v-dialog>
  </v-container>
</template>

<script>
import axios from 'axios';
import notifications from '@/modules/notifications';
import store from '@/store/store';
import AddVoucherType from './AddVoucherType';
import DeleteVoucherType from './DeleteVoucherType';
import {CONSTANTS} from '@/modules/constants';

export default {
	name: 'VoucherSettings',
	components: {
		'add-voucher-type': AddVoucherType,
		'delete-voucher-type': DeleteVoucherType
	},
	data() {
		return {
			voucherTypes: [],
			headers: [
				{
					text: 'Voucher Type',
					value: 'type'
				},
				{
					text: 'New Starter',
					value: 'new_starter'
				},
				{
					text: 'Agent',
					value: 'agent'
				},
				{
					text: 'Senior Agent',
					value: 'senior_agent'
				},
				{
					text: 'Team Leader',
					value: 'team_leader'
				},
				{
					text: 'Leadership Code',
					value: 'is_leadership_code'
				},
				{
					text: '',
					value: ''
				}
			],
			options: {
				sortBy: ['type']
			},
			search: '',
			showAddVoucherType: false,
			showDeleteVoucherType: false,
			selectedVoucherToDelete: null
		};
	},
	mounted() {
		this.getVoucherTypes();
	},
	methods: {
		getVoucherTypes() {
			axios
				.get(`${CONSTANTS.BASE_URL}/api/v1/vouchers/backup_types`, {
					params: {
						country: store.state.country
					}
				})
				.then((response) => {
					const { backup_voucher_types: voucherTypes } = response.data;
					this.voucherTypes = voucherTypes;
				})
				.catch((error) => {
					notifications.addNotification(
						'Error: Could not get voucher types',
						true
					);
				});
		},
		updateVoucherType(id) {
			const voucherType = this.voucherTypes.find(
				(voucherType) => voucherType.id === id
			);

			axios
				.put(`${CONSTANTS.BASE_URL}/api/v1/voucher_type`, {
					body: {
						voucher_type: voucherType
					}
				})
				.then((response) => {
					notifications.addNotification('Voucher type successfully updated!');
				})
				.catch((error) => {
					notifications.addNotification(
						'Error: could not update voucher type',
						true
					);
				});
		},
		showDeleteVoucherTypeModal(id, type) {
			this.selectedVoucherToDelete = { id, type };
			this.showDeleteVoucherType = true;
		},
		handleSubmit() {
			this.showAddVoucherType = false;
			this.showDeleteVoucherType = false;
			this.getVoucherTypes();
		}
	}
};
</script>

<style scoped></style>
