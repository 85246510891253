<template>
  <div>
    <div class="upload-lmcs-page">
      <v-progress-linear v-if="uploadInProgress" indeterminate class="progress"/>
      <v-container>
        <v-row>
          <v-col cols="12">
            <v-toolbar color="primary" dark short>
              <v-toolbar-title class="headline pa-3">Payment Orders</v-toolbar-title>
              <v-spacer/>
              <span class="lmc-upload">
                <label :for="`${file}-upload`"
                       class="v-btn upload-button"
                       :class="{'btn--disabled': uploadInProgress,
                                'upload-au': country === 'au',
                                'upload-nz': country === 'nz'}">
                  Upload Orders
              </label>
                <input :id="`${file}-upload`"
                       type="file"
                       @change="uploadFile($event.target.files[0], file)"/>
              </span>
            </v-toolbar>

          </v-col>
        </v-row>
      </v-container>
      <v-dialog v-model="showTestDialog" width="50%">
        <v-card>
          <v-card-title class="primary">
            Input details to send test SMS
          </v-card-title>
          <v-col cols="12" v-if="!showPreview">
            <v-form class="pa-8" ref="input_form">
              <v-text-field label="First Name"
                            v-model="testFirstName"
                            required
                            :rules="[v => !!v || 'First Name is required']"

              />
              <v-text-field label="Phone Number"
                            v-model="testPhone"
                            required
                            hint="Example: +61412345678"
                            :rules="[v => !!v || 'Phone Number is required. Example: +61412345678',
                                     v => (v[0] == '+' && v[1] == '6') || 'Please include country code. Example: +61412345678']"
              />
              <v-select
                :items="SMSTypes"
                label="SMS type"
                v-model="selectedSMSType"
                required
                :rules="[v => !!v || 'SMS type is required']"
              />
              <v-btn @click="getPreview" depressed color="primary" class="mt-4">Preview SMS</v-btn>
            </v-form>
          </v-col>

          <v-col cols="12" align="center" v-else>
            <v-row>
              <v-btn text @click="togglePreview" class="ma-8">Back</v-btn>
              <v-spacer/>
              <v-btn depressed dark color="red" @click="sendTestSMS" class="ma-8"> Send test SMS </v-btn>
            </v-row>
            <v-col cols="12">
              <v-card width="70%">
                <v-card-title class="primary">
                  Standard SMS
                  <v-spacer/>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        dark
                        v-bind="attrs"
                        v-on="on"
                      >
                        fa-info-circle
                      </v-icon>
                    </template>
                    <span>You can edit SMS templates in settings in Sentinel.</span>
                  </v-tooltip>
                </v-card-title>
                <v-col cols="12" class="pa-8">
                  <v-progress-circular
                    v-if="loading"
                    indeterminate
                    color="primary"
                  />
                  {{ previewText }}
                </v-col> </v-card>
            </v-col>
            <v-col>
              <v-card width="70%">
                <v-card-title class="primary">
                  Landing Page
                  <v-spacer/>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        dark
                        v-bind="attrs"
                        v-on="on"
                      >
                        fa-info-circle
                      </v-icon>
                    </template>
                    <span>You can edit Landing Pages in the Message Media hub.</span>
                  </v-tooltip>

                </v-card-title>
                <v-progress-circular
                  v-if="loading"
                  indeterminate
                  color="primary"
                />
                <v-card-title>{{ previewLanding.header }}</v-card-title>
                <v-col cols="12" class="pa-8"> <p v-html="previewLanding.body"></p> </v-col>
                <v-btn :href="previewLanding.link" class="ma-8"> Manage Your Account</v-btn>
              </v-card>
            </v-col>
            <v-row class="pa-8">
              <v-spacer/>
            </v-row>
          </v-col>

        </v-card>
      </v-dialog>
      <v-dialog v-model="showWarning" width="30%">
        <v-card class="pa-6">
          <v-toolbar v-if="showOutsideWindowWarning" color="red">
            {{currentTime.getHours()}}:{{currentTime.getMinutes()}} is not within the
            optimal sending window. </v-toolbar>
          <v-card-title>Warning</v-card-title>
          <v-card-text>You are about to send the <b>{{selectedTime}} SMS </b> to <b>{{this.errors_cutoff.length}} customers</b>.</v-card-text>
          <v-row>
            <v-spacer/>
            <v-btn color="red" class="mr-4" @click="sendSMS(selectedTime)">Confirm</v-btn>
          </v-row>

        </v-card>
      </v-dialog>
      <v-dialog v-model="showAnalytics" width="30%" persistent>
        <v-card>
          <v-card-title class="justify-center pa-6">Expected Percentage vs Jellyfish</v-card-title>
          <v-card-text class="text-center d-flex justify-center">
            <v-progress-circular
              v-if="loading"
              indeterminate
              color="primary"
            />
            <v-form ref="percentage_form" v-else-if="showPercentageEdit">
              <v-text-field label="Total % Recurring Transactions"
                            v-model="percentagesNew['total']"
                            :rules="[v => !!v || 'Expected percentage is required.']">
              </v-text-field>
              <v-text-field label="Blocked Card %"
                            v-model="percentagesNew['blocked']"
                            :rules="[v => !!v || 'Expected percentage is required.']">
              </v-text-field>
              <v-text-field label="Expired Card %"
                            v-model="percentagesNew['expired']"
                            :rules="[v => !!v || 'Expected percentage is required.']">
              </v-text-field>
              <v-text-field label="Paypal Revoked %"
                            v-model="percentagesNew['paypal']"
                            :rules="[v => !!v || 'Expected percentage is required.']">
              </v-text-field>
              <v-text-field label="General %"
                            v-model="percentagesNew['general']"
                            :rules="[v => !!v || 'Expected percentage is required.']">
              </v-text-field>
              <v-row>
                <v-btn @click="togglePercentageEdit" text class="ma-6">Back</v-btn>
                <v-btn @click="updateAnalytics" color="primary" class="ma-6">Update Percentages</v-btn>
              </v-row>
            </v-form>
            <v-col v-else>
              <table>
                <tr>
                  <th class="pa-16"></th>
                  <th class="pl-4">Expected</th>
                  <th class="pl-4">Jellyfish</th>
                  <th class="pl-4">Delta</th>
                </tr>
                <tr>
                  <td> Total % </td>
                  <td class="pl-4"> {{ this.percentages['total'] }}</td>
                  <td> {{ this.jfPercentages['total'] }}</td>
                  <td> {{ deltaTotal }}</td>
                </tr>
                <tr>
                  <td> Blocked Card % </td>
                  <td class="pl-4"> {{ percentages['blocked'] }}</td>
                  <td> {{ jfPercentages['blocked'] }}</td>
                  <td> {{ deltaBlocked }}</td>
                </tr>
                <tr>
                  <td> Expired Card % </td>
                  <td class="pl-4"> {{ this.percentages['expired'] }}</td>
                  <td> {{ this.jfPercentages['expired'] }}</td>
                  <td> {{ deltaExpired }}</td>
                </tr>
                <tr>
                  <td> Paypal Revoke % </td>
                  <td class="pl-4"> {{ this.percentages['paypal'] }}</td>
                  <td> {{ this.jfPercentages['paypal'] }}</td>
                  <td> {{ deltaPaypal }}</td>
                </tr>
                <tr>
                  <td> General % </td>
                  <td class="pl-4"> {{ this.percentages['general'] }}</td>
                  <td> {{ this.jfPercentages['general'] }}</td>
                  <td> {{ deltaGeneral }}</td>
                </tr>
              </table>
              <v-row>
                <v-btn @click="toggleAnalytics" color="primary" class="ma-6"> Close </v-btn>
                <v-spacer/>
                <v-btn @click="togglePercentageEdit" text class="ma-6"
                       :disabled="!userProfile.payment">Edit Expected</v-btn>
              </v-row>
            </v-col>

          </v-card-text>
        </v-card>
        <v-dialog/>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import notifications from '@/modules/notifications';
import DeprecateView from '@/components/DeprecateView';
import {CONSTANTS} from '@/modules/constants';

export default {
	name: 'PaymentLMCs',
	components: {DeprecateView},
	data() {
		return {
			userProfile: this.$store.state.profile,
			country: this.$store.state.country,
			dateLastSent: this.$store.state.dateCutoffSMSLastSent,
			errorMessage: '',
			jobs: [],
			uploadInProgress: false,
			file: undefined,
			showTestDialog: false,
			showPreview: false,
			showAnalytics: false,
			showWarning: false,
			showOutsideWindowWarning: false,
			showPercentageEdit: false,
			testFirstName: '',
			testPhone: '',
			previewText: '',
			previewLanding: {
				id: '',
				header: '',
				body: '',
				link: ''
			},
			SMSTypes: ['Paypal Authorization Revoked', 'Expired Card', 'Blocked Card', 'General', 'Last Warning AM', 'Last Warning - Cancel', 'Last Warning - Delivery Confirmation'],
			selectedSMSType: '',
			paymentSMSLockData: {
				amSMSSent: false,
				pmSMSSent: false
			},
			errors_cutoff: [],
			headers: [
				{text: 'Jellyfish Timestamp', value: 'timestamp'},
				{text: 'Customer ID', value: 'customer_id'},
				{text: 'First Name', value: 'first_name'},
				{text: 'Contact', value: 'phone'},
				{text: 'Decision', value: 'decision'},
				{text: 'Error Reason', value: 'message'},
				{text: '9am SMS Type', value: 'am_sms'},
				{text: '3pm SMS Type', value: 'pm_sms'}
			],
			loading: true,
			selectedTime: '',
			dates:[],
			currentTime: new Date(),
			progress: false,
			today: '',
			percentages: {},
			percentagesNew: {},
			jfPercentages: {}
		}
	},
	mounted() {
		this.getJobs();
		this.getPaymentCutoffErrors();
		this.getPaymentSMSLockData();
		this.getAnalytics();
	},
	beforeDestroy() {
		this.jobs.map((job) => {
			clearInterval(job.interval);
		});
	},
	computed: {
		dateRangeText() {
			return this.dates.join(' ~ ')
		},
		analyticsCorrect() {
			return (this.deltaTotal <= 2 && this.deltaTotal >= -2) &&
          (this.deltaGeneral <= 2 && this.deltaGeneral >= -2) &&
          (this.deltaExpired <= 2 && this.deltaExpired >= -2) &&
          (this.deltaBlocked <= 2 && this.deltaBlocked >= -2) &&
          (this.deltaPaypal <= 2 && this.deltaPaypal >= -2);
		},
		deltaBlocked() { return (this.jfPercentages['blocked'] - this.percentages['blocked']).toFixed(2) },
		deltaExpired() { return (this.jfPercentages['expired'] - this.percentages['expired']).toFixed(2) },
		deltaPaypal() { return (this.jfPercentages['paypal'] - this.percentages['paypal']).toFixed(2) },
		deltaGeneral() { return (this.jfPercentages['general'] - this.percentages['general']).toFixed(2) },
		deltaTotal() { return (this.jfPercentages['total'] - this.percentages['total']).toFixed(2) }
	},
	methods: {
		toggleAnalytics() {
			this.showAnalytics = !this.showAnalytics;
		},
		getPaymentCutoffErrors() {
			axios.get(`${CONSTANTS.BASE_URL}/api/v1` + '/payment/cutoff', {
				params: {'country': this.country}
			})
				.then(res => {
					if (res.data.msg === 'success') {
						this.errors_cutoff = res.data.errors
						this.jfPercentages = res.data.percentages
						notifications.addNotification('Refreshed')
					}
				})
				.catch((error) => {
					notifications.addNotification(error.response.data, true)
					notifications.addNotification('Problem getting payment errors.', true)
				}
				).finally(x => {this.loading = false})
		},
		reloadPaymentCutoffErrors() {
			this.loading = true;
			axios.post(`${CONSTANTS.BASE_URL}/api/v1` + '/payment/cutoff', {
				'country': this.country
			})
				.then(res => {
					if (res.data.msg === 'success') {
						this.loading = false;
						notifications.addNotification('Reloading. Please refresh page in 1 minute.')
					}
				})
				.catch((error) => {
					notifications.addNotification(error.response.data, true)
					notifications.addNotification('Problem getting payment errors.', true)
				}
				)
		},
		getAnalytics() {
			axios.get(`${CONSTANTS.BASE_URL}/api/v1` + '/payment/cutoff_analytics', {params:{'country': this.country}})
				.then(res => {
					if (res.data.msg === 'success') {
						this.percentages = res.data.percentages
					}
				}).catch(e => notifications.addNotification('Problem getting expected percentages.', true))
		},
		updateAnalytics(){
			if (this.$refs.percentage_form.validate()) {
				axios.post(`${CONSTANTS.BASE_URL}/api/v1` + '/payment/cutoff_analytics',
					{'percentages': this.percentagesNew,
						'country': this.country})
					.then( res => {
						if (res.data.msg === 'success') {
							notifications.addNotification('Percentages updated successfully.')
						}}).catch( e => {
						notifications.addNotification('Error updating percentages.', true)
					})
				this.percentages = this.percentagesNew
				this.togglePercentageEdit()
			}
		},
		togglePercentageEdit() {
			this.percentagesNew = this.percentages
			this.showPercentageEdit = !this.showPercentageEdit
		},
		sendSMS(time) {
			axios.post(`${CONSTANTS.BASE_URL}/api/v1` + '/payment/cutoff_sms', {'time': time, 'country': this.country}).then((res) => {
				if (res.data.msg === 'success') {
					this.jobs.push({id: res.data.job_id});
					this.updateJobs();
					this.showWarning = false;
				} else if (res.data.msg === 'failed') {
					notifications.addNotification('Error: ' + res.data.error, true);
					this.showWarning = false;
				}
			}).catch(e => notifications.addNotification('Error sending SMS. Try again', true))
				.finally(() => {
					this.getPaymentSMSLockData()
				})
		},
		toggleTestDialog(){
			this.showTestDialog = !this.showTestDialog;
		},
		togglePreview(){
			this.showPreview = !this.showPreview;
		},
		getPreview(){
			if (this.$refs.input_form.validate()) {
				this.retrieveTemplate();
				this.togglePreview();
			}
		},
		retrieveTemplate() {
			this.loading = true;
			axios.get(`${CONSTANTS.BASE_URL}/api/v1` + '/customers/text-template/' + this.selectedSMSType, {
				params: {
					'country': this.country
				}
			})
				.then(res => {
					if (res.data.msg === 'success') {
						this.loading = false;
						this.previewLanding = res.data.landing;
						this.previewLanding.body = this.previewLanding.body.replace(/\${first_name}/g, this.testFirstName);
						this.previewText = res.data.sms.replace(/\${first_name}/g, this.testFirstName);
					}
				}).catch(e => notifications.addNotification('Error retrieving preview SMS. Please try again.', true))
		},
		sendTestSMS(){
			axios.post(`${CONSTANTS.BASE_URL}/api/v1` + '/payment/cutoff_test_sms', {
				'recipient': [{
					'customer_id': null,
					'body': 'testing',
					'timestamp': null,
					'sender': this.userProfile.first_name,
					'country': this.country,
					'first_name': this.testFirstName,
					'phone': this.testPhone,
					'status': null,
					'notes': 'cutoff'
				}],
				'campaign': this.previewLanding.id,
				'country': this.country
			})
				.then(res => {
					if (res.data.msg === 'success') {
						notifications.addNotification('Success sending test SMS.')            }
				}).catch(e => notifications.addNotification('Error sending test SMS. Please try again.', true))
			this.togglePreview();
		},
		toggleWarning(time) {
			let hour = this.currentTime.getHours();
			let minute = this.currentTime.getMinutes();
			let date = this.currentTime.toLocaleDateString();
			if ((time == '9am' && hour != 8) || (time == '3pm' && hour != 10) || (minute < 45)) {
				this.showOutsideWindowWarning = true;
			}
			this.showWarning = true;
			this.selectedTime = time;

		},
		getPaymentSMSLockData() {
			axios.get(`${CONSTANTS.BASE_URL}/api/v1` + '/payment/sms_lock', {
				params: {
					country: this.country
				}
			}).then(res => {
				const { am_sms_sent, pm_sms_sent } = res.data;
				this.paymentSMSLockData.amSMSSent = am_sms_sent;
				this.paymentSMSLockData.pmSMSSent = pm_sms_sent;
			}).catch(e => notifications.addNotification('Unable to get Payment SMS Lock data', true))
		},
		exportData(){
			this.progress = true;
			var date_start = this.dates[0];
			var date_end = '';
			if (this.dates[1]) {
				date_end = this.dates[1]
			}
			axios({
				url: `${CONSTANTS.BASE_URL}/api/v1` + '/payment/failed/sent-sms-export',
				method: 'GET',
				responseType: 'blob',
				params: {
					date_start: date_start,
					date_end: date_end,
					country: this.country
				}
			}).then(res => {
				const url = window.URL.createObjectURL(new Blob([res.data]));
				const link = document.createElement('a');
				link.href = url;
				link.setAttribute(
					'download',
					'Payment Error SMS Sent -' +
            this.dates +
            '.csv'
				);
				document.body.appendChild(link);
				link.click();
			}).catch(e => {
				if (e.response.status === 404) {
					notifications.addNotification(
						'No SMS data found for given dates.',
						true
					);
				} else {
					console.log(e)
					notifications.addNotification('Error while exporting data.', true)
				}
			}).finally(this.progress = false)
		},
		uploadFile(file) {
			if (file) {
				let formData = new FormData();
				formData.append('file', file);
				this.uploadInProgress = true;
				console.log(file);
				axios.post(`${CONSTANTS.BASE_URL}/api/v1` + '/boxes/lmcs?country=' + this.country, formData,
					{
						headers: {
							'Content-Type': 'multipart/form-data'
						}
					})
					.then((response) => {
						this.file = [];
						this.jobs.push({id: response.data.job_id});
						this.updateJobs();
						notifications.addNotification(response.data.msg)
						this.uploadInProgress = false;
					})
					.catch((error) => {
						this.file = '';
						this.uploadInProgress = false;
						this.errorMessage = (error.response && error.response.data.error) ? error.response.data.error : 'Error during operation.';
						notifications.addNotification('Oups, there was an error while uploadings...', true);
					});
			}},
		getJobs() {
			axios.get(`${CONSTANTS.BASE_URL}/api/v1` + '/jobs').then((response) => {
				this.jobs = response.data.jobs.map((job_id) => {
					return {id: job_id};
				});
				this.updateJobs();
			});
		},
		updateJobs() {
			this.jobs = this.jobs.reduce((jobs, job) => {
				if (job.interval === undefined) {
					job.interval = setInterval(() => {
						axios.get(`${CONSTANTS.BASE_URL}/api/v1` + '/jobs/' + job.id).then((response) => {
							let progress = response.data.meta.progress;
							job.title = response.data.meta.title;
							if (progress > 0) {
								job.progress = progress;
								job.errors = response.data.meta.errors;
								job.status = response.data.meta.status;
							} else {
								job.progress = 0;
								job.status = 'Waiting for job';
							}

							job.is_failed = response.data.failed;
							if (job.is_failed) {
								job.color = 'error';
								job.title = response.data.error;
								clearInterval(job.interval);
							} else if (job.errors && job.errors.length > 0) {
								job.color = 'warning';
							} else {
								job.color = 'primary';
							}

							if (job.progress >= 100) {
								clearInterval(job.interval);
								job.title = 'Finished';
							}

							this.updateJobs();
						});
					}, 2000);
					this.$set(job, 'progress', -1);
					this.$set(job, 'title', 'Fetching job');
				}
				jobs.push(job);

				return jobs;
			}, []);
		}
	}
}

</script>

<style scoped>
  .upload {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
  }

  .upload-button {
    padding: 1em;
    border-radius: 5px;
    padding: 8px 16px;
  }
  .upload-au {
    background: #669933 !important
  }
  .upload-nz {
    color: #68c1ff !important
  }
  label {
    letter-spacing: normal;
    font-size: 0.9em;
  }

  .jobs-progress {
    width: 100%;
  }

  .job-title {
    margin: auto;
  }

  .lmc-upload > input {
    display:none;
  }
</style>
