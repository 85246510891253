<template>
  <div class="lmcs">
    <v-progress-linear indeterminate v-if="progress" color="secondary"></v-progress-linear>
    <v-container>
      <v-row>
        <v-col cols="7">
          <h1 class="display-3">LMCs</h1>
        </v-col>
        <v-col cols="1" class="mt-3">
          <v-btn color="primary" fab small dark @click="downloadLMCsLogistics">
            <v-icon>cloud_download</v-icon>
          </v-btn>
        </v-col>
        <v-col cols="1" class="mt-3">
                  <v-btn color="primary" fab small dark @click="downloadLMCsCC">
            <v-icon>mdi-face</v-icon>
          </v-btn>
        </v-col>
        <v-col cols="3" class="mt-4">
          <v-select
            :items="weeks"
            v-model="currentWeek"
            label="Week"
            solo
          ></v-select>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="4">
          <v-text-field
            v-model="search"
            prepend-icon="search"
            label="Search"
            color="white"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-data-table
            :search="search"
            :loading="progress"
            no-data-text="No data for this week"
            :loading-text="'Loading LMCs'"
            :headers="headers"
            :items="lmcs"
            :items-per-page="20"
            :no-data-text="'No data found'"
          >
            <template
              slot="item"
              slot-scope="props"
            >
              <tr>
                <td>
                  <v-checkbox disabled v-model="props.item.compensation_applied"></v-checkbox>
                </td>
                <td>
                  <a :href="getBoxLink(props.item.box_id)" style="text-decoration: none;">
                    {{props.item.box_id}}
                  </a>
                </td>
                <td>
                  {{props.item.agent}}
                </td>
                <td>
                  {{ reasons.find((obj) => { return obj.id === props.item.reason_id; }).name }}
                </td>

                <td>
                  ${{ props.item.compensation_sum }} {{ compensations.find((obj) => { return obj.id ===
                  props.item.compensation_type_id; }).name }}
                </td>
                <td>
                  {{props.item.comment}}
                </td>
                <td>
                  <v-btn color="primary" :disabled="!props.item.lmc_auth" @click="openLMC(props.item)" class="px-4">
                    Update LMC
                  </v-btn>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-container>

    <v-dialog v-model="create_lmc" max-width="500px">
      <v-card>
        <v-card-title class="secondary">
          <span class="headline">Update a LMC</span>
        </v-card-title>
        <v-card-text>
          <v-form ref="lmc_form" lazy-validation>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-select
                    :items="reasons"
                    item-value="id"
                    item-text="name"
                    v-model="new_lmc.reason_id"
                    label="Reason"
                    :rules="[v => !!v || 'A reason is required']"
                    required
                  ></v-select>
                </v-col>
                <v-col cols="8">
                  <v-select
                    :items="compensations"
                    item-value="id"
                    item-text="name"
                    v-model="new_lmc.compensation_type_id"
                    label="Compensation type"
                    :rules="[v => !!v || 'A compensation is required']"
                    required
                  ></v-select>
                </v-col>
                <v-col cols="4">
                  <v-text-field prefix="$" label="Amount" v-model="new_lmc.compensation_sum" required
                                :rules="[v => !!v || 'An amount is required']"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-switch required label="Compensation applied" v-model="new_lmc.compensation_applied"></v-switch>
                </v-col>
                <v-col cols="12">
                  <v-textarea label="Comment" v-model="new_lmc.comment"></v-textarea>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn color="error" text @click.native="deleteLMC">Delete</v-btn>
          <v-spacer></v-spacer>
          <v-btn text @click.native="create_lmc = false">Close</v-btn>
          <v-btn color="primary" @click.native="updateLMC">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </div>
</template>

<script>
import axios from 'axios';
import auth from '../modules/auth';
import notifications from '@/modules/notifications';
import {CONSTANTS} from '@/modules/constants';

export default {
	name: 'LMCS',
	data() {
		return {
			preferences: auth.user.preferences,
			progress: false,
			search: '',
			lmcs: [],
			create_lmc: false,
			reasons: [],
			compensations: [],
			new_lmc: {
				compensation_applied: false,
				comment: '',
				box_id: this.$route.params.id,
				country: auth.user.preferences.country
			},
			lmc_auth: false,
			headers: [
				{text: 'Compensation Applied', value: '', align: 'left', sortable: false},
				{text: 'Box', value: 'box_id', align: 'center', sortable: true},
				{text: 'Issued by', value: 'agent', align: 'center', sortable: true},
				{text: 'Reason', value: 'reason_id', align: 'center', sortable: true},
				{text: 'Compensation $', value: 'compensation_sum', align: 'center', sortable: false},
				{text: 'Comment', value: 'comment', align: 'center', sortable: false},
				{text: 'Action', value: '', align: 'right', sortable: false}
			]
		}
	},
	watch: {
		currentWeek: {
			handler: function() {
				this.refreshLMCs();
			},
			immediate: true
		}
	},
	mounted() {
		this.getReasons();
		this.getCompensations();
	},
	computed:{
		currentWeek: {
			set(week) {
				this.$store.dispatch('updateWeek', week)
			},
			get() {
				return this.$store.state.week
			}
		},
		weeks() {
			return this.$store.state.weeks
		}
	},
	methods: {
		getReasons() {
			axios.get(`${CONSTANTS.BASE_URL}/api/v1` + '/boxes/lmcs/reasons').then((response) => {
				if (response.data.msg === 'success') {
					this.reasons = response.data.reasons;
				}
			});
		},
		getCompensations() {
			axios.get(`${CONSTANTS.BASE_URL}/api/v1` + '/boxes/lmcs/compensations').then((response) => {
				if (response.data.msg === 'success') {
					this.compensations = response.data.compensations;
				}
			});
		},
		refreshLMCs() {
			this.progress = true;
			axios.get(`${CONSTANTS.BASE_URL}/api/v1` + '/boxes/lmcs', {
				params: {
					week: this.currentWeek,
					country: this.preferences.country
				}
			}).then((response) => {
				if (response.data.msg === 'success') {
					this.lmcs = response.data.lmcs;
				}
				this.progress = false;
			});
		},
		openLMC(lmc) {
			this.new_lmc = lmc;
			this.create_lmc = true;
		},
		updateLMC() {
			if (this.$refs.lmc_form.validate()) {
				this.create_lmc = false;
				this.progress = true;
				axios.post(`${CONSTANTS.BASE_URL}/api/v1` + '/boxes/lmcs', this.new_lmc).then((response) => {
					if (response.data.msg === 'success') {
						notifications.addNotification('LMC inserted successfully.');
					}
					this.progress = false;
				});
			}
		},
		deleteLMC() {
			this.create_lmc = false;
			this.progress = true;
			axios.delete(`${CONSTANTS.BASE_URL}/api/v1` + '/boxes/lmcs/' + this.box_id).then((response) => {
				if (response.data.msg === 'success') {
					notifications.addNotification('LMC deleted successfully.');
				}
				this.progress = false;
				this.refreshLMCs();
			});
		},
		getBoxLink(box_id) {
			return '#/box/' + box_id;
		},
		downloadLMCsCC(){
			axios({
				url: `${CONSTANTS.BASE_URL}/api/v1` + '/boxes/lmcs/download_cc',
				method: 'GET',
				responseType: 'blob',
				params: {week: this.currentWeek, country: this.preferences.country}
			}).then((response) => {
				const url = window.URL.createObjectURL(new Blob([response.data]));
				const link = document.createElement('a');
				link.href = url;
				link.setAttribute('download', 'LMCs - ' + this.currentWeek + '.csv');
				document.body.appendChild(link);
				link.click();
			});
		},
		downloadLMCsLogistics() {
			axios({
				url: `${CONSTANTS.BASE_URL}/api/v1` + '/boxes/lmcs/download_logistics',
				method: 'GET',
				responseType: 'blob',
				params: {week: this.currentWeek, country: this.preferences.country}
			}).then((response) => {
				const url = window.URL.createObjectURL(new Blob([response.data]));
				const link = document.createElement('a');
				link.href = url;
				link.setAttribute('download', 'LMCs - ' + this.currentWeek + '.csv');
				document.body.appendChild(link);
				link.click();
			});
		}
	}
}
</script>

<style scoped>

</style>
