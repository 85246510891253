<!--not sure if this componenet is used-->
<template>
  <div class="send">
    <v-progress-linear indeterminate v-if="progress" color="secondary" class="progress"></v-progress-linear>
    <v-container grid-list-sm>
      <v-row wrap align-center>
        <v-col cols="12">
          <v-btn icon to="/wrong_addresses">
            <v-icon>arrow_back</v-icon>
          </v-btn>
        </v-col>
        <v-col cols="8" v-if="type === 'courier'">
          <h1 class="display-3">Couriers notification</h1>
        </v-col>
        <v-col cols="8" v-if="type === 'customer_email'">
          <h1 class="display-3">Customers email notification</h1>
        </v-col>
        <v-col cols="8" v-if="type === 'customer_text'">
          <h1 class="display-3">Customers text notification</h1>
        </v-col>
        <v-col cols="4">
          <v-btn block color="primary" @click="send" :disabled="selected.length === 0" :loading="send_progress">
            <v-icon left>send</v-icon>
            Confirm sending
          </v-btn>
        </v-col>
        <v-col cols="12" class="mt-4" v-if="job !== null">
          <v-card>
            <v-card-text>
              <v-row>
                <v-col cols="2" class="ma-auto">
                  {{ job.title }}
                </v-col>
                <v-col cols="10">
                  <v-progress-linear color="primary" v-model="job.progress"
                                     :indeterminate="job.progress === 0"></v-progress-linear>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="6" class="mt-4" v-if="type === 'customer_text'">
          <v-select :items="methods" v-model="method" label="Sending method"></v-select>
        </v-col>
        <v-col cols="6" class="mt-4" v-if="type === 'customer_text'">
          <v-select :items="schedules" v-model="schedule" label="Sending schedule"></v-select>
        </v-col>
        <v-col cols="12" class="mt-4" v-if="type === 'customer_text'">
          <v-card>
            <v-card-title>
              <h3 class="headline">Number of customers in each batch</h3>
              <v-spacer></v-spacer>
              <h3 class="headline">{{ number_of_batches }}</h3>
            </v-card-title>
          </v-card>
        </v-col>
        <v-col cols="12" class="mt-4" v-if="type === 'courier'">
          <v-card>
            <v-card-title>
              <h3 class="headline">Number of emails that will be sent</h3>
              <v-spacer></v-spacer>
              <h3 class="headline">{{ number_of_email }}</h3>
            </v-card-title>
          </v-card>
        </v-col>
        <v-col cols="12" class="mt-4" v-if="type === 'courier'">
          <v-data-table :headers="headers_courier" :items="wrong_addresses" v-model="selected"
                        item-key="name" show-select class="elevation-1" :no-data-text="'No data found'" hide-actions>
            <template slot="headerCell" slot-scope="props">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                <span v-on="on" class="white--text">
                  {{ props.header.text }}
                </span></template>

                <span>
                  {{ props.header.text }}
                </span>
              </v-tooltip>
            </template>
            <template slot="items" slot-scope="props">
              <td>
                <v-checkbox v-model="props.selected" primary hide-details></v-checkbox>
              </td>
              <td>{{ props.item.box }}</td>
              <td class="text--right">{{ props.item.name }}</td>
              <td class="text--right">{{ props.item.delivery_date }}</td>
              <td class="text--right">{{ props.item.old_address }}</td>
              <td class="text--right">{{ props.item.new_address.new_street }}</td>
              <td class="text--right">{{ props.item.new_address.new_city }}</td>
              <td class="text--right">{{ props.item.new_address.new_postcode }}</td>
              <td class="text--right">{{ props.item.courier }}</td>
            </template>
          </v-data-table>
        </v-col>
        <v-col cols="12" class="mt-4" v-if="type === 'customer_email'">
          <v-data-table :headers="headers_customer_email" :items="wrong_addresses" :no-data-text="'No data found'"
                        v-model="selected" item-key="name" show-select class="elevation-1" hide-actions>
            <template slot="items" slot-scope="props">
              <td>
                <v-checkbox v-model="props.selected" primary hide-details></v-checkbox>
              </td>
              <td>{{ props.item.box }}</td>
              <td class="text--right">{{ props.item.name }}</td>
              <td class="text--right">{{ props.item.email }}</td>
              <td class="text--right">{{ props.item.delivery_date }}</td>
              <td class="text--right">{{ props.item.old_address }}</td>
            </template>
          </v-data-table>
        </v-col>
        <v-col cols="12" class="mt-4" v-if="type === 'customer_text'">
          <v-data-table :headers="headers_customer_text" :items="wrong_addresses" v-model="selected"
                        item-key="name" show-select class="elevation-1" :no-data-text="'No data found'" hide-actions>
            <template slot="headerCell" slot-scope="props">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                <span v-on="on" class="white--text">
                  {{ props.header.text }}
                </span>
                  </template>
                <span>
                  {{ props.header.text }}
                </span>
              </v-tooltip>
            </template>
            <template slot="items" slot-scope="props">
              <td>
                <v-checkbox v-model="props.selected" primary hide-details></v-checkbox>
              </td>
              <td>{{ props.item.box }}</td>
              <td class="text--right">{{ props.item.name }}</td>
              <td class="text--right">{{ props.item.phone }}</td>
              <td class="text--right">{{ props.item.delivery_date }}</td>
              <td class="text--right">{{ props.item.old_address }}</td>
              <td class="text--right">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                  <v-icon v-on="on" color="primary" dark>textsms</v-icon></template>
                  <span v-html="props.item.template"></span>
                </v-tooltip>
              </td>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-container>

  </div>
</template>

<script>
import axios from 'axios';
import auth from '../modules/auth';
import {CONSTANTS} from '@/modules/constants';

export default {
	name: 'WrongAddressesSend',
	data() {
		return {
			preferences: auth.user.preferences,
			type: this.$route.params.type,
			progress: false,
			send_progress: false,
			selected: [],
			headers_courier: [
				{text: 'Box', value: 'box'},
				{text: 'Customer name', value: 'name'},
				{text: 'Delivery date', value: 'delivery_date'},
				{text: 'Old address', value: 'old_address'},
				{text: 'New street', value: 'new_address.new_street'},
				{text: 'New city', value: 'new_address.new_city'},
				{text: 'New postcode', value: 'new_address.new_postcode'},
				{text: 'Courier', value: 'courier'}
			],
			headers_customer_email: [
				{text: 'Box', value: 'box'},
				{text: 'Customer name', value: 'name'},
				{text: 'Customer email', value: 'email'},
				{text: 'Delivery date', value: 'delivery_date'},
				{text: 'Old address', value: 'old_address'}
			],
			headers_customer_text: [
				{text: 'Box', value: 'box'},
				{text: 'Customer name', value: 'name'},
				{text: 'Customer phone', value: 'phone'},
				{text: 'Delivery date', value: 'delivery_date'},
				{text: 'Old address', value: 'old_address'},
				{text: 'Text', value: 'text'}
			],
			wrong_addresses: [],
			job: null,
			methods: [
				{text: 'Send all in once', value: 'all'},
				{text: 'Divide in 2 batches of customers', value: '2_batch'},
				{text: 'Divide in 3 batches of customers', value: '3_batch'},
				{text: 'Divide in 4 batches of customers', value: '4_batch'},
				{text: 'Divide in 5 batches of customers', value: '5_batch'},
				{text: 'Divide in 6 batches of customers', value: '6_batch'},
				{text: 'Divide in 7 batches of customers', value: '7_batch'},
				{text: 'Divide in 8 batches of customers', value: '8_batch'},
				{text: 'Divide in 9 batches of customers', value: '9_batch'},
				{text: 'Divide in 10 batches of customers', value: '10_batch'},
				{text: 'Divide in 15 batches of customers', value: '15_batch'},
				{text: 'Divide in 24 batches of customers', value: '24_batch'},
				{text: 'Divide in 48 batches of customers', value: '48_batch'},
				{text: 'Divide in 72 batches of customers', value: '72_batch'}
			],
			method: 'all',
			schedules: [
				{text: 'Send first now and then every 5 minutes', value: '5m'},
				{text: 'Send first now and then every 10 minutes', value: '10m'},
				{text: 'Send first now and then every 15 minutes', value: '15m'},
				{text: 'Send first now and then every 20 minutes', value: '20m'},
				{text: 'Send first now and then every 30 minutes', value: '30m'},
				{text: 'Send first now and then every 45 minutes', value: '45m'},
				{text: 'Send first now and then every hour', value: '1h'}
			],
			schedule: '5m'
		}
	},
	computed: {
		number_of_email: function () {
			let couriers = this.selected.reduce((couriers, box) => {
				if (couriers.indexOf(box.courier) === -1) {
					couriers.push(box.courier);
				}
				return couriers;
			}, []);
			return couriers.length;
		},
		number_of_batches: function () {
			let number_of_texts = this.selected.length;
			if (this.method === 'all') {
				return number_of_texts;
			} else {
				let divide_number = parseInt(this.method.split('_')[0]);
				return Math.ceil(number_of_texts / divide_number);
			}
		}
	},
	mounted() {
		this.getWrongAddresses();
		this.setTableHeaderColor();
	},
	methods: {
		getWrongAddresses: function () {
			this.progress = true;
			axios.get(`${CONSTANTS.BASE_URL}/api/v1` + '/boxes/wrong_addresses/send', {
				params: {
					week: this.$route.params.week,
					type: this.type,
					country: this.preferences.country
				}
			}).then((response) => {
				if (response.data.msg === 'success') {
					this.wrong_addresses = response.data.wrong_addresses;
				}
				this.progress = false;
			});
		},
		send: function () {
			let boxes = this.selected.reduce((boxes, box) => {
				boxes.push(box.box);
				return boxes;
			}, []);

			let data = {
				week: this.$route.params.week,
				type: this.type,
				boxes: boxes,
				method: this.method,
				schedule: this.schedule,
				country: this.preferences.country
			};

			axios.post(`${CONSTANTS.BASE_URL}/api/v1` + '/boxes/wrong_addresses/send', data).then((response) => {
				if (response.data.msg === 'success') {
					this.job = {};
					this.job.progress = 0;
					this.job.title = 'Waiting for job';
					this.send_progress = true;
					this.job.interval = setInterval(() => {
						this.get_job(response.data.job);
					}, 2000);
				}
			});
		},
		get_job: function (job_id) {
			axios.get(`${CONSTANTS.BASE_URL}/api/v1` + '/jobs/' + job_id).then((response) => {
				let progress = response.data.meta.progress;
				if (progress > 0) {
					this.job.progress = progress;
					this.job.title = response.data.meta.title;
				} else {
					this.job.progress = 0;
					this.job.title = 'Waiting for job';
				}

				if (this.job.progress >= 100) {
					clearInterval(this.job.interval);
					this.send_progress = false;
					this.job.title = 'Finished';
				}

				this.getWrongAddresses();
			});
		},
		setTableHeaderColor: function () {
			let d = document.getElementsByTagName('thead')[0];
			d.className = this.preferences.country === 'au' ?  'thead-au' : 'thead-nz';
		}
	}
}
</script>

<style>
  .thead-au {
    background-color: #8bcd57;
  }

  .thead-nz {
    background-color: #c180ff;
  }
</style>
