let store = {
	notifications: []
};

let initCustomAction = {
	text: '',
	active: false,
	action: null
};

function addNotification(text, error = false, multiLine = false, customAction = initCustomAction) {
	let id = Math.random().toString(36).substr(2, 9);
	let style = {
		bottom: 0,
		transition: 'bottom 0.4s ease-out',
		'-ms-transition': 'bottom 0.4s ease-out',
		'-moz-transition': 'bottom 0.4s ease-out',
		'-webkit-transition': 'bottom 0.4s ease-out',
		'-o-transition': 'bottom 0.4s ease-out'
	};
	store.notifications.forEach((notification) => {
		style.bottom += notification.multiLine ? 90 : 60;
	});
	style.bottom = style.bottom + 'px';
	let notification = {id: id, text: text, style: style, error: error, customAction: customAction, multiLine: multiLine};
	store.notifications.splice(0, 0, notification);
	setTimeout(() => removeNotification(notification), 7000);
}

function removeNotification(originalNotification) {
	let afterRemovedNotification = false;
	store.notifications.forEach((notification) => {
		if (!afterRemovedNotification) {
			let bottom = (notification.style.bottom.split('px')[0]) - (originalNotification.multiLine ? 90 : 60);
			notification.style.bottom = bottom + 'px';
		}
		if (notification.id === originalNotification.id) {
			afterRemovedNotification = true;
		}
	});
	store.notifications = store.notifications.filter((notification) => {
		return notification.id !== originalNotification.id;
	});
}

export default {
	addNotification,
	removeNotification,
	store
}
