<template>
  <div>
    <v-container>
      <h1>
        404 Not Found
      </h1>
      <h3>
        Please contact admin abie.joseph@hellofresh.con.au
      </h3>
    </v-container>
  </div>
</template>

<script>
export default {
	name: 'DeprecateView'
}
</script>

<style scoped>

</style>
