<template>
  <dashboard-summary
    title="Food Safety Dashboard Summary"
    :filters="dashboardFilters"
    :data-entries="dashboardData"
    :loading="loadingFoodSafetySummary"
    @week-select="val => selectedWeek = val"
    @food-safety-type-select="val => selectedFoodSafetyType = val"
  />
</template>

<script>
import axios from 'axios'
import store from '@/store/store'
import notifications from '@/modules/notifications'
import DashboardSummary from '@/components/util/DashboardSummary';
import {CONSTANTS} from '@/modules/constants';

export default {
	name: 'FoodSafetySummary',
	components: { DashboardSummary },
	data() {
		return {
			loadingExport: false,
			selectedWeek: '',
			loadingFoodSafetySummary: false,
			foodSafetySummary: {},
			selectedFoodSafetyType: [],
			foodSafetyTypes: []
		}
	},
	computed: {
		weeks() {
			return [...this.$store.state.weeks]
		},
		openIssues(){
			return this.selectedFoodSafetyType.map(x=> this.foodSafetySummary[x]['open'] || 0).reduce((a,b)=> a+b, 0)
		},
		closedIssues(){
			return this.selectedFoodSafetyType.map(x=> this.foodSafetySummary[x]['closed'] || 0).reduce((a,b)=> a+b, 0)
		},
		totalIssues(){
			return this.selectedFoodSafetyType.map(x=> this.foodSafetySummary[x]['total'] || 0).reduce((a,b)=> a+b, 0)
		},
		dashboardFilters() {
			return [
				{label: 'Select week', items: this.weeks, model: this.selectedWeek, event: 'week-select'},
				{label: 'Select complaint category', items: this.foodSafetyTypes, model: this.selectedFoodSafetyType, event: 'food-safety-type-select', multiple: true}
			]
		},
		dashboardData(){
		  return [
				{label: 'Total issues', value: this.totalIssues},
				{label: 'Open issues', value: this.openIssues},
				{label: 'Closed issues', value: this.closedIssues}
			]
		}
	},
	watch: {
		selectedWeek: {
			handler: function () {
				this.getFoodSafetySummary()
			}
		}
	},
	methods: {
		getFoodSafetySummary() {
			this.loadingFoodSafetySummary = true
			axios
				.get(`${CONSTANTS.BASE_URL}/api/v1/food_safety/summary`, {
					params: {
						country: store.state.country,
						week: this.selectedWeek
					}
				})
				.then((response) => {
					this.foodSafetySummary = response.data.summary
					this.foodSafetyTypes = Object.keys(this.foodSafetySummary).sort()
				})
				.catch((error) => {
					notifications.addNotification('Error: Could not get food safety summary information', true)
				}).finally(()=> {
					this.loadingFoodSafetySummary = false
				})
		}
	}
}
</script>
