<template>
  <div>
    <v-container>
      <v-row>
        <v-col cols="12">
          <h1 class="display-3">Voucher Code Lookup</h1>
        </v-col>
        <v-col cols="12">
          <h1 class="display-1 ma-3">Backup Vouchers</h1>
          <span class="headline"> </span>
          <v-card>
            <v-card-title class="headline"
              >Select the voucher settings you require</v-card-title
            >
            <v-card-text>
              <v-form ref="voucherLookupForm">
                <v-container grid-list-sm>
                  <v-row>
                    <v-col xs="12" sm="6" md="3">
                      <v-checkbox
                        v-model="is_new_customer"
                        label="Is this a new customer?"
                        hide-details
                        class="shrink mr-2"
                        color="secondary"
                      ></v-checkbox>
                      <v-text-field
                        v-if="is_new_customer"
                        v-model="customer_email"
                        label="New Customer Email / ID"
                        :rules="[
                          (v) =>
                            v.length >= 4 ||
                            'A customer email or ID is required',
                        ]"
                      ></v-text-field>
                      <v-text-field
                        v-else
                        v-model="customer_id"
                        label="Customer ID"
                        :rules="[
                          (v) => !isNaN(v) || 'A customer ID is required',
                          (v) => v.length >= 4 || 'A customer ID is required',
                        ]"
                      ></v-text-field>
                      <v-btn
                        class="mt-2"
                        v-if="!is_new_customer"
                        color="primary"
                        @click="getCustomerLoyalty"
                        >Check customer loyalty</v-btn
                      >
                    </v-col>
                    <v-col
                      v-if="userProfile.team_leader || userProfile.admin"
                      xs="12"
                      sm="6"
                      md="3"
                    >
                      <v-checkbox
                        v-model="show_leadership_codes"
                        label="Is this a leadership code"
                        @change="refreshDropdowns"
                      ></v-checkbox>
                      <v-progress-circular
                        indeterminate
                        v-if="loadingCustomerLoyalty"
                      ></v-progress-circular>
                      <h3 v-if="customerLoyalty !== null && !is_new_customer">
                        Customer Loyalty: {{ customerLoyalty }}
                      </h3>
                      <h3 v-if="customerLoyaltyError && !is_new_customer">
                        {{ customerLoyaltyError }}
                      </h3>
                    </v-col>
                  </v-row>
                  <v-row wrap>
                    <v-col v-if="show_leadership_codes" xs="12" sm="6" md="3">
                      <v-autocomplete
                        :items="agent_names"
                        label="Agent requesting code"
                        v-model="requesting_agent_name"
                        :rules="[
                          (v) => v.length > 0 || 'Please type an agent name',
                        ]"
                      ></v-autocomplete>
                    </v-col>
                    <v-col xs="12" sm="6" md="3">
                      <v-select
                        :items="backup_voucher_reasons"
                        label="Reason*"
                        v-model="backup_voucher_reason"
                        :rules="[
                          (v) =>
                            v.length > 0 ||
                            'Please select a reason for supplying this voucher',
                        ]"
                        @change="getBackUpVoucherReasonDetails"
                      ></v-select>
                    </v-col>
                    <v-col
                      v-if="backup_voucher_reason_details.length != 0"
                      xs="12"
                      sm="6"
                      md="3"
                    >
                      <v-select
                        :items="backup_voucher_reason_details"
                        label="Reason Detail*"
                        v-model="backup_voucher_reason_detail"
                        :rules="[
                          (v) =>
                            v.length > 0 ||
                            'Please select a reason for supplying this voucher',
                        ]"
                      ></v-select>
                    </v-col>
                  </v-row>
                  <v-row wrap>
                    <v-col xs="12" sm="6" md="3">
                      <v-select
                        :items="backup_voucher_types"
                        label="Voucher Type* (select first)"
                        v-model="backup_voucher_type"
                        :rules="[
                          (v) => v.length > 0 || 'Please select a voucher type',
                        ]"
                        @change="getBackupVoucherDetails"
                      ></v-select>
                    </v-col>
                    <v-col xs="12" sm="6" md="3">
                      <v-select
                        v-model="backup_voucher_detail"
                        :items="filteredBackupVoucherDetails"
                        :rules="[
                          (v) =>
                            v.length > 0 || 'Please select a voucher detail',
                        ]"
                        label="Voucher Detail*"
                      ></v-select>
                    </v-col>
                    <v-col cols="12" sm="6" md="3">
                      <v-text-field
                        v-if="oldCodePolicy == 'optional'"
                        v-model="old_code"
                        label="Old Code"
                      ></v-text-field>
                      <v-text-field
                        v-if="oldCodePolicy == 'compulsory'"
                        v-model="old_code"
                        label="Old Code*"
                        :rules="[(v) => v.length >= 1 || 'Old Code needed']"
                      ></v-text-field>
                      <v-text-field
                        v-if="
                          backup_voucher_reason_detail ===
                          'Other - please specify'
                        "
                        v-model="otherReason"
                        label="Other Reason*"
                        :rules="[(v) => v.length >= 1 || 'Reason needed']"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="3">
                      <v-btn color="primary"
                             :loading="loadingGenerateBackUpVoucher"
                             :disabled="loadingGenerateBackUpVoucher"
                             @click="getBackupVoucher"
                        >Get Backup Voucher Code
                      </v-btn>
                    </v-col>
                    <v-col cols="12" sm="6" md="3">
                      <v-card-text
                        v-if="backup_voucher_code !== ''"
                        class="headline"
                      >
                        {{ backup_voucher_code }}
                      </v-card-text>
                    </v-col>
                    <v-col cols="12">
                      <div
                        v-if="backup_voucher_code !== ''"
                        class="pl-3"
                        cols="3"
                      >
                        <span class="headline">{{
                          backup_voucher_message
                        }}</span>
                      </div>
                    </v-col>
                  </v-row>
                </v-container>
              </v-form>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import axios from 'axios';
import auth from '../../modules/auth';
import store from '@/store/store';
import notifications from '@/modules/notifications';
import {CONSTANTS} from '@/modules/constants';

export default {
	name: 'VoucherLookup',
	props: {
		customer_email: { type: String },
		lookup_customer_emails: { type: String },
		customer_id: { type: Number }
	},
	data() {
		return {
			customer_id: this.customer_id,
			preferences: auth.user.preferences,
			userProfile: auth.user.profile,
			lookup_codes: '',
			// lookup_customer_emails: '',
			codes: [],
			customer_emails: [],
			loadingGenerateBackUpVoucher: false,
			backup_voucher_types: [],
			backup_voucher_type: '',
			backup_voucher_details: [],
			backup_voucher_detail: '',
			backup_voucher_reasons: [
				'Logistics issue',
				'Quality issue',
				'Pick and pack issue',
				'Tech issue',
				'Employee error',
				'Customer error',
				'Incorrect voucher fraud',
				'Seniors discount'
			],
			backup_voucher_reason: '',
			backup_voucher_reason_detail: '',
			backup_voucher_reason_details: [],
			backup_voucher_code: '',
			backup_voucher_message: '',
			old_code: '',
			old_code_rules: {},
			// customer_id: '',
			// customer_email: '',
			otherReason: '',
			show_leadership_codes: false,
			requesting_agent_name: '',
			agent_names: [],
			users: [],
			is_new_customer: false,
			customerLoyalty: null,
			customerLoyaltyError: '',
			loadingCustomerLoyalty: false
		};
	},
	watch: {
		backup_voucher_reason: function () {
			this.backup_voucher_reason_detail = '';
		}
	},
	computed: {
		...mapGetters(['ccAccessLevel']),
		filteredBackupVoucherDetails: function () {
			// nz no longer has 100% vouchers for Refer a friend
			if (
				this.preferences.country === 'nz' &&
        this.backup_voucher_type === 'Refer A Friend'
			) {
				return this.backup_voucher_details.filter((voucher) => {
					const regex = /^100%.*/;
					return !regex.test(voucher);
				});
			}

			return this.backup_voucher_details;
		},
		requesting_agent_id: function () {
			if (this.requesting_agent_name.length > 0) {
				let i = this.agent_names.indexOf(this.requesting_agent_name);
				return this.users[i]['id'];
			} else {
				return '';
			}
		},
		isOldCodeDisplay: function () {
			if (this.backup_voucher_reason_detail) {
				return (
					this.old_code_rules[this.backup_voucher_reason_detail] ==
            'compulsory' ||
          this.old_code_rules[this.backup_voucher_reason_detail] == 'optional'
				);
			} else {
				return false;
			}
		},
		isOldCodeCompulsory: function () {
			if (this.backup_voucher_reason_detail) {
				return (
					this.old_code_rules[this.backup_voucher_reason_detail] == 'compulsory'
				);
			} else {
				return false;
			}
		},
		oldCodePolicy: function () {
			return this.old_code_rules[this.backup_voucher_reason_detail];
		}
	},
	mounted() {
		this.getBackupVoucherTypes();
		this.getAgentNames();
	},
	methods: {
		getCustomerEmails: function () {
			if (this.$refs.emailForm.validate()) {
				this.progress = true;
				axios
					.get(`${CONSTANTS.BASE_URL}/api/v1` + '/vouchers/customer_lookup', {
						params: {
							country: this.preferences.country,
							codes: this.lookup_codes
						}
					})
					.then((response) => {
						if (response.data.msg === 'success') {
							this.customer_emails = response.data.customer_emails;
							if (this.customer_emails.length == 0) {
								notifications.addNotification(
									'No email found for this voucher code',
									true
								);
							}
							this.progress = false;
						}
					})
					.catch(() => {
						this.progress = false;
						notifications.addNotification(
							'Oops, something went wrong looking up this email.',
							true
						);
					});
			}
		},
		getVouchers: function () {
			if (this.$refs.voucherForm.validate()) {
				this.progress = true;
				axios
					.get(`${CONSTANTS.BASE_URL}/api/v1` + '/vouchers/voucher_lookup', {
						params: {
							country: this.preferences.country,
							customer_emails: this.lookup_customer_emails
						}
					})
					.then((response) => {
						if (response.data.msg === 'success') {
							this.codes = response.data.codes;
							this.progress = false;
							if (this.codes.length == 0) {
								notifications.addNotification(
									'No voucher found for this email',
									true
								);
							}
						}
					})
					.catch(() => {
						this.progress = false;
						notifications.addNotification(
							'Oops, something went wrong looking up this voucher.',
							true
						);
					});
			}
		},
		getBackupVoucherTypes: function () {
			this.progress = true;
			axios
				.get(`${CONSTANTS.BASE_URL}/api/v1` + '/vouchers/backup_types', {
					params: {
						country: this.preferences.country,
						is_leadership_code: this.show_leadership_codes
					}
				})
				.then((response) => {
					if (response.data.msg === 'success') {
						this.backup_voucher_types = response.data.backup_voucher_types
							.filter((voucherType) => voucherType[this.ccAccessLevel])
							.map((voucherType) => voucherType.type);
					}
				});
		},
		getBackupVoucherDetails: function () {
			this.progress = true;
			axios
				.get(`${CONSTANTS.BASE_URL}/api/v1` + '/vouchers/backup_details', {
					params: {
						country: this.preferences.country,
						backup_voucher_type: this.backup_voucher_type,
						leadership_codes: this.show_leadership_codes
					}
				})
				.then((response) => {
					if (response.data.msg === 'success') {
						this.backup_voucher_details = response.data.backup_voucher_details;
					}
				});
		},
		getBackupVoucher: function () {
			if (this.$refs.voucherLookupForm.validate()) {
				this.progress = true;
				const otherReasonGiven =
          this.backup_voucher_reason_detail === 'Other - please specify';

				this.loadingGenerateBackUpVoucher = true
				axios
					.get(`${CONSTANTS.BASE_URL}/api/v1` + '/vouchers/backup_code', {
						params: {
							country: this.preferences.country,
							backup_voucher_type: this.backup_voucher_type,
							backup_voucher_detail: this.backup_voucher_detail,
							agent_id: this.userProfile.id,
							customer_id: this.customer_id,
							customer_email: this.customer_email,
							leadership_codes: this.show_leadership_codes,
							requesting_agent_id: this.requesting_agent_id,
							backup_voucher_reason: this.backup_voucher_reason,
							backup_voucher_reason_detail: otherReasonGiven
								? this.otherReason
								: this.backup_voucher_reason_detail,
							old_code: this.old_code,
							is_new_customer: this.is_new_customer
						}
					})
					.then((response) => {
						if (response.data.msg === 'success') {
							this.backup_voucher_code = response.data.backup_voucher_code;
							this.backup_voucher_message =
                response.data.backup_voucher_message;
						}
					})
					.catch(() => {
						this.progress = false;
						notifications.addNotification(
							'Oops, something went wrong getting this backup voucher.',
							true
						);
					})
					.finally(() => {
						this.loadingGenerateBackUpVoucher = false;
					});
			}
		},
		getBackUpVoucherReasonDetails: function () {
			this.progress = true;
			axios
				.get(`${CONSTANTS.BASE_URL}/api/v1` + '/vouchers/backup-reason-details', {
					params: {
						backup_voucher_reason: this.backup_voucher_reason
					}
				})
				.then((response) => {
					if (response.data.msg === 'success') {
						this.backup_voucher_reason_details =
              response.data.backup_voucher_reason_details;
						this.old_code_rules = response.data.old_code_rules;
						this.progress = false;
					}
				});
		},
		getCustomerLoyalty: function () {
			if (this.customer_id) {
				this.customerLoyalty = null;
				this.customerLoyaltyError = null;
				this.loadingCustomerLoyalty = true;

				axios
					.get(`${CONSTANTS.BASE_URL}/api/v1/customers/loyalty`, {
						params: {
							bob_id: this.customer_id,
							country: store.state.country
						}
					})
					.then((response) => {
						this.customerLoyalty = response.data.customer_loyalty;
						this.loadingCustomerLoyalty = false;
					})
					.catch((error) => {
						if (error.response.status === 404) {
							this.customerLoyaltyError = 'Customer not found in database';
						}
						notifications.addNotification(
							'Error: There was an error retrieving customer loyalty',
							true
						);
						this.loadingCustomerLoyalty = false;
					});
			}
		},
		getAgentNames: function () {
			if (this.userProfile.team_leader) {
				this.progress = true;
				axios
					.get(`${CONSTANTS.BASE_URL}/api/v1` + '/users/team_leader_access')
					.then((response) => {
						if (response.data.msg === 'success') {
							this.users = response.data.users;
							for (let i = 0; i < response.data.users.length; i++) {
								this.agent_names.push(
									response.data.users[i]['first_name'] +
                    ' ' +
                    response.data.users[i]['last_name']
								);
							}
						}
					});
			}
		},
		refreshDropdowns: function () {
			this.backup_voucher_detail = null;
			this.backup_voucher_type = null;
			this.backup_voucher_types = [];
			this.backup_voucher_details = [];
			this.getBackupVoucherTypes();
			this.getBackupVoucherDetails();
		}
	}
};
</script>
