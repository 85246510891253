<template>
  <div class="upload-page">
    <v-progress-linear
      v-if="uploadInProgress"
      indeterminate
      class="progress"
    ></v-progress-linear>
    <v-container>
      <v-row>
        <!-- <v-col cols="12">
          <v-card>
            <v-toolbar color="primary" dark flat short>
              <v-toolbar-title class="headline pa-3"
              >Boxes shipped</v-toolbar-title
              >
              <v-spacer/>

              <v-file-input
                class="upload"
                id="boxes_upload"
                @change="uploadFile(boxesFile, 'boxes/upload', 'boxes')"
                v-model="boxesFile"
              ></v-file-input>
              <label
                class="v-btn upload-button"
                :class="{
                  'btn--disabled': uploadInProgress,
                  'upload-au': preferences.country === 'au',
                  'upload-nz': preferences.country === 'nz',
                  'upload-ao': preferences.country === 'ao',
                }"
                for="boxes_upload"
              >
                Upload Boxes
              </label>

            </v-toolbar>
            <v-progress-linear v-if="boxesUploadRequestInProgress"
              indeterminate
              :color="primary"
            ></v-progress-linear>
          </v-card>
        </v-col>
        <v-col cols="12">
          <v-card>
            <v-toolbar color="primary" dark flat short>
              <v-toolbar-title class="headline pa-3"
              >Packages shipped
              </v-toolbar-title
              >
              <v-spacer/>

              <v-file-input
                class="upload"
                id="packages_upload"
                @change="uploadFile(packagesFile, 'packages/upload', 'packages')"
                v-model="packagesFile"
              ></v-file-input>
              <label
                class="v-btn upload-button"
                :class="{
                  'btn--disabled': uploadInProgress,
                  'upload-au': preferences.country === 'au',
                  'upload-nz': preferences.country === 'nz',
                  'upload-ao': preferences.country === 'ao',
                }"
                for="packages_upload"
              >
                Upload Packages
              </label>
              <v-toolbar color="primary" flat short>
                <v-select
                  :items="weeks"
                  v-model="retrySyncWeek"
                  required
                  label="Week"
                  solo
                  dense
                  light
                  hide-details
                  class="pr-3"
                  color="primary"
                ></v-select>
                <v-btn
                  color="secondary"
                  @click="retrySyncPackageShipped()"
                  :disabled="retrySyncWeek === ''"
                >
                  Retry
                </v-btn>
              </v-toolbar>
            </v-toolbar>
            <v-progress-linear v-if="packagesUploadRequestInProgress"
              indeterminate
              :color="primary"
            ></v-progress-linear>

          </v-card>
        </v-col> -->
        <v-col cols="12" v-if="preferences.country == 'ao'">
          <v-card>
            <v-toolbar color="primary" dark flat short>
              <v-toolbar-title class="headline pa-3"
              >BeCool Batch Delivery
              </v-toolbar-title
              >
              <v-spacer></v-spacer>
              <v-spacer></v-spacer>
              <v-btn
                :loading="progress"
                color="secondary"
                @click="openCreateDeliveriesDialog"
              >Create deliveries (BETA)
              </v-btn
              >
            </v-toolbar>
          </v-card>
        </v-col>
        <!-- <v-col cols="12">
          <v-card>
            <v-toolbar color="primary" flat short>
              <v-toolbar-title class="headline pa-3 white--text"
              >Delivery List output
              </v-toolbar-title
              >
              <v-spacer></v-spacer>
              <v-spacer></v-spacer>
              <v-spacer></v-spacer
              >
              <v-spacer></v-spacer>
              <v-select
                :items="weeks"
                v-model="currentWeek"
                label="Week"
                solo
                dense
                hide-details
                class="pr-3"
                color="primary"
              ></v-select>
              <v-btn color="secondary" @click="deliveryListOutput"
              >Refresh it
              </v-btn
              >
            </v-toolbar>
          </v-card>
        </v-col> -->
        <v-col cols="12">
          <v-card>
            <v-toolbar color="primary" dark short>
              <v-toolbar-title class="headline pa-3"
              >Formatted addresses
              </v-toolbar-title
              >
              <v-spacer></v-spacer>
              <v-spacer></v-spacer>
              <v-file-input
                class="upload"
                id="addresses_upload"
                @change="uploadFile(addressesFile, 'addresses/formatted', 'addresses')"
                v-model="addressesFile"
              ></v-file-input>
              <label
                class="v-btn upload-button"
                :class="{
                  'btn--disabled': uploadInProgress,
                  'upload-au': preferences.country === 'au',
                  'upload-nz': preferences.country === 'nz',
                  'upload-ao': preferences.country === 'ao',
                }"
                for="addresses_upload"
              >Upload Addresses
              </label>
            </v-toolbar>
            <v-progress-linear v-if="addressesUploadRequestInProgress"
              indeterminate
              :color="primary"
            ></v-progress-linear>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-card-text v-if="jobsWithError.length > 0">
      <v-expansion-panels>
        <v-expansion-panel v-for="job in jobsWithError" :key="job.id">
          <v-subheader>Jobs with errors</v-subheader>
          <v-expansion-panel-header
          >Job : {{ job.id }}
          </v-expansion-panel-header
          >
          <v-expansion-panel-content>
            <v-card-text>
              Oops, it seems that some of the data can't be uploaded
              !
            </v-card-text
            >
            <v-data-table
              :headers="headers"
              :items="job.errors"
              :no-data-text="'No data found'"
              hide-actions
              class="elevation-1"
            ></v-data-table>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-card-text>

    <v-dialog v-if="preferences.country === 'ao'" v-model="batchDeliveryDialog" width="50%">
      <v-card>
        <v-card-title>
          BeCool Batch Delivery
        </v-card-title>

        <v-card-text class="body-1">
          This will create deliveries in BeCool and retrieve tracking ID's for
          the latest week of packages: {{ latestWeekOfPackages }}
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            :loading="progress"
            color="primary"
            text
            @click="createDeliveriesInBeCool"
          >
            Create Deliveries
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-if="uploadMessageView" v-model="uploadMessageView" max-width="550px">
      <v-card>
        <v-card-title v-if="uploadMessageView">File upload requested</v-card-title>
        <v-card-text> {{modalMessage}} </v-card-text>
        <div style="padding-left: 80%; padding-bottom: 2%;">
          <v-btn color="primary" @click="updateUploadMessageView()">
            Close
          </v-btn>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from 'axios';
import auth from '../modules/auth';
import notifications from '@/modules/notifications';
import {CONSTANTS} from '@/modules/constants';

export default {
	name: 'Upload',
	data() {
		return {
			progress: false,
			indexationLoading: false,
			preferences: auth.user.preferences,
			errorMessage: '',
			jobs: [],
			jobsWithError: [],
			customerIndexWeek: '',
			uploadInProgress: false,
			headers: [
				{value: 'type', text: 'Type'},
				{value: 'box', text: 'Id'},
				{value: 'message', text: 'Error message'}
			],
			boxesFile: undefined,
			packagesFile: undefined,
			addressesFile: undefined,
			batchDeliveryDialog: false,
			latestWeekOfPackages: null,
			retrySyncWeek: '',
			uploadMessageView: false,
			modalMessage: '',
			boxesUploadRequestInProgress: false,
			packagesUploadRequestInProgress: false,
			addressesUploadRequestInProgress: false
		};
	},
	mounted() {
		this.getJobs();
		this.customerIndexWeek = this.weeks[0];
	},
	beforeDestroy() {
		this.jobs.map((job) => {
			clearInterval(job.interval);
		});
	},
	computed: {
		currentWeek: {
			set(week) {
				this.$store.dispatch('updateWeek', week);
			},
			get() {
				return this.$store.state.week;
			}
		},
		weeks() {
			return this.$store.state.weeks;
		}
	},
	methods: {
		getLatestWeekOfPackages() {
			return axios
				.get(`${CONSTANTS.BASE_URL}/api/v1/packages/week`, {
					params: {
						country: this.preferences.country
					}
				})
				.then(({data}) => {
					this.latestWeekOfPackages = data.week;
				})
				.catch((error) => {
					if (error.response.status === 404) {
						const {msg} = error.response.data;
						notifications.addNotification(`Error: ${msg}`, true);
					} else {
						notifications.addNotification(
							'Error: could not get latest week of packages',
							true
						);
					}
				});
		},
		openCreateDeliveriesDialog() {
			this.progress = true;
			this.getLatestWeekOfPackages()
				.then(() => {
					this.progress = false;
					this.batchDeliveryDialog = true;
				})
				.catch(() => {
					this.progress = false;
				});
		},
		createDeliveriesInBeCool() {
			this.progress = true;
			axios
				.post(`${CONSTANTS.BASE_URL}/api/v1/packages/tracking_ids`, {
					country: this.preferences.country,
					week: this.latestWeekOfPackages
				})
				.then((response) => {
					this.jobs.push({id: response.data.job_id});
					this.updateJobs();
					this.progress = false;
				})
				.catch((error) => {
					console.log(error);
					notifications.addNotification(
						'Error: could not send batch deliveries to BeCool',
						true
					);
					this.progress = false;
				});
			this.batchDeliveryDialog = false;
		},
		retrySyncPackageShipped() {
			axios
				.post(`${CONSTANTS.BASE_URL}/api/v1/packages/packages_shipped_sync/${this.preferences.country}/${this.retrySyncWeek}`)
				.then((response) => {
					if (response.data.msg === 'success') {
						notifications.addNotification('The resync is successful.');
					}
				})
				.catch((error) => {
					this.errorMessage =
            error.response && error.response.data.error
            	? error.response.data.error
            	: 'Error during operation.';
					notifications.addNotification(
						'The resync failed.',
						true
					);
				})
		},
		uploadFile(file, address, uploadType) {
			let formData = new FormData();
			formData.append('file', file);
			if (this.eventBasedBoxshippedPackageshippedUpload()) {
				this.setUploadRequestInProgressFlag(uploadType, true);
			}
			this.uploadInProgress = true;
			axios
				.post(`${CONSTANTS.BASE_URL}/api/v1` + '/' + address, formData, {
					headers: {
						'Content-Type': 'multipart/form-data'
					},
					params: {
						country: this.preferences.country
					}
				})
				.then((response) => {
					if (this.eventBasedBoxshippedPackageshippedUpload()) {
						this.uploadMessageView = true;
						this.setUploadRequestInProgressFlag(uploadType, false);
						this.modalMessage = response.data.msg;
						this.boxesFile = '';
						this.packagesFile = '';
						this.addressesFile = '';
					}
					// remove this block if feature flag gets removed
					else if (response.data.msg === 'success') {
						this.jobs.push({id: response.data.job_id});
						this.updateJobs();
						this.boxesFile = '';
						this.addressesFile = '';
					}

					this.uploadInProgress = false;
				})
				.catch((error) => {
					this.uploadInProgress = false;
					this.setUploadRequestInProgressFlag(uploadType, false);
					this.errorMessage =
            error.response && error.response.data.error
            	? error.response.data.error
            	: 'Error during operation.';
					notifications.addNotification(
						'Oops, there was an error while uploadings...',
						true
					);
					this.boxesFile = '';
					this.addressesFile = '';
					this.packagesFile = '';
				});
		},
		updateUploadMessageView() {
			this.uploadMessageView = false;
			this.modalMessage = '';
		},
		getJobs() {
			axios.get(`${CONSTANTS.BASE_URL}/api/v1` + '/jobs').then((response) => {
				this.jobs = response.data.jobs.map((job_id) => {
					return {id: job_id};
				});
				this.updateJobs();
			});
		},
		updateJobs() {
			this.jobs = this.jobs.reduce((jobs, job) => {
				if (job.interval === undefined) {
					job.interval = setInterval(() => {
						axios
							.get(`${CONSTANTS.BASE_URL}/api/v1` + '/jobs/' + job.id)
							.then((response) => {
								let progress = response.data.meta.progress;
								if (progress > 0) {
									job.progress = progress;
									job.title = response.data.meta.title;
									job.errors = response.data.meta.errors;
									job.progress_msg = response.data.meta.progress_msg;
								} else {
									job.progress = 0;
									job.title = 'Waiting for job';
								}

								job.is_failed = response.data.failed;
								if (job.is_failed) {
									job.color = 'error';
									job.title = response.data.error;
									clearInterval(job.interval);
								} else if (job.errors && job.errors.length > 0) {
									job.color = 'warning';
								} else {
									job.color = 'primary';
								}

								if (job.progress >= 100) {
									clearInterval(job.interval);
									job.title = 'Finished';
									if (job.errors.length > 0) {
										this.jobsWithError.push(job);
									}
									this.getJobs();
								}

								this.updateJobs();
							});
					}, 4000);
					this.$set(job, 'progress', -1);
					this.$set(job, 'title', 'Fetching job');
				}
				jobs.push(job);

				return jobs;
			}, []);
		},
		// deliveryListOutput() {
		// 	axios
		// 		.patch(`${CONSTANTS.BASE_URL}/api/v1` + '/boxes', {
		// 			week: this.currentWeek,
		// 			country: this.preferences.country
		// 		})
		// 		.then((response) => {
		// 			if (response.data.msg === 'success') {
		// 				this.jobs.push({id: response.data.job_id});
		// 				this.updateJobs();
		// 			}

		// 		});
		// },
		customersIndexation() {
			this.indexationLoading = true;
			axios
				.post(`${CONSTANTS.BASE_URL}/api/v1` + '/customers', {
					country: this.preferences.country,
					week: this.customerIndexWeek
				})
				.then((response) => {
					if (response.data.msg === 'success') {
						this.jobs.push({id: response.data.job_id});
						this.updateJobs();
					}
					this.indexationLoading = false;
				})
				.catch((error) => {
					this.indexationLoading = false;
				});
		},
		setUploadRequestInProgressFlag(uploadType, flag) {
			switch(uploadType) {
			case 'boxes':
				this.boxesUploadRequestInProgress = flag;
				break;
			case 'packages':
				this.packagesUploadRequestInProgress = flag;
				break;
			case 'addresses':
				this.addressesUploadRequestInProgress = flag;
			}
		},
		eventBasedBoxshippedPackageshippedUpload() {
			return this.$store.state.toggles.eventBasedBoxshippedPackageshippedUpload
		}
	}
};
</script>

<style scoped>
.upload {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.upload-button {
  padding: 1em;
  border-radius: 5px;
  padding: 8px 16px;
  margin-left: 20px;
}

.upload-au {
  background: #669933 !important;
}

.upload-nz {
  color: #68c1ff !important;
}

.upload-ao {
  background: #16becf !important;
}

label {
  letter-spacing: normal;
  font-size: 0.9em;
}

.jobs-progress {
  width: 100%;
}

.job-title {
  margin: auto;
}
</style>

<style>
.upload-page .v-text-field > .v-input__control > .v-input__slot {
  margin: 0;
}
</style>
