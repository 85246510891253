<template>
  <div class="main">

    <div v-for="courier in couriers" v-if="user.email.includes(courier.domain)">
      <v-card dark>
      <v-card-title>Page that {{courier.name}} couriers sees on login</v-card-title>
        </v-card>
      <courier-portal :courierName="courier.name"></courier-portal>
    </div>

    <div v-if="hellofreshAuthorised">
    <v-progress-linear indeterminate v-if="progress" color="secondary" class="progress"></v-progress-linear>
    <v-container>
      <v-row>
        <v-col cols="12" class="animation">
          <div class="loop-wrapper" :class="countryBorder">
            <div class="mountain"></div>
            <div class="hill" :class="countryHills"></div>
            <div class="tree">
              <svg xmlns="http://www.w3.org/2000/svg" width="32" height="100" viewBox="0 0 32 100">
                <path :class="countryTree" d="M31.945 74.986L17.37 1.148A1.416 1.416 0 0 0 15.988 0c-.673 0-1.252.48-1.383 1.148L.027 74.986c-.083.42.025.854.292 1.186.268.332.669.523 1.091.523h13.167V100h2.821V76.695h13.165c.422 0 .821-.191 1.09-.523.27-.331.375-.766.292-1.186z"/>
              </svg>
            </div>
            <div class="tree">
              <svg xmlns="http://www.w3.org/2000/svg" width="32" height="100" viewBox="0 0 32 100">
                <path :class="countryTree" d="M31.945 74.986L17.37 1.148A1.416 1.416 0 0 0 15.988 0c-.673 0-1.252.48-1.383 1.148L.027 74.986c-.083.42.025.854.292 1.186.268.332.669.523 1.091.523h13.167V100h2.821V76.695h13.165c.422 0 .821-.191 1.09-.523.27-.331.375-.766.292-1.186z"/>
              </svg>
            </div>
            <div class="tree">
              <svg xmlns="http://www.w3.org/2000/svg" width="32" height="100" viewBox="0 0 32 100">
                <path :class="countryTree" d="M31.945 74.986L17.37 1.148A1.416 1.416 0 0 0 15.988 0c-.673 0-1.252.48-1.383 1.148L.027 74.986c-.083.42.025.854.292 1.186.268.332.669.523 1.091.523h13.167V100h2.821V76.695h13.165c.422 0 .821-.191 1.09-.523.27-.331.375-.766.292-1.186z"/>
              </svg>
            </div>
            <div class="rock"></div>
            <div class="truck" :class="countryWind">
              <svg xmlns="http://www.w3.org/2000/svg" width="85" height="60" viewBox="0 0 85 60">
                <path :class="countryTruck" d="M5.138 54.039a3.77 3.77 0 0 1-.149-.005l-.859.002A4.134 4.134 0 0 1 0 49.906V5.195a4.135 4.135 0 0 1 4.13-4.133h46.973c2.272 0 4.123 1.95 4.123 4.229v44.612a4.133 4.133 0 0 1-4.127 4.131H29.962c-1.091-.019-3.239-.691-3.727-3.128-1.067-3.957-4.755-6.789-8.947-6.789-4.291 0-8.098 3.041-9.051 7.229-.225 1.817-1.723 2.693-3.099 2.693zM4.127 4.208c-.596 0-1.083.389-1.083.987v44.709c0 .597.488 1.086 1.086 1.086h1.004c.005-.047.123-.271.133-.317 1.267-5.562 6.323-9.597 12.022-9.597a12.356 12.356 0 0 1 11.912 9.137c.151.734.681.777.785.777h21.111c.596 0 1.083-.487 1.083-1.084V5.293c0-.598-.485-1.085-1.083-1.085H4.127zM84.929 35.828s-.093-2.093-.288-3.118c-.164-.873-1.087-2.644-1.087-2.644l-7.299-13.59c-.951-1.52-3.115-2.707-4.558-2.707H60.785a2.608 2.608 0 0 0-2.606 2.609v33.526a2.609 2.609 0 0 0 2.606 2.609h.643s.949-.123 1.304-1.312c.986-3.933 4.514-6.862 8.749-6.862 4.145 0 7.612 2.808 8.682 6.613.442 1.612 2.231 1.561 2.231 1.561A2.608 2.608 0 0 0 85 49.904l-.071-14.076zm-6.603-3.511H65.312a1.996 1.996 0 0 1-1.989-1.99V20.143c0-1.098.894-1.99 1.989-1.99h4.365c2.483 0 4.117 2.009 4.63 2.978l5.065 9.434c.521.962.053 1.752-1.046 1.752z"/>
                <path :class="countryTruck" d="M4.127 4.208c-.596 0-1.083.389-1.083.987v44.709c0 .597.488 1.086 1.086 1.086h1.004c.005-.047.123-.271.133-.317 1.267-5.562 6.323-9.597 12.022-9.597a12.356 12.356 0 0 1 11.912 9.137c.151.734.681.777.785.777h21.111c.596 0 1.083-.487 1.083-1.084V5.293c0-.598-.485-1.085-1.083-1.085H4.127z"/>
              </svg>
            </div>
            <div class="wheels">
              <svg xmlns="http://www.w3.org/2000/svg" width="85" height="15" viewBox="0 0 85 15">
                <circle :class="countryTruck" cx="17.289" cy="8.413" r="6.587"/>
                <circle :class="countryTruck" cx="71.48" cy="8.412" r="6.586"/>
              </svg>
            </div>
          </div>
        </v-col>
        <v-col cols="12" v-bind:class="{'title-img': true, 'middle': results === undefined}">
          <h1 class="display-4">Search</h1>
        </v-col>
        <v-col cols="12">
          <v-text-field
            solo
            label="Type anything related to a customer ..."
            append-icon="search"
            v-model="query"
            v-on:keyup.13="searchBox"
          ></v-text-field>
        </v-col>
        <div v-if="results !== undefined" style="width: 100%">
          <v-col cols="12" v-if="Object.keys(results).length === 0" class="no-result">
            <span class="title">No customer found :(</span>
          </v-col>
          <transition-group v-else tag="div" name="list-complete" appear>
            <v-col cols="12" v-for="result in results" :key="result.id">
              <v-card class="my-2" hover :to="{name: 'customer', params: {id: result.id}}">
                <v-card-title primary-title class="primary px-4 py-2">
                  <h3 class="headline font-weight-medium">{{ result.name }}</h3>
                  <v-spacer></v-spacer>
                  <v-btn class="mr-3" color="secondary" @click.prevent="$router.push('/customer/' + result.id + '/week_summary')">Weekly Summary</v-btn>
                  <v-btn class="mr-3" color="secondary" target="_blank" :href="result.bob_link" @click.stop>BOB Account</v-btn>
                  <v-btn color="error" target="_blank" :href="result.complaint_link" @click.stop>Complaint</v-btn>
                </v-card-title>
                <v-row>
                  <v-col cols="4">
                    <v-list two-line>
                      <v-list-item>
                        <v-list-item-action>
                          <v-icon>assignment_ind</v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                          <v-list-item-title>{{ result.bob_id }}</v-list-item-title>
                          <v-list-item-subtitle>Customer ID</v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </v-col>
                  <v-col cols="4">
                    <v-list two-line>
                      <v-list-item>
                        <v-list-item-action>
                          <v-icon>mail</v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                          <v-list-item-title>{{ result.email }}</v-list-item-title>
                          <v-list-item-subtitle>Email</v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </v-col>
                  <v-col cols="4">
                    <v-list two-line>
                      <v-list-item>
                        <v-list-item-action>
                          <v-icon>phone</v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                          <v-list-item-title>{{ result.phone }}</v-list-item-title>
                          <v-list-item-subtitle>Phone</v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </v-col>
                  <v-col cols="12" class="px-4 mb-4">
                    <v-subheader>{{ result.highlight.text }}</v-subheader>
                    <v-card hover v-for="box in result.highlight.boxes" :to="{name: 'box', params: {id: box.id}}"
                            :key="box.id" class="mx-4 my-1">
                      <v-card-title class="body-2">
                        <v-icon :color="box.color" class="mr-12">fas fa-box</v-icon>
                        {{ box.week}}
                        <span class="grey--text ml-4">{{ box.type }}</span>
                        <span class="grey--text ml-4">Order {{ box.customer_order }}</span>
                        <span class="grey--text ml-4" v-if="result.highlight.type === 'last_boxes'">Subscription {{ box.customer_subscription }}</span>
                        <b class="grey--text ml-4" v-if="result.highlight.type === 'sub'">Subscription {{ box.customer_subscription }}</b>
                        <v-spacer></v-spacer>
                        <span :class="box.address_color">{{ box.customer_address }}</span>
                        <v-spacer></v-spacer>
                        <b class="grey--text" v-if="result.highlight.type === 'boxes'">{{ box.id}}</b>
                        <span class="grey--text" v-else>{{ box.id}}</span>
                      </v-card-title>
                    </v-card>
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
          </transition-group>
        </div>
      </v-row>
    </v-container>
      </div>
  </div>
</template>

<script>
import axios from 'axios';
import auth from '../modules/auth';
import CourierPortal from './courier/CourierPortal';
import {CONSTANTS} from '@/modules/constants';

export default {
	name: 'Main',
	components: {CourierPortal: CourierPortal},
	data() {
		return {
			progress: false,
			query: '',
			results: undefined,
			preferences: auth.user.preferences,
			user: auth.user.profile,
			couriers: [
				{name: 'BeCool', domain: '@becoolcouriers.com.au'},
				{name: 'HDS', domain: '@gsuite.homedelivery.com.au'},
				{name: 'NZ Post', domain: '@nzpost.co.nz'},
				{name: 'Urgent Couriers', domain: '@urgent.co.nz'}
			],
			hellofreshDomains: [
				'@helloconnect.org',
				'@hellofresh.co',
				'@hellofresh.ca',
				'@hellofresh.de',
				'@hellofresh.nl',
				'@everyplate.com.au'
			]
		}
	},
	computed: {
		hellofreshAuthorised() {
			let authorised = false
			for (let domain of this.hellofreshDomains) {
				if (this.user.email.includes(domain)) {
					authorised = true
				}
			}
			return authorised
		},
		countryTruck(){
			if (this.preferences.country === 'au'){
				return 'au-truck-color'
			} else if (this.preferences.country === 'ao'){
				return 'ao-truck-color'
			} else if (this.preferences.country === 'nz'){
				return 'nz-truck-color'
			}else if (this.preferences.country === 'ye'){
				return 'au-truck-color'
			}
		},
		countryWind(){
			if (this.preferences.country === 'au'){
				return 'au-wind-color'
			} else if (this.preferences.country === 'ao'){
				return 'ao-wind-color'
			} else if (this.preferences.country === 'nz'){
				return 'nz-wind-color'
			}else if (this.preferences.country === 'ye'){
				return 'au-wind-color'
			}
		},
		countryHills(){
			if (this.preferences.country === 'au'){
				return 'au-hills-color'
			} else if (this.preferences.country === 'ao'){
				return 'ao-hills-color'
			} else if (this.preferences.country === 'nz'){
				return 'nz-hills-color'
			}else if (this.preferences.country === 'ye'){
				return 'au-hills-color'
			}
		},
		countryTree(){
			if (this.preferences.country === 'au'){
				return 'au-tree-color'
			} else if (this.preferences.country === 'ao'){
				return 'ao-tree-color'
			} else if (this.preferences.country === 'nz'){
				return 'nz-tree-color'
			}else if (this.preferences.country === 'ye'){
				return 'au-tree-color'
			}
		},
		countryBorder(){
			if (this.preferences.country === 'au'){
				return 'au-border-color'
			} else if (this.preferences.country === 'ao'){
				return 'ao-border-color'
			} else if (this.preferences.country === 'nz'){
				return 'nz-border-color'
			}else if (this.preferences.country === 'au'){
				return 'au-border-color'
			}
		}
	},
	methods: {
		searchBox: function () {
			this.progress = true;
			axios.get(`${CONSTANTS.BASE_URL}/api/v1` + '/customers', {
				params: {
					query: this.query,
					country: this.preferences.country
				}
			}).then((response) => {
				if (response.data.msg === 'success') {
					this.results = response.data.results;
					this.progress = false;

					this.results.forEach((result) => {
						result.highlight.boxes.forEach((box) => {
							box.color = 'primary';
							box.address_color = 'black--text';
							if (box.lmc) {
								box.color = 'warning';
							}
							if (box.errors || box.live_errors) {
								box.color = 'error';
							}
							if (box.wrong_address.fixed === false) {
								box.address_color = 'error--text';
							}
						});

						if (result.highlight.type === 'sub') {
							result.highlight.text = 'Subscription matching - Last boxes';
						} else if (result.highlight.type === 'boxes') {
							result.highlight.text = 'Boxes matching';
						} else if (result.highlight.type === 'last_boxes') {
							result.highlight.text = 'Last boxes';
						}
					});
				}
			});
		},
		getWeeklySummaryLink(id){
			return String(this.$router + '/customer/' + id + '/week_summary')
		}
	}
}
</script>

<style scoped>
  .title-img {
    margin: 1rem 0;
    transition: all 1s;
  }

  .v-list {
    background: transparent;
  }

  .no-result {
    margin: 1em;
    text-align: center;
  }

  .animation {
    overflow: hidden;
  }

  .loop-wrapper {
    margin: 0 auto;
    position: relative;
    display: block;
    width: 100%;
    height: 250px;
    overflow: hidden;
  }
  .mountain {
    position: absolute;
    right: -900px;
    bottom: -20px;
    width: 2px;
    height: 2px;
    box-shadow:
      0 0 0 50px rgba(117, 158, 139, 0.4),
      60px 50px 0 70px rgba(117, 157, 158, 0.8),
      90px 90px 0 50px rgba(118, 158, 117, 0.8),
      250px 250px 0 50px  rgba(74, 97, 73, 0.7),
      290px 320px 0 50px rgba(56, 167, 129, 0.5),
      320px 400px 0 50px rgba(139, 158, 117, 0.5)
  ;
    transform: rotate(130deg);
    animation: mtn 20s linear infinite;
  }
  .hill {
    position: absolute;
    right: -300px;
    bottom: -50px;
    width: 280px;
    border-radius: 50%;
    height: 20px;
    animation: hill 5s 2s linear infinite;
  }
  .tree, .tree:nth-child(2), .tree:nth-child(3) {
    position: absolute;
    height: 100px;
    width: 35px;
    bottom: 0;
  }
  .rock {
    margin-top: -17%;
    height: 2%;
    width: 2%;
    bottom: -2px;
    border-radius: 20px;
    position: absolute;
    background: #B18B3D;
  }
  .truck, .wheels {
    transition: all ease;
    width: 85px;
    margin-right: -60px;
    bottom: 0px;
    right: 50%;
    position: absolute;
  }
  .truck {
    background-size: contain;
    height: 60px;
  }
  .truck:before {
    content: " ";
    position: absolute;
    width: 25px;
  }
  .wheels {
    height: 15px;
    margin-bottom: 0;
  }

  .tree  { animation: tree 3s 0s linear infinite; }
  .tree:nth-child(2)  { animation: tree2 2s 0.150s linear infinite; }
  .tree:nth-child(3)  { animation: tree3 8s 0.050s linear infinite; }
  .rock  { animation: rock 4s -1s linear infinite; }
  .truck  { animation: truck 4s 0.080s ease infinite; }
  .wheels  { animation: truck 4s 0.001s ease infinite; }
  .truck:before { animation: wind 1.5s  0.000s ease infinite; }


  @keyframes tree {
    0%   { transform: translate(1350px); }
    50% {}
    100% { transform: translate(-50px); }
  }
  @keyframes tree2 {
    0%   { transform: translate(650px); }
    50% {}
    100% { transform: translate(-50px); }
  }
  @keyframes tree3 {
    0%   { transform: translate(2750px); }
    50% {}
    100% { transform: translate(-50px); }
  }

  @keyframes rock {
    0%   { right: -200px; }
    100% { right: 2000px; }
  }
  @keyframes truck {
    0%   { }
    6%   { transform: translateY(0px); }
    7%   { transform: translateY(-6px); }
    9%   { transform: translateY(0px); }
    10%   { transform: translateY(-1px); }
    11%   { transform: translateY(0px); }
    100%   { }
  }
  @keyframes wind {
    0%   {  }
    50%   { transform: translateY(3px) }
    100%   { }
  }
  @keyframes mtn {
    100% {
      transform: translateX(-2000px) rotate(130deg);
    }
  }
  @keyframes hill {
    100% {
      transform: translateX(-2000px);
    }
  }

  .au-truck-color {
    fill : #8bcd57;
  }

  .ao-truck-color {
    fill:#f99d33;
  }

  .nz-truck-color {
    fill: #c180ff;
  }


  .au-wind-color:before{
    box-shadow:
      -30px 28px 0 1.5px #8bcd57,
      -35px 18px 0 1.5px #8bcd57;
  }

  .ao-wind-color:before{
    box-shadow:
      -30px 28px 0 1.5px #f99d33,
      -35px 18px 0 1.5px #f99d33;
  }

  .nz-wind-color:before{
    box-shadow:
      -30px 28px 0 1.5px #c180ff,
      -35px 18px 0 1.5px #c180ff;
  }

  .au-hills-color {
    box-shadow:
      0 0 0 50px #669933,
      -20px 0 0 20px #669933,
      -90px 0 0 50px #669933,
      250px 0 0 50px #669933,
      290px 0 0 50px #669933,
      620px 0 0 50px #669933
  ;
  }
  .ao-hills-color {
    box-shadow:
      0 0 0 50px #FFBF00,
      -20px 0 0 20px #FFBF00,
      -90px 0 0 50px #FFBF00,
      250px 0 0 50px #FFBF00,
      290px 0 0 50px #FFBF00,
      620px 0 0 50px #FFBF00
  ;
  }

  .nz-hills-color {
    box-shadow:
      0 0 0 50px #B46ea6,
      -20px 0 0 20px #B46ea6,
      -90px 0 0 50px #B46ea6,
      250px 0 0 50px #B46ea6,
      290px 0 0 50px #B46ea6,
      620px 0 0 50px #B46ea6
  ;
  }

  .au-tree-color {
    fill:#095228;
  }

  .ao-tree-color {
    fill:#FDAB9F;
  }

  .nz-tree-color {
    fill: #32047c;
  }

  .au-border-color {
    border-bottom: 2px solid #669933;
    color: #669933;
  }

  .ao-border-color {
    border-bottom: 2px solid #FFBF00;
    color: #FFBF00;
  }

  .nz-border-color {
    border-bottom: 2px solid #B46ea6;
    color: #B46ea6;
  }

</style>
