<template>
  <div class="customer">
    <v-dialog v-model="showPaymentErrorsSummary" width="75%">
      <payment-errors-summary
        :paymentErrors="paymentErrors"
        :totalUnpaidAmount="totalUnpaidAmount"
        :customer_id="this.customer.bob_id"
      >
      </payment-errors-summary>
    </v-dialog>

    <v-dialog v-model="sendText" width="800" persistent>
      <text-message
        :send-text-messages="sendTextMessageToCustomer"
        :send-to-message="'Text to ' + customer.name"
        :close-pop-in="closePopInMessage"
      >
      </text-message>
    </v-dialog>

    <v-dialog
      v-model="isTextTemplatePopInOpened"
      width="800"
      :dark="preferences.country === 'nz'"
    >
      <v-card class="mx-auto">
        <v-card-title class="headline">
          <v-icon large left class="pr-4" color="primary">sms</v-icon>
          <span>
            {{
              "Text sent by " +
              selectedTextMessage.sender +
              " - " +
              selectedTextMessage.date +
              " - " +
              selectedTextMessage.hour
            }}
          </span>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pt-2" style="white-space: pre-line;">
          {{ selectedTextMessage.body }}
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="isTextTemplatePopInOpened = false"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-progress-linear
      indeterminate
      v-if="progress"
      color="secondary"
      class="progress"
    ></v-progress-linear>
    <v-container v-if="customer.id !== undefined">
      <transition-group name="list-complete" appear tag="div">
        <v-row key="row1">
          <v-col cols="12" key="fraudFlag" v-if="fraudFlag">
            <v-card class="fraud-card warning white--text">
              <v-card-title primary-title>
                <v-icon large color="white">warning</v-icon>
                <span class="display-1 font-weight-bold">
                  Customer Care Attention!</span
                >
              </v-card-title>
              <v-card-text>
                <!--              <span class="headline">This customer has claimed an error on more than 50% of deliveries.</span>-->
                <span class="headline"
                  >This customer has claimed an error on more than 30% of
                  deliveries.<br />Our known average error rate is less than
                  4%</span
                >
              </v-card-text>
            </v-card>
          </v-col>

          <v-col cols="9" sm="6" key="title" class="mb-6">
            <h1 class="display-3">{{ customer.name }}</h1>
            <h2 class="subtitle-1 grey--text">
              Customer {{ customer.bob_id }}
            </h2>
          </v-col>
          <v-col key="buttonBox" cols="3">
            <!--          <v-col key="escalation" cols="2">-->
            <!--            <v-btn color="error"-->
            <!--                   :href="escalationFormLink"-->
            <!--                   target="_blank"-->
            <!--            >Escalate customer-->
            <!--            </v-btn>-->
            <!--          </v-col>-->

            <v-col key="escalation" cols="12">
              <!--            <v-dialog max-width="1200px">-->
              <!--              <template v-slot:activator="{ on }">-->
              <!--              <v-btn v-on="on"-->
              <!--                     color="error"-->
              <!--              >Escalate Customer-->
              <!--              </v-btn>-->
              <!--              </template>-->
              <!--              <v-card>-->
              <!--                <live-data-form :issue_name="'Escalations'"-->
              <!--                                :leadership_only="false"-->
              <!--                                showHeader></live-data-form>-->
              <!--              </v-card>-->
              <!--            </v-dialog>-->
              <v-btn color="error" :href="escalationFormLink" target="_blank">
                Escalate customer
              </v-btn>

              <v-dialog max-width="1200px">
                <template v-slot:activator="{ on }">
                  <v-btn v-on="on" color="primary">
                    Backup Voucher
                  </v-btn>
                </template>
                <v-card>
                  <voucher-lookup
                    :customer_email="customer.email"
                    :customer_id="customer.bob_id"
                    :lookup_customer_emails="customer.email"
                  ></voucher-lookup>
                </v-card>
              </v-dialog>

              <v-btn
                @click="
                  $router.push('/customer/' + customer_id + '/week_summary')
                "
                class="px-4"
                color="secondary"
              >
                Week Summary of Packages
              </v-btn>
            </v-col>
          </v-col>
        </v-row>

        <v-row key="row2">
          <v-col cols="12" key="alert" class="mb-4">
            <v-alert :value="wrong_address.active" type="error" outlined>
              This customer has a wrong address for its last
              <router-link
                :to="{ name: 'box', params: { id: wrong_address.box } }"
                >box</router-link
              >
              . Check it out to fix it, and don't forget to maybe also update
              other subscriptions.
            </v-alert>
          </v-col>
        </v-row>
        <v-row key="row3">
          <v-col cols="12" key="alert" class="mb-4">
            <v-alert
              v-if="priceChangeDiscounts.length"
              type="warning"
              border="left"
            >
              <div class="title">
                Price Change Discount Applied
              </div>
              <p>
                This customer has a price change discount applied to the
                following subscriptions:
              </p>
              <div
                v-for="discount in priceChangeDiscounts"
                :key="discount.customer_subscription"
              >
                <p>Subscription {{ discount.customer_subscription }}</p>
                <ul>
                  <li>SKU: {{ discount.sku }}</li>
                  <li>Discount Amount: {{ discount.discount_amount }}</li>
                  <li>New Price: {{ discount.new_price }}</li>
                </ul>
              </div>
            </v-alert>
          </v-col>
        </v-row>
        <v-row key="row4">
          <v-col cols="12" class="mb-4" v-if="upsellStatus.customer_targeted">
            <v-alert v-if="!upsellStatus.upsell_attempted" dismissible dense prominent text type="info" color="green" outlined>
              <v-container class="d-flex align-center" pa-0>
                <v-col>
                  <h3 class="font-weight-bold ma-0">
                    Please inform this customer about our add-ons as they may not be aware. They are the ideal customer for it.
                  </h3>
                  <p class="font-italic ma-0 pt-4">
                    "I've just had a look at your menu and noticed you haven’t tried any of our sides, desserts, ready meals, snacks and fruits yet. Take a look at these on your menu page over the coming weeks to make the absolute most of your HelloFresh cooking experience! Let me know if you have any questions."
                  </p>
                </v-col>
                <v-dialog v-model="showUpsellAttemptedModal" max-width="500px">
                  <template v-slot:activator="{ on }">
                    <v-btn outlined plain medium color="secondary" v-on="on" v-clipboard="" :disabled="upsellStatus.upsellAttempted">
                      Mark as attempted
                    </v-btn>
                  </template>
                  <mark-upsell-attempted-modal
                    :mark-upsell-attempted="markUpsellAttempted"
                    :close-mark-upsell-attempted-modal="closeMarkUpsellAttemptedModal"
                  />
                </v-dialog>
              </v-container>
            </v-alert>
            <v-alert v-else dismissible dense prominent text type="success" color="primary" outlined>
              <v-container class="d-flex align-center" pa-0>
                <v-card-text class="font-weight-bold ma-0">
                  Customer upsell attempted
                </v-card-text>
              </v-container>
            </v-alert>
          </v-col>
        </v-row>
        <v-row key="col3">
          <v-col cols="12" key="customer" class="mb-12">
            <v-card class="customer-card">
              <v-row wrap>
                <v-col cols="3">
                  <v-list two-line>
                    <v-list-item>
                      <v-list-item-action>
                        <v-icon>mail</v-icon>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title>{{
                          customer.email
                        }}</v-list-item-title>
                        <v-list-item-subtitle>Email</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-action>
                        <v-icon>attach_money</v-icon>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title
                          >${{ customer.refunded }}</v-list-item-title
                        >
                        <v-list-item-subtitle
                          >Total refunded</v-list-item-subtitle
                        >
                      </v-list-item-content>
                    </v-list-item>
                    <!--Duplicating vouchers tile for now because it's quicker than re-organising tiles to shuffle automatically-->
                    <v-list-item
                      v-if="
                        num_vouchers_requested > 0 &&
                        !(medibank['next_points'] < 5 || medibank['points'] > 0)
                      "
                    >
                      <v-list-item-action>
                        <v-icon>fas fa-barcode</v-icon>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title>{{
                          num_vouchers_requested
                        }}</v-list-item-title>
                        <v-list-item-subtitle
                          >Backup vouchers requested</v-list-item-subtitle
                        >
                      </v-list-item-content>
                    </v-list-item>

                    <div
                      v-if="
                        medibank['next_points'] < 5 || medibank['points'] > 0
                      "
                      cols="4"
                    >
                      <v-tooltip
                        right
                        light
                        max-width="300px"
                        color="secondary"
                      >
                        <template v-slot:activator:="{ on }">
                          <v-list-item v-on="on">
                            <v-list-item-action>
                              <v-icon>fas fa-heart</v-icon>
                            </v-list-item-action>
                            <v-list-item-content>
                              <v-list-item-title
                                >{{ medibank.points }} Medibank Live Better
                                Points</v-list-item-title
                              >
                              <v-list-item-subtitle
                                >Next points awarded in
                                {{ medibank.next_points }}
                                deliveries</v-list-item-subtitle
                              >
                            </v-list-item-content>
                          </v-list-item>
                        </template>

                        <span
                          >4000 points for every 5 deliveries since linking.
                          Bonus 4000 points on 1st box if linked before. Not
                          valid if a discount voucher was used.</span
                        >
                      </v-tooltip>
                    </div>
                  </v-list>
                </v-col>
                <v-col cols="3" key="list1">
                  <v-list two-line>
                    <v-list-item>
                      <v-list-item-action>
                        <v-icon>phone</v-icon>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title>{{
                          customer.phone
                        }}</v-list-item-title>
                        <v-list-item-subtitle
                          >Phone number</v-list-item-subtitle
                        >
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-action>
                        <v-icon>cancel_presentation</v-icon>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title
                          >{{ customer.lmcs }} cancellations</v-list-item-title
                        >
                        <v-list-item-subtitle>LMCs</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                    <!--                  Duplicating vouchers tile for now because it's quicker than re-organising tiles to shuffle automatically-->
                    <v-list-item
                      v-if="
                        num_vouchers_requested > 0 &&
                        (medibank['next_points'] < 5 || medibank['points'] > 0)
                      "
                    >
                      <v-list-item-action>
                        <v-icon>fas fa-barcode</v-icon>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title>{{
                          num_vouchers_requested
                        }}</v-list-item-title>
                        <v-list-item-subtitle
                          >Backup vouchers requested</v-list-item-subtitle
                        >
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-col>
                <v-col cols="3" key="list2">
                  <v-list two-line>
                    <v-list-item>
                      <v-list-item-action>
                        <v-icon>loyalty</v-icon>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title>{{
                          customer.loyalty
                        }}</v-list-item-title>
                        <v-list-item-subtitle>Loyalty</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-action>
                        <v-icon>error</v-icon>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title
                          >{{ customer.errors }} errors</v-list-item-title
                        >
                        <v-list-item-subtitle>Errors</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-col>

                <v-col cols="3">
                  <v-list two-line>
                    <v-list-item>
                      <v-list-item-action>
                        <v-icon color="error">credit_card</v-icon>
                      </v-list-item-action>
                      <v-progress-circular
                        v-if="loadingPaymentErrors"
                        indeterminate
                        color="primary"
                      ></v-progress-circular>
                      <v-list-item-content v-else>
                        <v-list-item-title
                          >${{ totalUnpaidAmount }}</v-list-item-title
                        >
                        <v-list-item-subtitle
                          >Unpaid Amount</v-list-item-subtitle
                        >
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item @click="togglePaymentErrorsSummary">
                      <v-list-item-action>
                        <v-icon color="error">report_problem</v-icon>
                      </v-list-item-action>
                      <v-progress-circular
                        v-if="loadingPaymentErrors"
                        indeterminate
                        color="primary"
                      ></v-progress-circular>
                      <v-list-item-content v-else>
                        <v-list-item-title>{{
                          nbOrdersUnpaid
                        }}</v-list-item-title>
                        <v-list-item-subtitle
                          >NB Orders Unpaid</v-list-item-subtitle
                        >
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-col>
              </v-row>
              <v-card-actions class="pt-2">
                <v-spacer></v-spacer>
                <v-btn color="secondary" outlined @click="sendText = true">
                  Send text
                </v-btn>
                <v-btn
                  color="primary"
                  outlined
                  target="_blank"
                  :href="customer.bob_account_link"
                  >BOB Account</v-btn
                >
                <v-btn
                  color="error"
                  outlined
                  target="_blank"
                  :href="customer.complaint_link"
                  >Complaint</v-btn
                >
              </v-card-actions>
              <div
                v-if="
                  customer.text_messages !== undefined &&
                  customer.text_messages.length > 0
                "
              >
                <v-divider class="mt-2 mx-2"> </v-divider>
                <v-subheader class="pr-0">
                  <v-icon class="ml-1 mr-2">sms</v-icon>
                  Text messages already sent
                  <v-spacer></v-spacer>
                </v-subheader>
                <transition-group
                  class="layout row wrap px-4 pb-4"
                  name="scroll-x-transition"
                  appear
                  tag="div"
                >
                  <div
                    v-for="textMessage in customer.text_messages"
                    :key="'textMessages-' + textMessage.text_message_id"
                    style="display: flex; flex-direction: column;"
                  >
                    <v-btn
                      class="py-2"
                      outlined
                      color="secondary"
                      @click="changeSelectedMessage(textMessage)"
                    >
                      {{ textMessage.date + " - " + textMessage.hour }}
                    </v-btn>
                    <h5 class="pr-2 secondary--text" style="text-align: right;">
                      {{ "By " + textMessage.sender }}
                    </h5>
                  </div>
                </transition-group>
              </div>
            </v-card>
          </v-col>
        </v-row>
        <v-container
          grid-list-xl
          :key="sub.id"
          class="pa-0 mb-12"
          v-for="sub in customer.subscriptions"
        >
          <v-row wrap>
            <v-col cols="9" class="mb-6">
              <h1 class="headline">Subscription {{ sub.id }}</h1>
              <h2 class="body-2 grey--text">
                Last address : {{ sub.last_address }}
              </h2>
              <h2 class="body-2 grey--text">
                Last deliveries instructions :
                {{ sub.last_deliveries_instructions }}
              </h2>
            </v-col>
            <v-spacer></v-spacer>
            <v-col cols="3" style="text-align: right;">
              <v-btn
                color="primary"
                target="_blank"
                :href="sub.bob_subscription_link"
                >BOB Subscription</v-btn
              >
            </v-col>
            <v-col v-for="box in sub.boxes" :key="box.id" cols="4">
              <v-card tile hover :to="{ name: 'box', params: { id: box.id } }">
                <v-card-title primary-title :class="box.color">
                  <h3 class="headline font-weight-bold">{{ box.week }}</h3>
                  <v-badge color="warning" inline class="box-badge" v-if="box.coupon_code">
                    <template v-slot:badge>
                      <span>
                        <v-tooltip left>
                          <template v-slot:activator="{ on }">
                            <span v-on="on">$</span></template
                          >
                          Voucher : {{ box.coupon_template }}
                        </v-tooltip>
                      </span>
                    </template>
                  </v-badge>
                  <v-badge
                    color="error"
                    inline
                    class="box-badge"
                    v-if="box.food_safety_issue"
                  >
                    <template v-slot:badge>
                      <span>
                        <v-tooltip right>
                          <template v-slot:activator="{ on }">
                            <v-icon
                              v-on="on"
                              color="white"
                              >fas fa-skull</v-icon
                            >
                          </template>
                          <span>Food safety issue</span>
                        </v-tooltip>
                      </span>
                    </template>
                  </v-badge>
                  <v-badge
                    color="error"
                    inline
                    class="box-badge"
                    v-if="box.damage_claim"
                  >
                    <template v-slot:badge>
                      <span>
                        <v-tooltip right>
                          <template v-slot:activator="{ on }">
                            <v-icon
                              v-on="on"
                              color="white"
                              >fas fa-house-damage</v-icon
                            >
                          </template>
                          <span>Damage claim issue</span>
                        </v-tooltip>
                      </span>
                    </template>
                  </v-badge>
                  <v-spacer></v-spacer>
                  <span class="white--text">{{ box.type }}</span>
                  <span
                    class="payment-failure-badge"
                    v-if="
                      boxesWithPaymentErrors.includes(box.customer_order) &&
                      !loadingPaymentErrors
                    "
                  >
                    Payment Failure
                  </span>
                </v-card-title>
                <v-list two-line>
                  <v-list-item>
                    <v-list-item-action>
                      <v-icon>departure_board</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title
                        >{{ box.delivery_date }},
                        {{ box.delivery_window }},
                        {{ box.delivery_day_of_week }}
                      </v-list-item-title>
                      <v-list-item-subtitle
                        >Delivery interval</v-list-item-subtitle
                      >
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-action>
                      <v-icon>directions_bus</v-icon>
                    </v-list-item-action>
                    <v-list-item-content v-if="preferences.country === 'nz'">
                      <v-list-item-title>{{
                        box.delivery_tracking_number
                      }}</v-list-item-title>
                      <v-list-item-subtitle
                        >Tracking number</v-list-item-subtitle
                      >
                    </v-list-item-content>
                    <v-list-item-content v-else>
                      <v-list-item-title>{{
                        box.delivery_courier
                      }}</v-list-item-title>
                      <v-list-item-subtitle>Courier</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </transition-group>
    </v-container>
  </div>
</template>

<script>
import axios from 'axios';
import auth from '../../modules/auth';
import TextMessage from '../TextMessage';
import notifications from '@/modules/notifications';
import VoucherLookup from '../vouchers/VoucherLookup';
import LiveDataForm from '../issues/LiveDataForm';
import PaymentErrorsSummary from '../payment/PaymentErrorsSummary';
import MarkUpsellAttemptedModal from '@/components/customers/MarkUpsellAttemptedModal';
import {CONSTANTS} from '@/modules/constants';

export default {
	name: 'Customer',
	components: {
		VoucherLookup,
		TextMessage,
		LiveDataForm,
		PaymentErrorsSummary,
		MarkUpsellAttemptedModal
	},
	data() {
		return {
			preferences: auth.user.preferences,
			userProfile: auth.user.profile,
			progress: false,
			customer_id: this.$route.params.id,
			customer: {},
			loadingPaymentErrors: false,
			showPaymentErrorsSummary: false,
			paymentErrors: [],
			totalUnpaidAmount: null,
			nbOrdersUnpaid: null,
			boxesWithPaymentErrors: [],
			wrong_address: {
				active: false,
				box: null
			},
			sendText: false,
			sendingTextProgress: false,
			isTextTemplatePopInOpened: false,
			selectedTextMessage: {},
			medibank: {},
			num_vouchers_requested: 0,
			priceChangeDiscounts: [],
			showUpsellAttemptedModal: false,
			upsellStatus: {}
		};
	},
	computed: {
		escalationFormLink: function () {
			const url = 'https://hellofreshau1.typeform.com/to';
			const formId = this.preferences.country === 'ao' ? 'LtfsGw' : 'ossgGT';

			return `
                  ${url}/${formId}?customer_name=${
	this.customer.name
}&customer_phone=${this.customer.phone}&customer_email=${
	this.customer.email
}&bob_id=${this.customer.bob_id}&agent_name=${
	this.userProfile.first_name + ' ' + this.userProfile.last_name
}`;
		},
		fraudFlag: function () {
			if (
				this.customer.loyalty > 5 &&
        (100 * this.customer.error_count) / this.customer.loyalty > 30
			) {
				return true;
			} else {
				return false;
			}
		}
	},
	watch: {
		paymentErrors: function (val) {
			if (val) {
				this.totalUnpaidAmount = val
					.reduce((acc, cur) => {
						return acc + parseFloat(cur.grand_total);
					}, 0)
					.toFixed(2);

				this.nbOrdersUnpaid = val.length;
				this.boxesWithPaymentErrors = this.getBoxesWithPaymentErrors();
			}
		}
	},
	async mounted() {
		await this.getCustomer();
		this.getMedibank();
		this.getNumVouchersRequested();
		this.getPriceChangeDiscount();
		this.getUpsellingStatus();
		this.$nextTick(function () {
			this.getPaymentErrors();
		});
	},
	methods: {
		getCustomer() {
			this.progress = true;
			return axios
				.get(`${CONSTANTS.BASE_URL}/api/v1` + '/customers/' + this.customer_id, {
					params: {
						country: this.preferences.country
					}
				})
				.then((response) => {
					if (response.data.msg === 'success') {
						this.customer = response.data.customer;
						this.paymentErrors = response.data.payment_errors;
						this.progress = false;
						let error_count = 0;

						this.customer.subscriptions.forEach((sub) => {
							sub.boxes.forEach((box) => {
								box.color = 'primary';
								if (box.lmc) {
									box.color = 'warning';
								}
								if (box.food_safety_issue) {
									box.color = 'warning';
									error_count++;
								}
								if (box.errors || box.live_errors) {
									box.color = 'error';
									error_count++;
								}
								if (box.wrong_address.fixed === false) {
									box.color = 'error wrong-address';
									this.wrong_address.active = true;
									this.wrong_address.box = box.id;
								}
								this.customer.error_count = error_count;
								const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
								const date = new Date(box.delivery_date);
								box.delivery_day_of_week = days[date.getDay()]
							});
						});
					}
				});
		},
		getPriceChangeDiscount() {
			const customerSubscriptions = this.customer.subscriptions.map(
				(subscription) => subscription.id
			);
			axios
				.get(`${CONSTANTS.BASE_URL}/api/v1/customers/price_change_discounts`, {
					params: {
						country: this.preferences.country,
						bob_id: this.customer.bob_id,
						customer_subscriptions: JSON.stringify(customerSubscriptions)
					}
				})
				.then(({ data }) => {
					this.priceChangeDiscounts = data.price_change_discounts;
				})
				.catch((error) => {
					notifications.addNotification(
						'Error: could not get customer price change discount info',
						true
					);
				});
		},
		getUpsellingStatus() {
			axios.get(`${CONSTANTS.BASE_URL}/api/v1/customer/upselling_status`,{
				params: {
					country: this.preferences.country,
					customer_id: this.customer.bob_id
				}
			})
				.then(({ data }) => {
					this.upsellStatus = data.upsell_status;
				})
				.catch((error) => {
					notifications.addNotification(
						'Error: could not get customer upselling status',
						true
					);
				});
		},
		updateUpsellingStatus() {
			axios.post(`${CONSTANTS.BASE_URL}/api/v1/customer/upselling_status`,{
				country: this.preferences.country,
				customer_id: this.customer.bob_id
			})
				.then((response) => {
					if (response.data.msg === 'success')
						this.upsellStatus.upsell_attempted = true
					notifications.addNotification('Customer marked as upsell attempted',true);
				}
				)
				.catch(() => {
					notifications.addNotification('Error: could not update customer upselling status',true);
				});
		},
		getPaymentErrors() {
			// get customer payment error details from bob
			this.loadingPaymentErrors = true;

			axios
				.get(
					`${CONSTANTS.BASE_URL}/api/v1/payment_errors/customer/${this.customer.bob_id}`,
					{
						params: {
							country: this.preferences.country
						}
					}
				)
				.then((response) => {
					this.paymentErrors = response.data.payment_errors;
					this.loadingPaymentErrors = false;
				})
				.catch((error) => {
					notifications.addNotification(
						'There was an error getting payment error details from Bob',
						true
					);
					this.loadingPaymentErrors = false;
				});
		},
		sendTextMessageToCustomer(template) {
			this.sendText = false;
			this.sendingTextProgress = true;
			axios
				.post(
					`${CONSTANTS.BASE_URL}/api/v1` + '/customers/' + this.customer_id + '/send/text',
					{
						country: this.preferences.country,
						template: template
					}
				)
				.then((response) => {
					if (response.data.msg === 'success') {
						this.sendingTextProgress = false;
						notifications.addNotification('Message sent!')
						this.getCustomer();
					}
				})
				.catch(() => {
					this.sendingTextProgress = false;
					notifications.addNotification(
						'Oups, something goes wrong when sending a message.',
						true
					);
				});
		},
		markUpsellAttempted() {
			this.updateUpsellingStatus()
			this.closeMarkUpsellAttemptedModal()
		},
		closeMarkUpsellAttemptedModal() {
			this.showUpsellAttemptedModal=false
		},
		closePopInMessage() {
			this.sendText = false;
		},
		changeSelectedMessage(textMessage) {
			this.selectedTextMessage = textMessage;
			this.isTextTemplatePopInOpened = true;
		},
		getMedibank() {
			this.progress = true;
			axios
				.get(`${CONSTANTS.BASE_URL}/api/v1` + '/customers/medibank', {
					params: {
						country: this.preferences.country,
						customer_id: this.customer_id
					}
				})
				.then((response) => {
					if (response.data.msg === 'success') {
						this.medibank = response.data.medibank;
						this.progress = false;
					}
				});
		},
		getNumVouchersRequested() {
			this.progress = true;
			axios
				.get(`${CONSTANTS.BASE_URL}/api/v1` + '/customers/vouchers', {
					params: {
						country: this.preferences.country,
						customer_id: this.customer_id
					}
				})
				.then((response) => {
					if (response.data.msg === 'success') {
						this.num_vouchers_requested = response.data.num_vouchers_requested;
						this.progress = false;
					}
				});
		},
		getBoxesWithPaymentErrors() {
			const { subscriptions } = this.customer;
			const paymentErrorOrderNumbers = this.paymentErrors.map((error) =>
				parseInt(error.order_nr)
			);
			const boxOrderNumbers = subscriptions.reduce((result, subscription) => {
				const customerOrders = subscription.boxes.map(
					(box) => box.customer_order
				);
				return [...result, ...customerOrders];
			}, []);

			return boxOrderNumbers.filter((orderNumber) =>
				paymentErrorOrderNumbers.includes(orderNumber)
			);
		},
		togglePaymentErrorsSummary() {
			this.showPaymentErrorsSummary = !this.showPaymentErrorsSummary;
		}
	}
};
</script>

<style scoped>
.wrong-address {
  animation: blinker 2s linear infinite;
}

.box-badge {
  padding-right: 1.5em
}

.v-btn {
  margin: 4px;
}

.payment-failure-badge {
  display: inline-block;
  margin: 0.5rem auto 0 auto;
  background-color: #ff7a73;
  padding: 0.5rem 1.5rem;
}

@keyframes blinker {
  50% {
    opacity: 0.3;
  }
}
</style>
