<template>
  <v-col cols="12" class="wrong-address-box">
    <v-form ref="wrongAddress">
    <v-card raised tile>
      <v-card-title primary-title class="error">
        <h3 class="display-1 font-weight-bold">Wrong address detected</h3>
      </v-card-title>
      <v-card-text>
        <v-row wrap>
          <v-col cols="12">
            <v-text-field label="Search new address" v-model="new_address"
                          :loading="verify_address_loading"></v-text-field>
          </v-col>
        </v-row>
        <v-alert :value="error !== ''" type="warning" transition="scale-transition">
          {{ error }}
        </v-alert>
        <v-slide-y-transition>
          <v-row wrap v-if="validation.valid">
            <v-col cols="12" class="my-4">
              <span class="title">New address</span>
            </v-col>
            <v-col cols="6">
              <v-text-field label="New premise (business name for example)" v-model="validation.new_premise"
                            outlined></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field label="New subpremise (unit for example)" v-model="validation.new_subpremise"
                            outlined></v-text-field>
            </v-col>
            <v-col cols="4">
              <v-text-field label="New number" v-model="validation.new_street_number" outlined></v-text-field>
            </v-col>
            <v-col cols="8">
              <v-text-field label="New street" v-model="validation.new_street" outlined></v-text-field>
            </v-col>
            <v-col cols="8">
              <v-text-field label="New suburb" v-model="validation.new_city" outlined></v-text-field>
            </v-col>
            <v-col cols="4">
              <v-text-field label="New postcode" v-model="validation.new_postcode" outlined></v-text-field>
            </v-col>
            <v-col cols="12" v-if="new_windows.length > 0">
              <v-select :items="new_windows" label="New delivery window" v-model="validation.new_delivery_time"
                        outlined
                        prepend-icon="warning"></v-select>
            </v-col>
          </v-row>
        </v-slide-y-transition>
        <v-row wrap>
          <v-col cols="12">
            <v-text-field label="New delivery instructions" v-model="validation.new_di" counter="50" :rules="deliveryInstructionsRules" outlined></v-text-field>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions v-if="user.profile.has_bob_session">
        <v-spacer></v-spacer>
        <v-btn :outline="!validation.valid" :flat="validation.valid" color="error" @click="searchAddress"
               :loading="verify_address_loading">
          Search the address
        </v-btn>
        <v-btn outlined color="success" @click="validateAddress" :loading="fix_address_loading"
               v-if="validation.valid">
          Fix the address
        </v-btn>
      </v-card-actions>
      <v-card-actions v-else>
        <div style="width: 100%; text-align: center;">
          <span class="grey--text">Connect to <b>Bob</b> now to fix this wrong address!</span>
        </div>
      </v-card-actions>
    </v-card>
      </v-form>

    <v-dialog v-model="alert" persistent max-width="290">
      <v-card>
        <v-card-title class="headline">There was an issue with Bob</v-card-title>
        <v-card-text>I tried to upload the address in the Bob subscription, but I didn't manage to do it. Could you do it by hand ?</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click.native="alert = false; $emit('update-address');" target="_blank" :href="bobLink">Subscription</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-col>
</template>

<script>
import axios from 'axios';
import auth from '../../modules/auth';
import {CONSTANTS} from '@/modules/constants';

export default {
	name: 'WrongAddressBox',
	props: ['box', 'address', 'di', 'bobLink'],
	data() {
		return {
			fix_address_loading: false,
			verify_address_loading: false,
			new_address: this.address,
			error: '',
			validation: {
				valid: false,
				new_subpremise: '',
				new_premise: '',
				new_street_number: '',
				new_street: '',
				new_city: '',
				new_postcode: '',
				new_di: this.di,
				new_delivery_time: ''
			},
			new_windows: [],
			user: auth.user,
			alert: false,
			deliveryInstructionsRules: [v => v.length <= 50 || 'Max 50 characters']
		}
	},
	methods: {
		searchAddress() {
			this.verify_address_loading = true;
			this.error = '';
			this.validation.new_subpremise = '';
			this.validation.new_premise = '';
			this.validation.new_street_number = '';
			this.validation.new_street = '';
			this.validation.new_city = '';
			this.validation.new_postcode = '';
			this.validation.new_lat = '';
			this.validation.new_lng = '';
			this.validation.valid = false;
			axios.get(`${CONSTANTS.BASE_URL}/api/v1` + '/boxes/wrong_addresses/' + this.box + '/validate', {params: {address: this.new_address}}).then((response) => {
				if (response.data.msg === 'success') {
					this.validation.valid = true;
					this.validation.new_subpremise = response.data.search.subpremise;
					this.validation.new_premise = response.data.search.premise;
					this.validation.new_street_number = response.data.search.street_number;
					this.validation.new_street = response.data.search.street;
					this.validation.new_city = response.data.search.city;
					this.validation.new_postcode = response.data.search.postcode;
					this.validation.new_lat = response.data.search.lat;
					this.validation.new_lng = response.data.search.lng;
				}
				this.verify_address_loading = false;
			}).catch((error) => {
				this.error = 'Address not recognized. If you are confident about the new address, you can fill the correct fields';
				this.verify_address_loading = false;
				this.validation.valid = true;
			});
		},
		validateAddress() {
			if (this.$refs.wrongAddress.validate()) {
				if (this.new_windows.length === 0 || this.validation.new_delivery_time === '') {
					this.fix_address_loading = true;
					axios.post(`${CONSTANTS.BASE_URL}/api/v1` + '/boxes/wrong_addresses/' + this.box + '/validate', {new_address: this.validation}).then((response) => {
						if (response.data.msg === 'success') {
							if (response.data.validation.status !== 'validated') {
								this.new_windows = response.data.validation.windows;
								this.fix_address_loading = false;
							} else {
								this.fixAddress();
							}
						}
					});
				} else {
					this.fixAddress();
				}
			}
		},
		fixAddress() {
			this.fix_address_loading = true;
			this.error = '';
			axios.post(`${CONSTANTS.BASE_URL}/api/v1` + '/boxes/wrong_addresses/' + this.box, {
				validation: this.validation
			}).then((response) => {
				if (response.data.msg === 'success') {
					this.$emit('update-address');
				} else if (response.data.msg === 'warning') {
					this.alert = true;
				}
			});
		}
	}
}
</script>

<style scoped>
  .wrong-address-box {
    margin-bottom: 2em;
  }
</style>
