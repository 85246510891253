import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';
import createPersistedState from 'vuex-persistedstate';
import {CONSTANTS} from '@/modules/constants';

Vue.use(Vuex);

const initialState = () => ({
	country: '',
	weeks: [],
	week: '',
	currentWeek: '',
	drawer: true,
	accessToken: null,
	profile: {},
	toggles: {}
});

export default new Vuex.Store({
	state: initialState,
	plugins: [createPersistedState()],
	getters: {
		ccAccessLevel: (state) => {
			if (state.profile.new_starter) {
				return 'new_starter';
			} else if (state.profile.team_leader || state.profile.admin) {
				return 'team_leader';
			} else if (state.profile.senior_agent) {
				return 'senior_agent';
			} else {
				return 'agent';
			}
		}
	},
	mutations: {
		CLEAR_STATE(state) {
			const s = initialState();
			Object.keys(s).forEach((key) => {
				state[key] = s[key];
			});
		},
		SET_ACCESS_TOKEN(state, accessToken) {
			state.accessToken = accessToken;
		},
		SET_PROFILE(state, profile) {
			state.profile = profile;
		},
		SET_WEEKS(state, weeks) {
			state.weeks = weeks;
		},
		SET_CURRENT_WEEK(state, currentWeek) {
			state.currentWeek = currentWeek;
			if (state.week === '') {
				state.week = state.currentWeek;
			}
		},
		SET_WEEK(state, newWeek) {
			state.week = newWeek;
		},
		RESET_WEEK(state) {
			state.week = state.currentWeek;
		},
		SET_COUNTRY(state, country) {
			state.country = country;
		},
		CLOSE_NAV_DRAWER(state) {
			state.drawer = false;
		},
		TOGGLE_NAV_DRAWER(state) {
			state.drawer = !state.drawer;
		},
		SET_NAV_DRAWER(state, drawer) {
			state.drawer = drawer;
		},
		SET_TOGGLES(state, toggles) {
		  state.toggles = toggles;
		}
	},
	actions: {
		clearState({ commit }) {
			commit('CLEAR_STATE');
		},
		setAccessToken({ commit }, accessToken) {
			commit('SET_ACCESS_TOKEN', accessToken);
		},
		setProfile({ commit }, profile) {
			commit('SET_PROFILE', profile);
		},
		getWeeks({ commit }) {
			axios
				.get(`${CONSTANTS.BASE_URL}/api/v1` + '/boxes/weeks_fast', {
					params: { country: this.state.country }
				})
				.then((response) => {
					commit('SET_WEEKS', response.data.weeks);
					commit('SET_CURRENT_WEEK', response.data.current_week);
				});
		},
		getCurrentWeek({ commit }) {
			axios
				.get(`${CONSTANTS.BASE_URL}/api/v1` + '/boxes/current-week', {
					params: { country: this.state.country }
				})
				.then((response) => {
					if (response.data.msg === 'success') {
						commit('SET_CURRENT_WEEK', response.data.current_week);
					}
				});
		},
		updateWeek({ commit }, newWeek) {
			commit('SET_WEEK', newWeek);
		},
		resetWeek({ commit }) {
			commit('RESET_WEEK');
		},
		closeNavDrawer({ commit }) {
			commit('CLOSE_NAV_DRAWER');
		},
		toggleNavDrawer({ commit }) {
			commit('TOGGLE_NAV_DRAWER');
		},
		setNavDrawer({ commit }, drawer) {
			commit('SET_NAV_DRAWER', drawer);
		},
		setFeatureToggle({ commit }, toggles) {
			commit('SET_TOGGLES', toggles)
		}
	}
});
