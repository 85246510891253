<template>
  <div>
    <v-dialog v-model="damageClaimPopUp">
      <v-card v-if="!damageClaimSent">
        <v-row>
          <v-col cols="12">
            <v-card-subtitle>Are you sure to send a damage claim form to {{ customer.name }} ( {{ customer.id }} ) ?
            </v-card-subtitle>
          </v-col>
          <v-col cols="12">
            <v-spacer></v-spacer>
            <v-btn color="primary" class="mx-1" @click="sendDamageClaim">Send</v-btn>
            <v-btn color="primary" class="mx-1" @click="damageClaimPopUp = false">Cancel</v-btn>
          </v-col>
        </v-row>
      </v-card>

      <v-card v-if="damageClaimSent">
        <v-card-subtitle>Damage claim form sent to {{ customer.name }} ( {{ customer.id }} )
        </v-card-subtitle>
      </v-card>
    </v-dialog>
    <v-container>
      <v-row>
        <v-col cols="8">
          <h1 class="display-3">{{ customer.name }}</h1>
          <h2 class="subtitle-1 grey--text">Customer {{ customer.bob_id }}</h2>
          <v-btn text icon color="black" :to="{name: 'customer', params: {id: this.customer.id}}">
            <v-icon large>arrow_back</v-icon>
          </v-btn>
        </v-col>
        <v-spacer></v-spacer>
        <v-col cols="3">
          <v-select :items="weeks" v-model="selectedWeek" label="Week" solo :loading="progress"></v-select>
        </v-col>
      </v-row>
      <v-row>
        <v-spacer></v-spacer>
        <v-col cols="6" class="text-right">
          <v-btn color="primary" class="mx-1" @click="damageClaimPopUp = true" v-if="preferences.country !== 'ye'">
            Damage
            Claim
          </v-btn>
          <v-btn color="primary" class="mx-1" :href="customer.bob_account_link">Bob Account</v-btn>
        </v-col>
      </v-row>

    </v-container>
    <div v-for="sub_id in subscriptions">
      <SubscriptionSummary
        :key="sub_id + selectedWeek"
        :sub_id="sub_id"
        :week="selectedWeek"
        :packages="packages"
      >
      </SubscriptionSummary>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import auth from '@/modules/auth';
import SubscriptionSummary from './SubscriptionSummary';
import notifications from '@/modules/notifications';
import {CONSTANTS} from '@/modules/constants';

export default {
	name: 'CustomerWeekSummary',
	components: {SubscriptionSummary},
	data() {
		return {
			customer_id: this.$route.params.id,
			customer: {},
			subscriptions: null,
			preferences: auth.user.preferences,
			progress: false,
			windows: [],
			search: '',
			damageClaimPopUp: false,
			damageClaimSent: false,
			packages: []
		}
	},
	watch: {
		selectedWeek: function () {
			this.refreshWindows();
			this.getPackages();
		}
	},
	computed: {
		selectedWeek: {
			set(week) {
				this.$store.dispatch('updateWeek', week)
			},
			get() {
				return this.$store.state.week
			}
		},
		weeks() {
			return this.$store.state.weeks
		}
	},
	mounted() {
		this.getCustomer();
		this.getCustomerSubscriptionIds();
		this.getPackages();
	},
	methods: {
		getPackages() {
			this.progress = true;
			axios
				.get(`${CONSTANTS.BASE_URL}/api/v1/packages`, {
					params: {
						week: this.selectedWeek,
						country: this.preferences.country,
						customer_id: this.customer_id
					}
				})
				.then(response => {
					this.packages = response.data.packages;
				})
				.catch(error => {
					notifications.addNotification('Error getting pacakages.', true)
				})
		},
		refreshWindows() {
			this.progress = true;
			this.getCustomerSubscriptionIds()
		},
		getCustomer() {
			this.progress = true;
			axios.get(`${CONSTANTS.BASE_URL}/api/v1` + '/customers/' + this.customer_id, {
				params:
          {country: this.preferences.country}
			}).then((response) => {
				if (response.data.msg === 'success') {
					this.customer = response.data.customer;
				}
				this.progress = false;
			});
		},
		getCustomerSubscriptionIds() {
			this.progress = true;
			axios.get(`${CONSTANTS.BASE_URL}/api/v1` + '/customer/' + this.customer_id + '/subscriptions', {
				params:
          {
          	country: this.preferences.country,
          	week: this.selectedWeek
          }
			}).then((response) => {
				if (response.data.msg === 'success') {
					this.subscriptions = response.data.subscription_list
				}
				this.progress = false;
			})
		},
		sendDamageClaim() {
			this.progress = true;
			axios.get(`${CONSTANTS.BASE_URL}/api/v1` + '/damage-claim/' + this.customer_id + '/send', {
				params:
          {country: this.preferences.country}
			}).then((response) => {
				if (response.data.msg === 'success') {
					this.damageClaimSent = true;
					this.progress = false;
				}
			})
		}
	}
}
</script>

<style scoped>

</style>
