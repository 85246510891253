<template>
  <div>
    <v-form ref="editForm">
      <v-card class="pa-6">
        <v-progress-linear indeterminate v-if="progress" color="secondary" class="progress"></v-progress-linear>
        <v-card-title class="display-3">Edit Form</v-card-title>
        <v-card-text>
        <v-container fluid>
          <v-row>
            <v-col xs="12" sm="6">
              <v-text-field
                label="name"
                v-model="row['name']">
              </v-text-field>
            </v-col>
            <v-col xs="12" sm="6" md="3">
              <v-checkbox
                label="leadership only"
                v-model="row['leadership_only']">
              </v-checkbox>
            </v-col>
            <v-col xs="12" sm="6" md="3">
              <v-checkbox
                :label="duplicateFormUpdateLabel"
                v-model="save_other_country">
              </v-checkbox>
            </v-col>
            <v-col xs="12" sm="6">
              <v-text-field
                label="description"
                v-model="row['description']">
              </v-text-field>
            </v-col>
            <v-col xs="12" sm="6">
              <v-text-field
                label="notification email (response submitted)"
                v-model="row['email']">
              </v-text-field>
            </v-col>

            <v-col cols="12">
              <v-row wrap>
                <v-col xs="12" sm="6" md="3">
                  <v-checkbox
                    label="get customer id"
                    v-model="row['get_customer_id']">
                  </v-checkbox>
                </v-col>
                <v-col xs="12" sm="6" md="3" v-if="row['get_customer_id']">
                  <v-checkbox
                    label="display customer contact"
                    v-model="row['display_customer_contact']">
                  </v-checkbox>
                </v-col>
                <v-col xs="12" sm="6" md="3" v-if="row['get_customer_id']">
                  <v-checkbox
                    label="display customer address"
                    v-model="row['display_customer_address']">
                  </v-checkbox>
                </v-col>
                <v-col xs="12" sm="6" md="3" v-if="row['get_customer_id']">
                  <v-checkbox
                    label="display customer loyalty"
                    v-model="row['display_customer_loyalty']">
                  </v-checkbox>
                </v-col>
              </v-row>
            </v-col>

            <v-row v-for="i in 15" :key="i" row wrap>
                <v-col xs="12" sm="6" md="3">
                  <v-text-field
                    :label="'field ' + i.toString()"
                    v-model="row['field_' + (i-1).toString()]">
                  </v-text-field>
                </v-col>
                <v-col xs="12" sm="6" md="3">
                  <v-autocomplete v-if="rowDB['field_' + (i-1).toString()] === null"
                                  :label="'type ' + i.toString()"
                                  v-model="row['type_' + (i-1).toString()]"
                                  :items="data_types">
                  </v-autocomplete>
                  <v-text-field v-else
                                :label="'type ' + i.toString() + ' (read only)'"
                                v-model="row['type_' + (i-1).toString()]"
                                :items="data_types"
                                readonly></v-text-field>
                </v-col>
                <v-col xs="12" sm="6" md="3">
                  <v-text-field
                    :label="'dropdown'"
                    v-model="dropdowns['item_' + (i-1).toString()]">
                  </v-text-field>
                </v-col>
                <v-col
                        xs="12" sm="6" md="3">
                  <v-checkbox
                    :label="'required'"
                    :disabled="row['type_' + (i-1).toString()] === 'Email Notification'"
                    v-model="row['required_' + (i-1).toString()]"
                    class="ma-0 pa-0"
                    hide-details>
                  </v-checkbox>
                  <v-checkbox
                    :label="'hidden'"
                    :disabled="row['type_' + (i-1).toString()] === 'Email Notification'"
                    v-model="row['hidden_' + (i-1).toString()]"
                    class="ma-0 pa-0"
                    hide-details>
                  </v-checkbox>
                </v-col>


            </v-row>
          </v-row>
        </v-container>
        </v-card-text>
        <v-card-actions class="justify-center">
          <v-btn text
                 block
                 color="primary"
                 @click="saveIssue(preferences.country)"
          >
            Save issue
          </v-btn>
        </v-card-actions>
        <v-progress-circular indeterminate v-if="progress" color="secondary" class="progress"></v-progress-circular>
      </v-card>
    </v-form>

  </div>
</template>

<script>
import axios from 'axios';
import auth from '../../modules/auth';
import notifications from '@/modules/notifications';
import {CONSTANTS} from '@/modules/constants';

export default {
	name: 'LiveDataEditIssue',
	props: {
		issue_name: {type: String, required: true}
	},
	data() {
		return {
			preferences: auth.user.preferences,
			userProfile: auth.user.profile,
			progress: false,
			row: {},
			rowDB: {},
			dropdowns: {},
			data_types: [
				'Text',
				'Number',
				'Decimal',
				'Checkbox',
				'Date',
				'Time',
				'Email Notification'],
			// issue_name: this.issue_name,
			save_other_country: false,
			rules: {
				'Customer Id': [v => !isNaN(v) || 'A customer id is required', v => v.length > +5 || 'A customer id is required'],
				'Date': [v => v.length === 10 || 'date format: yyyy-mm-dd', v => v[4] === '-' || 'date format yyyy-mm-dd', v => v[7] === '-' || 'date format yyyy-mm-dd'],
				'Time': [v => v.length === 5 || '24 hr: hh:mm', v => v[2] === ':' || '24 hr: hh:mm', v => !isNaN(v.substring(0, 2)) && v.substring(0, 2) < 24 || '24 hr: hh:mm',
					v => !isNaN(v.substring(3, 5)) && v.substring(3, 5) < 60 || '24 hr: hh:mm'],
				'Number': [v => !isNaN(v) || 'Enter a number', v => v.length > 0 || 'Enter a number'],
				'Text': [v => v.length > 0 || 'A response is required']
			}
		}
	},
	mounted: function () {
		this.getIssueRow()
	},
	watch: {
		issue_name: function () {
			this.getIssueRow()
		}
	},
	computed: {
		duplicateFormUpdateLabel: function () {
			if (this.preferences.country === 'au') {
				return 'Update form in NZ'
			} else {
				return 'Update form in AU'
			}
		}
	},
	methods: {
		getIssueRow: function () {
			this.progress = true;
			axios.get(`${CONSTANTS.BASE_URL}/api/v1` + '/issues/issue_row', {
				params: {
					country: this.preferences.country,
					issue_name: this.issue_name
				}
			})
				.then((response) => {
					if (response.data.msg === 'success') {
						this.row = response.data.row
						this.rowDB = Object.assign({}, response.data.row)
						this.dropdowns = response.data.dropdowns
						this.progress = false;


					}
				})
		},
		saveIssue: function (country) {
			if (this.$refs.editForm.validate()) {
				this.progress = true;
				axios.post(`${CONSTANTS.BASE_URL}/api/v1` + '/issues/edit_issue', {
					issue_name: this.issue_name,
					country: country,
					row: this.row,
					dropdowns: this.dropdowns
				})
					.then((response) => {
						if (response.data.msg === 'success') {
							notifications.addNotification(country.toUpperCase() + ` - Form parameters updated successfully`, true)
							this.$emit('issueUpdated')
							if (this.save_other_country) {
								this.save_other_country = false
								this.saveIssue(country === 'au' ? 'nz' : 'au')
								this.progress = false;

							}
						} else {
							notifications.addNotification(response.data.msg, true)
							this.progress = false;

						}
					}).catch(() => {
						notifications.addNotification(`There was a problem updating the form parameters`, true)
						this.progress = false;
					})
			}
		}
	}
}


</script>
