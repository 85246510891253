<template>
  <div class="details">
    <v-dialog v-model="foodSafetyPopIn" width="800">
      <food-safety
        :close-pop-in="closeFoodSafetyPopin"
        :customer-mail="box.customer !== undefined ? box.customer.email : ''"
        :send-food-safety-form-to-customer="sendFoodSafetyFormTemplate"
        :recipes="recipesOptions"
        :food-safety-form="box.food_safety !== undefined ? box.food_safety : {}"
        :box="box"
      ></food-safety>
    </v-dialog>

    <v-dialog v-model="damageClaimPopIn" width="800">
      <damage-claim
        :close-pop-in="closeDamageClaimPopin"
        :box="box"
        :send-damage-claim-form-to-customer="sendDamageClaimForm"
        :email="box.customer.email"
        :damage-claim-form="box.damage_claim"
      />
    </v-dialog>

    <v-progress-linear
      indeterminate
      v-if="progress"
      color="secondary"
      class="progress"
    ></v-progress-linear>
    <v-container grid-list-sm>
      <transition-group
        class="layout row wrap"
        name="list-complete"
        v-if="box.id !== undefined"
        appear
        tag="div"
      >
        <v-col cols="6" key="title" class="mb-6">
          <h1 class="display-3">Box {{ box.id }}</h1>
          <h2 class="subheading grey--text">Week {{ box.week }}</h2>
        </v-col>
        <v-col cols="3" key="prevision" v-if="box.prevision">
          <v-alert :value="true" type="warning" outlined class="prevision-box">
            This box is a <b>prevision</b> box. It has not been confirmed yet.
          </v-alert>
        </v-col>
        <v-col
          cols="3"
          v-else-if="box.food_safety !== undefined && preferences.country !== COUNTRYCODE.YE"
          key="food_safety"
          class="headline-flex"
        >
          <v-spacer/>
          <v-btn
            class="ma-4"
            color="warning"
            @click="
              foodSafetyPopIn = true;
              foodSafetyFormSent = false;
            "
          >
            <v-icon v-if="!box.food_safety.answered" class="pr-4"
            >router
            </v-icon
            >
            <v-icon v-else-if="!box.food_safety.qa_reviewed" class="pr-4"
            >find_replace
            </v-icon
            >
            <v-icon v-else class="pr-4">done</v-icon>
            {{
              box.food_safety.food_safety_type.charAt(0).toUpperCase() +
              box.food_safety.food_safety_type.slice(1).replace(/\_/g, " ")
            }}
          </v-btn>
        </v-col>
        <v-col cols="3" v-else-if="preferences.country !== COUNTRYCODE.YE" key="food_safety_progress"
               class="headline-flex">
          <v-spacer/>
          <v-btn
            class="mx-2 my-4"
            color="warning"
            @click="
              foodSafetyPopIn = true;
              foodSafetyFormSent = false;
            "
          >
            <v-progress-circular
              v-if="foodSafetyProgress"
              class="mr-4"
              right
              indeterminate
              color="white"
              :size="24"
              :width="3"
            ></v-progress-circular>
            <v-icon v-else-if="foodSafetyFormSent" class="pr-4"
            >hourglass_empty
            </v-icon
            >
            <v-icon v-else class="pr-4">new_releases</v-icon>
            Food safety issue
          </v-btn>

          <!--          <v-btn v-if="agentIsInZagreb" class="mt-4" color="warning" :href="zagrebLMCTypeFromLink" target="_blank">-->
          <!--            <v-icon class="pr-4">alarm</v-icon>-->
          <!--            Zagreb LMC-->
          <!--          </v-btn>-->

          <v-dialog v-if="overnightLmcEnabled" max-width="1200px">
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" class="mx-2 my-4" color="warning">
                <v-icon class="pr-4">alarm</v-icon>
                Overnight LMC
              </v-btn>
            </template>
            <v-card>
              <live-data-form
                :issue_name="'Overnight LMC Process'"
                :leadership_only="false"
                showHeader
              ></live-data-form>
            </v-card>
          </v-dialog>
        </v-col>
        <v-col cols="3" key="damage_claim" class="headline-flex" v-if="!['nz', 'ye'].includes(preferences.country)">
          <v-spacer/>
          <v-btn
            class="mx-12 my-5"
            color="warning"
            :loading="damageClaimProgress"
            @click="
              damageClaimPopIn = true;
              damageClaimFormSent = false;
            "
          >
            <v-icon class="pr-4">new_releases</v-icon>
            Damage claim
          </v-btn>
        </v-col>

        <wrong-address-box
          key="wrong-address"
          v-if="wrong_address && wrong_address.status === 'issued'"
          :box="box.id"
          :address="complete_address"
          :di="box.delivery_instructions"
          :bob-link="bob_subscription_link"
          v-on:update-address="updateAddress"
        ></wrong-address-box>
        <v-col cols="12"
               :key="error.id"
               v-for="error in box.live_errors.slice().reverse()"
        >
          <div class="headline-flex">
            <v-icon color="error">history</v-icon>
            <h4 class="px-2 py-1 error--text">
              {{
                error.date + " - " + error.hour + ", by " + error.team_leader
              }}
            </h4>
          </div>
          <v-card class="error-card">
            <v-card-title primary-title class="error">
              <h3 class="display-1 font-weight-bold">
                Updates reported : {{ error.title }}
              </h3>
            </v-card-title>
            <v-row wrap>
              <v-col cols="4">
                <v-list two-line>
                  <v-list-item>
                    <v-list-item-action>
                      <v-icon>warning</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-subtitle>Complaint</v-list-item-subtitle>
                      <v-list-item-title>
                        {{ error.complaint }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-col>
              o
              <v-col cols="8" class="header">
                <v-row wrap>
                  <v-col cols="4">
                    <v-list two-line>
                      <v-list-item>
                        <v-list-item-action>
                          <v-btn
                            color="primary"
                            text
                            icon
                            large
                            @click="seeTextTemplate(error)"
                            :disabled="
                              !error.text_notification_sent ||
                              error.text_notification_template === null
                            "
                          >
                            <v-icon
                              v-if="error.text_notification_sent"
                              class="pt-1"
                            >sms
                            </v-icon
                            >
                            <v-icon v-else class="pt-1">sms_failed</v-icon>
                          </v-btn>
                        </v-list-item-action>
                        <v-list-item-content>
                          <v-list-item-subtitle
                          >Text message
                          </v-list-item-subtitle
                          >
                          <v-list-item-title v-if="error.text_notification_sent"
                          >Sent
                          </v-list-item-title
                          >
                          <v-list-item-title v-else>Not sent</v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </v-col>
                  <v-col cols="4">
                    <v-list two-line>
                      <v-list-item>
                        <v-list-item-action>
                          <v-icon>attach_money</v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                          <v-list-item-subtitle>
                            {{
                              "Compensation " +
                              (error.credit_update !== null ? "applied" : "")
                            }}
                          </v-list-item-subtitle>
                          <v-list-item-title
                            v-if="error.credit_update !== null"
                          >
                            {{ error.credit_update }}
                          </v-list-item-title>
                          <v-list-item-title
                            v-else-if="error.compensation_amount.length > 0"
                          >
                            ${{ error.compensation_amount }} of
                            {{ error.compensation_type }}
                          </v-list-item-title>
                          <v-list-item-title v-else>{{
                              error.compensation_type
                            }}
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </v-col>
                  <v-col cols="4" class="px-4 py-2">
                    <v-btn
                      color="warning"
                      class="mt-4"
                      block
                      @click="updateCompensationDetails(error.id)"
                      :disabled="error.credit_update !== null"
                    >
                      <v-icon left dark>attach_money</v-icon>
                      Compensate
                    </v-btn>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12">
                <v-subheader>Team leader information</v-subheader>
                <div class="px-6 pb-2" v-html="error.script"></div>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
        <v-col cols="12" key="lmc" v-if="(lmc_auth || lmc_present)">
          <v-card class="lmc-card">
            <v-card-title primary-title class="warning">
              <h3>
                LMC process changed. Please go to Week Summary Page to LMC all
                boxes from the delivery
              </h3>
              <v-btn @click="$router.push(weekSummaryLink)">
                Week Summary
              </v-btn>
            </v-card-title>
            <v-alert
              :value="true"
              type="warning"
              class="black--text ma-0"
              v-if="lmc_message">
              <div v-html="lmc_message"></div>
            </v-alert>
          </v-card>
        </v-col>
        <v-col cols="12" :key="error.id" v-for="error in box.errors">
          <v-card class="error-card">
            <v-card-title primary-title class="error">
              <h3 class="display-1 font-weight-bold">
                Error reported on {{ error.date }}
              </h3>
              <v-spacer/>
              <span class="subheading">{{ error.id }}</span>
            </v-card-title>
            <v-row wrap>
              <v-col cols="4">
                <v-list two-line>
                  <v-list-item>
                    <v-list-item-action>
                      <v-icon>label</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>{{ error.reason }}</v-list-item-title>
                      <v-list-item-subtitle>Reason</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-hover>
                    <v-list-item slot-scope="{ hover }" width="344">
                      <v-list-item-action>
                        <v-icon>speaker_notes</v-icon>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title>{{
                            error.comment
                          }}
                        </v-list-item-title>
                        <v-list-item-subtitle>Comment</v-list-item-subtitle>
                      </v-list-item-content>
                      <v-card
                        v-if="hover && error.comment.length > 35"
                        width="10000000"
                      >
                        <v-card-text>
                          {{ error.comment }}
                        </v-card-text>
                      </v-card>
                    </v-list-item>
                  </v-hover>
                </v-list>
              </v-col>
              <v-col cols="4">
                <v-list two-line>
                  <v-list-item>
                    <v-list-item-action>
                      <v-icon>warning</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>{{
                          error.complaint
                        }}
                      </v-list-item-title>
                      <v-list-item-subtitle>Complaint</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-action>
                      <v-icon>attach_money</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>{{
                          error.compensation_amount
                        }}
                      </v-list-item-title>
                      <v-list-item-subtitle
                      >Compensation amount
                      </v-list-item-subtitle
                      >
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-col>
              <v-col cols="4">
                <v-list two-line>
                  <v-list-item>
                    <v-list-item-action>
                      <v-icon>details</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>{{ error.detail }}</v-list-item-title>
                      <v-list-item-subtitle
                      >Complaint detail
                      </v-list-item-subtitle
                      >
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-action>
                      <v-icon>details</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>{{
                          error.subdetail
                        }}
                      </v-list-item-title>
                      <v-list-item-subtitle
                      >Complaint subdetail
                      </v-list-item-subtitle
                      >
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
        <v-col cols="12" key="customer">
          <v-card class="customer-card">
            <v-card-title primary-title class="secondary" @click="">
              <v-btn
                text
                icon
                color="white"
                :to="{ name: 'customer', params: { id: box.customer.id } }"
              >
                <v-icon large>arrow_back</v-icon>
              </v-btn>
              <h3 class="display-1 font-weight-bold">
                {{ box.customer.name }}
              </h3>
              <h4 class="display-1">: {{ box.customer.bob_id }}</h4>
              <v-spacer/>
              <v-col cols="4">
                <v-row wrap>
                  <v-btn
                    color="primary"
                    target="_blank"
                    :href="bob_account_link"
                  >BOB Account
                  </v-btn
                  >
                  <v-btn
                    color="primary"
                    target="_blank"
                    :href="bob_subscription_link"
                  >BOB Subscription
                  </v-btn
                  >
                  <div v-if="box.delivery_courier">
                    <v-btn
                      color="secondary"
                      :href="beCoolPortalLink"
                      v-if="
                        box.delivery_courier.name !== 'HDS WA' &&
                        preferences.country !== 'nz'
                      "
                      target="_blank"
                    >
                      Be Cool Portal v2
                    </v-btn>
                  </div>
                  <v-btn
                    v-if="
                      !box.prevision &&
                      box.delivery_courier.main_courier !== 'BeCool'
                    "
                    color="secondary"
                    :href="box.tracking_link"
                    target="_blank"
                  >Tracking link
                  </v-btn>
                  <div v-if="
                    box.delivery_courier &&
                    box.delivery_courier.name === 'NZ POST'
                  ">
                    <tracking-status-modal
                      :trackingDetails="deliveryDetails"
                      :nzpError="nzpError"
                    />
                  </div>
                  <div
                    v-if="
                       !box.prevision &&
                      (userProfile.team_leader || userProfile.admin || userProfile.food_safety)
                    "
                  >
                    <v-dialog max-width="400px">
                      <template v-slot:activator="{ on }">
                        <v-btn
                          v-on="on"
                          color="secondary"
                          v-clipboard="deliveryInfoSummaryToCopy"
                        >
                          Delivery Info Summary
                        </v-btn>
                      </template>
                      <div>
                        <v-card>
                          <v-card-title>
                            <h3 class="headline">Delivery info summary</h3>
                          </v-card-title>
                          <v-card-text class="delivery-info-summary">
                            ID: {{ box.customer_subscription }}<br/>
                            NAME: {{ box.customer.name }}<br/>
                            ADDRESS: {{ complete_address }}<br/>
                            DELIVERY INST: {{
                              box.delivery_instructions
                            }}<br/><br/>
                            <span v-if="box.delivery_courier"
                            >COURIER: {{ box.delivery_courier.name }}</span
                            >
                            <br/>
                            DELIVERY DATE: {{ box.delivery_date }}<br/>
                            WINDOW: {{ box.delivery_window }}<br/><br/>
                            ROUTE: {{ box.delivery_route }}<br/>
                            <span v-if="preferences.country !== 'nz'">
                              DROP: {{ box.delivery_drop }}<br/>
                              RUN: {{ box.delivery_run }}<br/>
                            </span>
                            <span v-if="box.delivery_courier">
                              EMAIL: {{ box.delivery_courier.email }} <br/>
                            </span>
                          </v-card-text>
                        </v-card>
                      </div>
                    </v-dialog>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-btn
                          v-on="on"
                          color="secondary"
                          v-clipboard="deliveryInfoSummaryToCopy"
                        >
                          <v-icon>mdi-content-copy</v-icon>
                        </v-btn
                        >
                      </template>
                      Copy Delivery Info Summary to clipboard
                    </v-tooltip>
                  </div>
                </v-row>
              </v-col>
            </v-card-title>

            <v-row wrap>
              <v-col cols="6">
                <v-list two-line>
                  <v-list-item>
                    <v-list-item-action>
                      <v-icon>loyalty</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title
                      >{{ box.customer_loyalty }} boxes
                      </v-list-item-title
                      >
                      <v-list-item-subtitle>Loyalty</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item>
                    <v-list-item-action>
                      <v-icon>mail</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>{{
                          box.customer.email
                        }}
                      </v-list-item-title>
                      <v-list-item-subtitle>Email</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>

                  <v-hover>
                    <v-list-item v-if="wrong_address" slot-scope="{ hover }">
                      <v-list-item-action>
                        <v-icon
                          color="error"
                          v-if="wrong_address.status === 'issued'"
                        >directions
                        </v-icon
                        >
                        <v-icon
                          color="warning"
                          v-if="wrong_address.status === 'fixed'"
                        >directions
                        </v-icon
                        >
                      </v-list-item-action>
                      <v-list-item-content
                        v-if="wrong_address.status === 'fixed'"
                      >
                        <v-list-item-title>{{
                            complete_address
                          }}
                        </v-list-item-title>
                        <v-list-item-subtitle
                          v-if="wrong_address.courier_sent"
                          class="primary--text"
                        >
                          New Address (sent to courier)
                        </v-list-item-subtitle>
                        <v-list-item-subtitle v-else class="error--text">
                          New Address (not sent to courier yet)
                        </v-list-item-subtitle>
                      </v-list-item-content>
                      <v-list-item-content
                        v-if="wrong_address.status === 'issued'"
                      >
                        <v-list-item-title class="error--text">{{
                            complete_address
                          }}
                        </v-list-item-title>
                        <v-list-item-subtitle class="error--text"
                        >Address
                        </v-list-item-subtitle
                        >
                      </v-list-item-content>
                      <transition name="slide-x-transition">
                        <v-list-item-action
                          v-show="hover && wrong_address.status !== 'issued'"
                        >
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                              <v-btn
                                icon
                                ripple
                                v-on="on"
                                @click="createWrongAddress"
                              >
                                <v-icon color="warning" large
                                >edit_location
                                </v-icon
                                >
                              </v-btn>
                            </template>
                            <span>This address is wrong</span>
                          </v-tooltip>
                        </v-list-item-action>
                      </transition>
                    </v-list-item>

                    <v-list-item v-else slot-scope="{ hover }">
                      <v-list-item-action>
                        <v-icon>directions</v-icon>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title>{{
                            complete_address
                          }}
                        </v-list-item-title>
                        <v-list-item-subtitle>Address</v-list-item-subtitle>
                      </v-list-item-content>
                      <transition name="slide-x-transition">
                        <v-list-item-action v-show="hover">
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                              <v-btn
                                icon
                                ripple
                                v-on="on"
                                @click="createWrongAddress"
                              >
                                <v-icon color="warning" large
                                >edit_location
                                </v-icon
                                >
                              </v-btn>
                            </template>
                            <span>This address is wrong</span>
                          </v-tooltip>
                        </v-list-item-action>
                      </transition>
                    </v-list-item>
                  </v-hover>
                </v-list>
              </v-col>

              <v-col cols="6">
                <v-list two-line>
                  <v-list-item>
                    <v-list-item-action>
                      <v-icon>assignment</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>{{
                          box.customer_subscription
                        }}
                      </v-list-item-title>
                      <v-list-item-subtitle
                      >Subscription number
                      </v-list-item-subtitle
                      >
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item>
                    <v-list-item-action>
                      <v-icon>receipt</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>{{
                          box.customer_order
                        }}
                      </v-list-item-title>
                      <v-list-item-subtitle>Order number</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item>
                    <v-list-item-action>
                      <v-icon
                        color="warning"
                        v-if="wrong_address && wrong_address.new_di"
                      >create
                      </v-icon
                      >
                      <v-icon v-else>create</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title
                        v-if="wrong_address && wrong_address.new_di"
                      >
                        {{ wrong_address.new_di }}
                      </v-list-item-title>
                      <v-list-item-title v-else>{{
                          box.delivery_instructions
                        }}
                      </v-list-item-title>
                      <v-list-item-subtitle
                      >Delivery instructions
                      </v-list-item-subtitle
                      >
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
        <v-col cols="12" key="box" v-if="!box.prevision">
          <v-card class="box-card">
            <v-card-title primary-title class="primary">
              <div>
                <h3 class="headline font-weight-bold">
                  Box {{ box.id }}, {{ box.type }}
                </h3>
                <h4>Number of packages: {{ packages.length }}</h4>
              </div>
              <v-spacer/>
              <v-progress-circular
                v-if="loadingBoxPaymentErrors"
                indeterminate
                color="secondary"
              ></v-progress-circular>
              <v-alert v-if="boxPaymentErrorsExist" type="error"
              >Payment Failure
              </v-alert
              >
            </v-card-title>

            <v-row wrap>
              <v-col cols="6">
                <v-list two-line>
                  <v-list-item>
                    <v-list-item-action>
                      <v-icon>restaurant_menu</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>{{ box.swap }}</v-list-item-title>
                      <v-list-item-subtitle>Swap numbers</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-action>
                      <v-icon>attach_money</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>{{ box.value }}</v-list-item-title>
                      <v-list-item-subtitle>
                        Box value (Box + Delivery fee + Gourmet + D2L)
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item v-if="boxPaymentErrors">
                    <v-list-item-action>
                      <v-icon color="error">report_problem</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>{{
                          boxPaymentErrors.error_reason
                        }}
                      </v-list-item-title>
                      <v-list-item-subtitle>
                        Payment Error Reason
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-col>
              <v-col cols="6">
                <v-list two-line>
                  <v-list-item>
                    <v-list-item-action>
                      <v-icon>send</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title
                      ><span>{{ box.cutoff_day }} </span>
                        <span
                          v-if="
                            userProfile.admin ||
                            userProfile.team_leader ||
                            userProfile.qa_team ||
                            userProfile.food_safety
                          "
                        >
                          / {{ box.production_day }}</span
                        ></v-list-item-title
                      >
                      <v-list-item-subtitle
                      ><span>Cut-off Day</span
                      ><span
                        v-if="
                            userProfile.admin ||
                            userProfile.team_leader ||
                            userProfile.qa_team ||
                            userProfile.food_safety
                          "
                      >
                          / Production Day</span
                      ></v-list-item-subtitle
                      >
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item>
                    <v-list-item-action>
                      <v-icon>card_membership</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title
                      >{{ box.coupon_template }} /
                        {{ box.coupon_code }}
                      </v-list-item-title
                      >
                      <v-list-item-subtitle
                      >Marketing campaign / Code
                      </v-list-item-subtitle
                      >
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item v-if="boxPaymentErrors">
                    <v-list-item-action>
                      <v-icon color="error">credit_card</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>{{
                          boxPaymentErrors.payment_method
                        }}
                      </v-list-item-title>
                      <v-list-item-subtitle>
                        Payment Method
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-col>

              <!--              <v-col cols="12" v-if="box.meal_kits !== undefined && box.meal_kits.length > 0">-->
              <!--                <v-divider class="mx-6"></v-divider>-->
              <!--                <meal-kits :meal-kits="box.meal_kits"></meal-kits>-->
              <!--              </v-col>-->

              <v-col cols="12" v-if="packages.length" v-for="pkg in packages">
                <v-row class="mx-2">
                  <v-col cols="12">
                    <v-card>
                      <v-card-title primary-title class="primary">
                        <h3 class="headline font-weight-bold">
                          Package {{ pkg.id }}
                        </h3>
                        <v-spacer/>
                        <v-btn
                          v-if="pkg.be_cool_tracking_id"
                          :loading="loadingBeCoolDeliveryDetails"
                          color="secondary"
                          @click="
                            getDeliveryDetails(pkg.be_cool_tracking_id)
                          "
                        >
                          Delivery Details
                        </v-btn>
                      </v-card-title>
                      <v-card-text>
                        <v-row>
                          <v-col cols="6">
                            <v-list two-line>
                              <v-list-item>
                                <v-list-item-action>
                                  <v-icon>restaurant_menu</v-icon>
                                </v-list-item-action>
                                <v-list-item-content>
                                  <v-list-item-title>{{
                                      pkg.slot
                                    }}
                                  </v-list-item-title>
                                  <v-list-item-subtitle
                                  >Package Slots
                                  </v-list-item-subtitle
                                  >
                                </v-list-item-content>
                              </v-list-item>
                              <v-list-item>
                                <v-list-item-action>
                                  <v-icon>shopping_cart</v-icon>
                                </v-list-item-action>
                                <v-list-item-content>
                                  <v-list-item-title
                                  >${{
                                      totalRetailValue(pkg)
                                    }}
                                  </v-list-item-title
                                  >
                                  <v-list-item-subtitle>
                                    Package Retail Value
                                  </v-list-item-subtitle>
                                </v-list-item-content>
                              </v-list-item>
                            </v-list>
                          </v-col>
                          <v-col cols="6">
                            <v-list two-line>
                              <v-list-item>
                                <v-list-item-action>
                                  <v-icon>local_shipping</v-icon>
                                </v-list-item-action>
                                <v-list-item-content>
                                  <v-list-item-title
                                  >${{ pkg.dfs }}
                                  </v-list-item-title
                                  >
                                  <v-list-item-subtitle
                                  >Delivery Fee
                                  </v-list-item-subtitle
                                  >
                                </v-list-item-content>
                              </v-list-item>
                              <v-list-item>
                                <v-list-item-action>
                                  <v-icon>attach_money</v-icon>
                                </v-list-item-action>
                                <v-list-item-content>
                                  <v-list-item-title
                                  >${{
                                      totalPackagePrice(pkg)
                                    }}
                                  </v-list-item-title
                                  >
                                  <v-list-item-subtitle
                                  >Package Total Value
                                  </v-list-item-subtitle
                                  >
                                </v-list-item-content>
                              </v-list-item>
                            </v-list>
                          </v-col>
                        </v-row>
                      </v-card-text>
                      <v-card-text>
                        <v-row>
                          <v-col cols="4" v-for="(recipe, index) in pkg.recipe_info" :key="index">
                            <v-card>
                              <a :href="recipe.website_url" target="_blank">
                                <v-img :src="recipe.image_link">
                                  <v-avatar class="primary ma-2" size="30">
                                    <span class="white--text body-2 font-weight-medium">
                                      {{ recipe.slot }}
                                    </span>
                                  </v-avatar>
                                </v-img>
                              </a>
                              <v-card-title>{{
                                  recipe.recipe_title
                                }}
                              </v-card-title>
                              <v-card-subtitle>{{
                                  recipe.recipe_subtitle
                                }}
                              </v-card-subtitle>
                              <div v-if="recipe.mod_types">
                                <ingredient-substitution-details-modal
                                  :slot="recipe.slot"
                                  :modTypes="recipe.mod_types"
                                  :modIngredientSubstitutions="recipe.mod_ingredient_substitutions"
                                />
                              </div>
                              <v-divider class="mx-4"/>
                              <v-card-text>
                                <v-row
                                  justify="space-between"
                                  class="recipe-prices"
                                >
                                  <v-col cols="6">
                                    <span>Price: ${{ recipe.price }}</span>
                                  </v-col>
                                  <v-col cols="6">
                                    <span
                                    >Surcharge: ${{ recipe.surcharge }}</span
                                    >
                                  </v-col>
                                </v-row>
                              </v-card-text>
                            </v-card>
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </transition-group>
    </v-container>

    <v-dialog v-model="create_lmc" max-width="500px">
      <v-card>
        <v-card-title class="secondary">
          <span class="headline" v-if="lmc_present">Update a LMC</span>
          <span class="headline" v-else>Create a LMC</span>
        </v-card-title>
        <v-card-text>
          <v-form ref="lmc_form" lazy-validation>
            <v-container grid-list-md>
              <v-row wrap>
                <v-col cols="12">
                  <v-select
                    :items="reasons"
                    item-value="id"
                    item-text="name"
                    v-model="new_lmc.reason_id"
                    label="Reason"
                    :loading="progress_reasons"
                    :rules="[(v) => !!v || 'A reason is required']"
                    required
                  ></v-select>
                </v-col>
                <v-col cols="8">
                  <v-select
                    :items="compensations"
                    item-value="id"
                    item-text="name"
                    v-model="new_lmc.compensation_type_id"
                    label="Compensation type"
                    :loading="progress_compensations"
                    :rules="[(v) => !!v || 'A compensation is required']"
                    required
                  ></v-select>
                </v-col>
                <v-col cols="4">
                  <v-text-field
                    prefix="$"
                    label="Amount"
                    v-model="new_lmc.compensation_sum"
                    required
                    :rules="[(v) => !!v || 'An amount is required']"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-switch
                    required
                    label="Compensation applied"
                    v-model="new_lmc.compensation_applied"
                  ></v-switch>
                </v-col>
                <v-col cols="12">
                  <v-textarea
                    label="Comment"
                    v-model="new_lmc.comment"
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn color="error" text @click.native="deleteLMC"
                 v-if="lmc_present">
            Delete
          </v-btn>
          <v-spacer/>
          <v-btn text @click.native="create_lmc = false">Close</v-btn>
          <v-btn color="primary" @click.native="createLMC">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="isCompensationPopInOpened" width="800">
      <v-card class="mx-auto" color="white" max-width="800">
        <v-card-title>
          <v-icon large left class="pr-2" color="primary">done_all</v-icon>
          <span class="title font-weight-medium">Apply compensation</span>
        </v-card-title>
        <v-text-field
          class="px-6"
          label="Enter credit update (ex. 100% bulk credit)"
          v-model="creditUpdate"
          v-on:keyup.13="applyCompensation"
        ></v-text-field>
        <v-card-actions>
          <v-list-item class="grow">
            <v-list-item-avatar color="primary">
              <v-icon color="white">attach_money</v-icon>
            </v-list-item-avatar>
            <v-list-item-title
            >You will update live error
              {{ selectedLiveErrorId }}
            </v-list-item-title
            >
            <v-spacer/>
            <v-btn
              left
              color="error"
              text
              @click="isCompensationPopInOpened = false"
            >Cancel
            </v-btn
            >
            <v-btn left color="primary" text @click="applyCompensation"
            >Apply
            </v-btn
            >
          </v-list-item>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="isTextTemplatePopInOpened" width="800">
      <v-card class="mx-auto sms-text" color="white">
        <v-card-title class="headline">
          <v-icon large left class="pr-4" color="primary">sms</v-icon>
          <span>Text message sent</span>
        </v-card-title>
        <v-divider/>
        <v-card-text class="pt-2" style="white-space: pre-line;">
          {{ selectedLiveError.text_notification_template }}
        </v-card-text>
        <v-divider/>
        <v-card-actions>
          <v-spacer/>
          <v-btn
            color="primary"
            text
            @click="isTextTemplatePopInOpened = false"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="showDeliveryDetailsDialog">
      <delivery-details
        :selected-delivery-details="selectedDeliveryDetails"
      ></delivery-details>
    </v-dialog>

    <v-snackbar v-model="suggestion" :timeout="0" bottom right color="accent">
      We detected another box for this customer on this week
      <v-btn
        color="white"
        text
        :to="{ name: 'box', params: { id: suggestion_box } }"
      >
        See the other box
      </v-btn>
      <v-btn color="white" text @click="suggestion = false">
        Close
      </v-btn>
    </v-snackbar>
  </div>
</template>

<script>
import axios from 'axios';
import auth from '../../modules/auth';
import notifications from '@/modules/notifications';
import WrongAddressBox from './WrongAddressBox';
import FoodSafety from '../errors/FoodSafety';
import DamageClaim from '../errors/DamageClaim';
import MealKits from '../MealKits';
import LiveDataForm from '../issues/LiveDataForm';
import DeliveryDetails from './DeliveryDetails';
import VueClipboard from 'vue-clipboard2';
import TrackingStatusModal from './TrackingStatusModal';
import IngredientSubstitutionDetailsModal from '@/components/boxes/IngredientSubstitutionDetailsModal';
import {CONSTANTS, COUNTRYCODE} from '@/modules/constants';

var moment = require('moment-timezone');

export default {
	name: 'Details',
	components: {
		IngredientSubstitutionDetailsModal,
		TrackingStatusModal,
		LiveDataForm,
		WrongAddressBox,
		FoodSafety,
		DamageClaim,
		MealKits,
		DeliveryDetails,
		VueClipboard
	},
	data() {
		return {
			moment: moment,
			progress: false,
			loadingBeCoolDeliveryDetails: false,
			selectedDeliveryDetails: {},
			showDeliveryDetailsDialog: false,
			preferences: auth.user.preferences,
			nzpError: '',
			deliveryDetails: {},
			box_id: this.$route.params.id,
			box: {},
			packages: [],
			recipes: [],
			boxPaymentErrors: null,
			loadingBoxPaymentErrors: false,
			create_lmc: false,
			progress_reasons: false,
			reasons: [],
			progress_compensations: false,
			compensations: [],
			new_lmc: {
				compensation_applied: false,
				comment: '',
				country: auth.user.preferences.country
			},
			lmc_present: false,
			lmc_auth: false,
			lmc_message: null,
			wrong_address: null,
			userProfile: auth.user.profile,
			suggestion: false,
			suggestion_box: null,
			// edit_text: false,
			isCompensationPopInOpened: false,
			isTextTemplatePopInOpened: false,
			creditUpdate: '',
			selectedLiveErrorId: undefined,
			selectedLiveError: {},
			foodSafetyPopIn: false,
			foodSafetyProgress: false,
			foodSafetyFormSent: false,
			damageClaimPopIn: false,
			damageClaimProgress: false,
			damageClaimFormSent: false,
			zagrebLMCTypeFromLink: 'https://hellofreshau1.typeform.com/to/UCdyhq',
			thingToCopy: 'test test',
			bobLinks: {
				au: 'https://bob.hellofresh.com.au',
				nz: 'https://bob.hellofresh.co.nz',
				ao: 'https://bob.everyplate.com.au'
			},
			COUNTRYCODE: COUNTRYCODE
		};
	},
	computed: {
		bob_account_link: function () {
			return `${
				this.bobLinks[this.preferences.country]
			}/customer-ext/edit_index/index/id_customer/${this.box.customer.bob_id}`;
		},
		bob_subscription_link: function () {
			return `${
				this.bobLinks[this.preferences.country]
			}/subscription-ext/edit_index/index/id_subscription/${
				this.box.customer_subscription
			}`;
		},
		complete_address: function () {
			if (this.wrong_address && this.wrong_address.status === 'fixed') {
				return this.wrong_address.new_address;
			} else {
				return this.box.address;
			}
		},
		recipesOptions: function () {
			return (this.box.meal_kits !== undefined
				? this.box.meal_kits.map(
					(x) =>
						x.recipe_title +
              (x.recipe_subtitle !== undefined ? ' ' + x.recipe_subtitle : '')
				)
				: []
			).concat(['Entire box']);
		},
		overnightLmcEnabled: function () {
			// Assuming that agents need this button between midnight and 6am, before boxes have been uploaded. Feels like I'm missing something. Shouldn't this button should be on the customer page?
			let sydneyTime = this.moment.tz('Australia/Sydney');
			if (
				(sydneyTime.weekday() === 3 || sydneyTime.weekday() === 4) &&
        sydneyTime.hour() < 6
			) {
				return true;
			} else {
				return false;
			}
		},
		beCoolPortalLink: function () {
			return ('https://portalv2.becoolcouriers.com.au/search?invoice=' + this.box.customer_subscription.toString())
		},
		weekSummaryLink() {
			return '/customer/' + this.box.customer.id + '/week_summary';
		},
		deliveryInfoSummaryToCopy: function () {
			let deliveryInfoString =
        'ID: ' +
        this.box.customer.bob_id +
        '\n' +
        'NAME: ' +
        this.box.customer.name +
        '\n' +
        'ADDRESS: ' +
        this.complete_address +
        '\n' +
        'DELIVERY INST: ' +
        this.box.delivery_instructions +
        '\n\n' +
        'DELIVERY DATE: ' +
        this.box.delivery_date +
        '\n' +
        'WINDOW: ' +
        this.box.delivery_window +
        '\n\n' +
        'ROUTE: ' +
        this.box.delivery_route +
        '\n';
			if (this.preferences.country === 'au') {
				deliveryInfoString +=
          'DROP: ' +
          this.box.delivery_drop +
          '\n' +
          'RUN: ' +
          this.box.delivery_run;
			}
			return deliveryInfoString;
		},
		boxPaymentErrorsExist: function () {
			return !!this.boxPaymentErrors;
		}
	},
	watch: {
		box: function () {
			this.getBoxPaymentErrors();
		}
	},
	async mounted() {
		await this.getBox();
		axios
			.get(`${CONSTANTS.BASE_URL}/api/v1` + '/boxes/lmcs/' + this.box_id)
			.then((response) => {
				if (response.data.lmc) {
					this.new_lmc = response.data.lmc;
					this.lmc_present = true;
				}
			});
		this.getPackages();
	},
	methods: {
		openLMC() {
			this.progress_reasons = true;
			this.progress_compensations = true;
			axios
				.get(`${CONSTANTS.BASE_URL}/api/v1` + '/boxes/lmcs/reasons')
				.then((response) => {
					if (response.data.msg === 'success') {
						this.reasons = response.data.reasons;
					}
					this.progress_reasons = false;
				});
			axios
				.get(`${CONSTANTS.BASE_URL}/api/v1` + '/boxes/lmcs/compensations')
				.then((response) => {
					if (response.data.msg === 'success') {
						this.compensations = response.data.compensations;
					}
					this.progress_compensations = false;
				});
			this.create_lmc = true;
		},
		createLMC() {
			if (this.$refs.lmc_form.validate()) {
				this.create_lmc = false;
				this.progress = true;
				axios
					.post(`${CONSTANTS.BASE_URL}/api/v1` + '/boxes/lmcs/' + this.box_id, {
						params: {
							lmc: this.new_lmc
						}
					})
					.then((response) => {
						if (response.data.msg === 'success') {
							this.lmc_present = true;
							notifications.addNotification('LMC inserted successfully.');
							if (response.data.suggestion) {
								this.suggestion = true;
								this.suggestion_box = response.data.suggestion;
							}
						}
						this.progress = false;
					});
			}
		},
		deleteLMC() {
			this.create_lmc = false;
			this.progress = true;
			axios
				.delete(`${CONSTANTS.BASE_URL}/api/v1` + '/boxes/lmcs/' + this.box_id)
				.then((response) => {
					if (response.data.msg === 'success') {
						this.lmc_present = false;
						notifications.addNotification('LMC deleted successfully.');
					}
					this.progress = false;
				});
		},
		getBox() {
			this.progress = true;
			axios
				.get(`${CONSTANTS.BASE_URL}/api/v1` + '/boxes/' + this.box_id)
				.then((response) => {
					if (response.data.msg === 'success') {
						this.box = response.data.box;
						this.lmc_auth = response.data.lmc_auth;
						this.lmc_message = response.data.lmc_message;
						this.wrong_address = response.data.wrong_address;
						if (this.foodSafetyProgress) {
							this.foodSafetyProgress = false
							this.foodSafetyPopIn = true
						}
						this.progress = false;
					}
				})
				.catch((error) => {
					if (error.response.status === 404) {
						this.progress = false;
						notifications.addNotification('Box not found :(', error);
					}
				});
		},
		getPackages() {
			this.progress = true;
			axios
				.get(`${CONSTANTS.BASE_URL}/api/v1/packages`, {
					params: {
						box_id: this.box_id,
						country: this.preferences.country
					}
				})
				.then((response) => {
					this.packages = response.data.packages;
					this.getDeliveryDetails(this.packages[0]['delivery_tracking_number'])
				})
				.catch((error) => {
					notifications.addNotification(
						'Error: Could not get package data',
						true
					);
				});
		},
		getDeliveryDetails(trackingId) {
			this.loadingBeCoolDeliveryDetails = true;
			this.progress = true;
			axios
				.get(`${CONSTANTS.BASE_URL}/api/v1/packages/delivery_details`, {
					params: {
						tracking_id: trackingId,
						country: this.preferences.country
					}
				})
				.then((response) => {
					const deliveryCourier = response.data.delivery_courier_name;
					this.deliveryDetails = response.data.delivery_details;

					if (deliveryCourier === 'BeCool') {
						this.selectedDeliveryDetails.package_id = response.data.delivery_details.tags[0].number;
						this.showDeliveryDetailsDialog = true;
					}
				})
				.catch((error) => {
					const {msg} = error.response.data;
					this.nzpError = 'NZP Parcel Track - ' + msg
					notifications.addNotification(msg, true);
				})
				.finally(() => {
					this.loadingBeCoolDeliveryDetails = false;
					this.progress = false;
				});
		},
		getBoxPaymentErrors() {
			this.loadingBoxPaymentErrors = true;

			axios
				.get(
					`${CONSTANTS.BASE_URL}/api/v1/payment_errors/box/${this.box.customer_order}`,
					{
						params: {
							country: this.preferences.country
						}
					}
				)
				.then((response) => {
					const {box_payment_errors: boxPaymentErrors} = response.data;
					this.boxPaymentErrors = boxPaymentErrors;
					this.loadingBoxPaymentErrors = false;
				})
				.catch((error) => {
					notifications.addNotification(
						'There was an error getting payment error details from Bob',
						true
					);
					this.loadingBoxPaymentErrors = false;
				});
		},
		updateAddress() {
			this.getBox();
		},
		createWrongAddress() {
			this.progress = true;
			axios
				.post(`${CONSTANTS.BASE_URL}/api/v1` + '/boxes/wrong_addresses/' + this.box_id)
				.then((response) => {
					if (response.data.msg === 'success') {
						this.getBox();
						this.progress = false;
					}
				});
		},
		applyCompensation: function () {
			this.isCompensationPopInOpened = false;
			this.progress = true;
			axios
				.post(`${CONSTANTS.BASE_URL}/api/v1` + '/errors/live/compensation/compensate', {
					country: this.preferences.country,
					credit_update: this.creditUpdate,
					live_error_ids: [this.selectedLiveErrorId]
				})
				.then((response) => {
					if (response.data.msg === 'success') {
						this.progress = false;
						this.getBox();
					}
				});
		},
		updateCompensationDetails: function (liveErrorId) {
			this.selectedLiveErrorId = liveErrorId;
			this.isCompensationPopInOpened = true;
		},
		seeTextTemplate: function (liveError) {
			this.selectedLiveError = liveError;
			this.isTextTemplatePopInOpened = true;
		},
		closeFoodSafetyPopin: function () {
			this.foodSafetyPopIn = false;
		},
		closeDamageClaimPopin: function () {
			this.damageClaimPopIn = false;
		},
		sendDamageClaimForm: function (email) {
			this.damageClaimProgress = true;
			this.damageClaimFormSent = false;
			this.closeDamageClaimPopin();
			axios.post(`${CONSTANTS.BASE_URL}/api/v1` + '/damage-claim/send', {
				country: this.preferences.country,
				box_id: this.box.id,
				email
			}).then((response) => {
				if (response.data.msg === 'success') {
					this.damageClaimFormSent = true;
					this.damageClaimProgress = false;
				}
			})
		},
		sendFoodSafetyFormTemplate: function (recipeName, formTemplate, sendForm) {
			this.foodSafetyProgress = true;
			this.foodSafetyFormSent = false;
			this.closeFoodSafetyPopin();
			axios
				.put(`${CONSTANTS.BASE_URL}/api/v1` + '/food_safety/' + this.box_id + '/send', {
					country: this.preferences.country,
					recipe_name: recipeName,
					template_type: formTemplate,
					send_to_customer: sendForm
				})
				.then((response) => {
					if (response.data.msg === 'success') {
						this.foodSafetyFormSent = true;
						this.getBox();
					}
				})
				.catch(() => {
					this.foodSafetyProgress = false;
					notifications.addNotification(
						'Oups, error when sending mail to customer.',
						error
					);
				});
		},
		copyDeliveryInfo() {
			this.$copyText(this.thingToCopy);
		},
		totalRetailValue(pkg) {
			const {retail_prices: retailPrices} = pkg;
			return retailPrices
				.replace('|', '_')
				.split('_')
				.reduce((acc, cur) => acc + parseFloat(cur), 0)
				.toFixed(2);
		},
		totalPackagePrice(pkg) {
			const {retail_prices: retailPrices, surcharges, dfs} = pkg;
			const priceString = `${retailPrices}_${surcharges}_${dfs}`;
			return priceString
				.replace('|', '_')
				.split('_')
				.reduce((acc, cur) => acc + parseFloat(cur), 0)
				.toFixed(2);
		}
	}
};
</script>

<style scoped>
.lmc-card,
.customer-card,
.box-card,
.delivery-card,
.courier-card,
.informations-card,
.error-card {
  margin-bottom: 2em;
}

.prevision-box {
  height: 86px;
}

.box-component {
  width: 100%;
}

.flex {
  display: inline-block;
  transition: all 1s;
}

.headline-flex {
  display: flex;
}

.theme--dark .sms-text {
  color: #303030 !important;
}

.v-btn {
  margin: 4px;
}

.recipe-prices {
  text-align: center;
}
</style>
