<template>
  <v-card class="mx-auto" max-width="800">
    <v-row>
      <v-col cols="8">
        <v-card-title>
          <v-icon large left class="pr-2" color="primary">done_all</v-icon>
          <span class="title font-weight-medium">Send a text to selected customers</span>
        </v-card-title>
      </v-col>
      <v-col cols="4">
        <v-select
          class="mt-4 mr-4"
          :items="Object.keys(textTemplates)"
          label="Select a template"
          @change="changeTemplate"
          solo
        >
        </v-select>
      </v-col>
    </v-row>
    <span class="subheading font-weight-thin ml-4">Keep {{ }} attributes for name and/or phone number :</span>
    <v-textarea
      solo auto-grow class="mx-4 mt-2" name="template_area" label="Text message template"
      v-model="templateBody" hide-details
    ></v-textarea>
    <v-card-actions class="mt-1">
      <v-list-item class="grow">
        <v-list-item-avatar color="primary">
          <v-icon color="white">mobile_screen_share</v-icon>
        </v-list-item-avatar>
        <v-list-item-title>{{sendToMessage}}</v-list-item-title>
        <v-spacer></v-spacer>
        <v-btn left color="error" text @click="closePopIn">Cancel</v-btn>
        <v-btn left color="primary" text @click="sendTextMessages(templateBody)">Send messages</v-btn>
      </v-list-item>
    </v-card-actions>
  </v-card>
</template>

<script>
import axios from 'axios';
import auth from '../modules/auth';
import {CONSTANTS} from '@/modules/constants';

export default {
	name: 'TextMessage',
	mounted() {
		this.getTextTemplates()
	},
	methods: {
		getTextTemplates() {
			const { country } = this.preferences;
			this.loading = true;
			axios.get(`${CONSTANTS.BASE_URL}/api/v1/customers/text-templates`, {
				params: { country }
			})
				.then(response => {
					// format data to hash table
					const formattedTemplates = {};
					response.data.text_templates.forEach(template => {
						formattedTemplates[template.name] = template
					});
					this.textTemplates = formattedTemplates;
					this.loading = false;
				})
				.catch(e => console.log(e))
		},
		changeTemplate(name) {
			this.templateBody = this.textTemplates[name].body
		}
	},
	data() {
		return {
			preferences: auth.user.preferences,
			loading: false,
			textTemplates: {},
			templateBody: ''
		}
	},
	props: {
		closePopIn: Function,
		sendTextMessages: Function,
		sendToMessage: String
	}
}
</script>

<style scoped>

</style>
