<template>
  <v-card>
    <v-card-title>
      <span class="headline">Edit Template</span>
    </v-card-title>
    <v-card-text>
      <v-alert
        border="top"
        colored-border
        type="error"
        elevation="2"
        class="caption"
      >
        <p v-pre>
          Note: If you want your template to fill in the customer name or CC phone number automatically, use either
          {{ name }} or {{ phone }} in the template
        </p>
        <p v-pre>
          eg. Hi {{ name }}, please call us at {{ phone }}
        </p>
      </v-alert>
      <v-container>
        <v-form
          ref="form"
          v-model="valid"
        >
          <v-row>
            <v-col cols="12">
              <v-text-field
                label="Name*"
                required
                v-model="name"
                :rules="inputRules"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-textarea
                auto-grow
                label="Body*"
                required
                v-model="body"
                :rules="inputRules"
              ></v-textarea>
            </v-col>
            <v-col cols="12">
              <span>Select Country</span>
            </v-col>
            <v-col cols="6" sm="6">
              <v-checkbox v-model="isAu" label="Australia"></v-checkbox>
            </v-col>
            <v-col cols="6" sm="6">
              <v-checkbox v-model="isNz" label="New Zealand"></v-checkbox>
            </v-col>
          </v-row>
        </v-form>
      </v-container>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="blue" text @click="toggleDialog">Close</v-btn>
      <v-btn color="error" text @click="toggleDeleteDialog">Delete</v-btn>
      <v-btn color="primary" @click="editTemplate" :loading="loading">Save</v-btn>
    </v-card-actions>

    <v-dialog
      v-model="deleteDialog"
      width="500"
    >
      <v-card>
        <v-card-title>
          <h3>Delete Template</h3>
        </v-card-title>
        <v-card-text>
          <p>Are you sure you want to delete this template?</p>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="black" text @click="toggleDeleteDialog">Cancel</v-btn>
          <v-btn color="error" text @click="deleteTemplate" :loading="loading">Delete</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import axios from 'axios';
import notifications from '@/modules/notifications';
import {CONSTANTS} from '@/modules/constants';

export default {
	name: 'EditTextTemplate',
	props: {
		dialog: Boolean,
		template: Object,
		toggleDialog: Function,
		getTextTemplates: Function
	},
	watch: {
		dialog: {
			handler: function(dialog) {
				// this resets the value of the template to the original
				// when someone makes template edits and closes the dialog
				if (dialog) {
					this.setValues();
				}
			},
			immediate: true
		}
	},
	data() {
		return {
			deleteDialog: false,
			valid: false,
			loading: false,
			id: null,
			name: '',
			body: '',
			isAu: false,
			isNz: false,
			inputRules: [v => !!v || 'Required field']
		}
	},
	methods: {
		setValues() {
			this.id = this.template.id
			this.name = this.template.name;
			this.body = this.template.body;
			this.isAu = this.template.au;
			this.isNz = this.template.nz;
		},
		editTemplate() {
			const template = {
				id: this.id,
				name: this.name,
				body: this.body,
				au: this.isAu,
				nz: this.isNz
			}

			if (this.$refs.form.validate()) {
				this.loading = true;
				axios
					.patch(`${CONSTANTS.BASE_URL}/api/v1/customers/text-template/${template.id}`, { template })
					.then(response => {
						this.loading = false;
						this.toggleDialog();
						this.getTextTemplates();
						notifications.addNotification('Successfully edited template!');
					})
					.catch(error => {
						notifications.addNotification('Error: Could not update template', true);
						this.loading = false;
					})
			}
		},
		deleteTemplate() {
			this.loading = true;
			axios
				.delete(`${CONSTANTS.BASE_URL}/api/v1/customers/text-template/${this.id}`)
				.then(response => {
					this.loading = false;
					this.toggleDeleteDialog();
					this.toggleDialog();
					this.getTextTemplates();
					notifications.addNotification('Successfully deleted template!');
				})
				.catch(error => {
					notifications.addNotification('Error: Could not update template', true);
					this.toggleDeleteDialog();
					this.loading = false;
				})
		},
		toggleDeleteDialog() {
			this.deleteDialog = !this.deleteDialog;
		}
	}
}
</script>

<style scoped>

</style>
