<template>
  <div>
    <v-card>
      <v-row v-for="(recipe, index) in recipe_info" :key="index">
        <v-col cols="6" align-self="center">
          <a :href="recipe.website_url" target="_blank">
            <v-img
              :src="recipe.image_link "
              :alt="recipe.recipe_title + ' - picture'"
            >
            <v-container class="pt-1 pl-2">
              <v-avatar class="primary" size=30 style="opacity: 0.9;">
                <span class="white--text body-2 font-weight-medium">{{ recipe.slot }}</span>
              </v-avatar>
            </v-container>
          </v-img>
          </a>
        </v-col>
        <v-col cols="6" align-self="center">
          <v-card-title>{{ recipe.recipe_title }}</v-card-title>
          <v-card-subtitle>{{ recipe.recipe_subtitle }}</v-card-subtitle>
          <div v-if="recipe.mod_types">
            <ingredient-substitution-details-modal
              :slot="recipe.slot"
              :modTypes="recipe.mod_types"
              :modIngredientSubstitutions="recipe.mod_ingredient_substitutions"
            />
          </div>
          <v-list>
            <v-list-item>
              <v-list-item-content>
                <span>Price : ${{ recipe.price }}</span>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <span>Surcharge : ${{ recipe.surcharge }}</span>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-col>
      </v-row>
    </v-card>

  </div>
</template>

<script>
import IngredientSubstitutionDetailsModal from '@/components/boxes/IngredientSubstitutionDetailsModal';
export default {
	name: 'PackageBoxRecipes',
	components: {IngredientSubstitutionDetailsModal},
	props: {
		week: { type: String },
		recipe_info: { type: Object }
	},
	data(){
		return {
			progress: false
		}
	}

}
</script>

<style scoped>

</style>
