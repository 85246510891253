<template>
  <div class="settings">
    <v-container>
      <v-row align-center>
        <v-col cols="12">
          <h1 class="display-3">Settings</h1>
        </v-col>
        <v-col cols="12">
          <v-tabs background-color="primary" slider-color="accent" centered>
            <v-tab ripple v-for="tab in tabs" :key="tab">
              {{ tab }}
            </v-tab>
            <v-tab-item>
              <v-card flat>
                <v-toolbar text color="inherit">
                  <v-toolbar-title class="pt-4">Users</v-toolbar-title>
                  <v-spacer></v-spacer>
                  <v-text-field
                    v-model="search"
                    append-icon="search"
                    label="User reference"
                    single-line
                    hide-details
                    :loading="progress"
                  ></v-text-field>
                  <v-divider class="mx-6" inset vertical></v-divider>
                  <v-dialog v-model="add_user.active" max-width="500px">
                    <template v-slot:activator="{ on }">
                      <v-btn v-on="on" color="primary" dark class="mb-2"
                        >New user</v-btn
                      >
                    </template>
                    <v-card>
                      <v-card-title>
                        <v-subheader class="headline">New user</v-subheader>
                      </v-card-title>
                      <v-card-text>
                        <v-container grid-list-md>
                          <v-row wrap>
                            <v-col xs="12">
                              <v-text-field
                                v-model="add_user.email"
                                label="Email"
                              ></v-text-field>
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-card-text>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                          color="error"
                          text
                          @click.native="add_user.active = false"
                          >Cancel</v-btn
                        >
                        <v-btn color="primary" text @click.native="addUser"
                          >Save</v-btn
                        >
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </v-toolbar>
                <v-data-table
                  :headers="user_headers"
                  :items="users"
                  class="elevation-1"
                  :search="search"
                  :items-per-page-options="row_items"
                  fixed-header
                  height="600px"
                  :no-data-text="'No data found'"
                >
                  <template v-slot:item="{ item }">
                    <tr>
                      <!--                    <td>{{ item.email }}</td>-->
                      <!--                    <td class="text&#45;&#45;right">{{ item.first_name }}</td>-->
                      <!--                    <td class="text&#45;&#45;right">{{ item.last_name }}</td>-->

                      <td
                        class="text--right"
                        v-for="role in Object.keys(item).filter(
                          (v) => v !== 'id'
                        )"
                      >
                        <div
                          v-if="
                            role === 'first_name' ||
                            role === 'last_name' ||
                            role === 'email'
                          "
                        >
                          {{ item[role] }}
                        </div>
                        <v-checkbox
                          v-else
                          color="primary"
                          v-model="item[role]"
                          primary
                          hide-details
                          @click.native="updateUser(item.id)"
                        ></v-checkbox>
                      </td>

                      <!--                    <td class="text&#45;&#45;right">-->
                      <!--                      <v-checkbox color="primary"-->
                      <!--                        v-model="item.admin" primary hide-details @click.native="updateUser(item.id)"-->
                      <!--                      ></v-checkbox>-->
                      <!--                    </td>-->
                      <!--                    <td class="text&#45;&#45;right">-->
                      <!--                      <v-checkbox color="primary"-->
                      <!--                        v-model="item.team_leader" primary hide-details @click.native="updateUser(item.id)"-->
                      <!--                      ></v-checkbox>-->
                      <!--                    </td>-->
                      <!--                    <td class="text&#45;&#45;right">-->
                      <!--                      <v-checkbox color="primary"-->
                      <!--                        v-model="item.payment" primary hide-details @click.native="updateUser(item.id)"-->
                      <!--                      ></v-checkbox>-->
                      <!--                    </td>-->
                      <!--                    <td class="text&#45;&#45;right">-->
                      <!--                      <v-checkbox color="primary"-->
                      <!--                        v-model="item.qa" primary hide-details @click.native="updateUser(item.id)"-->
                      <!--                      ></v-checkbox>-->
                      <!--                    </td>-->
                      <!--                    <td class="text&#45;&#45;right">-->
                      <!--                      <v-checkbox color="primary"-->
                      <!--                        v-model="item.beta_test" primary hide-details @click.native="updateUser(item.id)"-->
                      <!--                      ></v-checkbox>-->
                      <!--                    </td>-->
                    </tr>
                  </template>
                </v-data-table>
              </v-card>
            </v-tab-item>
            <v-tab-item>
              <text-templates></text-templates>
            </v-tab-item>
            <v-tab-item>
              <voucher-settings></voucher-settings>
            </v-tab-item>
          </v-tabs>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import axios from 'axios';
import notifications from '@/modules/notifications';
import TextTemplates from './TextTemplates';
import VoucherSettings from './VoucherSettings';
import {CONSTANTS} from '@/modules/constants';

export default {
	name: 'Settings',
	components: {
		'text-templates': TextTemplates,
		'voucher-settings': VoucherSettings
	},
	data() {
		return {
			tabs: ['Users', 'Text Templates', 'Voucher Settings'],
			// user_headers: [
			//   {text: 'Email', value: 'email'},
			//   {text: 'First name', value: 'first_name'},
			//   {text: 'Last name', value: 'last_name'},
			//   {text: 'Admin', value: 'admin'},
			//   {text: 'Team leader', value: 'team_leader'},
			//   {text: 'Payment', value: 'payment'},
			//   {text: 'QA', value: 'qa'},
			//   {text: 'Beta Test', value: 'beta_test'}
			// ],
			user_headers: [],
			users: [],
			add_user: {
				active: false,
				email: ''
			},
			search: '',
			row_items: [
				10,
				60,
				{ text: '$vuetify.dataIterator.rowsPerPageAll', value: -1 }
			],
			progress: true
		};
	},
	// computed: {
	//   user_headers() {
	//     let headers = []
	//     for(let user_header of this.users[0]) {
	//       headers.push({
	//         text: this.titleCase(Object.keys(user_header)[0]),
	//         value: Object.keys(user_header)[0]
	//       })
	//     }
	//     return headers
	//   }
	// },
	created() {
		this.getUsers();
	},
	methods: {
		getUsers: function () {
			axios
				.get(`${CONSTANTS.BASE_URL}/api/v1` + '/users')
				.then((response) => {
					if (response.data.msg === 'success') {
						this.users = response.data.users;
						this.user_headers = this.makeHeaders();
						this.progress = false;
					}
				})
				.catch((error) => {
					notifications.addNotification(
						error.response && error.response.data.msg !== undefined
							? error.response.data.msg
							: 'Oups, something goes wrong when fetching users.',
						true
					);
					this.progress = false;
				});
		},
		updateUser: function (user_id) {
			this.progress = true;
			axios
				.post(
					`${CONSTANTS.BASE_URL}/api/v1` + '/user/' + user_id,
					this.users.filter((item) => {
						return item.id === user_id;
					})[0]
				)
				.then((response) => {
					if (response.data.msg === 'success') {
						notifications.addNotification('User updated!');
					}
					this.progress = false;
				})
				.catch((error) => {
					notifications.addNotification(
						error.response && error.response.data.msg !== undefined
							? error.response.data.msg
							: 'Oups, something goes wrong when updating the user.',
						true
					);
					this.progress = false;
				});
		},
		addUser: function () {
			this.progress = true;
			axios
				.post(`${CONSTANTS.BASE_URL}/api/v1` + '/users', this.add_user)
				.then((response) => {
					if (response.data.msg === 'success') {
						this.add_user.active = false;
						this.getUsers();
						this.progress = false;
					}
				});
		},
		titleCase(str) {
			return str
				.toLowerCase()
				.split(' ')
				.map(function (word) {
					return word.replace(word[0], word[0].toUpperCase());
				})
				.join(' ');
		},
		makeHeaders() {
			let headers = [];
			for (let header of Object.keys(this.users[0]).filter((v) => v !== 'id')) {
				headers.push({
					text: this.titleCase(header.replace('_', ' ')),
					value: header
				});
			}
			return headers;
		}
	}
};
</script>

<style scoped></style>
