<template>
  <div>
    <v-toolbar elevation="4">
      <v-spacer></v-spacer>
      <img src="../assets/logo-neutral.png" class="logo"/>
      <v-spacer></v-spacer>

      <v-menu offset-y>
        <template v-slot:activator="{ on }">
          <v-btn icon v-on="on">
            <v-icon>more_vert</v-icon>
          </v-btn>
        </template>
        <v-card>
          <v-list>
            <v-list-item @click="logout">
              <v-list-item-title>
                Logout ({{ name }})
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-card>
      </v-menu>

    </v-toolbar>
    <div>
      <v-row class="banners">
        <v-col
          :cols="bannerStyles.auHf.cols"
          class="australia"
          @click="goToAU"
          v-if="hfAgent || helloConnectAgent || profile.admin || nonCcAgent"
        >
          <div class="australia-img" data-cy="australia-img"></div>
          <div class="australia-title">
            <img src="../assets/hellofresh-logo.png"/>
            <h3 class="headline font-weight-bold">Australia</h3>
          </div>
        </v-col>
        <v-col
          :cols="bannerStyles.auEp.cols"
          class="australia-ep"
          @click="goToAUEP"
          v-if="epAgent || helloConnectAgent || profile.admin || nonCcAgent"
        >
          <div class="australia-ep-img" data-cy="australia-ep-img"></div>
          <div class="australia-ep-title">
            <img src="../assets/everyplate-logo.png"/>
            <h3 class="headline font-weight-bold">Australia</h3>
          </div>
        </v-col>
        <v-col
          :cols="bannerStyles.auYe.cols"
          class="australia-ye"
          @click="goToAUYE"
          v-if="helloConnectAgent || profile.admin || nonCcAgent"
        >
          <div class="ye-img" data-cy="ye-img"></div>
          <div class="ye-title">
            <img src="../assets/ye-logo.svg"/>
            <h3 class="headline font-weight-bold">Australia</h3>
          </div>
        </v-col>

        <v-col
          :cols="bannerStyles.nzHf.cols"
          class="nz"
          @click="goToNZ"
          v-if="hfAgent || helloConnectAgent || profile.admin || nonCcAgent"
        >
          <div class="nz-img" data-cy="nz-img"></div>
          <div class="nz-title">
            <img src="../assets/hellofresh-logo.png"/>
            <h3 class="headline font-weight-bold">New Zealand</h3>
          </div>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import {mapState} from 'vuex'
import auth from '../modules/auth';

export default {
	name: 'Country',
	data() {
		return {
			name: null
		}
	},
	computed: mapState({
		profile: state => state.profile,
		domain(state) {
			const email = state.profile.email;
			const emailDomain = email.split('@')[1];
			const helloFreshRegex = /^hellofresh.*/
			const helloConnectRegex = /^helloconnect.*/
			const everyPlateRegex = /^everyplate.*/

			if (helloFreshRegex.test(emailDomain)) {
				return 'HF';
			} else if (everyPlateRegex.test(emailDomain)) {
				return 'EP';
			} else if (helloConnectRegex.test(emailDomain)) {
				return 'HelloConnect'
			} else {
				return null;
			}
		},
		nonCcAgent() {
			return !this.profile.senior_agent && !this.profile.team_leader && this.hasOtherRolePermission()

		},
		epAgent() {
			return this.domain === 'EP' && !this.profile.admin;
		},
		hfAgent() {
			return this.domain === 'HF' && !this.profile.admin;
		},
		helloConnectAgent() {
			return this.domain === 'HelloConnect';
		},
		bannerStyles() {
			return {
				auHf: {
					cols: 6
				},
				nzHf: {
					cols: 6
				},
				auEp: {
					cols: 6
				},
				auYe: {
					cols: 6
				}
			}
		}
	}),
	mounted() {
		auth.checkAuth().then(() => {
			this.name = auth.user.profile.first_name + ' ' + auth.user.profile.last_name;
		}).catch((err) => {
			console.log(err);
		});
	},
	updated() {
		this.$vuetify.theme.dark = false
	},
	methods: {
		goToAU: function () {
			auth.setCountry('au');
			this.$router.replace('/home');
		},
		goToAUEP: function () {
			auth.setCountry('ao');
			this.$router.replace('/home');
		},
		goToAUYE: function () {
			auth.setCountry('ye');
			this.$router.replace('/home');
		},
		goToNZ: function () {
			auth.setCountry('nz');
			this.$router.replace('/home');
		},
		logout: function () {
			auth.logout();
		},
		hasOtherRolePermission: function () {
			return (this.profile.beta_test ||
        this.profile.system ||
        this.profile.logistics ||
        this.profile.culinary ||
        this.profile.marketing ||
        this.profile.food_safety ||
        this.profile.payment
			)
		}
	}
}
</script>

<style scoped>
.banners {
  background-color: white;
  width: 120%;
  margin-left: -10%;
}

.australia,
.australia-ep {
  overflow: hidden;
  transform: skew(-20deg);
  padding-top: 0px;
  padding-bottom: 0px;
  transform-origin: bottom left;
}


.australia:hover,
.australia-ep:hover,
.australia-ye:hover {
  cursor: pointer;
}

.australia:hover .australia-img,
.australia-ep:hover .australia-ep-img {
  filter: blur(0);
  transform: skew(20deg);
}

.australia:hover .australia-img::before,
.australia-ep:hover .australia-ep-img::before {
  opacity: 0.7;
}

.australia-img {
  background: url("../assets/australia.jpg");
  background-size: auto 150%;
  background-position-x: 45%;
  background-position-y: 50%;
  background-repeat: no-repeat;
  height: 100%;
  width: 200%;
  transition: all .5s;
  transform: skew(20deg);
  filter: blur(5px);
  margin-left: -40%;
}

.australia-ep-img {
  background: url("../assets/australia-ep.jpeg");
  background-size: auto 250%;
  background-position: calc(70%) center;
  background-repeat: no-repeat;
  height: 100%;
  width: 100%;
  transition: all .5s;
  transform: skew(20deg);
  filter: blur(5px);
  margin-left: -20%;
}

.australia-img::before,
.australia-ep-img::before,
.ye-img::before {
  content: "";
  display: block;
  height: 100%;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.90);
  transition: all .5s;
}

/*.australia-title,*/
/*.nz-title {*/
/*  position: absolute;*/
/*  top: 10%;*/
/*  left: 15%;*/
/*  width: 40%;*/
/*  transform: skew(20deg);*/
/*}*/

.australia-title {
  position: absolute;
  text-align: center;
  transform: skew(20deg) translate(-50%, -50%);
  top: 50%;
  left: 60%;
  width: 200px;
}

.australia-title img {
  width: 100%;
}

.australia-ep-title {
  text-align: center;
  position: absolute;
  top: 50%;
  left: 55%;
  width: 200px;
  transform: skew(20deg) translate(-50%, -50%);
}

.australia-ep-title img {
  width: 100%;
}

.nz {
  overflow: hidden;
  transform: skew(-20deg);
  padding-top: 0px;
  padding-bottom: 0px;
  width: 100%;
  transform-origin: top left;
}

.nz:hover {
  cursor: pointer;
}

.nz:hover .nz-img {
  filter: blur(0);
  transform: skew(20deg);
}

.nz:hover .nz-img::before {
  opacity: 0.7;
}

.nz-img {
  background: url("../assets/new_zealand.jpg");
  background-size: auto 200%;
  background-position-x: 100%;
  height: 100%;
  width: 200%;
  transition: all .5s;
  transform: skew(20deg);
  filter: blur(5px);
  margin-left: -75%;
  background-repeat: no-repeat;
}

.nz-img::before {
  content: "";
  display: block;
  height: 100%;
  width: 100%;
  background-color: rgba(50, 50, 50, 0.90);
  transition: all .5s;
}

.nz-title {
  position: absolute;
  color: #ffffff;
  text-align: center;
  transform: skew(20deg) translate(-50%, -50%);
  top: 50%;
  left: 50%;
  width: 200px;
}

.nz-title img {
  width: 100%;
}


.australia-ye {
  overflow: hidden;
  transform: skew(-20deg);
  padding-top: 0px;
  padding-bottom: 0px;


}

.ye-img {
  background: url("../assets/youfoodz.jpg");
  background-size: auto 400%;
  background-position-x: center;
  background-position-y: 55%;
  background-repeat: no-repeat;
  height: 100%;
  width: 200%;
  transition: all .5s;
  transform: skew(20deg);
  margin-left: -40%;
}

.australia-ye:hover .ye-img::before {
  opacity: 0.7;
}

.ye-img::before {
  content: "";
  display: block;
  height: 100%;
  width: 100%;
  transition: all .5s;
}

.ye-title {
  text-align: center;
  position: absolute;
  top: 50%;
  left: 55%;
  width: 200px;
  transform: skew(20deg) translate(-50%, -50%);
}

.ye-title img {
  padding-left: 5%;
  width: 446%;
}

.ye-title h3 {
  padding-top: 5%;
}


.col {
  height: calc(50vh - 32px);
  width: 120%;
}

.logo {
  height: 80%;
}
</style>
