<template>
  <v-form
    ref="error_form"
    class="pa-5">
    <template v-if="error === 'Waive'">
      <v-col cols="12">
        <v-row><span class="headline">Order No: {{order_nr}}</span>
        <v-spacer/>
        <span class="headline">Box Price: {{clicked_amount}}</span></v-row>

        <v-text-field label="Amount"
                      class="pt-8"
                      v-model="specific_error_info.amount"
                      prepend-icon="attach_money"
                      required
                      :rules="[v => !!v || 'Amount is required',
                               v => !isNaN(v) || 'Please only enter numbers',
                               v => (Number(v) <= this.clicked_amount) || 'Cannot waive more than total box price']"/>
        <v-text-field
          label="Order ID"
          v-model="specific_error_info.order_id"
          prepend-icon="fa-fingerprint"
          required
          :rules="[v => !!v || 'Order ID is required',
                   v => !isNaN(v) || 'Please only enter numbers']"
        />
      </v-col>
    </template>
    <template v-if="error === 'PaymentPlan'">
      <v-col cols="12">
        <v-text-field
          label="Total Amount"
          v-model="specific_error_info.total_amount"
          prepend-icon="fa-exclamation-triangle"
          required
          hint="Total amount unpaid, as shown on Abiliware."
          :rules= "[v => !!v || 'Total amount is required',
                              v => !isNaN(v) || 'Please only enter numbers']"
        ></v-text-field>
        <v-menu
          v-model="showMenu"
          :close-on-content-click="false"
          max-width="290px"
          min-width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              label="Start Date"
              v-model="specific_error_info.formatted_date"
              required
              :rules="[v => !!v || 'A start date is required']"
              readonly
              v-bind="attrs"
              v-on="on"
              prepend-icon="event"
            ></v-text-field>
          </template>
          <v-date-picker v-model="date" no-title @input="showMenu = false"></v-date-picker>
        </v-menu>
        <v-select
          :items="interval_items"
          label="Frequency"
          prepend-icon="fa-pencil-alt"
          v-model="specific_error_info.frequency"
          required
          :rules="[v => !!v || 'Frequency is required']"
        ></v-select>
        <v-text-field
          label="Interval Amount"
          v-model="specific_error_info.amount"
          prepend-icon="attach_money"
          required
          :rules= "[v => !!v || 'Amount is required',
                              v => !isNaN(v) || 'Please only enter numbers']"
        ></v-text-field>
        <v-checkbox
          v-model="specific_error_info.financial_difficulty"
          :label="`Financial Difficulty?`"
        ></v-checkbox>
      </v-col>
    </template>
    <template v-if="error === 'OverpaidCustomer'">
      <v-col cols="12">
        <v-text-field
          label="Amount"
          v-model="specific_error_info.amount"
          prepend-icon="attach_money"
          required
          :rules="[v => !!v || 'Amount is required',
                     v => !isNaN(v) || 'Please only enter numbers']"
        />
        <v-text-field
          label="Bank Account Name"
          v-model="specific_error_info.bank_account_name"
          prepend-icon="fa-user-alt"
          required
          :rules="[v => !!v || 'Bank account name is required']"
        />
        <v-text-field
          label="Bank Name"
          v-model="specific_error_info.bank_name"
          prepend-icon="fa-building"
          required
          :rules="[v => !!v || 'Bank name is required']"
        />
        <v-text-field
          label="BSB"
          v-model="specific_error_info.bsb"
          prepend-icon="fa-info"
          :rules="[function(v) {
                      if (specific_error_info.country == 'au' || specific_error_info.country == 'ao'){
                          return v.length == 6 || 'A 6-digit BSB is required'
                          }
                      else {return true}
                        }]"
        />
        <v-text-field
          label="Account Number"
          v-model="specific_error_info.bank_account_nr"
          prepend-icon="fa-id-badge"
          required
          :rules="[v => !!v || 'Account number is required',
                     v => !isNaN(v) || 'Please only enter numbers']"
        />
      </v-col>
    </template>
    <template v-if="error === 'ProofOfPayment'">
      <v-col cols="12">
        <v-text-field
          label="Abiliware Ref"
          hint="Click on question mark to see example"
          v-model="specific_error_info.abiliware_ref"
          prepend-icon="fa-question"
          required
          :rules="[v => !!v || 'Abiliware Ref is required',
                     v => !isNaN(v) || 'Please only enter numbers']"
          @click:prepend="toggleExample"
        />
        <v-menu
          v-model="showMenu"
          :close-on-content-click="false"
          max-width="290px"
          min-width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              label="Date"
              v-model="specific_error_info.formatted_date"
              required
              :rules="[v => !!v || 'A start date is required']"
              readonly
              v-bind="attrs"
              v-on="on"
              prepend-icon="fa-question"
              @click:prepend="toggleExample"
            />
          </template>
          <v-date-picker v-model="date" no-title @input="showMenu = false"></v-date-picker>
        </v-menu>
        <v-text-field label="Amount" v-model="specific_error_info.amount" prepend-icon="attach_money" required
                      :rules="[v => !!v || 'Amount is required',
                     v => !isNaN(v) || 'Please only enter numbers']"
        />
        <v-text-field
          label="Description"
          v-model="specific_error_info.description"
          prepend-icon="fa-question"
          required
          :rules="[v => !!v || 'Description is required']"
          @click:prepend="toggleExample"
        />
      </v-col>
      <v-dialog v-model="showExample" width="20%">
        <img src="../.././assets/proof-of-payment.png"/>
      </v-dialog>
    </template>
    <v-row class="mt-6">
      <v-btn class="justify-start" text @click="toggleErrorForm">
        Close
      </v-btn>
      <v-spacer/>
      <v-btn class="px-7 justify-end" color="primary" @click="submitErrorForm">
        Submit
      </v-btn>
    </v-row>
  </v-form>
</template>

<script>
import axios from 'axios';
import notifications from '@/modules/notifications';
import {CONSTANTS} from '@/modules/constants';

export default {
	name: 'PaymentErrorProcessing',
	props: ['customer_id', 'order_nr', 'delivery_date', 'error', 'clicked_amount','all_order_nrs'],
	data() {
		return {
			interval_items: ['Weekly', 'Fortnightly', 'Monthly'],
			showExample:false,
			showMenu:false,
			date:'',
			specific_error_info: {
				financial_difficulty: false,
				frequency: '',
				amount: '',
				formatted_date:'',
				order_id:'',
				reason:'',
				bank_account_name:'',
				bank_name:'',
				bsb:'',
				bank_account_nr:'',
				abiliware_ref:'',
				date:'',
				description:''
			}
		};
	},
	watch: {
		date() {
			this.specific_error_info.formatted_date = this.formatDate(this.date)
		}
	},

	methods: {
		formatDate(date) {
			if(!date) return null
			const [year, month, day] = date.split('-')
			return `${day}/${month}/${year}`
		},
		toggleExample() {
			this.showExample = !this.showExample;
		},
		resetErrorData() {
			this.specific_error_info = {
				financial_difficulty: false,
				frequency: '',
				amount: '',
				formatted_date:'',
				order_id:'',
				reason:'',
				bank_account_name:'',
				bank_name:'',
				bsb:'',
				bank_account_nr:'',
				abiliware_ref:'',
				date:'',
				description:''
			}
		},
		toggleErrorForm() {
			this.resetErrorData();
			this.$refs.error_form.resetValidation();
			this.$emit('toggle-error-form');
		},
		submitErrorForm() {
			if (this.$refs.error_form.validate()) {
				const request_full = {
					'country': this.$store.state.country,
					'error_details': {
						'error_type': this.error,
						'customer_id': this.customer_id,
						'order_nr': this.order_nr,
						'delivery_date': this.delivery_date,
						'total_amount': this.clicked_amount,
						...this.specific_error_info
					}
				}
				if (this.specific_error_info.bsb == '') {
					this.specific_error_info.bsb = '-';
				}
				axios
					.post(`${CONSTANTS.BASE_URL}/api/v1/payment/errors/processing`, request_full)
					.then((response) => {
						if (response.data.msg === 'success') {
							notifications.addNotification('Payment error processed successfully');
						}
						this.toggleErrorForm();
					})
					.catch(e => {
						notifications.addNotification('Error processing payment error. Try again.', true);
					})
			}
		}
	}
};
</script>

<style scoped>
  h1 {
    background-color: white;
    text-align: center;
    font-weight: 400;
  }
</style>

