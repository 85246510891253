<template>
  <div id="app">
    <v-app>
      <transition name="fade" mode="out-in">
        <div v-if="$route.name === 'home'">
          <img v-if="preferences.country === 'nz'" src="./assets/background-nz.png" class="background"/>
          <img v-else-if="preferences.country === 'au'" src="./assets/background-au.png" class="background"/>
          <img v-else-if="preferences.country === 'ao'" src="./assets/background-ep.png" class="background"/>
          <img v-else-if="preferences.country === 'ye'" src="./assets/background-ye.png" class="background"/>
        </div>
      </transition>
      <sentinel-header v-if="forbiddenPath()"/>
      <v-content>
        <transition name="fade" mode="out-in">
          <router-view :key="$route.fullPath"/>
        </transition>
      </v-content>
    </v-app>
  </div>
</template>

<script>
import SentinelHeader from '@/components/util/Header';
import auth from './modules/auth';
import features from './modules/features';

export default {
	name: 'App',
	data() {
		return {
			preferences: auth.user.preferences,
			user: auth.user.profile
		}
	},
	// async beforeMount() {
	// 	await features.configureLaunchDarklyBeforeLogin();
	// },
	components: {
		sentinelHeader: SentinelHeader
	},
	methods: {
		forbiddenPath: function () {
			return !(this.$route.name === 'login' || this.$route.name === 'callback' || this.$route.name === 'country');
		}
	}
}
</script>

<style>
#app {
  min-height: 100vh;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .3s;
}

.fade-enter, .fade-leave-to {
  opacity: 0;
}

.v-progress-linear.progress {
  position: fixed;
  top: 0;
  left: 0;
  margin: 0;
  z-index: 1;
}

.list-complete-enter, .list-complete-leave-to {
  opacity: 0;
  transform: translateX(30px);
}

.list-complete-leave-active {
  position: absolute;
}

.theme--light.v-card .v-card__title.primary,
.theme--light.v-card .v-card__title.secondary,
.theme--light.v-card .v-card__title.warning,
.theme--light.v-card .v-card__title.error {
  color: white;
}

.background {
  position: fixed;
  top: 0;
  left: 0;
  min-height: 100%;
  min-width: 100%;
  opacity: 0.7;
}

.flex, .container {
  transition: all 1s;
}
</style>

<!--<style lang="stylus">-->
<!--  $heading-font-family = 'Lato'-->
<!--  $body-font-family = 'Roboto'-->

<!--  $body-bg-dark = '#323232'-->
<!--  $body-bg-light = '#ffffff'-->

<!--  /*@import '../node_modules/vuetify/src/stylus/main'*/-->
<!--</style>-->
