<template>
  <v-card>
    <v-card-title class="headline" primary-title>
      Delete Voucher Type - {{ selectedVoucherToDelete.type }}
    </v-card-title>
    <v-card-text>
      <p>Are you sure you want to delete this voucher type?</p>
      <p>
        Deleting this voucher type will delete all existing backup vouchers with
        the same type in Sentinel.
      </p>
      <v-btn
        color="error"
        outlined
        class="mr-4"
        @click="handleSubmit"
        :loading="loading"
        >Delete
      </v-btn>
      <v-btn class="mr-4" @click="$emit('closeModal')">Close</v-btn>
    </v-card-text>
  </v-card>
</template>

<script>
import axios from 'axios';
import store from '@/store/store';
import notifications from '@/modules/notifications';
import {CONSTANTS} from '@/modules/constants';

export default {
	name: 'DeleteVoucherType',
	props: ['selectedVoucherToDelete'],
	data() {
		return {
			loading: false
		};
	},
	methods: {
		handleSubmit() {
			const { country } = store.state;
			const { id, type } = this.selectedVoucherToDelete;

			this.loading = true;

			axios
				.delete(`${CONSTANTS.BASE_URL}/api/v1/voucher_type`, {
					params: {
						id,
						country,
						voucher_type: type
					}
				})
				.then((response) => {
					const {
						number_of_deleted_vouchers: numberOfDeletedVouchers
					} = response.data;
					this.$emit('handleSubmit');
					this.loading = false;
					notifications.addNotification(
						`Successfully deleted voucher type, along with ${numberOfDeletedVouchers} backup voucher(s)`
					);
				})
				.catch((error) => {
					notifications.addNotification(
						'Error: could not delete voucher type',
						true
					);
					this.loading = false;
				});
		}
	}
};
</script>
