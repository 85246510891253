<template>
  <div class="windows">
    <v-progress-linear indeterminate v-if="progress" color="secondary" class="progress"></v-progress-linear>

    <v-dialog v-model="isCompensationPopInOpened" width="800">
      <v-card class="mx-auto" color="white" max-width="800">
        <v-card-title>
          <v-icon large left class="pr-2" color="primary">done_all</v-icon>
          <span class="title font-weight-medium">{{compensationPopInTitle}}</span>
        </v-card-title>
        <v-text-field
          v-if="!validation"
          class="px-6"
          label="Enter credit update (ex. 100% bulk credit)"
          v-model="creditUpdate"
          v-on:keyup.13="applyCompensation"
        ></v-text-field>
        <v-card-actions>
          <v-list-item class="grow">
            <v-list-item-avatar color="primary">
              <v-icon color="white">attach_money</v-icon>
            </v-list-item-avatar>
            <v-list-item-title>{{liveErrorsUpdatedMessage}}</v-list-item-title>
            <v-spacer></v-spacer>
            <v-btn left color="error" text @click="isCompensationPopInOpened = false">Cancel</v-btn>
            <v-btn v-if="validation" left color="primary" text @click="validateCompensation">Update</v-btn>
            <v-btn v-else left color="primary" text @click="applyCompensation">Update</v-btn>
          </v-list-item>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-container>
      <v-row>
        <v-col cols="8">
          <h1 class="display-3 mb-4">Missed Courier Deliveries</h1>
        </v-col>
        <v-col cols="2" class="mt-4">
          <v-btn color="secondary" block :to="mcd_report">
            <v-icon left dark>trending_up</v-icon>
            Report
          </v-btn>
        </v-col>
        <v-col cols="2" class="mt-4">
          <v-btn color="primary" block @click="downloadMcdLiveErrors(1)" :disabled="mcdErrors.length === 0">
            <v-icon left dark>cloud_download</v-icon>
            Export
          </v-btn>
        </v-col>
        <v-col cols="12" class="pb-0">
          <v-card>
            <v-card-title>
              <v-row>
                <v-col cols="3">
                  <v-select :items="weeks" v-model="selectedWeek" label="Week" solo class="mt-2"></v-select>
                </v-col>
                <v-spacer></v-spacer>
                <v-col cols="2">
                  <v-btn color="warning" class="mt-4"
                         :disabled="disabledValidationButton"
                         block
                         @click="updateCompensationDetailsForLiveErrors(true)">
                    <v-icon left dark>done</v-icon>
                    Validate
                  </v-btn>
                </v-col>
                <v-col cols="2">
                  <v-btn color="warning" class="mt-4"
                         :disabled="disabledCompensationButton"
                         block
                         @click="updateCompensationDetailsForLiveErrors(false)">
                    <v-icon left dark>attach_money</v-icon>
                    Compensate
                  </v-btn>
                </v-col>
                <v-spacer></v-spacer>

                <v-col cols="3">
                  <v-text-field v-model="search" append-icon="search" label="Any reference" single-line hide-details
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-card-title>
            <v-data-table
              :headers="headers"
              :items="mcdErrors"
              :search="search"
              v-model="selected"
              :loading="progress"
              item-key="id"
              show-select
              fixed-header
              :height="mcdErrors.length < 10 ? '':'700px'"
              multi-sort
              hide-default-footer
              disable-pagination
              :loading-text="'Loading data'"
              :no-data-text="'No data found'"
              >
              <template v-slot:item.text_notification_sent="{ item }">
                <v-icon v-if="item.text_notification_sent" small>done</v-icon><v-icon v-else small>clear</v-icon>
              </template>

              <template v-slot:item.data-table-select="{ isSelected, select }">
                <v-checkbox hide-details color="primary" :value="isSelected" @change="select($event)"></v-checkbox>
              </template>

              <template v-slot:footer>
              <v-card height="50px">
                <v-divider class="mb-2"></v-divider>
                <span :style="{textAlign: 'right', float: 'right', fontSize: '0.8em', color:'grey'}"
                      class="ma-2 pr-4" >
                  Rows: {{ mcdErrors.length }}
                </span>
                </v-card>
            </template>

              <v-alert v-slot:no-results :value="true" color="error" icon="warning">
                Your search for "{{ search }}" found no results.
              </v-alert>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import axios from 'axios';
import auth from '../../modules/auth';
import notifications from '@/modules/notifications';
import {CONSTANTS} from '@/modules/constants';

export default {
	name: 'MissedCourierDeliveries',
	data() {
		return {
			progress: false,
			isCompensationPopInOpened: false,
			compensationPopInTitle: '',
			preferences: auth.user.preferences,
			search: '',
			mcdErrors: [],
			selected: [],
			successMessage: '',
			headers: [
				{text: 'Error ID', align: 'left', value: 'id'},
				{text: 'Box ID', align: 'left', value: 'box_id', color: '#8bcd57'},
				{text: 'Error', align: 'left', value: 'error'},
				{text: 'Contacted', align: 'left', value: 'text_notification_sent'},
				{text: 'Compensation', align: 'left', value: 'compensation'},
				{text: 'Error tracked as', align: 'left', value: 'category'},
				{text: 'Product', align: 'left', value: 'product'},
				{text: 'Customer ID', align: 'left', value: 'customer_id'},
				{text: 'Customer Name', align: 'left', value: 'customer_name'},
				{text: 'Email', align: 'left', value: 'customer_email'},
				{text: 'Phone', align: 'left', value: 'customer_phone'},
				{text: 'Courier', align: 'left', value: 'courier'},
				{text: 'Day input', align: 'left', value: 'date'},
				{text: 'Time input', align: 'left', value: 'hour'},
				{text: 'Input by', align: 'left', value: 'team_leader'},
				{text: 'Agent', align: 'left', value: 'agent'},
				{text: 'Credit update', align: 'left', value: 'credit_update'},
				{text: 'Compensation approved by', align: 'left', value: 'approval_team_leader'}
			],
			creditUpdate: '',
			validation: false,
			batchSize: 200
			// ,
			// footerProps: {
			//     itemsPerPageOptions: [15, 100, 200],
			//     options: {
			//         itemsPerPage: 200
			//     }
			// }
		}
	},
	computed: {
		liveErrorsUpdatedMessage: function () {
			return this.selected.length + ' MCD updates' + (this.selected.length > 1 ? 's' : '') + ' will be updated.'
		},
		disabledValidationButton: function () {
			return this.selected.length === 0 || this.selected
				.reduce((accumulator, currentValue) => accumulator || currentValue.approval_team_leader !== null, false)
		},
		disabledCompensationButton: function () {
			return this.selected.length === 0 || this.selected
				.reduce((accumulator, currentValue) => accumulator || currentValue.credit_update !== null, false)
		},
		mcd_report: function () {
			return {name: 'mcd_report', params: {week: this.selectedWeek}};
		},
		selectedWeek: {
			set(week) {
				this.$store.dispatch('updateWeek', week)
			},
			get() {
				return this.$store.state.week
			}
		},
		weeks() {
			return this.$store.state.weeks
		}
	},
	mounted() {
		this.setTableHeaderColor();
	},
	watch: {
		selectedWeek: {
			handler: function() {
				this.refreshMcdLiveErrors(1);
			},
			immediate: true
		}
	},
	methods: {
		refreshMcdLiveErrors: function (page) {
			this.progress = true;
			if (page === 1) {
				this.mcdErrors = [];
			}
			axios.get(`${CONSTANTS.BASE_URL}/api/v1` + '/errors/live/mcd', {
				params: {
					country: this.preferences.country,
					week: this.selectedWeek,
					page: page,
					batch_size: this.batchSize
				}
			}).then((response) => {
				if (response.data.msg === 'success') {
					let mcdErrors = response.data.errors;
					mcdErrors.forEach(function (mcdError) {
						mcdError.compensation = mcdError.compensation_amount + ' ' + mcdError.compensation_type;
					});
					this.mcdErrors = this.mcdErrors.concat(mcdErrors);
					if (response.data.count < this.batchSize) {
						this.progress = false;
					} else {
						this.refreshMcdLiveErrors(page + 1);
					}
				}
			}).catch(() => {
				this.mcdErrors = [];
				this.progress = false;
				notifications.addNotification('Oups, no MCD errors found.', true);
			});
		},
		downloadMcdLiveErrors: function (page) {
			this.progress = true;
			axios({
				url: `${CONSTANTS.BASE_URL}/api/v1` + '/errors/live/mcd/download',
				method: 'GET',
				responseType: 'blob',
				params: {
					country: this.preferences.country,
					week: this.selectedWeek,
					page: page
				}
			}).then((response) => {
				const url = window.URL.createObjectURL(new Blob([response.data]));
				const link = document.createElement('a');
				link.href = url;
				link.setAttribute(
					'download',
					'MCD - ' + this.preferences.country.toUpperCase() + ' - ' + this.selectedWeek + ' - page_' + page + '.csv'
				);
				document.body.appendChild(link);
				link.click();
				this.downloadMcdLiveErrors(page + 1);
			}).catch(() => {
				this.progress = false;
			});
		},
		applyCompensation: function () {
			this.isCompensationPopInOpened = false;
			this.progress = true;
			axios.post(`${CONSTANTS.BASE_URL}/api/v1` + '/errors/live/compensation/compensate', {
				country: this.preferences.country,
				credit_update: this.creditUpdate,
				live_error_ids: this.selected.map((live_error) => {
					return live_error.id
				})
			}).then((response) => {
				if (response.data.msg === 'success') {
					this.successMessage = this.selected.length + ' compensation' + (this.selected.length > 1 ? 's' : '')
              + ' applied. [' + this.creditUpdate + ']';
					this.progress = false;
					this.selected = [];
					notifications.addNotification(this.successMessage);
					this.refreshMcdLiveErrors(1);
				}
			})
		},
		validateCompensation: function () {
			this.isCompensationPopInOpened = false;
			this.progress = true;
			axios.post(`${CONSTANTS.BASE_URL}/api/v1` + '/errors/live/compensation/validate', {
				country: this.preferences.country,
				live_error_ids: this.selected.map((live_error) => {
					return live_error.id
				})
			}).then((response) => {
				if (response.data.msg === 'success') {
					this.successMessage = this.selected.length + ' compensation' + (this.selected.length > 1 ? 's' : '')
              + ' validated.';
					this.progress = false;
					this.selected = [];
					notifications.addNotification(this.successMessage);
					this.refreshMcdLiveErrors(1);
				}
			})
		},
		updateCompensationDetailsForLiveErrors: function (validation) {
			this.validation = validation;
			this.compensationPopInTitle = validation ? 'Validate compensation' : 'Apply compensation';
			this.isCompensationPopInOpened = true;
		},
		setTableHeaderColor: function () {
			let d = document.getElementsByTagName('th');
			for (let element of d) {
				element.className = this.preferences.country === 'au' ? 'thead-au' : 'thead-nz';
			}
		}
	}
}
</script>

<style scoped>

  td {
    white-space: nowrap;
  }
  >>>.thead-au {
    background-color: #8bcd57 !important;
  }
  >>>.thead-nz {
    background-color: #c180ff !important;
  }
</style>
