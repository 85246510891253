<template>
  <div>

    <v-card class="px-8 py-4">
      <span class="headline"> Inspect Response ID: {{ Object.entries(row).filter(v => v[0] === 'id')[0][1] }} </span>
      <v-icon :style="{float: 'right', textAlign: 'right'}" @click="$emit('close')">close</v-icon>
      <v-row class="mt-4">
        <v-col cols="3" v-for="response, index in Object.entries(row).filter(v => v[0] !== 'id' && v[0] !== null)" :key="index">
          <p class="subtitle-2">{{ response[0] }}:</p> <p class="subtitle-1">{{ response[1] }}</p>
        </v-col>
      </v-row>
    </v-card>

  </div>
</template>

<script>
export default {
	name: 'LiveDataDisplayRowPopup',
	props: {
		row: {type: Object, required: true},
		name: {type: String, required: true}
	}
}
</script>

<style scoped>

</style>
