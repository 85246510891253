<template>
  <div class="live-errors">
    <v-progress-linear indeterminate v-if="progress" color="secondary" class="progress"></v-progress-linear>
    <v-container>
      <v-row>
        <v-col cols="9"><h1 class="display-3">Courier Contact Page for CC Team Leaders</h1></v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import auth from '../../modules/auth';

export default {
	name: 'CourierContact',
	data() {
		return {
			preferences: auth.user.preferences,
			progress: false
		}
	}
}
</script>

<style scoped>

</style>
loc: '/dashboard', disabled: true},
