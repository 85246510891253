<template>
  <v-card>
    <v-card-title class="headline warning" primary-title>
      <v-icon color="white" class="px-4">new_releases</v-icon>
      {{
        isTemplateAlreadySent
          ? foodSafetyForm.food_safety_type.charAt(0).toUpperCase() +
            foodSafetyForm.food_safety_type.slice(1).replace(/\_/g, " ") +
            " issue"
          : "Food safety issue"
      }}
      <v-spacer></v-spacer>
      <v-btn color="secondary" @click="closePopIn" v-if="isTemplateAlreadySent"
        >Close</v-btn
      >
      <v-subheader v-else class="subheading"
        >Don't forget to<b>&nbsp error track &nbsp</b>on customer complaint tool
        !
      </v-subheader>
    </v-card-title>
    <v-divider></v-divider>
    <v-card-text>
      <v-row wrap v-if="isTemplateAlreadySent">
        <v-col cols="12">
          <v-list two-line>
            <v-list-item>
              <v-list-item-action>
                <v-icon v-if="!foodSafetyForm.answered" color="error"
                  >router</v-icon
                >
                <v-icon v-else-if="!foodSafetyForm.qa_reviewed" color="warning"
                  >find_replace</v-icon
                >
                <v-icon v-else color="success">check</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>
                  <span
                    :class="
                      foodSafetyForm.answered
                        ? foodSafetyForm.qa_reviewed
                          ? 'primary--text'
                          : 'warning--text'
                        : 'error--text'
                    "
                  >
                    {{
                      foodSafetyForm.answered
                        ? foodSafetyForm.qa_reviewed
                          ? foodSafetyForm.cc_reviewed
                            ? "Food safety issue closed and customer notified"
                            : "Food safety issue closed, customer not notified yet"
                          : "Under QA investigation"
                        : "Waiting for customer response"
                    }}
                  </span>
                </v-list-item-title>
                <v-list-item-subtitle>Status</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-col>
        <v-col cols="12">
          <v-list two-line>
            <v-list-item>
              <v-list-item-action>
                <v-icon>speaker_notes</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>
                  <a :href="foodSafetyForm.form_link" target="_blank"
                    >{{ foodSafetyForm.form_link }}
                  </a>
                </v-list-item-title>
                <v-list-item-subtitle>Form link</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-col>
      </v-row>
      <v-row wrap v-else>
        <v-col cols="12">
          <v-select
            class="px-2"
            prepend-icon="restaurant"
            :items="recipes"
            v-model="selectedRecipe"
            label="Recipe"
          ></v-select>
        </v-col>
        <v-col cols="12">
          <v-select
            class="px-2"
            prepend-icon="rate_review"
            :items="Object.keys(formTemplates)"
            v-model="selectedFormTemplate"
            label="Form template"
          ></v-select>
        </v-col>
        <v-col cols="12">
          <v-text-field
            class="px-2"
            prepend-icon="place"
            v-model="orderNumberInput"
            label="Error track tool Order ID"
          >
          </v-text-field>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions v-if="!isTemplateAlreadySent" class="px-2">
      <v-checkbox
        v-model="sendFoodSafetyFormToCustomerConfirmation"
        :label="`Send food safety mail to ${customerMail}?`">
      </v-checkbox>
      <v-spacer></v-spacer>
      <v-btn color="error" text @click="closePopIn">Close</v-btn>
      <v-btn
        color="warning"
        text
        @click="sendFormToCustomer"
        :disabled="!areFoodSafetyOptionsSelected"
      >
        {{sendFoodSafetyFormToCustomerConfirmation ? 'Send form' : 'Create form'}}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import notifications from '@/modules/notifications';
import auth from '../../modules/auth';
import axios from 'axios';
import {CONSTANTS} from '@/modules/constants';

export default {
	name: 'FoodSafety',
	data() {
		return {
			selectedRecipe: '',
			formTemplates: {},
			selectedFormTemplate: '',
			orderNumberInput: '',
			sendFoodSafetyFormToCustomerConfirmation: false
		};
	},
	props: {
		closePopIn: Function,
		sendFoodSafetyFormToCustomer: Function,
		customerMail: String,
		recipes: Array,
		foodSafetyForm: Object,
		box: Object
	},
	computed: {
		areFoodSafetyOptionsSelected: function () {
			return (
				this.selectedRecipe !== '' &&
        this.selectedFormTemplate !== '' &&
        this.orderNumberInput.length >= 7 &&
        Number.isNaN(parseInt(this.orderNumberInput, 10)) === false
			);
			// return this.selectedRecipe !== '' && this.selectedFormTemplate !== '' && parseInt(this.orderNumberInput,10) === this.box.customer_order
		},
		isTemplateAlreadySent: function () {
			return this.foodSafetyForm.box_id !== undefined;
		}
	},
	mounted(){
		this.getFormTemplates()
	},
	methods: {
		getFormTemplates(){
			axios
				.get(`${CONSTANTS.BASE_URL}/api/v1` + `/food_safety/types`, {
					params: {
						country: auth.user.preferences.country
					}
				})
				.then((response) => {
					this.formTemplates = response.data.food_safety_types
				})
				.catch(() => {
					notifications.addNotification(`Oops, there was a problem getting the food safety issue types`, true)
				})
		},
		sendFormToCustomer() {
			this.sendFoodSafetyFormToCustomer(
				this.selectedRecipe,
				this.formTemplates[this.selectedFormTemplate],
				this.sendFoodSafetyFormToCustomerConfirmation
			);
			this.selectedRecipe = '';
			this.selectedFormTemplate = '';
		}
	}
};
</script>

<style scoped></style>
