<template>
  <div class="windows">
    <v-progress-linear indeterminate v-if="progress" color="secondary" class="progress"></v-progress-linear>

    <v-container>
      <v-row class="mb-3">
        <v-col cols="4">
          <h1 class="display-3">MCD Report</h1>
        </v-col>
        <v-spacer></v-spacer>
        <v-col cols="2" class="mt-4">
          <v-progress-circular v-if="progress" indeterminate color="primary"></v-progress-circular>
        </v-col>
        <v-col cols="2" class="mt-4">
          <v-select :items="weeks" v-model="selectedWeek" label="Week" solo hide-details dense></v-select>
        </v-col>
        <v-col cols="2" class="mt-4">
          <v-btn color="primary" block @click="downloadMcdLiveErrorsReport" :disabled="report.length === 0">
            <v-icon left dark>cloud_download</v-icon>
            Export
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" class="mb-3">
          <v-card>
            <v-data-table
              :headers="headers"
              :items="report"
              :loading="progress"
              multi-sort
              fixed-header
              disable-pagination
              :height="tableHeight"
              hide-default-footer
              :no-data-text="'No data found'"
            >
              <template v-slot:footer>
                <v-card height="40px">
                  <v-divider class="ma-0"></v-divider>
                  <span class="tableFooter">
                  Rows: {{ report.length }}
                </span>
                </v-card>
              </template>
            </v-data-table>

          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import axios from 'axios';
import auth from '../../modules/auth';
import notifications from '@/modules/notifications';
import {CONSTANTS} from '@/modules/constants';

export default {
	name: 'MissedCourierDeliveriesReport',
	data() {
		return {
			preferences: auth.user.preferences,
			progress: false,
			headers: [
				{text: 'Courier', align: 'left', value: 'courier'},
				{text: 'Error tracked as', align: 'left', value: 'error_track_as'},
				{text: 'Error', align: 'left', value: 'error'},
				{text: 'Day input', align: 'left', value: 'day_input'},
				{text: 'Count', align: 'left', value: 'count'}
			],
			report: []
		}
	},
	mounted() {
		this.selectedWeek = this.$route.params.week;
	},
	computed: {
		tableHeight() {
			let rows = this.report.length
			return rows < (window.innerHeight * 0.7 - 104) / 38 ? (104 + rows * 38).toString() + 'px' : window.innerHeight * 0.7
		},
		weeks() {
			return this.$store.state.weeks
		},
		selectedWeek: {
			set(week) {
				this.$store.dispatch('updateWeek', week)
			},
			get() {
				return this.$store.state.week
			}
		}
	},
	watch: {
		selectedWeek: function () {
			this.getMCDLiveErrorsReport();
		}
	},
	methods: {
		getMCDLiveErrorsReport: function () {
			this.progress = true;
			axios.get(`${CONSTANTS.BASE_URL}/api/v1` + '/errors/live/mcd/report', {
				params: {country: this.preferences.country, week: this.selectedWeek}
			}).then((response) => {
				if (response.data.msg === 'success') {
					this.report = response.data.report;
					this.progress = false;
				}
			}).catch(() => {
				this.report = [];
				this.progress = false;
				notifications.addNotification('Oups, something went wrong when retrieving the report.', true);
			});
		},
		downloadMcdLiveErrorsReport: function () {
			this.progress = true;
			axios({
				url: `${CONSTANTS.BASE_URL}/api/v1` + '/errors/live/mcd/report/download',
				method: 'GET',
				responseType: 'blob',
				params: {country: this.preferences.country, week: this.selectedWeek}
			}).then((response) => {
				const url = window.URL.createObjectURL(new Blob([response.data]));
				const link = document.createElement('a');
				link.href = url;
				link.setAttribute(
					'download',
					'MCD Report - ' + this.preferences.country.toUpperCase() + ' - ' + this.selectedWeek + '.csv'
				);
				document.body.appendChild(link);
				link.click();
				this.progress = false;
			});
		}
	}
}
</script>

<style scoped>
  th {
    white-space: nowrap;
  }

  .tableFooter {
    text-align: right;
    float: right;
    font-size: 0.8em;
    color: grey;
    margin: 8px;
    padding-right: 16px;
  }
</style>
