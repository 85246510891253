<template>
  <v-container>
    <v-row>
      <v-card width="100%">
        <v-card-title>
          <v-row align="center">
            <v-col>
              <h2 class="font-weight-thin">
                {{ title }}
              </h2>
            </v-col>
          </v-row>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-subtitle>
          <v-row align="center">
            <v-col v-for="filter in filters">
              <v-select
                outlined
                :label="filter.label"
                :items="filter.items"
                :key="filter.label"
                v-model="filter.model"
                :multiple="filter.multiple"
                @change="value => $emit(filter.event, value)"
              ></v-select>
            </v-col>
            <v-col align-self="stretch" v-if="exportConfig">
              <v-btn
                color="primary"
                height="50px"
                width="100%"
                :loading="exportConfig.loading"
                :disabled="exportConfig.disabled"
                @click="$emit('export-data')"
              >
                {{ exportConfig.label }}
              </v-btn>
            </v-col>
          </v-row>
        </v-card-subtitle>
        <v-card-text class="mb-6">
          <v-row>
            <v-col cols="4" v-for="data in dataEntries" :key="data.label">
              <v-row align="center" class="flex-column">
                <span> {{data.label}} </span>
                <v-progress-circular
                  class="mt-4"
                  indeterminate
                  v-if="loading"
                  color="primary"
                ></v-progress-circular>
                <span v-else class="number-data">
                  {{ data.value }}
                </span>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-row>
  </v-container>
</template>

<script>

export default {
	name: 'HotwiresDashboardSummary',
	props: {
	  title: String,
		filters: Array,
		dataEntries: Array,
		exportConfig: Object,
		loading: Boolean
	}
}
</script>

<style scoped>
  h2 {
    text-align: center;
  }

  .number-data {
    margin-top: 2rem;
    font-size: 3.75rem;
    font-weight: 300;
  }
</style>
