<template>
  <div class="windows">
    <v-progress-linear
      indeterminate
      v-if="progress"
      color="secondary"
      class="progress"
    ></v-progress-linear>

    <v-container>
      <v-row>
        <v-col cols="12">
          <h1 class="display-3">Lookup tool</h1>
        </v-col>

        <v-scroll-y-transition tag="jobs">
          <v-col cols="12" v-if="jobs.length > 0" class="pb-2">
            <v-divider class="mx-2"></v-divider>
            <v-subheader class="pt-4" style="height: 40px;">Jobs</v-subheader>
            <v-scroll-y-transition group>
              <v-row class="mr-4 pl-4" v-for="job in jobs" :key="job.id">
                <v-col
                  cols="2"
                  class="job-title"
                  v-if="updateMCDJobId === job.id"
                >Create MCD
                </v-col
                >
                <v-col cols="2" class="job-title" v-else>Sending text</v-col>
                <v-col cols="3" class="job-title">{{ job.title }}</v-col>
                <v-col cols="12">
                  <v-progress-linear
                    :indeterminate="job.progress < 0"
                    :value="job.progress"
                    :color="job.color"
                  >
                  </v-progress-linear>
                </v-col>
              </v-row>
            </v-scroll-y-transition>
          </v-col>
        </v-scroll-y-transition>

        <v-col cols="12">
          <v-stepper v-model="selectedStep" vertical non-linear>
            <v-stepper-step :complete="selectedStep > 1" step="1" editable>
              Search for customers
              <small>
                {{
                  selectedStep === "1"
                    ? "Which type of ids do you want to search ?"
                    : "Type or copy/paste ids"
                }}
              </small>
            </v-stepper-step>

            <v-stepper-content step="1" class="pt-2">
              <div class="chips pb-2">
                <div v-for="type in types" class="pr-2">
                  <v-chip
                    v-if="type.slot === selectedType"
                    label
                    color="primary"
                    text-color="white"
                  >
                    <v-icon small class="pr-2">{{ type.icon }}</v-icon>
                    {{ type.name }}
                  </v-chip>
                  <v-chip
                    v-else
                    label
                    color="primary"
                    outlined
                    @click="selectedType = type.slot"
                  >
                    <v-icon small class="pr-2" color="primary">{{
                        type.icon
                      }}
                    </v-icon>
                    {{ type.name }}
                  </v-chip>
                </div>
                <v-spacer></v-spacer>
              </div>
              <v-row>
                <v-col cols="4">
                  <v-switch
                    v-model="allBoxesSwitch"
                    :label="'Display all boxes for each customer'"
                    color="secondary"
                    class="ma-0"
                  ></v-switch>
                </v-col>
                <v-col cols="4" v-if="preferences.country !== COUNTRYCODE.YE">
                  <v-switch
                    v-model="paymentColumnsSwitch"
                    :label="'Calculate failed payment data (slower)'"
                    color="secondary"
                    class="ma-0"
                  ></v-switch>
                </v-col>
              </v-row>
              <v-textarea
                outlined
                v-model="query"
                hide-details
                class="mb-2 pr-4"
                :label="'Customers by ' + selectedType + ' ids'"
              ></v-textarea>
              <v-scroll-x-transition tag="button-step-1-list">
                <div v-if="customers.length === 0">
                  <v-btn
                    :loading="progress"
                    color="primary"
                    @click="searchCustomersLookup()"
                  >Search
                  </v-btn
                  >
                  <v-btn text @click="selectedStep = 0">Cancel</v-btn>
                  <v-alert v-model="boxTypeErrorPaymentColumns" type="warning">
                    Failed payment columns may be inaccurate. Sku or Type not
                    found for the following boxes: {{ boxesCausingErrors }}
                  </v-alert>
                  <v-alert v-model="boxTypeError" type="warning">
                    Sku or Type not found for the following boxes:
                    {{ searchedBoxesCausingErrors }}
                  </v-alert>
                </div>
                <div v-else style="display: flex;" class="mr-4">
                  <v-btn
                    :loading="progress"
                    color="primary"
                    class="mb-2"
                    @click="searchCustomersLookup()"
                  >Search
                  </v-btn
                  >
                  <v-btn text color="secondary" @click="selectedStep = 2"
                  >Continue
                  </v-btn
                  >
                  <v-btn
                    text
                    color="black"
                    @click="
                      customers = [];
                      query = '';
                    "
                  >Clear
                  </v-btn
                  >
                  <v-btn
                    :loading="loadingExport"
                    text
                    color="secondary"
                    @click="downloadCustomersLookup"
                  >
                    <v-icon left dark class="pr-4 ml-0">cloud_download</v-icon>
                    Export
                  </v-btn>
                  <v-spacer></v-spacer>
                  <v-chip
                    v-if="selectedType !== 'box' && !allBoxesSwitch"
                    label
                    color="warning"
                    outlined
                  >
                    <v-icon left color="warning" class="pl-1">help</v-icon>
                    The boxes associated with the customer are those in the last
                    uploaded delivery.
                  </v-chip>
                </div>
              </v-scroll-x-transition>
              <v-scroll-y-transition tag="customer-list">
                <v-card v-if="customers.length > 0" text class="mb-2 mr-4">
                  <div>
                    <v-alert
                      v-model="boxTypeErrorPaymentColumns"
                      type="warning"
                    >
                      Failed payment columns may be inaccurate. Sku or Type not
                      found for the following boxes: {{ boxesCausingErrors }}
                    </v-alert>
                    <v-alert v-model="boxTypeError" type="warning">
                      One or more boxes not displayed. Sku or Type not found for
                      the following boxes: {{ searchedBoxesCausingErrors }}
                    </v-alert>
                  </div>
                  <v-card-title class="pt-0 pr-0">
                    <v-subheader class="pt-6 pl-0"
                    >{{ filteredSelected.length }} selected
                      customers
                    </v-subheader
                    >
                    <v-spacer></v-spacer>
                    <v-text-field
                      v-model="search"
                      append-icon="search"
                      label="Customer reference"
                      single-line
                      hide-details
                      class="pr-2"
                    ></v-text-field>
                  </v-card-title>
                  <v-data-table
                    v-model="selected"
                    :headers="headers"
                    :items="filteredCustomers"
                    :search="search"
                    show-select
                    multi-sort
                    item-key="box_id"
                    class="elevation-1"
                    fixed-header
                    hide-default-footer
                    disable-pagination
                    :height="tableHeight"
                    :no-data-text="'No data found'"
                  >
                    <template v-slot:header.data-table-select="{ on, props }">
                      <v-simple-checkbox
                        color="secondary"
                        v-bind="props"
                        v-on="on"
                      ></v-simple-checkbox>
                    </template>

                    <template
                      v-slot:item.data-table-select="{ isSelected, select }"
                    >
                      <v-simple-checkbox
                        color="secondary"
                        :value="isSelected"
                        @input="select($event)"
                      ></v-simple-checkbox>
                    </template>

                    <template v-slot:body.prepend>
                      <tr
                        :class="
                          preferences.country === 'au'
                            ? 'grey lighten-3'
                            : 'grey'
                        "
                      >
                        <th>
                          <v-icon>filter_list</v-icon>
                        </th>
                        <th v-for="header in headers" :key="header.text">
                          <div v-if="filters.hasOwnProperty(header.value)">
                            <v-select
                              text
                              hide-details
                              small
                              multiple
                              clearable
                              :items="columnValueList(header.value)"
                              v-model="filters[header.value]"
                            ></v-select>
                          </div>
                        </th>
                      </tr>
                    </template>

                    <!--                    <template v-slot:item="{ item, isSelected, select}">-->
                    <!--                      <tr :active="isSelected" @click="isSelected = !isSelected">-->
                    <!--                        <td>-->
                    <!--                          <v-simple-checkbox color="secondary"-->
                    <!--                                             :value="isSelected"-->
                    <!--                                             @input="select($event)"-->
                    <!--                          ></v-simple-checkbox>-->
                    <!--                        </td>-->
                    <!--                        <td>{{item.bob_id}}</td>-->
                    <!--                        <td>{{(item.box_id !== undefined ? item.box_id : '-')}}</td>-->
                    <!--                        <td>{{(item.subscription_id !== undefined ? item.subscription_id : '-')}}</td>-->
                    <!--                        <td>{{item.name}}</td>-->
                    <!--                        <td>{{item.email}}</td>-->
                    <!--                        <td>{{item.phone}}</td>-->
                    <!--                        <td>{{(item.box_type !== undefined ? item.box_type : '-')}}</td>-->
                    <!--                        <td>{{(item.is_add_on !== undefined ? item.is_add_on : '-')}}</td>-->
                    <!--                        <td>{{item.loyalty}}</td>-->
                    <!--                        <td>{{item.num_failed_payments}}</td>-->
                    <!--                        <td>{{item.num_failed_meal_boxes}}</td>-->
                    <!--                        <td>{{item.num_failed_add_ons}}</td>-->
                    <!--                      </tr>-->
                    <!--                    </template>-->

                    <template v-slot:footer>
                      <v-card height="50px">
                        <v-divider class="mb-2"></v-divider>
                        <span
                          :style="{
                            textAlign: 'right',
                            float: 'right',
                            fontSize: '0.8em',
                            color: 'grey',
                          }"
                          class="ma-2 pr-4"
                        >
                          Rows: {{ filteredCustomers.length }}
                        </span>
                      </v-card>
                    </template>

                    <template v-slot:no-results>
                      <v-alert :value="true" color="error" icon="warning">
                        Your search for "{{ search }}" found no results.
                      </v-alert>
                    </template>
                  </v-data-table>
                </v-card>
              </v-scroll-y-transition>
            </v-stepper-content>

            <v-stepper-step
              :complete="selectedStep > 2"
              step="2"
              editable
              color="secondary"
            >
              Create MCD updates
              <small>Optional</small>
            </v-stepper-step>

            <v-stepper-content step="2">
              <v-row v-if="customers.length !== 0">
                <v-col cols="8">
                  <h3 class="subheading font-weight-medium">
                    Classify the updates:
                  </h3>
                </v-col>
                <v-col cols="4"></v-col>
                <v-col cols="6" class="pr-4">
                  <v-combobox
                    v-model="errorsSelection.errorType"
                    :items="errorsSelection.errorTypeItems"
                    label="Enter the error type"
                  ></v-combobox>
                </v-col>
                <v-col cols="6" class="pl-1 pr-4">
                  <v-combobox
                    v-model="errorsSelection.compensationType"
                    :items="errorsSelection.compensationTypeItems"
                    label="Select the compensation type"
                  ></v-combobox>
                </v-col>
                <v-col cols="6" class="pr-4">
                  <v-text-field
                    v-model="errorsSelection.errorName"
                    label="Enter the error name"
                    :disabled="errorsSelection.errorType === ''"
                    class="pt-0"
                  ></v-text-field>
                </v-col>
                <v-col cols="3" class="pl-1">
                  <v-combobox
                    v-model="errorsSelection.compensationAmount"
                    :items="errorsSelection.compensationAmountItems"
                    label="Select the amount"
                    :disabled="
                      errorsSelection.compensationType === '' ||
                      errorsSelection.compensationType == 'Case by case' ||
                      errorsSelection.compensationType == 'No'
                    "
                    class="pt-0"
                  ></v-combobox>
                </v-col>
                <v-col cols="3" class="pl-6 pr-4">
                  <v-text-field
                    v-model="totalCompensation"
                    label="Total compensation"
                    disabled
                    prepend-inner-icon="attach_money"
                    class="pt-0"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12" class="pr-4 pb-4">
                  <v-textarea
                    auto-grow
                    name="script_area"
                    label="Instructions to agents"
                    hide-details
                    v-model="errorsSelection.errorScript"
                    rows="1"
                    class="pt-0"
                  ></v-textarea>
                </v-col>
                <v-col cols="12" style="display: flex;">
                  <v-tooltip right>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        v-on="on"
                        color="warning"
                        block
                        @click="addMCDLiveErrors"
                        :disabled="!displayUploadButton"
                      >
                        <v-icon left dark>add</v-icon>
                        Create MCD Updates
                      </v-btn>
                    </template
                    >
                    <span>Updates will be created for all customers</span>
                  </v-tooltip>
                  <v-btn
                    text
                    color="warning"
                    @click="selectedStep = 3"
                    class="mr-0"
                  >Skip
                  </v-btn
                  >
                  <v-btn text @click="selectedStep = 1" class="ml-0"
                  >Cancel
                  </v-btn
                  >
                </v-col>
              </v-row>
              <v-subheader v-else style="height: 30px;" class="pb-2">
                <v-icon class="pr-2">warning</v-icon>
                You can only create MCD updates on boxes.
              </v-subheader>
            </v-stepper-content>

            <v-stepper-step :complete="selectedStep > 3" step="3" editable
            >Send text messages
              <small>{{
                  "To " +
                  selected.length +
                  " customer" +
                  (selected.length !== 1 ? "s" : "")
                }}</small>
            </v-stepper-step>

            <v-stepper-content step="3">
              <v-row v-if="selected.length > 0">
                <v-col cols="6">
                  <h3 class="subheading font-weight-medium pt-4">
                    Choose your method to send text messages:
                  </h3>
                </v-col>
                <v-col cols="2"></v-col>
                <v-col cols="4" class="pr-4">
                  <v-card>
                    <v-card-title class="py-1 my-2">
                      <h3 class="subtitle-1">
                        Number of customers in each batch :
                      </h3>
                      <v-spacer></v-spacer>
                      <h3 class="subtitle-1">{{ numberOfBatches }}</h3>
                    </v-card-title>
                  </v-card>
                </v-col>
                <v-col cols="6" class="pr-4">
                  <v-select
                    :items="methods"
                    v-model="method"
                    label="Sending method"
                  ></v-select>
                </v-col>
                <v-col cols="6" class="pr-4">
                  <v-select
                    :items="schedules"
                    v-model="schedule"
                    label="Sending schedule"
                  ></v-select>
                </v-col>
                <v-col cols="12" style="display: flex;">
                  <v-tooltip right>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        v-on="on"
                        color="warning"
                        block
                        @click="sendText = true"
                        :disabled="!displaySendTextButton"
                      >
                        <v-icon left dark>message</v-icon>
                        Send text
                      </v-btn>
                    </template
                    >
                    <span>Do not forget to select appropriate customers</span>
                  </v-tooltip>
                  <v-btn text @click="selectedStep = 2">Cancel</v-btn>
                </v-col>
              </v-row>
              <v-subheader v-else style="height: 30px;" class="pb-2">
                <v-icon class="pr-2">warning</v-icon>
                Select customers to send them text messages.
              </v-subheader>
            </v-stepper-content>
          </v-stepper>
        </v-col>
      </v-row>
    </v-container>

    <v-dialog v-model="sendText" width="800" persistent>
      <text-message
        :send-text-messages="sendTextMessageToCustomers"
        :send-to-message="sendToMessage"
        :close-pop-in="closePopInMessage"
      >
      </text-message>
    </v-dialog>
  </div>
</template>

<script>
import axios from 'axios';
import auth from '../modules/auth';
import TextMessage from './TextMessage';
import notifications from '@/modules/notifications';
import {CONSTANTS, COUNTRYCODE} from '@/modules/constants';

export default {
	name: 'Lookup',
	components: {TextMessage},
	data() {
		return {
			sendText: false,
			progress: false,
			loadingExport: false,
			MCDLiveErrorGroup: null,
			preferences: auth.user.preferences,
			search: '',
			query: '',
			customers: [],
			boxesCausingErrors: null,
			boxTypeError: false,
			boxTypeErrorPaymentColumns: false,
			previsionError: false,
			searchedBoxesCausingErrors: [],
			selected: [],
			paymentColumnsSwitch: false,
			allBoxesSwitch: false,
			methods: [
				{text: 'Send all in once', value: 'all'},
				{text: 'Divide in 2 batches of customers', value: '2_batch'},
				{text: 'Divide in 3 batches of customers', value: '3_batch'},
				{text: 'Divide in 4 batches of customers', value: '4_batch'},
				{text: 'Divide in 5 batches of customers', value: '5_batch'},
				{text: 'Divide in 6 batches of customers', value: '6_batch'},
				{text: 'Divide in 7 batches of customers', value: '7_batch'},
				{text: 'Divide in 8 batches of customers', value: '8_batch'},
				{text: 'Divide in 9 batches of customers', value: '9_batch'},
				{text: 'Divide in 10 batches of customers', value: '10_batch'},
				{text: 'Divide in 15 batches of customers', value: '15_batch'},
				{text: 'Divide in 24 batches of customers', value: '24_batch'},
				{text: 'Divide in 48 batches of customers', value: '48_batch'},
				{text: 'Divide in 72 batches of customers', value: '72_batch'}
			],
			columnValueList(val) {
				return this.customers.map((d) => d[val]);
			},
			method: 'all',
			schedules: [
				{text: 'Send first now and then every 5 minutes', value: '5m'},
				{text: 'Send first now and then every 10 minutes', value: '10m'},
				{text: 'Send first now and then every 15 minutes', value: '15m'},
				{text: 'Send first now and then every 20 minutes', value: '20m'},
				{text: 'Send first now and then every 30 minutes', value: '30m'},
				{text: 'Send first now and then every 45 minutes', value: '45m'},
				{text: 'Send first now and then every hour', value: '1h'}
			],
			schedule: '5m',
			errorsSelection: {
				errorScript: '',
				errorType: '',
				errorTypeItems: [
					'Customer -> Need more info',
					'Customer -> Wrong Address',
					'Pick & Pack -> Damaged Box',
					'Pick & Pack -> Missing Box',
					'Logistics -> No Delivery',
					'Logistics -> Late',
					'Software -> Out Of Area',
					'Direct Sales -> Wrong Adress',
					'Line Haul',
					'N/A'
				],
				errorName: '',
				compensationType: '',
				compensationTypeItems: ['No', 'Case by case', 'Credit', 'Refund'],
				compensationAmount: '',
				compensationAmountItems: ['20%', '100%', '120%']
			},
			jobs: [],
			updateMCDJobId: '',
			selectedStep: '1',
			selectedType: 'box',
			types: [
				{slot: 'box', name: 'Box ids', icon: 'fas fa-box-open'},
				{slot: 'customer', name: 'Customer ids', icon: 'fas fa-users'},
				{
					slot: 'subscription',
					name: 'Subscription ids',
					icon: 'fas fa-user-tag'
				},
				{slot: 'email', name: 'Emails', icon: 'fas fa-envelope'},
				{slot: 'tracking', name: 'Tracking ids', icon: 'fa-map-marked-alt'}
			],
			headers: [
				{text: 'Customer ID', align: 'left', value: 'bob_id'},
				{text: 'Box id', align: 'left', value: 'box_id'},
				{text: 'Week', align: 'left', value: 'week', width: '8%'},
				{text: 'Subscription id', align: 'left', value: 'subscription_id'},
				{text: 'Name', align: 'left', value: 'name'},
				{text: 'Email', align: 'left', value: 'email'},
				{text: 'Phone', align: 'left', value: 'phone'},
				{text: 'Box type', align: 'left', value: 'box_type'},
				{text: 'Box price', align: 'left', value: 'box_value'},
				{text: 'Add-on', align: 'left', value: 'is_add_on'},
				{text: 'Loyalty', align: 'left', value: 'loyalty'},
				{
					text: 'Failed Payments',
					align: 'left',
					value: 'num_failed_payments'
				},
				{
					text: 'Failed Meal Boxes',
					align: 'left',
					value: 'num_failed_meal_boxes'
				},
				{text: 'Failed Add-ons', align: 'left', value: 'num_failed_add_ons'}
			],
			filters: {
				week: [],
				box_type: [],
				is_add_on: ['\u2718']
			},
			COUNTRYCODE: COUNTRYCODE

		};
	},
	computed: {
		templateNames: function () {
			let templates = [];
			for (let key in this.textTemplates) {
				templates.push(key);
			}
			return templates;
		},
		areMCDErrorOptionsChosen: function () {
			return (
				!!this.errorsSelection.errorType &&
        !!this.errorsSelection.errorName &&
        !!this.errorsSelection.compensationType &&
        (!!this.errorsSelection.compensationAmount ||
          this.errorsSelection.compensationType === 'Case by case' ||
          this.errorsSelection.compensationType === 'No')
			);
		},
		areWorkerTasksDone: function () {
			this.jobs.forEach(function (job) {
				if (job.progress !== 100) {
					return false;
				}
			});
			return true;
		},
		displayUploadButton: function () {
			return (
				this.areWorkerTasksDone &&
        this.MCDLiveErrorGroup === null &&
        !this.progress &&
        this.areMCDErrorOptionsChosen
			);
		},
		displaySendTextButton: function () {
			return (
				this.areWorkerTasksDone && !this.progress && this.selected.length > 0
			);
		},
		numberOfBatches: function () {
			let numberOfTexts = this.selected.length;
			if (this.method === 'all') {
				return numberOfTexts;
			} else {
				let divideNumber = parseInt(this.method.split('_')[0]);
				return Math.ceil(numberOfTexts / divideNumber);
			}
		},
		sendToMessage: function () {
			return (
				'Text to ' +
        this.selected.length +
        ' customer' +
        (this.selected.length > 1 ? 's' : '')
			);
		},
		totalBoxValue: function () {
			let reducer = (accumulator, customer) => accumulator + customer.box_value;
			return Math.round(this.filteredCustomers.reduce(reducer, 0) * 100) / 100;
		},
		totalCompensation: function () {
			if (
				(this.errorsSelection.compensationType === 'Credit' ||
          this.errorsSelection.compensationType === 'Refund') &&
        this.errorsSelection.compensationAmount !== undefined &&
        this.errorsSelection.compensationAmount !== ''
			) {
				return (
					Math.round(
						this.totalBoxValue *
            parseFloat(this.errorsSelection.compensationAmount.slice(0, -1))
					) / 100
				);
			}
			return 0;
		},
		filteredCustomers() {
			return this.customers.filter((customer) => {
				return Object.keys(this.filters).every((filter) => {
					return (
						this.filters[filter].length < 1 ||
            this.filters[filter].includes(customer[filter])
					);
				});
			});
		},
		filteredSelected() {
			return this.selected.filter((customer) =>
				this.filteredCustomers.includes(customer)
			);
		},
		tableHeight() {
			let rows = this.filteredCustomers.length;
			return rows < (window.innerHeight * 0.7 - 150) / 50
				? (150 + rows * 50).toString() + 'px'
				: window.innerHeight * 0.7;
		}
	},
	methods: {
		searchCustomersLookup: function () {
			this.progress = true;
			this.jobs = [];
			this.clearErrorOptions();
			axios
				.post(`${CONSTANTS.BASE_URL}/api/v1` + '/lookup', {
					country: this.preferences.country,
					ids: this.query,
					id_type: this.selectedType,
					payment_columns_switch: this.paymentColumnsSwitch,
					all_boxes_switch: this.allBoxesSwitch
				})
				.then((response) => {
					if (response.data.msg === 'success') {
						this.progress = false;
						this.previsionError = false;
						this.boxTypeError = false;
						this.boxTypeErrorPaymentColumns = false;
						this.customers = response.data.customers;
						this.selected = response.data.customers;
						this.boxesCausingErrors = response.data.boxes_causing_errors;
						this.searchedBoxesCausingErrors =
              response.data.searched_boxes_causing_errors;

						this.jobs = [];
						if (this.boxesCausingErrors.length > 0) {
							if (this.searchedBoxesCausingErrors.length > 0) {
								this.boxTypeError = true;
								notifications.addNotification(
									`[Database Error] One or more boxes are not displayed`,
									true
								);
							} else {
								this.boxTypeErrorPaymentColumns = true;
								notifications.addNotification(
									`[Database Error] Failed payment columns may be inaccurate.`,
									true
								);
							}
						}
					}
					if (response.data.msg === 'prevision error') {
						this.progress = false;
						this.previsionError = true;
						this.boxTypeError = false;
						this.boxTypeErrorPaymentColumns = false;
						this.jobs = [];
						if (response.data.boxes_causing_errors.length > 0) {
							notifications.addNotification(
								`Box ${response.data.boxes_causing_errors} is marked as prevision. Remove this box to return results.`,
								true
							);
						}
					}
				})
				.catch(() => {
					this.customers = [];
					this.selected = [];
					this.progress = false;
					this.previsionError = false;
					this.boxTypeError = false;
					this.boxTypeErrorPaymentColumns = false;
					if (this.selectedType == 'box') {
						notifications.addNotification(
							`No customers found for these ${this.selectedType}es.`,
							true
						);
					} else {
						notifications.addNotification(
							`No customers found for these ${this.selectedType}s.`,
							true
						);
					}
				});
		},
		downloadCustomersLookup: function () {
			this.loadingExport = true;
			axios({
				url: `${CONSTANTS.BASE_URL}/api/v1` + '/lookup/download',
				method: 'POST',
				responseType: 'blob',
				data: {
					country: this.preferences.country,
					week: this.selectedWeek,
					ids: this.filteredCustomers.map((x) => x.box_id),
					payment_columns_switch: this.paymentColumnsSwitch
				}
			}).then((response) => {
				const url = window.URL.createObjectURL(new Blob([response.data]));
				const link = document.createElement('a');
				link.href = url;
				link.setAttribute(
					'download',
					'Lookup - Customers - ' +
          this.preferences.country.toUpperCase() +
          '.csv'
				);
				document.body.appendChild(link);
				link.click();
				this.loadingExport = false;
			});
		},
		clearErrorOptions: function () {
			this.MCDLiveErrorGroup = null;
			this.errorsSelection.errorType = '';
			this.errorsSelection.errorName = '';
			this.errorsSelection.compensationType = '';
			this.errorsSelection.compensationAmount = '';
			this.errorsSelection.errorScript = '';
		},
		sendTextMessageToCustomers: function (template) {
			this.sendText = false;
			this.progress = true;
			axios
				.post(`${CONSTANTS.BASE_URL}/api/v1` + '/lookup/send', {
					country: this.preferences.country,
					ids: this.filteredSelected.map((x) => x.box_id),
					mcd_live_errors_group: this.MCDLiveErrorGroup,
					method: this.method,
					schedule: this.schedule,
					template: template
				})
				.then((response) => {
					if (response.data.msg === 'success') {
						this.progress = false;
						this.clearErrorOptions();
						this.jobs.push({id: response.data.job_id});
						this.updateJobs();
						this.selectedStep = 4;
						notifications.addNotification(
							'Job to send text messages launched, look above.'
						);
					}
				})
				.catch(() => {
					this.progress = false;
					notifications.addNotification(
						'Oops, something goes wrong when sending a message.',
						true
					);
				});
		},
		addMCDLiveErrors: function () {
			this.progress = true;
			axios
				.post(`${CONSTANTS.BASE_URL}/api/v1` + '/errors/live/mcd', {
					country: this.preferences.country,
					box_ids: this.filteredCustomers.map((x) => x.box_id),
					error_type: this.errorsSelection.errorType,
					error_name: this.errorsSelection.errorName,
					script: this.errorsSelection.errorScript,
					compensation_type: this.errorsSelection.compensationType,
					compensation_amount: this.errorsSelection.compensationAmount
				})
				.then((response) => {
					if (response.data.msg === 'success') {
						this.progress = false;
						this.MCDLiveErrorGroup = response.data.mcd_live_errors_group;
						this.jobs.push({id: response.data.job_id});
						this.updateMCDJobId = response.data.job_id;
						this.updateJobs();
						this.selectedStep = 3;
						notifications.addNotification(
							'Job to create MCD updates launched, look above.'
						);
					}
				});
		},
		updateJobs() {
			this.jobs = this.jobs.reduce((jobs, job) => {
				if (job.interval === undefined) {
					this.progress = true;
					job.interval = setInterval(() => {
						axios
							.get(`${CONSTANTS.BASE_URL}/api/v1` + '/jobs/' + job.id)
							.then((response) => {
								let progress = response.data.meta.progress;
								if (progress > 0) {
									job.progress = progress;
									job.title = response.data.meta.title;
									job.errors = response.data.meta.errors;
								} else {
									job.progress = 0;
									job.title = 'Waiting for job';
								}

								job.is_failed = response.data.failed;
								if (job.is_failed) {
									job.color = 'error';
									job.title = response.data.error;
									clearInterval(job.interval);
								} else if (job.errors && job.errors.length > 0) {
									job.color = 'warning';
								} else {
									job.color = 'primary';
								}

								if (job.progress >= 100) {
									this.progress = false;
									clearInterval(job.interval);
									job.title = 'Finished';
								}

								this.updateJobs();
							});
					}, 2000);
					this.$set(job, 'progress', -1);
					this.$set(job, 'title', 'Fetching job');
				}
				jobs.push(job);

				return jobs;
			}, []);
		},
		closePopInMessage() {
			this.sendText = false;
		}
		// ,
		// toggleAll () {
		//     if (this.selected.length) this.selected = []
		//     else this.selected = this.customers.slice()
		// }
	}
};
</script>

<style scoped>
.job-title {
  margin: auto;
}

.chips {
  display: flex;
  justify-content: left;
}
</style>
