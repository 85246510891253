var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-form',{ref:"error_form",staticClass:"pa-5"},[(_vm.error === 'Waive')?[_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',[_c('span',{staticClass:"headline"},[_vm._v("Order No: "+_vm._s(_vm.order_nr))]),_c('v-spacer'),_c('span',{staticClass:"headline"},[_vm._v("Box Price: "+_vm._s(_vm.clicked_amount))])],1),_c('v-text-field',{staticClass:"pt-8",attrs:{"label":"Amount","prepend-icon":"attach_money","required":"","rules":[function (v) { return !!v || 'Amount is required'; },
                             function (v) { return !isNaN(v) || 'Please only enter numbers'; },
                             function (v) { return (Number(v) <= this$1.clicked_amount) || 'Cannot waive more than total box price'; }]},model:{value:(_vm.specific_error_info.amount),callback:function ($$v) {_vm.$set(_vm.specific_error_info, "amount", $$v)},expression:"specific_error_info.amount"}}),_c('v-text-field',{attrs:{"label":"Order ID","prepend-icon":"fa-fingerprint","required":"","rules":[function (v) { return !!v || 'Order ID is required'; },
                 function (v) { return !isNaN(v) || 'Please only enter numbers'; }]},model:{value:(_vm.specific_error_info.order_id),callback:function ($$v) {_vm.$set(_vm.specific_error_info, "order_id", $$v)},expression:"specific_error_info.order_id"}})],1)]:_vm._e(),(_vm.error === 'PaymentPlan')?[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":"Total Amount","prepend-icon":"fa-exclamation-triangle","required":"","hint":"Total amount unpaid, as shown on Abiliware.","rules":[function (v) { return !!v || 'Total amount is required'; },
                            function (v) { return !isNaN(v) || 'Please only enter numbers'; }]},model:{value:(_vm.specific_error_info.total_amount),callback:function ($$v) {_vm.$set(_vm.specific_error_info, "total_amount", $$v)},expression:"specific_error_info.total_amount"}}),_c('v-menu',{attrs:{"close-on-content-click":false,"max-width":"290px","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                            var on = ref.on;
                            var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Start Date","required":"","rules":[function (v) { return !!v || 'A start date is required'; }],"readonly":"","prepend-icon":"event"},model:{value:(_vm.specific_error_info.formatted_date),callback:function ($$v) {_vm.$set(_vm.specific_error_info, "formatted_date", $$v)},expression:"specific_error_info.formatted_date"}},'v-text-field',attrs,false),on))]}}],null,false,388192183),model:{value:(_vm.showMenu),callback:function ($$v) {_vm.showMenu=$$v},expression:"showMenu"}},[_c('v-date-picker',{attrs:{"no-title":""},on:{"input":function($event){_vm.showMenu = false}},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}})],1),_c('v-select',{attrs:{"items":_vm.interval_items,"label":"Frequency","prepend-icon":"fa-pencil-alt","required":"","rules":[function (v) { return !!v || 'Frequency is required'; }]},model:{value:(_vm.specific_error_info.frequency),callback:function ($$v) {_vm.$set(_vm.specific_error_info, "frequency", $$v)},expression:"specific_error_info.frequency"}}),_c('v-text-field',{attrs:{"label":"Interval Amount","prepend-icon":"attach_money","required":"","rules":[function (v) { return !!v || 'Amount is required'; },
                            function (v) { return !isNaN(v) || 'Please only enter numbers'; }]},model:{value:(_vm.specific_error_info.amount),callback:function ($$v) {_vm.$set(_vm.specific_error_info, "amount", $$v)},expression:"specific_error_info.amount"}}),_c('v-checkbox',{attrs:{"label":"Financial Difficulty?"},model:{value:(_vm.specific_error_info.financial_difficulty),callback:function ($$v) {_vm.$set(_vm.specific_error_info, "financial_difficulty", $$v)},expression:"specific_error_info.financial_difficulty"}})],1)]:_vm._e(),(_vm.error === 'OverpaidCustomer')?[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":"Amount","prepend-icon":"attach_money","required":"","rules":[function (v) { return !!v || 'Amount is required'; },
                   function (v) { return !isNaN(v) || 'Please only enter numbers'; }]},model:{value:(_vm.specific_error_info.amount),callback:function ($$v) {_vm.$set(_vm.specific_error_info, "amount", $$v)},expression:"specific_error_info.amount"}}),_c('v-text-field',{attrs:{"label":"Bank Account Name","prepend-icon":"fa-user-alt","required":"","rules":[function (v) { return !!v || 'Bank account name is required'; }]},model:{value:(_vm.specific_error_info.bank_account_name),callback:function ($$v) {_vm.$set(_vm.specific_error_info, "bank_account_name", $$v)},expression:"specific_error_info.bank_account_name"}}),_c('v-text-field',{attrs:{"label":"Bank Name","prepend-icon":"fa-building","required":"","rules":[function (v) { return !!v || 'Bank name is required'; }]},model:{value:(_vm.specific_error_info.bank_name),callback:function ($$v) {_vm.$set(_vm.specific_error_info, "bank_name", $$v)},expression:"specific_error_info.bank_name"}}),_c('v-text-field',{attrs:{"label":"BSB","prepend-icon":"fa-info","rules":[function(v) {
                    if (_vm.specific_error_info.country == 'au' || _vm.specific_error_info.country == 'ao'){
                        return v.length == 6 || 'A 6-digit BSB is required'
                        }
                    else {return true}
                      }]},model:{value:(_vm.specific_error_info.bsb),callback:function ($$v) {_vm.$set(_vm.specific_error_info, "bsb", $$v)},expression:"specific_error_info.bsb"}}),_c('v-text-field',{attrs:{"label":"Account Number","prepend-icon":"fa-id-badge","required":"","rules":[function (v) { return !!v || 'Account number is required'; },
                   function (v) { return !isNaN(v) || 'Please only enter numbers'; }]},model:{value:(_vm.specific_error_info.bank_account_nr),callback:function ($$v) {_vm.$set(_vm.specific_error_info, "bank_account_nr", $$v)},expression:"specific_error_info.bank_account_nr"}})],1)]:_vm._e(),(_vm.error === 'ProofOfPayment')?[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":"Abiliware Ref","hint":"Click on question mark to see example","prepend-icon":"fa-question","required":"","rules":[function (v) { return !!v || 'Abiliware Ref is required'; },
                   function (v) { return !isNaN(v) || 'Please only enter numbers'; }]},on:{"click:prepend":_vm.toggleExample},model:{value:(_vm.specific_error_info.abiliware_ref),callback:function ($$v) {_vm.$set(_vm.specific_error_info, "abiliware_ref", $$v)},expression:"specific_error_info.abiliware_ref"}}),_c('v-menu',{attrs:{"close-on-content-click":false,"max-width":"290px","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                   var on = ref.on;
                   var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Date","required":"","rules":[function (v) { return !!v || 'A start date is required'; }],"readonly":"","prepend-icon":"fa-question"},on:{"click:prepend":_vm.toggleExample},model:{value:(_vm.specific_error_info.formatted_date),callback:function ($$v) {_vm.$set(_vm.specific_error_info, "formatted_date", $$v)},expression:"specific_error_info.formatted_date"}},'v-text-field',attrs,false),on))]}}],null,false,1941213410),model:{value:(_vm.showMenu),callback:function ($$v) {_vm.showMenu=$$v},expression:"showMenu"}},[_c('v-date-picker',{attrs:{"no-title":""},on:{"input":function($event){_vm.showMenu = false}},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}})],1),_c('v-text-field',{attrs:{"label":"Amount","prepend-icon":"attach_money","required":"","rules":[function (v) { return !!v || 'Amount is required'; },
                   function (v) { return !isNaN(v) || 'Please only enter numbers'; }]},model:{value:(_vm.specific_error_info.amount),callback:function ($$v) {_vm.$set(_vm.specific_error_info, "amount", $$v)},expression:"specific_error_info.amount"}}),_c('v-text-field',{attrs:{"label":"Description","prepend-icon":"fa-question","required":"","rules":[function (v) { return !!v || 'Description is required'; }]},on:{"click:prepend":_vm.toggleExample},model:{value:(_vm.specific_error_info.description),callback:function ($$v) {_vm.$set(_vm.specific_error_info, "description", $$v)},expression:"specific_error_info.description"}})],1),_c('v-dialog',{attrs:{"width":"20%"},model:{value:(_vm.showExample),callback:function ($$v) {_vm.showExample=$$v},expression:"showExample"}},[_c('img',{attrs:{"src":require("../.././assets/proof-of-payment.png")}})])]:_vm._e(),_c('v-row',{staticClass:"mt-6"},[_c('v-btn',{staticClass:"justify-start",attrs:{"text":""},on:{"click":_vm.toggleErrorForm}},[_vm._v(" Close ")]),_c('v-spacer'),_c('v-btn',{staticClass:"px-7 justify-end",attrs:{"color":"primary"},on:{"click":_vm.submitErrorForm}},[_vm._v(" Submit ")])],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }