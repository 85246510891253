var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"settings"},[_c('v-container',[_c('v-row',{attrs:{"align-center":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('h1',{staticClass:"display-3"},[_vm._v("Settings")])]),_c('v-col',{attrs:{"cols":"12"}},[_c('v-tabs',{attrs:{"background-color":"primary","slider-color":"accent","centered":""}},[_vm._l((_vm.tabs),function(tab){return _c('v-tab',{key:tab,attrs:{"ripple":""}},[_vm._v(" "+_vm._s(tab)+" ")])}),_c('v-tab-item',[_c('v-card',{attrs:{"flat":""}},[_c('v-toolbar',{attrs:{"text":"","color":"inherit"}},[_c('v-toolbar-title',{staticClass:"pt-4"},[_vm._v("Users")]),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"search","label":"User reference","single-line":"","hide-details":"","loading":_vm.progress},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-divider',{staticClass:"mx-6",attrs:{"inset":"","vertical":""}}),_c('v-dialog',{attrs:{"max-width":"500px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mb-2",attrs:{"color":"primary","dark":""}},on),[_vm._v("New user")])]}}]),model:{value:(_vm.add_user.active),callback:function ($$v) {_vm.$set(_vm.add_user, "active", $$v)},expression:"add_user.active"}},[_c('v-card',[_c('v-card-title',[_c('v-subheader',{staticClass:"headline"},[_vm._v("New user")])],1),_c('v-card-text',[_c('v-container',{attrs:{"grid-list-md":""}},[_c('v-row',{attrs:{"wrap":""}},[_c('v-col',{attrs:{"xs":"12"}},[_c('v-text-field',{attrs:{"label":"Email"},model:{value:(_vm.add_user.email),callback:function ($$v) {_vm.$set(_vm.add_user, "email", $$v)},expression:"add_user.email"}})],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"error","text":""},nativeOn:{"click":function($event){_vm.add_user.active = false}}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"color":"primary","text":""},nativeOn:{"click":function($event){return _vm.addUser($event)}}},[_vm._v("Save")])],1)],1)],1)],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.user_headers,"items":_vm.users,"search":_vm.search,"items-per-page-options":_vm.row_items,"fixed-header":"","height":"600px","no-data-text":'No data found'},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('tr',_vm._l((Object.keys(item).filter(
                        function (v) { return v !== 'id'; }
                      )),function(role){return _c('td',{staticClass:"text--right"},[(
                          role === 'first_name' ||
                          role === 'last_name' ||
                          role === 'email'
                        )?_c('div',[_vm._v(" "+_vm._s(item[role])+" ")]):_c('v-checkbox',{attrs:{"color":"primary","primary":"","hide-details":""},nativeOn:{"click":function($event){return _vm.updateUser(item.id)}},model:{value:(item[role]),callback:function ($$v) {_vm.$set(item, role, $$v)},expression:"item[role]"}})],1)}),0)]}}])})],1)],1),_c('v-tab-item',[_c('text-templates')],1),_c('v-tab-item',[_c('voucher-settings')],1)],2)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }