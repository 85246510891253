<template>
  <v-card class="mx-auto">
    <v-card-title class="headline"
      >Be Cool Delivery Details | Package
      {{ selectedDeliveryDetails.package_id }}</v-card-title
    >
    <v-card-text>
      <v-row>
        <v-col cols="3">
          <v-list-item>
            <v-list-item-icon>
              <v-icon color="primary">account_circle</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Name</v-list-item-title>
              <p>{{ selectedDeliveryDetails.name }}</p>
            </v-list-item-content>
          </v-list-item>
        </v-col>
        <v-col cols="3">
          <v-list-item>
            <v-list-item-icon>
              <v-icon color="primary">location_on</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Address</v-list-item-title>
              <p>{{ selectedDeliveryDetails.street1 }}</p>
            </v-list-item-content>
          </v-list-item>
        </v-col>
        <v-col cols="3">
          <v-list-item>
            <v-list-item-icon>
              <v-icon color="primary">location_on</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Suburb</v-list-item-title>
              <p>{{ selectedDeliveryDetails.suburb }}</p>
            </v-list-item-content>
          </v-list-item>
        </v-col>
        <v-col cols="3">
          <v-list-item>
            <v-list-item-icon>
              <v-icon color="primary">location_on</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Postcode</v-list-item-title>
              <p>{{ selectedDeliveryDetails.postcode }}</p>
            </v-list-item-content>
          </v-list-item>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="3">
          <v-list-item>
            <v-list-item-icon>
              <v-icon color="primary">help_outline</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Status</v-list-item-title>
              <p>{{ selectedDeliveryDetails.status }}</p>
            </v-list-item-content>
          </v-list-item>
        </v-col>
        <v-col cols="3">
          <v-list-item>
            <v-list-item-icon>
              <v-icon color="primary">article</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Reason Code</v-list-item-title>
              <p>{{ selectedDeliveryDetails.reason_code || "N/A" }}</p>
            </v-list-item-content>
          </v-list-item>
        </v-col>
        <v-col cols="3">
          <v-list-item>
            <v-list-item-icon>
              <v-icon color="primary">track_changes</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Tracking Number</v-list-item-title>
              <p>{{ selectedDeliveryDetails.tracking }}</p>
            </v-list-item-content>
          </v-list-item>
        </v-col>
        <v-col cols="3">
          <v-list-item>
            <v-list-item-icon>
              <v-icon color="primary">receipt</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Invoice Number</v-list-item-title>
              <p>{{ selectedDeliveryDetails.invoice || "N/A" }}</p>
            </v-list-item-content>
          </v-list-item>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="3">
          <v-list-item>
            <v-list-item-icon>
              <v-icon color="primary">today</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Scheduled Date</v-list-item-title>
              <p>{{ selectedDeliveryDetails.date }}</p>
            </v-list-item-content>
          </v-list-item>
        </v-col>
        <v-col cols="3">
          <v-list-item>
            <v-list-item-icon>
              <v-icon color="primary">schedule</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Estimated Time</v-list-item-title>
              <p>
                {{ selectedDeliveryDetails.estimated_time || "N/A" }}
              </p>
            </v-list-item-content>
          </v-list-item>
        </v-col>
        <v-col cols="3">
          <v-list-item>
            <v-list-item-icon>
              <v-icon color="primary">local_shipping</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Delivery Instructions</v-list-item-title>
              <p>
                {{ selectedDeliveryDetails.instructions || "N/A" }}
              </p>
            </v-list-item-content>
          </v-list-item>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
	name: 'DeliveryDetails',
	props: ['selectedDeliveryDetails']
};
</script>

<style scoped></style>
