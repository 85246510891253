<template>
  <div>
    <v-tabs grow>
      <v-tab>
        Outstanding Payment Errors
      </v-tab>
      <v-tab @click="this.getHistory">
        History
      </v-tab>
      <v-tab-item class="pa-6">
        <v-data-table
          class="pb-6"
          :headers="headersOutstanding"
          :items="paymentErrors"
          :hide-default-footer="true">
          <template v-slot:item.controls="props">
            <v-btn class="mx-2" depressed small @click="toggleWaive(props.item)">
              Waive
            </v-btn>
          </template>
        </v-data-table>
        <div class="pb-4" align="center">
          <span class="total pb-12">Total: ${{ totalUnpaidAmount }}</span>
        </div>
        <v-row v-if="showAllOptions">
          <v-col align="center" v-for="errorType in paymentErrorTypes">
            <v-spacer/>
                <v-btn
                  color="primary"
                  target="_blank"
                  @click="toggleForm(errorType)"
                >{{ formatErrorType(errorType) }}</v-btn>
            <v-spacer/>
          </v-col>
        </v-row>
      </v-tab-item>
      <v-tab-item>
        <v-data-table
          class="pb-12"
          :headers="headersHistory"
          :items="history"
          :items-per-page="-1"
          hide-default-footer
          :loading="loading"
          loading-text="Getting data... Please wait"
          sort-by="order_nr"
        >
          <template v-slot:item.controls="props" v-if="showSubmissionDetailsButton">
            <v-btn class="mx-2" depressed small
                   @click="toggleSubmissionDetails(props.item)">
              Details
            </v-btn>
          </template>
        </v-data-table>
      </v-tab-item>
      <v-dialog v-model="showForm" width="50%" persistent >
        <v-card>
          <v-card-title class="secondary">
            <span class="headline">Customer ID: {{customer_id}}</span>
          </v-card-title>
          <v-card-text>
            <payment-error-processing
              :customer_id="customer_id"
              :order_nr="clicked_order_nr"
              :delivery_date="clicked_delivery_date"
              :error="error_type"
              :clicked_amount="clicked_amount"
              v-on:toggle-error-form="toggleForm()"
            />
          </v-card-text>
        </v-card>
      </v-dialog>
      <v-dialog v-model="showSubmissionDetails" width="50%">
        <v-card class="pa-12">
          <v-list-item v-for="(value, key) in clicked_history_details">
            <v-list-item-content>
              <v-list-item-title>{{key}}</v-list-item-title>
              <v-list-item-subtitle>{{value}}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-card>
      </v-dialog>
      <v-dialog v-model="showWarning" width="25%" persistent>
        <v-card class="pa-10 text-center">
          <h2 class="red--text text-center pb-2">Reminder</h2>
          Check Abiliware for the amount outstanding and if the customer has been
          sent to debt collection. Payment plans cannot be submitted for customers that are with collections.</v-card>
        <v-btn depressed @click="showWarning = false"><v-icon>close</v-icon></v-btn>
      </v-dialog>
    </v-tabs>
  </div>
</template>

<script>
import PaymentErrorProcessing from './PaymentErrorForm';
import notifications from '@/modules/notifications';
import util from '@/modules/util'

import axios from 'axios';
import {CONSTANTS} from '@/modules/constants';
export default {
	name: 'PaymentErrorSummary',
	components: {
		PaymentErrorProcessing
	},
	props: ['paymentErrors', 'totalUnpaidAmount', 'customer_id'],
	data() {
		return {
			country: this.$store.state.country,
			headersOutstanding: [
				{text: 'Order Number',value: 'order_nr'},
				{text: 'Delivery Date',value: 'delivery_date'},
				{text: 'Box Type',value: 'box_type'},
				{text: 'Price',value: 'grand_total'},
				{text: '', value: 'controls', sortable: false}
			],
			headersHistory: [
				{text: 'Sent to Payments', value: 'Error Type', sortable: true},
				{text: 'Order Number (if applicable)', value: 'Order Number', sortable: true},
				{text: 'Submission Date', value: 'Date', sortable: true},
				{text: 'Amount', value: 'Amount', sortable: true},
				{text: 'Date Actioned', value: 'Date Actioned', sortable: true},
				{text: 'Comments', value: 'Comments', sortable: true},
				{text: '', value: 'controls', sortable: false}
			],
			showPaymentPlan: false,
			showWarning: false,
			showForm: false,
			showSubmissionDetails: false,
			showSubmissionDetailsButton: true,
			error_type: '',
			clicked_order_nr: '',
			clicked_delivery_date: '',
			clicked_amount:'',
			history: [],
			clicked_history_details: [],
			loading: true,
			paymentErrorTypes: ['PaymentPlan', 'OverpaidCustomer', 'ProofOfPayment']
		};
	},
	computed: {
		showAllOptions() {
			return (this.country === 'au' || this.country ==='nz')
		}
	},
	methods: {
		formatErrorType(string) {
			return util.camelToTitleCase(string);
		},
		togglePaymentPlan() {
			this.showPaymentPlan = !this.showPaymentPlan;
		},
		toggleWaive(value) {
			this.clicked_order_nr = value.order_nr;
			this.clicked_amount = value.grand_total;
			this.toggleForm('Waive');
		},
		toggleForm(error) {
			this.error_type = error;
			this.showForm = !this.showForm;
			if (this.error_type === 'PaymentPlans') {
				setTimeout(() => this.showWarning = true, 1000);
			}
		},
		toggleSubmissionDetails(t){
			this.clicked_history_details = t;
			this.showSubmissionDetails = !this.showSubmissionDetails;
		},
		getHistory() {
			this.loading = true;
			axios
				.get(`${CONSTANTS.BASE_URL}/api/v1` + '/payment/errors/history', {
					params: {
						customer_id: this.customer_id
					}
				}).then((response) => {
					if (response.data.msg === 'success') {
						this.history = []
						for (let submission of response.data.history) {
							if (submission.error_type === 'PaymentPlans') {
								this.history.push({
									'Error Type': submission.error_type,
									'Order Number': submission.order,
									'Amount': submission.amount,
									'Date': submission.date.substr(0,10),
									'Date Actioned': submission.date_actioned,
									'Comments': submission.comments,
									'Start Date': submission.start_date,
									'Frequency': submission.freq,
									'Total Amount': submission.total_amount,
									'Number of Payments' : Math.ceil(submission.total_amount/submission.amount),
									'Financial Difficulty?': submission.fin_dif
								})
							}
							else if (submission.error_type === 'OverpaidCustomers') {
								this.history.push({
									'Error Type': submission.error_type,
									'Order Number': submission.order,
									'Amount': submission.amount,
									'Date': submission.date.substr(0,10),
									'Date Actioned': submission.date_actioned,
									'Comments': submission.comments,
									'Bank Name': submission.bank_name,
									'Bank Account Holder Name': submission.bank_acc,
									'Bank Number': submission.bank_no,
									'BSB' : submission.bsb

								})
							}
							else {
								this.history.push({
									'Error Type': submission.error_type,
									'Order Number': submission.order_nr,
									'Amount': submission.amount,
									'Date': submission.date.substr(0,10),
									'Date Actioned': submission.date_actioned,
									'Comments': submission.comments
								});
							}
						}
					}
				}).catch(e => {
					notifications.addNotification('Error retrieving history. Try again', true);
				}).finally(() => this.loading = false)
		}
	}
}
</script>

<style scoped>
  h1 {
    background-color: white;
    text-align: center;
    font-weight: 400;
  }

  .total {
    width: 100%;
    display: inline;
    font-weight: bold;
    font-size: 1.2rem;
    padding: 10px;
  }
  .pa-2 {
    text-align: right;
  }
  .button {
    width: 200px;
  }
</style>

