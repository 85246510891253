<template>
  <div>
    <v-card>
      <v-row>
        <v-col cols="12" class="py-0">
          <v-card class="mb-0" color="primary" tile>
            <v-row>
              <v-col cols="6">
                <v-card-title class="white--text"
                  >Package ID: {{ pkg.id }}</v-card-title
                >
                <v-card-subtitle class="white--text"
                  >Box ID: {{ pkg.main_box_id }}</v-card-subtitle
                >
              </v-col>
              <v-spacer></v-spacer>
              <v-col cols="4" class="text-right mr-2">
                <v-row>
                  <v-col cols="12">
                    <v-btn
                      color="secondary"
                      :href="pkg.tracking_link"
                      target="_blank"
                    >
                      Tracking Link
                    </v-btn>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <v-btn color="secondary" @click="changedIsDetailed">
                      Show Recipes
                      <v-icon v-if="isDetailed">mdi-minus-circle</v-icon>
                      <v-icon v-else="isDetailed">mdi-plus-circle</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
      <div v-if="isDetailed">
        <package-box-recipes
          :recipe_info="pkg.recipe_info"
          :week="week"
          class="mx-5 my-5"
        >
        </package-box-recipes>
        <v-row class="align-center">
          <v-col cols="4" class="text-center">
            <span>Retail Value: ${{ totalPayloadValue }}</span>
          </v-col>

          <v-col cols="4" class="text-center">
            <span class="text-center"> Delivery Fee: ${{ pkg.dfs }}</span>
          </v-col>
          <v-col cols="4" class="text-center">
            <span>Total Package Price: ${{ totalPackagePrice }}</span>
          </v-col>
        </v-row>
      </div>
      <div v-else class="px-0">
        <v-row class="mx-0 text-justify--center">
          <v-col cols="12">
            <v-data-table
              :headers="headers"
              hide-default-footer
              :no-data-text="'No data found'"
            >
              <template v-slot:body>
                <tr>
                  <td>{{ formattedRecipeIds }}</td>
                  <td>{{ pkg.order_numbers }}</td>
                  <td>{{ pkg.delivery_date }}</td>
                  <td>{{ pkg.cutoff_day }}</td>
                  <td>${{ totalPayloadValue }}</td>
                </tr>
              </template>
            </v-data-table>
          </v-col>

          <v-col cols="12">
            <v-row class="align-bottom">
              <v-col cols="12" class="text-right">
                <span>Retail Value: ${{ totalPayloadValue }}</span>
              </v-col>

              <v-col cols="12" class="text-right">
                <span class="text-right">Delivery Fee: ${{ pkg.dfs }}</span>
              </v-col>
              <v-col cols="12" class="text-right">
                <h3 class="text-right">
                  Total Package Price: ${{ totalPackagePrice }}
                </h3>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </div>
    </v-card>
  </div>
</template>

<script>
import auth from '../../modules/auth';
import PackageBoxRecipes from './PackageBoxRecipes';

export default {
	name: 'PackageItem',
	components: { PackageBoxRecipes },
	data() {
		return {
			preferences: auth.user.preferences,
			userProfile: auth.user.profile,
			headers: [
				{ text: 'Recipes', value: 'recipes' },
				{ text: 'Order Number', value: 'order_number' },
				{ text: 'Delivery Date', value: 'delivery_date' },
				{ text: 'Cutoff', value: 'cutoff_day' },
				{ text: 'Total Value', value: 'total_value' }
			],
			progress: false,
			isDetailed: false,
			package_data: {
				boxesInfo: []
			},
			total_price: 0.0,
			selectedBox: {}
		};
	},
	props: {
		pkg: { type: Object },
		package_id: { type: String },
		week: { type: String }
	},
	computed: {
		totalPayloadValue: function () {
			const { retail_prices: retailPrices } = this.pkg;
			return retailPrices
				.replace('|', '_')
				.split('_')
				.reduce((acc, cur) => acc + parseFloat(cur), 0)
				.toFixed(2);
		},
		totalPackagePrice: function () {
			const { retail_prices: retailPrices, surcharges, dfs } = this.pkg;
			const priceString = `${retailPrices}_${surcharges}_${dfs}`;
			return priceString
				.replace('|', '_')
				.split('_')
				.reduce((acc, cur) => acc + parseFloat(cur), 0)
				.toFixed(2);
		},
		formattedRecipeIds: function () {
			const { recipes_ids: recipesIds } = this.pkg;
			return recipesIds.replace(/_/g, ' ').replace(/\|/g, ' ');
		}
	},
	updated() {
		this.$emit('appendTotalPrice', {
			package_price: this.total_price,
			package_id: this.package_id
		});
	},
	methods: {
		changedIsDetailed() {
			this.isDetailed = !this.isDetailed;
		}
	}
};
</script>

<style scoped></style>
