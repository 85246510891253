<template>
  <v-container>
    <v-row class="justify-lg-space-aroundus">
      <v-col cols="4">
        <h1 class="display-1">Text Templates</h1>
      </v-col>
      <v-col cols="4">
        <v-text-field
          v-model="search"
          append-icon="search"
          label="Search"
          single-line
          hide-details
          clearable
        />
      </v-col>
      <v-col cols="4" class="text-end">
        <v-btn color="primary" @click="toggleAddDialog()">Add Template</v-btn>
      </v-col>


    </v-row>
    <v-row>
      <v-col
        v-for="template in filterSearch(textTemplates)"
        :key="template.id"
        :cols="4"
      >
        <v-card>
          <v-card-text>
            <h3>{{ template.name }}</h3>
            <p>
              {{ template.body | limitWords }}
            </p>
          </v-card-text>
          <v-card-actions>
            <v-btn
              text
              color="secondary"
              @click="selectTemplate(template)"
            >
              Edit
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>

      <v-dialog
        v-model="editDialog"
        width="500"
      >
        <edit-text-template
          :template="selectedTemplate"
          :dialog="editDialog"
          :toggleDialog="toggleEditDialog"
          :getTextTemplates="getTextTemplates"
        ></edit-text-template>
      </v-dialog>

      <v-dialog
        v-model="addDialog"
        width="500"
      >
        <add-text-template
          :dialog="addDialog"
          :toggleDialog="toggleAddDialog"
          :getTextTemplates="getTextTemplates"
        ></add-text-template>
      </v-dialog>
    </v-row>
  </v-container>

</template>

<script>
import axios from 'axios';
import notifications from '@/modules/notifications';
import auth from '../../modules/auth';
import EditTextTemplate from './EditTextTemplate';
import AddTextTemplate from './AddTextTemplate';
import {CONSTANTS} from '@/modules/constants';

export default {
	name: 'TextTemplates',
	components: {
		'edit-text-template': EditTextTemplate,
		'add-text-template': AddTextTemplate
	},
	filters: {
		limitWords: function (paragraph) {
			if (paragraph.length >= 250) {
				return `${paragraph.substring(0, 200)}...`
			}

			return paragraph;
		}
	},
	data() {
		return {
			search: '',
			addDialog: false,
			editDialog: false,
			loading: false,
			textTemplates: [],
			selectedTemplate: {}
		}
	},
	created() {
		this.getTextTemplates();
	},
	methods: {
		getTextTemplates() {
			const {country} = auth.user.preferences;
			this.loading = true;
			axios
				.get(`${CONSTANTS.BASE_URL}/api/v1/customers/text-templates`, {
					params: {country}
				})
				.then(response => {
					this.textTemplates = response.data.text_templates;
					this.loading = false;
				})
				.catch(error => {
					notifications.addNotification('There was an error loading the text templates', true);
				})
		},
		selectTemplate(template) {
			this.selectedTemplate = template;
			this.toggleEditDialog();
		},
		toggleEditDialog() {
			this.editDialog = !this.editDialog;
		},
		toggleAddDialog() {
			this.addDialog = !this.addDialog;
		},
		filterSearch(textTemplates) {
			let filteredTextTemplates = [];
			if (textTemplates) {
				textTemplates.forEach(textTemplate => {
					if (textTemplate.name.toLowerCase().includes(this.search.toLowerCase()) || textTemplate.body.toLowerCase().includes(this.search.toLowerCase())) {
						filteredTextTemplates.push(textTemplate);
					}
				});
			}
			return filteredTextTemplates;
		}
	}
}
</script>

<style scoped>

</style>
