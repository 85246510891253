<template>
  <dashboard-summary
    title="Hotwires Dashboard Summary"
    :filters="dashboardFilters"
    :data-entries="dashboardData"
    :loading="loadingHotwiresSummary"
    :export-config="{loading: loadingExport, label: 'Export Boxes', disabled: !selectedNumberOfHotwires || !hotwiresSummarySelectedWeek}"
    @week-select="val => hotwiresSummarySelectedWeek = val"
    @hotwires-count-select="val => selectedNumberOfHotwires = val"
    @export-data="launchExport"
  />
</template>

<script>
import axios from 'axios'
import { isEmpty } from 'lodash'
import store from '@/store/store'
import notifications from '@/modules/notifications'
import DashboardSummary from '@/components/util/DashboardSummary';
import {CONSTANTS} from '@/modules/constants';

export default {
	name: 'HotwiresDashboardSummary',
	components: { DashboardSummary },
	data() {
		return {
			loadingExport: false,
			hotwiresSummarySelectedWeek: '',
			loadingHotwiresSummary: false,
			totalHotwires: 0,
			totalUniqueCustomersAffected: 0,
			uniqueCustomersBreakdown: null,
			selectedNumberOfHotwires: ''
		}
	},
	computed: {
		weeks() {
			return [...this.$store.state.weeks]
		},
		dashboardFilters() {
			return [
				{label: 'Select week', items: this.weeks, model: this.hotwiresSummarySelectedWeek, event: 'week-select'},
				{label: 'Select no. of hotwires', items: this.numberOfHotwires, model: this.selectedNumberOfHotwires, event: 'hotwires-count-select'}
			]
		},
		dashboardData(){
		  return [
				{label: 'Total hotwires', value: this.totalHotwires},
				{label: 'Total unique customers affected', value: this.totalUniqueCustomersAffected},
				{label: `Customers with ${this.selectedNumberOfHotwires || 'X'} hotwires(s)`, value: this.customersWithXHotwires}
			]
		},
		numberOfHotwires() {
			return this.uniqueCustomersBreakdown ? Object.keys(this.uniqueCustomersBreakdown) : []
		},
		customersWithXHotwires() {
			return !isEmpty(this.uniqueCustomersBreakdown)
				? this.uniqueCustomersBreakdown[this.selectedNumberOfHotwires]
				: 0
		}
	},
	watch: {
		hotwiresSummarySelectedWeek: {
			handler: function () {
				this.getHotwiresDashboardSummary()
			}
		},
		uniqueCustomersBreakdown: function (newVal) {
			if (newVal) {
				this.selectedNumberOfHotwires = Object.keys(newVal)[0]
			}
		}
	},
	methods: {
		getHotwiresDashboardSummary() {
			this.loadingHotwiresSummary = true

			axios
				.get(`${CONSTANTS.BASE_URL}/api/v1/hotwires/summary`, {
					params: {
						country: store.state.country,
						week: this.hotwiresSummarySelectedWeek
					}
				})
				.then((response) => {
					const {
						total_hotwires: totalHotwires,
						total_unique_customers_affected: totalUniqueCustomersAffected,
						unique_customers_breakdown: uniqueCustomersBreakdown
					} = response.data

					this.totalHotwires = totalHotwires
					this.totalUniqueCustomersAffected = totalUniqueCustomersAffected
					this.uniqueCustomersBreakdown = uniqueCustomersBreakdown
					this.loadingHotwiresSummary = false
				})
				.catch((error) => {
					notifications.addNotification('Error: Could not get hotwires summary information', true)
					this.loadingHotwiresSummary = false
				})
		},
		launchExport() {
			this.loadingExport = true
			axios({
				url: `${CONSTANTS.BASE_URL}/api/v1/hotwires/summary/export`,
				method: 'GET',
				responseType: 'blob',
				params: {
					week: this.hotwiresSummarySelectedWeek,
					country: store.state.country,
					number_of_hotwires: this.selectedNumberOfHotwires
				}
			})
				.then((response) => {
					const url = window.URL.createObjectURL(new Blob([response.data]))
					const link = document.createElement('a')
					link.href = url
					link.setAttribute(
						'download',
						`Hotwire Summary Export ${this.hotwiresSummarySelectedWeek} - ${store.state.country} - ${this.selectedNumberOfHotwires}.csv`
					)
					document.body.appendChild(link)
					link.click()
				})
				.catch((error) => {
					notifications.addNotification('Oops, there was an error exporting the data', true)
				})
				.finally(() => {
					this.loadingExport = false
				})
		}
	}
}
</script>
