<template>
  <div class="slot_allocations">
    <v-progress-linear
      indeterminate
      v-if="progress"
      color="secondary"
      class="progress"
    ></v-progress-linear>
    <v-container grid-list-sm>
      <v-row wrap>
        <v-col cols="8">
          <h1 class="display-3">Slot Allocations</h1>
        </v-col>
        <v-col cols="4" class="pt-4">
          <v-select
            :items="weeks"
            v-model="selectedWeek"
            label="Week"
            solo
            :loading="progress"
          ></v-select>
        </v-col>
        <v-col
          cols="12"
          v-for="(boxType, index) in Object.keys(mealKitsByBoxType)"
          :key="index"
          class="pb-3"
        >
          <v-card class="pb-2" raised>
            <v-card-title>
              <v-icon class="pt-1 px-2" v-if="boxType === 'family-box'"
                >fas fa-users</v-icon
              >
              <v-icon class="pt-1 px-2" v-else-if="boxType === 'veggie-box'"
                >fas fa-leaf</v-icon
              >
              <v-icon class="pt-1 px-2" v-else-if="boxType === 'fruit-box'"
                >fas fa-apple-alt</v-icon
              >
              <v-icon class="pt-1 px-2" v-else-if="boxType === 'snack-box'"
                >fas fa-cookie</v-icon
              >
              <v-icon class="pt-1 px-2" v-else>fas fa-box-open</v-icon>
              <span
                :class="[
                  preferences.country === 'au' || preferences.country === 'ao'
                    ? 'grey--text'
                    : 'white--text',
                  'pl-2 display-1 text--darken-2',
                ]"
                style="text-transform: capitalize;"
              >
                {{ boxType.replace("-", " ") }}
              </span>
            </v-card-title>
            <meal-kits :meal-kits="mealKitsByBoxType[boxType]"></meal-kits>
          </v-card>
        </v-col>
        <v-col cols="12">
          <v-alert
            :value="Object.entries(mealKitsByBoxType).length === 0"
            type="warning"
            outlined
          >
            Not data for slot allocations yet, please retry later.
          </v-alert>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import axios from 'axios';
import auth from '../modules/auth';
import MealKits from './MealKits';
import {CONSTANTS} from '@/modules/constants';

export default {
	name: 'SlotAllocations',
	components: { MealKits: MealKits },
	data() {
		return {
			preferences: auth.user.preferences,
			progress: false,
			weeks: [],
			selectedWeek: '',
			mealKitsByBoxType: {}
		};
	},
	watch: {
		selectedWeek: {
			handler: function () {
				this.getMealKits();
			}
		}
	},
	mounted() {
		this.getWeeks();
	},
	methods: {
		getWeeks() {
			this.progress = true;
			axios
				.get(`${CONSTANTS.BASE_URL}/api/v1` + '/weeks', {
					params: {
						starting: 15,
						max_amount: 5
					}
				})
				.then((response) => {
					this.weeks = response.data.weeks;
					this.selectedWeek = response.data.default_week;
					this.progress = false;
				});
		},
		getMealKits: function () {
			this.progress = true;
			axios
				.get(`${CONSTANTS.BASE_URL}/api/v1` + '/meal_kits', {
					params: {
						country: this.preferences.country,
						week: this.selectedWeek
					}
				})
				.then((response) => {
					if (response.data.msg === 'success') {
						this.mealKitsByBoxType = response.data.meal_kits_by_box_type;
					}
					this.progress = false;
				});
		}
	}
};
</script>

<style scoped></style>
