<template>
  <div>
    <v-progress-linear indeterminate v-if="progress" color="secondary" class="progress"></v-progress-linear>
      <v-container>
        <v-row>
          <v-col v-if="issue_name.length > 0 && showHeader"
                 cols="12">
            <span class="display-1">{{issue_name}}</span>
          </v-col>
          <v-col v-if="issue_description.length > 0"
                 cols="12">
            <span class="subtitle-1">{{issue_description}}</span>
          </v-col>
        </v-row>
      </v-container>
    <v-form ref="responseForm">
      <v-container>
        <v-row>
          <v-col v-for="(field, index) in form_fields"
                 :key="index"
                 v-if="field['hidden'] === false"
                 cols="12" sm="6" md="3">

            <v-checkbox v-if="field['type'] === 'Checkbox'"
                        v-model="form_input[field['name']]"
                        :label="field['name'].replace(/_/g, ' ')">
            </v-checkbox>
            <v-menu v-else-if="field['type'] === 'Date'"
                    v-model="field['date_menu']"
                    :close-on-content-click="false"
                    offset-x>
              <template v-slot:activator="{ on }">
                <v-text-field
                  v-model="form_input[field['name']]"
                  v-on="on"
                  :label="field['name'].replace(/_/g, ' ') + (field['required'] ? '*': '')"
                  :rules="rules[field['name']]"
                  @change="makeRules(field)"
                ></v-text-field></template>
              <v-date-picker
                v-model="form_input[field['name']]"
                @input="field['date_menu'] = false"
              ></v-date-picker>
            </v-menu>

            <!--                  <v-text-field v-else-if="field['type'] === 'Time'"-->
            <!--                                v-model="form_input[field['name']]"-->
            <!--                                :label="field['name'].replace(/_/g, ' ') + (field['required'] ? '*': '')"-->
            <!--                                :rules="rules['time']"></v-text-field>-->

            <v-menu v-else-if="field['type'] === 'Time'"
                    v-model="field['time_menu']"
                    :close-on-content-click="false"
                    :nudge-right="40">
              <template v-slot:activator="{ on }">
                <v-text-field
                  v-on="on"
                  v-model="form_input[field['name']]"
                  :label="field['name'].replace(/_/g, ' ') + (field['required'] ? '*': '')"
                  :rules="rules[field['name']]"
                  @change="makeRules(field)"
                ></v-text-field></template>
              <v-time-picker
                v-model="form_input[field['name']]"
                full-width
              ></v-time-picker>
            </v-menu>

            <!--                  <v-row v-else-if="field['type'] === 'Time'" cols="3">-->
            <!--                    <v-col cols="2">-->
            <!--                      <v-autocomplete-->
            <!--                        v-model="form_input[field['time_hour']]"-->
            <!--                        :label="field['name'].replace(/_/g, ' ') + ' (24 hr)' + (field['required'] ? '*': '')"-->
            <!--                        :items="hours"-->
            <!--                        cols="1"-->
            <!--                      ></v-autocomplete>-->
            <!--                    </v-col>-->
            <!--                    <v-col cols="1">-->
            <!--                      <span> : </span>-->

            <!--                    </v-col>-->
            <!--                    <v-col cols="1">-->
            <!--                    <v-autocomplete v-model="form_input[field['time_minute']]"-->
            <!--                                    label="'minute'"-->
            <!--                                  :items="minutes"-->
            <!--                                  cols="1"-->
            <!--                  ></v-autocomplete>-->
            <!--                      </v-col>-->
            <!--                  </v-row>-->

            <v-combobox v-else-if="field['dropdown'].length > 0"
                        v-model="form_input[field['name']]"
                        :items="field['dropdown']"
                        :label="field['name'].replace(/_/g, ' ') + (field['required'] ? '*': '')"
                        :rules="rules[field['name']]"
                        @change="makeRules(field)"
            ></v-combobox>
            <v-text-field v-else-if="field['type'] === 'Number'"
                          v-model="form_input[field['name']]"
                          :label="field['name'].replace(/_/g, ' ') + (field['required'] ? '*': '')"
                          :rules="rules[field['name']]"
                          @change="makeRules(field)">
            </v-text-field>

            <v-tooltip v-else-if="field['type'] === 'Customer Id'"
                       top
                       color="primary">
              <template v-slot:activator="{ on }">
                <v-text-field v-on="on"
                              v-model="form_input[field['name']]"
                              :label="field['name'].replace(/_/g, ' ') + (isNewCustomer ? ' (new)': ' (existing)') + (field['required'] ? '*': '')"
                              :rules="rules[field['name']]"
                              @change="makeRules(field)">
                  <v-checkbox slot="prepend" v-model="isNewCustomer"
                              color="secondary"
                              dense
                              class="ma-0"
                  ></v-checkbox>
                </v-text-field>
              </template>
              Tick for new customer
            </v-tooltip>


            <v-text-field v-else
                          v-model="form_input[field['name']]"
                          :label="field['name'].replace(/_/g, ' ') + (field['required'] ? '*': '')"
                          :rules="rules[field['name']]"
                          @change="makeRules(field)">
            </v-text-field>
          </v-col>
          <v-col xs="12" sm="6" md="3">
            <v-btn
              label="Submit Response"
              color="primary"
              @click="submitIssueForm">
              Submit Response
            </v-btn>
            <v-progress-circular indeterminate v-if="submitProgress" color="secondary" class="progress"></v-progress-circular>
          </v-col>
        </v-row>
      </v-container>
    </v-form>

  </div>
</template>

<script>

import axios from 'axios';
import auth from '../../modules/auth';
import notifications from '@/modules/notifications';
import {CONSTANTS} from '@/modules/constants';

export default {
	name: 'LiveDataForm',
	props: {
		issue_name: {type: String, required: true, default: ''},
		showHeader: {type: Boolean, default: false}
	},
	data() {
		return {
			preferences: auth.user.preferences,
			userProfile: auth.user.profile,
			progress: false,
			customer_id: '',
			form_input: {},
			rules_template: {
				'Customer Id': [v => !isNaN(v) || 'A customer id is required', v => v.length > +5 || 'A customer id is required'],
				'Date': [v => v.length === 10 || 'date format: yyyy-mm-dd', v => v[4] === '-' || 'date format yyyy-mm-dd', v => v[7] === '-' || 'date format yyyy-mm-dd'],
				'Time': [v => v.length === 5 || '24 hr: hh:mm', v => v[2] === ':' || '24 hr: hh:mm', v => !isNaN(v.substring(0, 2)) && v.substring(0, 2) < 24 || '24 hr: hh:mm',
					v => !isNaN(v.substring(3, 5)) && v.substring(3, 5) < 60 || '24 hr: hh:mm'],
				'Number': [v => !isNaN(v) || 'Enter a number', v => v.length > 0 || 'Enter a number'],
				'Text': [v => v.length > 0 || 'A response is required']
			},
			rules: {},
			issue_description: '',
			form_fields: [],
			// issue_name: this.issue_name,
			isNewCustomer: false,
			submitProgress: false
		}
	},
	watch: {
		issue_name: {
			immediate: true,
			handler() {
				this.getFormFields()
			}
		},
		issue_update: function () {
			this.getFormFields()
			this.$emit('formFieldsRefreshed')
		}
	},
	methods: {
		buildFormInput() {
			for (let field of this.form_fields) {
				if (field['type'] === 'Checkbox') {
					this.form_input[field['name']] = false
				} else {
					this.form_input[field['name']] = ''
				}
			}
		},
		makeRules(field) {
			if (field['required'] || this.form_input[field['name']].length > 0) {
				this.rules[field['name']] = this.rules_template[field['type']]
			} else {
				this.rules[field['name']] = []
			}
		},
		submitIssueForm() {

			if (this.submitProgress === true) {
				notifications.addNotification(`Your submission is in progress`, true)
				return null
			}
			else if (this.submitProgress === false) {
				if (this.$refs.responseForm.validate()) {
					this.submitProgress = true
					this.progress = true
					axios.post(`${CONSTANTS.BASE_URL}/api/v1` + '/issues/form_response',
						{
							country: this.preferences.country,
							agent_id: this.userProfile.id,
							issue_name: this.issue_name,
							customer_bob_id: this.customer_id,
							form_input: this.form_input,
							is_new_customer: this.isNewCustomer || false
						})
						.then(response => {
							if (response.data.msg === 'success') {
								notifications.addNotification(`Form response submitted successfully`, false)
								this.$emit('formSubmitted')
								this.$refs.responseForm.reset()
							} else if (response.data.msg.slice(0, 7) === '[Error]') {
								notifications.addNotification(response.data.msg, true)
							}
						})
						.catch(() => {
							notifications.addNotification('Oops. There was a problem submitting your response. Try refreshing your page', true);
						})
						.finally(() => {
							this.submitProgress = false
							this.progress = false
						}

						)
				}
			}
		},
		getFormFields: function () {
			this.progress = true;
			axios.get(`${CONSTANTS.BASE_URL}/api/v1` + '/issues/form_fields', {params: {
				country: this.preferences.country,
				issue_name: this.issue_name}})
				.then((response) => {
					if (response.data.msg === 'success') {
						this.form_fields = response.data.form_fields
						this.issue_description = response.data.issue_description
						this.buildFormInput()
						for (let field of this.form_fields) {
							this.makeRules(field)
						}
						this.progress = false;
					} else if (response.data.msg.slice(0, 7) === '[Error]') {
						notifications.addNotification(response.data.msg, true)
					}
				}).catch(error => {
					notifications.addNotification('Oops. Getting the details of this form. Try refreshing your page', true)
				})
		}
	}
}
</script>
