<template>
  <div class="header">
    <div v-if="hellofreshAuthorised">
      <v-navigation-drawer app v-model="drawer" class="drawer elevation-1">
        <a href="#/home" v-if="preferences.country === 'nz'" class="logo"><img src="../../assets/title-nz.png"/></a>
        <a href="#/home" v-else-if="preferences.country == 'au'" class="logo"><img src="../../assets/title-au.png"/></a>
        <a href="#/home" v-else-if="preferences.country == 'ao'" class="ep-logo"><img
          src="../../assets/everyplate-logo.png"/></a>
        <a href="#/home" v-else-if="preferences.country == 'ye'" class="logo"><img src="../../assets/title-au.png"/></a>
        <div class="mx-2">
          <v-btn block large :text="$route.name === 'home'" color="primary" to="/home">
            Search
          </v-btn>
        </div>

        <div style="width: 100%;" v-if="loading">
          <v-progress-circular indeterminate color="primary" class="loading"></v-progress-circular>
        </div>

        <div v-for="subheader in computedSub"
             v-if="(subheader.items.length > 0) && (subheader.title === 'Agent' || userProfile[subheader.access] || (userProfile.admin && subheader.title !== 'Senior Agent'))">
          <v-subheader class="mt-6" v-if="subheader.title !== 'Agent' && subheader.title !== 'Senior Agent'">
            {{ subheader.title }}
          </v-subheader>

          <v-list dense class="mt-6">
            <v-list-item v-for="item in subheader.items" :key="item.title" :to="item.loc" :disabled="item.disabled"
                         v-if="item.country ? item.country.includes(preferences.country) : true">
              <v-list-item-action>
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </div>

        <v-divider class="my-1"></v-divider>
        <v-list>
          <v-list-item to="/country">
            <v-list-item-title class="subtitle-2">Change country</v-list-item-title>
          </v-list-item>
          <v-list-item @click="logout">
            <v-list-item-title class="subtitle-2">Logout ({{ name }})</v-list-item-title>
          </v-list-item>
        </v-list>
        <div class="grey--text caption pl-4">
          You are currently on the <span class="version-text">{{ preferences.country | getVersionByCountry }}</span>
          version
        </div>
        <div class="grey--text caption pl-4">
          Sentinel <span class="version-text">{{ }}</span>
        </div>
      </v-navigation-drawer>

      <v-app-bar app flat color="inherit" class="header" scroll-off-screen>
        <div @click="toggleNavDrawer">
          <v-icon>menu</v-icon>
        </div>
        <v-spacer></v-spacer>
        <v-scale-transition>
          <div class="mx-12" v-if="!drawer">
            <v-btn large :text="$route.name === 'home'" color="primary" to="/home">Search</v-btn>
          </div>
        </v-scale-transition>
        <v-tooltip left v-model="bob.hint.active">
          <template v-slot:activator="{ on }">
            <v-menu left v-model="bob.auth" :close-on-content-click="false" v-on="on">
              <template v-slot:activator="{ on }">
                <v-btn icon v-on="on">
                  <v-icon :color="bob.color">insert_emoticon</v-icon>
                </v-btn>
              </template>
              <bob-login v-on:close="checkBobAuth" v-if="bob.color !== '#8bcd57'"></bob-login>
            </v-menu>
          </template>
          <span>{{ bob.hint.text }}</span>
        </v-tooltip>

        <v-menu
          class="mx-2" bottom :disabled="userNotifications.length === 0" offset-y :close-on-content-click="false"
        >
          <template v-slot:activator="{ on }">
            <div v-on="on">
              <v-badge color="error" right overlap v-model="userNotifications.length > 0">
                <span slot="badge">{{ userNotifications.length }}</span>
                <v-btn icon :disabled="userNotifications.length === 0">
                  <v-icon color="primary">notification_important</v-icon>
                </v-btn>
              </v-badge>
            </div>
          </template>
          <v-list style="width: 30vw;" three-line>
            <v-slide-x-transition group>
              <v-list-item
                v-for="userNotif in userNotifications" :key="userNotif.id"
                @click="dismissUserNotification(userNotif.id)"
              >
                <v-list-item-content>
                  <v-list-item-title v-html="userNotif.title"></v-list-item-title>
                  <v-list-item-subtitle v-html="userNotif.message"></v-list-item-subtitle>
                </v-list-item-content>

                <v-list-item-action>
                  <v-btn icon @click="dismissUserNotification(userNotif.id)">
                    <v-icon color="error">cancel</v-icon>
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
            </v-slide-x-transition>
          </v-list>
        </v-menu>

        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn v-on="on" icon @click="history_drawer = true">
              <v-icon color="primary">bookmarks</v-icon>
            </v-btn>
          </template>
          User history
        </v-tooltip>
        <v-btn icon v-if="admin || team_leader" :to="{name: 'settings'}">
          <v-icon>settings</v-icon>
        </v-btn>
      </v-app-bar>

      <v-navigation-drawer v-model="history_drawer" absolute temporary right width="350">
        <div class="display-1 mx-4 my-4">
          <v-btn icon large @click="history_drawer=false" class="mt-0 pt-0">
            <v-icon>mdi-chevron-right</v-icon>
          </v-btn>
          History
        </div>
        <v-subheader class="mt-6">Last customer pages</v-subheader>
        <v-list>
          <v-list-item v-for="item in history.customers" :key="item.timestamp"
                       :to="{name: 'customer', params: {id: item.id}}">
            <v-list-item-content class="pl-4">
              <v-list-item-title>{{ item.name }}</v-list-item-title>
              <span class="grey--text">{{ item.id }}</span>
            </v-list-item-content>
            <v-list-item-action>{{ item.diff }}</v-list-item-action>
          </v-list-item>
        </v-list>
        <v-subheader class="mt-6">Last boxes pages</v-subheader>
        <v-list>
          <v-list-item v-for="item in history.boxes" :key="item.timestamp"
                       :to="{name: 'box', params: {id: item.id}}">
            <v-list-item-content class="pl-4">
              <v-list-item-title>{{ item.id }}</v-list-item-title>
              <span class="grey--text">{{ item.week }}</span>
            </v-list-item-content>
            <v-list-item-action>{{ item.diff }}</v-list-item-action>
          </v-list-item>
        </v-list>
      </v-navigation-drawer>

      <v-snackbar
        :value="true" v-for="notif in notificationsStore.notifications" :key="notif.id" :id="notif.id" right
        :timeout="0" :style="notif.style" :multi-line="notif.multiLine"
      >
        <v-icon v-if="notif.error" color="error" class="mr-4">warning</v-icon>
        <span v-html="notif.text"></span>
        <v-btn v-if="notif.customAction.active" color="primary" text @click="notif.customAction.action">
          {{ notif.customAction.text }}
        </v-btn>
        <v-btn v-else color="primary" text @click="dismissNotification(notif)">
          Close
        </v-btn>
      </v-snackbar>
    </div>

    <v-dialog v-model="addUserNotification.active" width="500" v-if="admin || team_leader">
      <template v-slot:activator="{ on }">
        <v-btn icon v-on="on">
          <v-icon color="warning">add_alert</v-icon>
        </v-btn>
      </template>
      <v-card>
        <v-card-title class="headline warning" primary-title>
          Add notification
        </v-card-title>

        <v-container grid-list-md>
          <v-row wrap>
            <v-col cols="12">
              <v-select
                :items="user_groups" v-model="addUserNotification.user_group" label="Users targeted"
              ></v-select>
            </v-col>
            <v-col cols="12">
              <v-text-field v-model="addUserNotification.title" label="Title"></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-textarea v-model="addUserNotification.message" label="Message"></v-textarea>
            </v-col>
          </v-row>
        </v-container>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="createUserNotification" :loading="addUserNotification.progress">
            Send
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog width="500" v-model="documentationPopIn">
      <template v-slot:activator="{ on }">
        <v-btn icon v-on="on">
          <v-icon color="warning">help</v-icon>
        </v-btn>
      </template>
      <documentation :close-pop-in="closeDocumentationPopIn"></documentation>
    </v-dialog>

  </div>
</template>

<script>
import axios from 'axios';
import auth from '../../modules/auth/index';
import notifications from '@/modules/notifications';

import BobLogin from '../auth/BobLogin';
import Documentation from '../Documentation';
import {CONSTANTS, COUNTRYCODE} from '@/modules/constants';

export default {
	name: 'Header',
	components: {
		bobLogin: BobLogin,
		documentation: Documentation
	},
	filters: {
		getVersionByCountry: function (value) {
			switch (value) {
			case COUNTRYCODE.AU:
				return 'Australia HelloFresh';
			case COUNTRYCODE.NZ:
				return 'New Zealand HelloFresh';
			case COUNTRYCODE.AO:
				return 'Australia EveryPlate';
			case COUNTRYCODE.YE:
				return 'Australia HelloFresh';
			default:
				return null;
			}
		}
	},
	data() {
		return {
			name: null,
			menu: false,
			preferences: auth.user.preferences,
			userProfile: auth.user.profile,
			notificationsStore: notifications.store,
			admin: false,
			team_leader: false,
			qa_team: false,
			payment_team: false,
			beta_test: false,
			loading: true,
			userNotifications: [],
			addUserNotification: {
				active: false,
				user_group: 'all',
				type: 'test',
				title: '',
				message: '',
				progress: false,
				country: auth.user.preferences.country
			},
			user_groups: [{text: 'Admins', value: 'admin'},
				{text: 'Team leaders', value: 'team_leader'},
				{text: 'Payment team', value: 'payment'},
				{text: 'Beta test', value: 'beta_test'},
				{text: 'All', value: 'all'}],
			bob: {
				color: 'grey',
				auth: false,
				hint: {
					active: false,
					text: 'Bob authentification'
				}
			},
			history_drawer: false,
			history: {
				customers: [],
				boxes: []
			},
			documentationPopIn: false,
			hellofreshDomains: [
				'@helloconnect.org',
				'@hellofresh.co',
				'@hellofresh.ca',
				'@hellofresh.de',
				'@hellofresh.nl',
				'@everyplate.com.au'
			]
		}
	},
	created() {
		auth.loadCountry(this);
		this.checkAuth();

		setInterval(this.checkAuth, 300000);

		this.$router.afterEach((to, from) => {
			if (from.name === 'customer' || from.name === 'box') {
				this.getHistory();
			}
		});
	},
	computed: {
		hellofreshAuthorised() {
			let authorised = false
			for (let domain of this.hellofreshDomains) {
				if (this.userProfile.email.includes(domain)) {
					authorised = true
				}
			}
			return authorised
		},
		drawer: {
			set(drawer) {
				this.$store.dispatch('setNavDrawer', drawer)
			},
			get() {
				return this.$store.state.drawer
			}
		},
		getCountryToEnableJellyfish() {
			return ['au', 'nz', 'ao', 'ye']
		},
		computedSub() {
			var sub = [
				{
					title: 'Agent', access: true, items: [
						// {title: 'UploadPostcodeDeliveryList', icon: 'add_location', loc: '/upload_postcode_delivery_list'},
						{title: 'Postcode Requests', icon: 'add_location', loc: '/postcode_requests'},
						{title: 'SuperBob Changes', icon: 'book', loc: '/super_bob_changes', country: ['au', 'ao', 'nz']}, // ANZOPSTECH-1022 Hide this option for ye
						// {title: 'Holiday Windows', icon: 'departure_board', loc: '/holiday_windows'}, // ANZOPSTECH-1017 hide component as it is no longer considered as important
						{title: 'Missed Courier Deliveries', icon: 'local_shipping', loc: '/mcd'},
						{title: 'Slot Allocations', icon: 'restaurant_menu', loc: '/slot_allocations', country: ['au', 'ao', 'nz']},
						{title: 'Postcode Lookup', icon: 'fas fa-globe-asia', loc: '/postcode_lookup'},
						{title: 'Voucher Code Lookup', icon: 'fas fa-barcode', loc: '/voucher_lookup'},
						{title: 'Hot Topic', icon: 'fas fa-pencil-alt', loc: '/issues'}
					]
				},
				{
					title: 'Senior Agent', access: true, items: [
						{title: 'Lookup Tool', icon: 'people', loc: '/lookup'}
					]
				},
				{
					title: 'Payment', access: 'payment', items: [
						{
							title: 'Failed Payments',
							icon: 'fa-cash-register',
							loc: '/payment/failed_payments',
							country: ['au', 'nz']
						},
						{
							title: 'Payment LMCs',
							icon: 'cancel_presentation',
							loc: '/payment/lmcs',
							country: this.getCountryToEnableJellyfish
						}
						// {title: 'Payment Errors', icon: 'payment', loc: '/payment/errors', country: this.getCountryToEnableJellyfish}, //ANZOPSTECH-1010-Hide payment errors component as after analysis it is not considered as important feature
						// {title: 'Bob Management', icon: 'mood', loc: '/payment/bob_management'} // ANZOPSTECH-1011: Hide component
					]
				},
				{
					title: 'Team Leaders', access: 'team_leader', items: [
						{title: 'LMCs', icon: 'cancel_presentation', loc: '/lmcs'},
						// {title: 'Updates', icon: 'warning', loc: '/errors/live'}, // ANZOPSTECH-1013: Hide component
						// {title: 'Wrong Addresses', icon: 'not_listed_location', loc: '/wrong_addresses', notification: false}, // ANZOPSTECH-1018: Hide component
						{title: 'Lookup Tool', icon: 'people', loc: '/lookup'},
						{title: 'Voucher Tools', icon: 'fas fa-barcode ', loc: '/voucher_tools'}
						// {title: 'Damage Claim Dashboard', icon: 'warning', loc: '/damageclaim_board', country: ['au', 'ao']} // ANZOPSTECH-1005
						// {title: 'Courier Contact', icon: 'fas fa-comment-dots ', loc: '/voucher_tools', disabled: true},
					]
				},
				{
					title: 'Food Safety', access: 'food_safety', items: [
						{title: 'Food Safety Issues', icon: 'bug_report', loc: '/food_safety', country: ['au', 'ao', 'nz']}
						// {title: 'Error Reports', icon: 'error', loc: '/errors/report'} // ANZOPSTECH-1307: Hide component
					]
				},
				{
					title: 'Marketing', access: 'marketing', items: []
				},
				{
					title: 'Culinary', access: 'culinary', items: []
				},
				{
					title: 'Logistics', access: 'logistics', items: [
						// {title: 'Dashboard', icon: 'dashboard', loc: '/dashboard', disabled: true}, // ANZOPSTECH-1021
						{title: 'Upload Boxes', icon: 'fas fa-box', loc: '/upload'}
					]
				},
				{
					title: 'Beta', access: 'beta_test', items: []
				}
				// ANZOPSTECH-1019
				// {
				// 	title: 'System', access: 'system', items: [
				// 		{title: 'Jobs Overview', icon: 'mdi-hammer-screwdriver', loc: '/jobs'}
				// 	]
				// }
				// {
				//   title: 'Courier', access: 'courier', items: [
				//     {title: 'Customer Care Contact', icon: 'fas fa-comment-dots', loc: '/courier/contact', disabled: true},
				//   ]
				// }
			];

			if (this.admin) {
				for (var key of Object.keys(sub)) {
					if (sub[key].title === 'Team Leaders') {
						sub[key].items.push({
							title: 'Hotwires',
							icon: 'fas fa-pepper-hot',
							loc: '/hotwires',
							country: ['au', 'ao', 'nz']
						})
					}
				}
			} else {
				for (var key2 of Object.keys(sub)) {
					if (sub[key2].title === 'Team Leaders' || sub[key2].title === 'Food Safety' || sub[key2].title === 'Culinary') {
						sub[key2].items.push({title: 'Hotwires', icon: 'fas fa-pepper-hot', loc: '/hotwires'})
					}
				}
			}
			if (auth.user.preferences.country === 'ye') {
				// ANZOPSTECH-1307 - remove Food Safety section as there is no options below
				sub = sub.filter(function (item) {
					return item.title !== 'Food Safety';
				});
			}
			return sub;
		}
	},
	methods: {
		checkAuth() {
			auth.checkAuth().then(() => {
				this.name = auth.user.profile.first_name + ' ' + auth.user.profile.last_name;
				this.admin = auth.user.profile.admin;
				this.team_leader = auth.user.profile.team_leader;
				this.payment = auth.user.profile.payment;
				this.qa_team = auth.user.profile.qa;
				this.beta_test = auth.user.profile.beta_test;
				this.marketing = auth.user.profile.marketing;
				this.culinary = auth.user.profile.culinary;
				this.warehouse = auth.user.profile.warehouse;
				this.courier = auth.user.profile.courier;
				this.getUserNotifications();
				this.getHistory();
				this.loading = false;

				if (!auth.user.profile.has_bob_session) {
					let bobCustomAction = {
						text: 'Connect',
						active: true,
						action: this.authBob
					};
					notifications.addNotification(
						'You are not connected to <b>Bob</b>, and therefore have not access to every feature we developed in Sentinel.',
						true, true, bobCustomAction
					);
					this.bob.color = 'error';
				} else {
					this.successBobAuth();
				}
			}).catch((err) => {
				console.log(err);
			});
		},
		logout() {
			auth.logout();
		},
		getUserNotifications() {
			axios.get(`${CONSTANTS.BASE_URL}/api/v1` + '/notifications', {params: {country: this.preferences.country}}).then((response) => {
				if (response.data.msg === 'success') {
					response.data.notifications.forEach((notification) => {
						if (notification.type === 'wrong_address') {
							this.tl[2].notification = true;
						}
					});
					this.userNotifications = response.data.notifications;
				}
			});
		},
		dismissUserNotification(notif_id) {
			axios.post(`${CONSTANTS.BASE_URL}/api/v1` + '/notifications/' + notif_id).then((response) => {
				if (response.data.msg === 'success') {
					this.getUserNotifications();
				}
			});
		},
		createUserNotification() {
			this.addUserNotification.progress = true;
			axios.post(`${CONSTANTS.BASE_URL}/api/v1` + '/notifications', this.addUserNotification).then((response) => {
				if (response.data.msg === 'success') {
					this.getUserNotifications();
					this.addUserNotification.progress = false;
					this.addUserNotification.active = false;
				}
			});
		},
		getHistory() {
			axios.get(`${CONSTANTS.BASE_URL}/api/v1` + '/history', {params: {country: this.preferences.country}}).then((response) => {
				if (response.data.msg === 'success') {
					this.history = response.data.history;
				}
			});
		},
		authBob() {
			this.bob.auth = true;
		},
		checkBobAuth() {
			auth.checkAuth().then(() => {
				if (!auth.user.profile.has_bob_session) {
					this.bob.color = 'error';
					this.bob.auth = false;
				} else {
					this.successBobAuth();
				}
			});
		},
		successBobAuth() {
			this.bob.color = '#8bcd57';
			this.bob.hint.active = true;
			this.bob.hint.text = 'Successfully connected to Bob.';
			setTimeout(() => {
				this.bob.hint.active = false;
			}, 5000);
			this.bob.auth = false;
		},
		dismissNotification(notification) {
			notifications.removeNotification(notification);
		},
		closeDocumentationPopIn() {
			this.documentationPopIn = false;
		},
		toggleNavDrawer() {
			this.$store.dispatch('toggleNavDrawer')
		}
	}

};
</script>

<style scoped>

/*@media (min-width: 960px) {*/
/*  .header {*/
/*    margin: 2em;*/
/*  }*/
/*}*/

.logo,
.logo img {
  width: 90%;
  margin-left: 2%;
}

.ep-logo {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 2.5em 0;
}

.ep-logo img {
  width: 50%;
}

.loading {
  display: block;
  margin: 5em auto;
}

.version-text {
  font-weight: 700;
}
</style>
