<template>
  <v-card>
    <v-card-title class="headline" primary-title>
      Add Voucher Type
    </v-card-title>
    <v-card-text>
      <v-form ref="form" v-model="valid">
        <v-text-field
          v-model="type"
          :rules="inputRules"
          label="Voucher Type"
          required
        ></v-text-field>
        <v-checkbox
          color="primary"
          v-model="newStarter"
          label="New Starter"
        ></v-checkbox>
        <v-checkbox color="primary" v-model="agent" label="Agent"></v-checkbox>
        <v-checkbox
          color="primary"
          v-model="teamLeader"
          label="Team Leader"
        ></v-checkbox>
        <v-checkbox
          v-model="isLeadershipCode"
          label="Is this a leadership code?"
        >
        </v-checkbox>
        <v-btn
          color="primary"
          class="mr-4"
          @click="handleSubmit"
          :loading="loading"
          >Submit
        </v-btn>
        <v-btn class="mr-4" @click="$emit('closeModal')">Close</v-btn>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import axios from 'axios';
import store from '@/store/store';
import notifications from '@/modules/notifications';
import {CONSTANTS} from '@/modules/constants';

export default {
	name: 'AddVoucherType',
	data() {
		return {
			valid: false,
			loading: false,
			type: '',
			newStarter: false,
			agent: false,
			teamLeader: true,
			isLeadershipCode: false,
			inputRules: [(v) => !!v || 'Required field']
		};
	},
	methods: {
		handleSubmit() {
			const voucherType = {
				type: this.type,
				new_starter: this.newStarter,
				agent: this.agent,
				team_leader: this.teamLeader,
				is_leadership_code: this.isLeadershipCode,
				country: store.state.country
			};

			if (this.$refs.form.validate()) {
				this.loading = true;

				axios
					.post(`${CONSTANTS.BASE_URL}/api/v1/voucher_type`, {
						body: { voucher_type: voucherType }
					})
					.then((response) => {
						this.$emit('handleSubmit');
						this.loading = false;
						this.type = '';
						this.newStarter = false;
						this.agent = false;
						this.teamLeader = true;
						this.isLeadershipCode = false;
						notifications.addNotification('Successfully added voucher type!');
					})
					.catch((error) => {
						notifications.addNotification(
							'Error: could not add voucher type',
							true
						);
						this.loading = false;
					});
			}
		}
	}
};
</script>
