<template>
  <div>
    <v-container fluid class="pa-0 ma-0">
      <v-row>
        <v-col cols="12" class="pa-0 ma-0">
          <v-card>
            <v-card-title class="pb-0">
              <v-row>
                <v-col cols="3">
                  <v-text-field
                    v-model="search"
                    append-icon="search"
                    label="Any reference"
                    single-line
                    hide-details
                  ></v-text-field>
                </v-col>
                <v-spacer></v-spacer>
                <v-col cols="2" class="mt-3">
                  <v-switch
                    v-model="filterByWeek"
                    :label="filterByWeek ? 'Week Filter On' : 'Week Filter Off'"
                    color="secondary"
                    @change="getVoucherUses"
                  ></v-switch>
                </v-col>
                <v-col cols="2" class="mt-3">
                  <v-select
                    :items="weeks"
                    v-model="selectedWeek"
                    label="Week"
                    @change="getVoucherUses"
                    :disabled="!filterByWeek"
                  ></v-select>
                </v-col>
                <v-col cols="2" class="mt-3">
                  <v-btn
                    color="primary"
                    block
                    :disabled="voucher_uses.length === 0"
                    @click="downloadVoucherUses"
                  >
                    <v-icon left dark>cloud_download</v-icon>
                    Export
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-title>
            <template>
              <v-data-table
                :headers="headers"
                :items="filteredVoucherUses"
                :search="search"
                :loading="progress"
                item-key="id"
                class="elevation-1"
                fixed-header
                :height="filteredVoucherUses.length < 10 ? '' : '600px'"
                multi-sort
                hide-default-footer
                disable-pagination
                :no-data-text="'No data found'"
              >
                <template v-slot:body.prepend>
                  <tr
                    :class="
                      preferences.country === 'au' ? 'grey lighten-3' : 'grey'
                    "
                  >
                    <th>
                      <v-icon>filter_list</v-icon>
                    </th>
                    <th
                      v-for="(header, index) in headers.slice(1)"
                      :key="index"
                    >
                      <div v-if="filters.hasOwnProperty(header.value)">
                        <v-select
                          text
                          hide-details
                          small
                          multiple
                          clearable
                          :items="columnValueList(header.value)"
                          v-model="filters[header.value]"
                        >
                        </v-select>
                      </div>
                    </th>
                  </tr>
                </template>

                <template v-slot:item.date_supplied="{ item }">
                  {{
                    new Date(item.date_supplied).toLocaleString("en-US", {
                      timeZone: "Australia/Sydney",
                      hour12: false,
                      dateStyle: "medium",
                      timeStyle: "short",
                    })
                  }}
                </template>

                <template v-slot:footer>
                  <v-card height="50px">
                    <v-divider class="mb-2"></v-divider>
                    <span
                      :style="{
                        textAlign: 'right',
                        float: 'right',
                        fontSize: '0.8em',
                        color: 'grey',
                      }"
                      class="ma-2 pr-4"
                    >
                      Rows: {{ filteredVoucherUses.length }}
                    </span>
                  </v-card>
                </template>

                <v-alert
                  v-slot:no-data
                  :value="true"
                  color="warning"
                  icon="warning"
                >
                  No voucher uses for this week.
                </v-alert>
                <v-alert
                  v-slot:no-results
                  :value="true"
                  color="error"
                  icon="warning"
                >
                  Your search for "{{ search }}" found no results.
                </v-alert>
              </v-data-table>
            </template>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import axios from 'axios';
import auth from '../../modules/auth';
import _ from 'lodash';
import notifications from '@/modules/notifications';
import {CONSTANTS} from '@/modules/constants';

export default {
	name: 'VoucherUse',
	data() {
		return {
			progress: false,
			weeksProgress: true,
			preferences: auth.user.preferences,
			search: '',
			voucher_uses: [],
			headers: [
				{
					text: 'Date/Time',
					value: 'date_supplied',
					align: 'left',
					fixed: true
				},
				{ text: 'Week', value: 'week_supplied', align: 'left', fixed: true },
				{ text: 'Agent Name', value: 'agent_name', align: 'left', fixed: true },
				{
					text: 'Team Leader Name',
					value: 'team_leader_name',
					align: 'left',
					fixed: true
				},
				{ text: 'Customer ID', value: 'customer_id', align: 'left' },
				{ text: 'Customer Email', value: 'customer_email', align: 'left' },
				{ text: 'Type', value: 'type', align: 'left' },
				{ text: 'Detail', value: 'detail', align: 'left' },
				{ text: 'Reason', value: 'reason', align: 'left' },
				{ text: 'Reason Detail', value: 'reason_detail', align: 'left' },
				{ text: 'Old Code', value: 'old_code', align: 'left' }
			],
			filterByWeek: true,
			filters: {
				week_supplied: [],
				type: [],
				detail: [],
				agent_name: [],
				team_leader_name: [],
				customer_id: [],
				customer_email: [],
				reason: [],
				reason_detail: [],
				old_code: []
			}
		};
	},
	computed: {
		filteredVoucherUses() {
			return this.voucher_uses.filter((voucher_use) => {
				return Object.keys(this.filters).every((filter) => {
					return (
						this.filters[filter].length < 1 ||
            this.filters[filter].includes(voucher_use[filter])
					);
				});
			});
		},
		selectedWeek: {
			set(week) {
				this.$store.dispatch('updateWeek', week);
			},
			get() {
				return this.$store.state.week;
			}
		},
		weeks() {
			return this.$store.state.weeks;
		}
	},
	mounted() {
		this.setTableHeaderColor();
	},
	watch: {
		selectedWeek: {
			handler: function () {
				this.getVoucherUses();
			},
			immediate: true
		}
	},
	methods: {
		setTableHeaderColor: function () {
			let d = document.getElementsByTagName('thead')[0];
			d.className = this.preferences.country === 'au' ? 'thead-au' : 'thead-nz';
		},
		getVoucherUses: function () {
			if (this.selectedWeek !== '') {
				this.progress = true;
				axios
					.get(`${CONSTANTS.BASE_URL}/api/v1` + '/vouchers/voucher_use', {
						params: {
							country: this.preferences.country,
							week: this.selectedWeek,
							filter_by_week: this.filterByWeek
						}
					})
					.then((response) => {
						if (response.data.msg === 'success') {
							this.voucher_uses = response.data.voucher_uses.sort(function (
								a,
								b
							) {
								return a.week > b.week;
								this.voucher_uses = response.data.voucher_uses;
							});
							this.progress = false;
						}
					})
					.catch(() => {
						this.voucher_uses = [];
						this.progress = false;
						notifications.addNotification(
							'Oops, error when getting voucher uses..',
							true
						);
					});
			}
		},
		downloadVoucherUses: function () {
			this.progress = true;
			axios({
				url: `${CONSTANTS.BASE_URL}/api/v1` + '/voucher_use/download',
				method: 'GET',
				responseType: 'blob',
				params: {
					country: this.preferences.country,
					filters: this.filters,
					week: this.selectedWeek
				}
			})
				.then((response) => {
					const url = window.URL.createObjectURL(new Blob([response.data]));
					const link = document.createElement('a');
					link.href = url;
					link.setAttribute(
						'download',
						`Voucher Uses - ${this.preferences.country.toUpperCase()} ${
							this.selectedWeek
						}.csv`
					);
					document.body.appendChild(link);
					link.click();
					this.progress = false;
				})
				.catch(() => {
					this.progress = false;
				});
		},
		columnValueList(val) {
			return this.voucher_uses.map((d) => d[val]);
		}
	}
	// ,
	// changeSort (column) {
	//     if (this.pagination.sortBy === column) {
	//         this.pagination.descending = !this.pagination.descending
	//     } else {
	//         this.pagination.sortBy = column
	//         this.pagination.descending = false
	//     }
	// }
};
</script>

<style scoped>
th {
  white-space: nowrap;
}

.thead-au {
  background-color: #8bcd57;
}

.thead-nz {
  background-color: #c180ff;
}
</style>
