<template>
  <v-card>
    <v-card-title class="headline warning" primary-title>
      <v-icon color="white" class="px-4">new_releases</v-icon>
        Damage Claim issue
      <v-spacer></v-spacer>
      <v-btn color="secondary" @click="closePopIn" v-if="isFormAlreadySent"
        >Close</v-btn
      >
      <v-subheader v-else class="subheading"
        >Don't forget to<b>&nbsp; error track &nbsp;</b>on damage claim dashboard
        !
      </v-subheader>
    </v-card-title>
    <v-divider></v-divider>
    <v-card-text>
      <v-row wrap v-if="isFormAlreadySent">
        <v-col cols="12">
          <v-list two-line>
            <v-list-item>
              <v-list-item-action>
                <v-icon v-if="!damageClaimForm.answered" color="error"
                  >router</v-icon
                >
                <v-icon v-else-if="!damageClaimForm.courier_reviewed" color="warning"
                  >find_replace</v-icon
                >
                <v-icon v-else color="success">check</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>
                  <span
                    :class="
                      damageClaimForm.answered
                        ? damageClaimForm.courier_reviewed
                          ? 'primary--text'
                          : 'warning--text'
                        : 'error--text'
                    "
                  >
                    {{ damageClaimMessage }}
                  </span>
                </v-list-item-title>
                <v-list-item-subtitle>Status</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-col>
        <v-col cols="12">
          <v-list two-line>
            <v-list-item>
              <v-list-item-action>
                <v-icon>speaker_notes</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>
                  <a :href="damageClaimForm.form_link" target="_blank"
                    >{{ damageClaimForm.form_link }}
                  </a>
                </v-list-item-title>
                <v-list-item-subtitle>Form link</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-col>
      </v-row>
      <v-row wrap v-else>
        <v-subheader>
        Damage claim issue form will be sent to this email address.
        </v-subheader>
        <v-col cols="12">
          <v-text-field
            class="px-2"
            prepend-icon="message"
            v-model="email"
            label="Email address"
            :disabled="this.box"
          >
          </v-text-field>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions v-if="!isFormAlreadySent" class="px-2">
      <v-spacer></v-spacer>
      <v-btn color="error" text @click="closePopIn">Close</v-btn>
      <v-btn
        color="warning"
        text
        @click="sendDamageClaimFormToCustomer(email)"
        :disabled="!email"
      >
        Send form
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>

export default {
	name: 'DamageClaim',
	props: {
		closePopIn: Function,
		sendDamageClaimFormToCustomer: Function,
		email: {type: String, required: false, default: ''},
		damageClaimForm: {type: Object, required: false},
		box: {type: Object, required: false, default: ()=>{}}
	},
	computed: {
		isFormAlreadySent: function () {
			return this.damageClaimForm && this.damageClaimForm.box_id !== undefined;
		},
		damageClaimMessage: function(){
			return this.damageClaimForm.answered
				? this.damageClaimForm.courier_reviewed
					? this.damageClaimForm.cc_resolved
						? 'Damage claim issue closed and resolved by CC'
						: 'Damage claim issue is still under review by courier'
					: 'Courier has been notified'
				: 'Waiting for customer response'
		}
	}
};
</script>

<style scoped></style>
