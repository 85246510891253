<template>
  <div>
    <v-progress-linear indeterminate v-if="progress" color="secondary" class="progress"></v-progress-linear>

    <v-dialog v-model="rowPopupDialog" width="80vw">
      <live-data-display-row-popup :row="selectedRow" :name="issue_name" @close="rowPopupDialog = false"/>
    </v-dialog>

      <v-container fluid class="pa-0 ma-0">
          <v-row>
            <v-col cols="6">
              <v-col cols="12"><span class="subtitle-1">{{ issue_name }}</span></v-col>
              <v-col cols="12"><v-col cols="2"><v-progress-circular v-if="loadingResponses" indeterminate color="secondary"/></v-col></v-col>
            </v-col>

            <v-col cols="6">
              <v-row>
            <v-col cols="2" class="mr-0 pr-0">
              <v-spacer/>
              <v-tooltip top class="mr-0 pr-0">
                <template v-slot:activator="{ on }">
                  <v-switch v-on="on"
                            v-model="weekFilter"
                            color="secondary"
                            @change="$emit('week-change', weekFilter ? week : '')"
                            class="mr-0 pr-0"
                  />
                </template>
                {{(weekFilter ? 'Disable' : 'Enable') + ' Week Filter'}}
              </v-tooltip>
            </v-col>
            <v-col cols="4" class="pl-0 ml-0">
              <v-combobox v-model="week"
                          :items="weeks"
                          label="Week"
                          :disabled="!weekFilter"
                          :loading="weeks.length === 0"
              />
            </v-col>
            <v-col cols="4">
              <v-text-field v-model="search"
                            append-icon="search"
                            label="Any reference"
                            single-line
                            hide-details
              ></v-text-field>
            </v-col>
                <v-spacer/>
            <v-col cols="4" v-if="form_responses.length > 0">
              <v-btn color="primary" block
                     :disabled="form_responses.length === 0"
                     :href="externalViewGoogleSheet"
                     target="_blank">
                <v-icon left dark>fas fa-table</v-icon>
                Sheet View
              </v-btn>
            </v-col>
            <v-col cols="4" v-if="form_responses.length > 0">
              <v-btn color="primary" block
                     :disabled="form_responses.length === 0"
                     target="_blank"
                     @click="downloadFormResponses">
                <v-icon left dark>cloud_download</v-icon>
                Export Issue
              </v-btn>
            </v-col>
                </v-row>
              </v-col>
          </v-row>

        <v-expansion-panels class="mb-1" v-if="form_responses.length > 0">
          <v-expansion-panel>
            <v-expansion-panel-header class="subtitle-2" expand-icon="fas fa-eye">Hide / Show Columns</v-expansion-panel-header>
            <v-expansion-panel-content>
            <span v-for="header in headers" @click="header.hide = !header.hide" class="subtitle-2">
                         <v-icon class="ma-4 mt-6" :dense="header.hide" color="secondary">
            {{header.hide ? 'fas fa-eye-slash' : 'fas fa-eye'}}
            </v-icon>
              {{header.text}}
            </span>

            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
        <div v-if="!loadingResponses">
          <span v-if="form_responses.length === 0 && weekFilter">No form responses for the selected week</span>
          <span v-else-if="form_responses.length === 0 && !weekFilter">No form responses yet</span>

        <v-data-table v-else
                      :headers="headers.filter(v => !v.hide)"
                      :items="loadingResponses ? [] : filteredFormResponses"
                      item-key="id"
                      fixed-header
                      multi-sort
                      :height="tableHeight"
                      sort-by="date_recorded"
                      sort-desc
                      hide-default-footer
                      disable-pagination
                      dense
                      class="elevation-1"
                      :no-data-text="'No data found'"
        >
          <template v-slot:body="{ items }">
            <tbody>
              <tr :class="preferences.country === 'au' ? 'grey lighten-3' : 'grey'">
                <th><v-icon>filter_list</v-icon></th>
                <th v-for="(header, index) in headers.slice(1).filter(v => !v.hide)" :key="index">
                  <div v-if="filters.hasOwnProperty(header.value)">
                    <v-autocomplete text
                              hide-details
                              small
                              multiple
                              clearable
                              :items="columnValueList(header.value)"
                              v-model="filters[header.value]"
                    ></v-autocomplete>
                  </div>
                </th>
              </tr>

              <tr v-for="(item, index) in items" :key="index" @click="openRowPopup(item)" class="cursor">
                <td v-for="(field, colIndex) in filteredDisplayFields"
                    :key="colIndex"
                    valign="top"
                    align="left"
                >
                  <v-checkbox v-if="field['type'] === 'Checkbox'"
                              v-model="item[field['name']]"
                              color="secondary"
                              @change="updateRow(item, field['name'], item[field['name']])"
                              @click.stop="item[field['name']] = !item[field['name']]"
                              on-icon="fas fa-hamburger"
                              off-icon="far fa-circle"
                  />
                  <div v-else-if="field['edit']">
                    <v-edit-dialog
                      :return-value.sync="editValue"
                      single-line
                      @open="editValue=item[field['name']]; editDialog=true;"
                      @close="editDialog=false"
                      @save="updateRow(item, field['name'], editValue)"
                    >
                      <div>
                        <v-icon small left v-if="item[field['name']] === null">edit</v-icon>
                        <v-icon small left v-else-if="item[field['name']].trim() === ''">edit</v-icon>
                        <span v-if="item[field['name']] !== null">{{  item[field['name']].toString().slice(0, 24) }}
                      <span v-if="item[field['name']].length > 25">...</span>
                      </span>
                      </div>

                      <template slot="input">
                        <v-combobox v-if="field['dropdown'].length > 0"
                                    v-model="editValue"
                                    :items="field['dropdown']"
                                    label="Edit"
                        ></v-combobox>
                        <v-text-field v-else
                                      v-model="editValue"
                                      label="Edit"
                        ></v-text-field>
                      </template>
                    </v-edit-dialog>
                  </div>
                  <div v-else>
                    <span v-if="item[field['name']] !== null">
                    {{ item[field['name']].toString().slice(0, 24) }}
                      <span v-if="item[field['name']].length > 25 ">...</span>
                    </span>
                  </div>

                </td>
              </tr>
              </tbody>
            </template>

            <template v-slot:footer>
              <v-card height="50px">
                <v-divider class="mb-2"></v-divider>
                <span :style="{textAlign: 'right', float: 'right', fontSize: '0.8em', color:'grey'}"
                      class="ma-2 pr-4" >
                  Rows: {{ filteredFormResponses.length }}
                </span>
              </v-card>
            </template>

            <v-alert v-slot:no-data :value="true" color="warning" icon="warning">
              No responses to this issue yet
            </v-alert>
            <v-alert v-slot:no-results :value="true" color="error" icon="warning">
              Your search for "{{ search }}" found no results.
            </v-alert>

          </v-data-table>
          </div>
      </v-container>

  </div>
</template>

<script>
import axios from 'axios';
import auth from '../../modules/auth';
import notifications from '@/modules/notifications';
import LiveDataDisplayRowPopup from './LiveDataDisplayRowPopup';
import {CONSTANTS} from '@/modules/constants';

export default {
	name: 'LiveDataDisplay',
	components: {LiveDataDisplayRowPopup: LiveDataDisplayRowPopup},
	props: {
		issue_name: {type: String, required: true},
		form_responses: {type: Array, required: true},
		display_fields: {type: Array, required: true},
		filters: {type: Object, required: true},
		loadingResponses: { type: Boolean, required: true }
	},
	data() {
		return {
			preferences: auth.user.preferences,
			userProfile: auth.user.profile,
			progress: false,
			search: '',
			filterByWeek: false,
			row_items: [15, 100, 200],
			rules: {
				length: v => (v || '').length <= 255 || `Maximum 255 characters`
			},
			localFilters: this.filters,
			externalViewGoogleSheet: 'https://docs.google.com/spreadsheets/d/15U7XIU5DAg8I3ScWOQ7mfY6Kc_zquseSc4TYIMcJbko/edit?usp=sharing',
			editValue: '',
			new_filter_value: {},
			selectedRow: {},
			rowPopupDialog: false,
			editDialog: false,
			headers: [],
			weekFilter: true
		}
	},
	created() {
		this.headers = this.buildHeaders()
		this.$store.dispatch('closeNavDrawer')
		if (this.week === '') {
			this.weekFilter = false
		}
	},
	computed: {
		filteredFormResponses() {
			return this.form_responses.filter(form_response => {
				return Object.keys(this.filters).every(filter => {
					return this.filters[filter].length < 1 || this.filters[filter].includes(form_response[filter])
				})
			})
		},
		agent_name() {
			return this.userProfile.first_name + ' ' + this.userProfile.last_name
		},
		tableHeight() {
			let rows = this.filteredFormResponses.length
			return rows < (window.innerHeight * 0.8 - 200) / 38 ? (200 + rows * 38).toString() + 'px' : window.innerHeight * 0.8
		},
		filteredDisplayFields() {
			if (this.form_responses.length > 0 && this.headers.length > 0) {
				return this.display_fields.filter(v => !this.headers[this.headers.findIndex(w => w.value === v.name)].hide)
			}
		},
		week: {
			set(week) {
				this.$store.dispatch('updateWeek', week)
			},
			get() {
				return this.$store.state.week
			}
		},
		weeks() {
			return this.$store.state.weeks
		}
	},
	watch: {
		weekFilter: function() {
			if (this.weekFilter) {
				this.$store.dispatch('resetWeek')
			} else {
				this.$store.dispatch('updateWeek', '')
			}
		}
	},
	methods: {
		setTableHeaderColor: function () {
			let d = document.getElementsByTagName('thead')[0];
			d.className = this.preferences.country === 'au' ? 'thead-au' : 'thead-nz';
		},
		downloadFormResponses: function () {
			this.progress = true;
			axios({
				url: `${CONSTANTS.BASE_URL}/api/v1` + '/issues/download',
				method: 'GET',
				responseType: 'blob',
				params: {
					week: this.week,
					filters: this.localFilters,
					issue_name: this.issue_name,
					// leadership_only: this.team_leader_issues,
					country: this.preferences.country
				}
			}).then((response) => {
				const url = window.URL.createObjectURL(new Blob([response.data]));
				const link = document.createElement('a');
				link.href = url;
				link.setAttribute(
					'download',
					'Hot Topic - ' + this.issue_name + '-' + this.preferences.country.toUpperCase() + '.csv'
				);
				document.body.appendChild(link);
				link.click();
				this.progress = false;
			}).catch(() => {
				this.progress = false;
			});
		},
		columnValueList(val) {
			return this.filteredFormResponses.map(d => d[val])
		},
		updateRow: function (row, updatedField, updatedValue) {
			this.$emit('rowUpdate', row, updatedField, updatedValue)
			this.progress = true;
			axios.post(`${CONSTANTS.BASE_URL}/api/v1` + '/issues/update', {
				row:row,
				field: updatedField,
				value: updatedValue,
				country: this.preferences.country,
				issue_name: this.issue_name,
				agent_name: this.agent_name
			})
				.then((response) => {
					if (response.data.msg === 'success') {
						notifications.addNotification(`Row updated successfully`, true);
					}
					if (response.data.msg === 'Error. This column cannot be edited') {
						notifications.addNotification('Error. This column cannot be edited', true);
					}
					if(response.data.msg === 'Rows updated, but email notification failed') {
						notifications.addNotification(`Rows updated, but email notification failed`, true);
					}
					this.progress = false;
				}).catch(() => {
					notifications.addNotification(`There was a problem updating the row`, true);
					this.progress = false;
				});
		},
		openRowPopup(row) {
			this.selectedRow = row
			if (this.editDialog === false) {
				this.rowPopupDialog = true
			}
		},
		buildHeaders() {
			let output = []
			for (let [index, display_field] of this.display_fields.entries()) {
				output.push({
					text: display_field['name'].replace(/_/g, ' '),
					value: display_field['name'],
					align: 'left',
					hide: false,
					fixed: index < 4 ? true : false
				})
			}
			return output
		},
		updateWeek() {
			this.$store.dispatch('updateWeek', this.week)
		}
	}
}
</script>

<style scoped>

  /*Reversed input variant*/
.v-input--reverse .v-input__slot {
  flex-direction: row-reverse;
  justify-content: flex-end;
  .v-application--is-ltr & {
    .v-input--selection-controls__input {
      margin-right: 0;
      margin-left: 8px;
    }
  }
  .v-application--is-rtl & {
    .v-input--selection-controls__input {
      margin-left: 0;
      margin-right: 8px;
    }
  }
}

  .cursor {
    cursor: pointer;
  }

</style>
