<template>
  <div>
    <v-progress-linear
      :active="loading"
      indeterminate
      color="secondary"
    ></v-progress-linear>
    <v-container>
      <v-row class="mb-4">
        <v-col cols="6">
          <h1 class="display-2">Postcode Requests</h1>
        </v-col>
        <v-col cols="6" class="d-flex justify-space-around" style="align-items: center">
          <v-btn
            v-if="!creation"
            color="error"
            @click="creation = true"
          >
            <v-icon left dark>add_location</v-icon>
            Add a new postcode
          </v-btn>
          <v-btn
            color="secondary"
            @click="downloadPostcodeRequests"
          >
            <v-icon left>cloud_download</v-icon>
            Export
          </v-btn>
        </v-col>
      </v-row>

        <v-data-table
          :headers="headers"
          :items="requests"
          :loading="loading"
          :items-per-page="50"
          :footer-props="{
            'items-per-page-options': [50,100,-1]
          }"
          :no-data-text="'No data found'"
        />

    </v-container>

    <v-dialog v-model="creation" max-width="500px">
      <v-card>
        <v-card-title class="secondary">
          <span class="headline">Add a postcode request</span>
        </v-card-title>
        <v-card-text>
          <v-form ref="postcode_form" lazy-validation>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-text-field label="Postcode" v-model="new_postcode.postcode"
                                required
                                :rules="[v => !!v || 'A postcode is required', v => !isNaN(v) || 'A number is required']"
                                prepend-icon="place"></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field label="Suburb" v-model="new_postcode.suburb"
                                required
                                :rules="[v => !!v || 'A suburb is required', v => isNaN(v) || 'A suburb name is required']"
                                prepend-icon="map"></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field label="Customer Name (optional)" v-model="new_postcode.customer_name" prepend-icon="person"></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field label="Customer Email" v-model="new_postcode.customer_email"
                                required
                                :rules="[v => !!v || 'A contact method is required']"
                                prepend-icon="email"></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field label="Customer Phone (optional)" v-model="new_postcode.customer_phone"
                                :rules="[v => !isNaN(v) || 'A number is required']"
                                prepend-icon="phone"></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-textarea label="Comment (optional)" v-model="new_postcode.comment"
                              prepend-icon="create"></v-textarea>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn class="px-7" text @click.native="creation = false">Close</v-btn>
          <v-btn class="px-7" color="primary" @click.native="createPostcodeRequest">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </div>
</template>

<script>
import axios from 'axios';
import auth from '../../modules/auth';
import notifications from '@/modules/notifications';
import {CONSTANTS} from '@/modules/constants';

export default {
	name: 'PostcodeRequests',
	data() {
		return {
			preferences: auth.user.preferences,
			loading: false,
			requests: [],
			creation: false,
			new_postcode: {
				date_requested: '',
				postcode: '',
				suburb: '',
				customer_name: '',
				customer_email: '',
				customer_phone: '',
				comment: '',
				country: auth.user.preferences.country
			},
			headers:[
				{text: 'Postcode', value: 'postcode', align: 'left', sortable: true, width: '10%'},
				{text: 'Suburb', value: 'suburb', align: 'center', sortable: true, width: '20%'},
				{text: 'Request by', value: 'agent', align: 'center', sortable: true, width: '20%'},
				{text: 'Comment', value: 'comment', align: 'center', sortable: false, width: '50%'}
			]
		}
	},
	async mounted() {
		this.loading = true;
		await this.refreshPostcodeRequests();
		this.loading = false;
	},
	methods: {
		refreshPostcodeRequests() {
			this.loading = true;
			return axios.get(`${CONSTANTS.BASE_URL}/api/v1` + '/requests/postcode',
				{
					params: {
						country: this.preferences.country
					}
				})
				.then((response) => {
					if (response.data.msg === 'success') {
						this.requests = response.data.postcode_requests;
						this.loading = false;
					}
				});
		},
		createPostcodeRequest() {
			if (this.$refs.postcode_form.validate()) {
				this.creation = false;
				this.loading = true;
				axios.post(`${CONSTANTS.BASE_URL}/api/v1` + '/requests/postcode', this.new_postcode).then((response) => {
					if (response.data.msg === 'success') {
						notifications.addNotification('Postcode inserted successfully !');
						this.refreshPostcodeRequests();
					}
					this.loading = false;
				});
			}
		},
		downloadPostcodeRequests: function () {
			this.loading = true;
			axios({
				url: `${CONSTANTS.BASE_URL}/api/v1` + '/requests/postcode/download',
				method: 'POST',
				responseType: 'blob',
				data: {
					country: this.preferences.country
				}
			}).then((response) => {
				const url = window.URL.createObjectURL(new Blob([response.data]));
				const link = document.createElement('a');
				link.href = url;
				link.setAttribute(
					'download',
					'Postcode Requests - ' + this.preferences.country.toUpperCase() + '.csv'
				);
				document.body.appendChild(link);
				link.click();
				this.loading = false;
			});
		}
	}
}
</script>

<style scoped>
</style>
