<template>
  <v-row wrap class="px-4 my-2 py-0">
    <v-col class="py-5" cols="3" xs="12" sm="6" md="4" lg="3" v-for="mealKit in mealKits" :key="mealKit.value">
      <v-card v-if="mealKit.website_url !== null" class="mx-2 mt-2" hover elevation="1" light>
        <a :href="mealKit.website_url" target="_blank">
          <v-img
            lazy-src="https://is4-ssl.mzstatic.com/image/thumb/Purple123/v4/aa/fb/72/aafb722a-b525-e7c4-90f1-9b587a241ad1/AppIcon-0-1x_U007emarketing-0-0-85-220-0-6.png/1200x630wa.png"
            :src="mealKit.image_link || 'https://is4-ssl.mzstatic.com/image/thumb/Purple123/v4/aa/fb/72/aafb722a-b525-e7c4-90f1-9b587a241ad1/AppIcon-0-1x_U007emarketing-0-0-85-220-0-6.png/1200x630wa.png'"
            :alt="mealKit.recipe_title + ' - picture'"
          >
            <v-container class="pt-1 pl-2">
              <v-avatar class="primary" size="30">
                <span class="white--text body-2 font-weight-medium">{{mealKit.slot}}</span>
              </v-avatar>
            </v-container>
          </v-img>
        </a>
      </v-card>
      <v-card v-else class="mx-2 mt-2" elevation="1" light>
        <v-img
          :src="mealKit.image_link || 'https://is4-ssl.mzstatic.com/image/thumb/Purple123/v4/aa/fb/72/aafb722a-b525-e7c4-90f1-9b587a241ad1/AppIcon-0-1x_U007emarketing-0-0-85-220-0-6.png/1200x630wa.png'"
          :alt="mealKit.recipe_title + ' - picture'"
        >
          <v-container class="pt-1 pl-2">
            <v-avatar class="primary" size="30">
              <span class="white--text body-2 font-weight-medium">{{mealKit.slot}}</span>
            </v-avatar>
          </v-container>
        </v-img>
        <v-card-title class="pb-1 pt-4 px-2" style="background-color: #F8F8F8; text-align: right;">
          <v-spacer></v-spacer>
          <v-icon small class="pr-1" color="error">open_in_new</v-icon>
          <span class="caption error--text">No link to recipe page yet.</span>
        </v-card-title>
      </v-card>
      <v-row wrap>
        <v-spacer></v-spacer>
        <span
          :class="[preferences.country === 'au' || preferences.country === 'ao' ? 'grey--text' : 'white--text', 'body-2 pr-4 pt-1 text--darken-3']"
          style="text-align: right"
        >
          {{mealKit.recipe_title}}
        </span>
      </v-row>
      <v-row wrap v-if="mealKit.recipe_subtitle !== null">
        <v-spacer></v-spacer>
        <span
          :class="[preferences.country === 'au' || preferences.country === 'au' ? 'text--darken-1' : 'text--lighten-1', 'caption pr-4 grey--text text--lighten-1']"
          style="text-align: right">{{mealKit.recipe_subtitle}}
        </span>
      </v-row>
      <div v-if="mealKit.mod_types">
        <ingredient-substitution-details-modal
          :slot="mealKit.slot"
          :modTypes="mealKit.mod_types"
          :modIngredientSubstitutions="mealKit.mod_ingredient_substitutions"
        />
      </div>
      <v-row class="py-0">
      <v-col cols="6" style="text-align: left" class="py-0" >
        <span v-if="mealKit.slot === 16 || mealKit.slot === 22 || mealKit.slot == 23 || mealKit.slot == 24"
          class="warning--text pl-5 caption"
          style="text-align: center">MU-CAP: 1
        </span>
      </v-col>
        <v-col cols="6" style="text-align: right" class="py-0" v-if="mealKit.recipe_type">
          <span
            class="warning--text pr-2 caption"
            style="text-align: right"
            v-if="mealKit.recipe_type !== 'regular'"
          >
            {{ mealKit.recipe_type.toUpperCase() }}
          </span>
        </v-col>
        </v-row>
    </v-col>
  </v-row>
</template>

<script>
import auth from '../modules/auth';
import IngredientSubstitutionDetailsModal from '@/components/boxes/IngredientSubstitutionDetailsModal';

export default {
	name: 'MealKits',
	components: {IngredientSubstitutionDetailsModal},
	data() {
		return {
			preferences: auth.user.preferences
		}
	},
	props: {
		mealKits: Array
	}
}
</script>

<style scoped>

</style>
