// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import axios from 'axios';
import Vue from 'vue';
import VueClipboard from 'vue-clipboard2';
import VueParticles from 'vue-particles';
import Vuetify from 'vuetify';
import 'vuetify/dist/vuetify.min.css';
import App from './App';
import router from './modules/router';
import store from './store/store';


Vue.use(VueParticles);
Vue.use(VueClipboard);
Vue.use(Vuetify);

const opts = {
	icons: {
		iconfont: 'mdi'
	},
	theme: {
		dark: false,
		themes: {
			light: {
				primary: '#8bcd57',
				secondary: '#669933',
				error: '#ff7a73',
				warning: '#ffc852'
			},
			dark: {
				primary: '#c180ff',
				secondary: '#68c1ff',
				error: '#ff7a73',
				warning: '#ffc852'
			},
			ep: {
				primaryOne: '#f99d33',
				primaryTwo: '#fdba4d',
				secondaryOne: '#16becf',
				secondaryTwo: '#88d1d1',
				secondaryThree: '#007885',
				black: '#333333',
				white: '#ffffff',
				error: '#ff7a73',
				warning: '#ffc852'
			}
		}
	}
};

Vue.config.productionTip = process.env.NODE_ENV === 'production';

axios.defaults.withCredentials = true;

new Vue({
	vuetify: new Vuetify(opts),
	el: '#app',
	router,
	store,
	components: { App },
	template: '<App/>'
});
