function snakeToTitleCase(string) {
	return (string.replace(/_/g, ' ').split(' ')
		.map((s) => s.charAt(0).toUpperCase() + s.substring(1))
		.join(' '));
}

function camelToTitleCase(string) {
	return (string.replace(/([A-Z])/g, ' $1')
		.replace(/^./, (str) => str.toUpperCase())
		.trimStart());
}

export default {
	snakeToTitleCase,
	camelToTitleCase
}

