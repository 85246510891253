<template>
  <div>
    <v-container>
      <v-row>
        <v-col cols="12">
          <h1 class="display-3">Voucher tools</h1>
        </v-col>
      </v-row>
    </v-container>
    <v-tabs v-model="tab" background-color="transparent" color="primary" grow>
      <v-tab>
        Backup voucher code upload
      </v-tab>
      <v-tab>
        Voucher use dashboard
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab">
      <v-tab-item>
        <voucher-upload />
      </v-tab-item>

      <v-tab-item>
        <voucher-use />
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import axios from 'axios';
import auth from '../../modules/auth';
import notifications from '@/modules/notifications';
import VoucherUse from './VoucherUse';
import VoucherUpload from './VoucherUpload';
import {CONSTANTS} from '@/modules/constants';

export default {
	name: 'VoucherTools',
	components: {
		VoucherUse: VoucherUse,
		VoucherUpload: VoucherUpload
	},
	data() {
		return {
			tab: 0,
			preferences: auth.user.preferences,
			userProfile: auth.user.profile,
			backup_voucher_type: '',
			backup_voucher_detail: '',
			backup_voucher_types: [],
			backup_voucher_details: [],
			number_uses_allowed: '',
			backup_voucher_popup: '',
			voucher_expiry_date: null,
			voucher_codes: '',
			country_input: '',
			date_menu: false,
			tomorrow_date: null,
			jobs: [],
			is_leadership_code: false,
			backup_voucher_popup_list: []
		};
	},
	mounted() {
		this.tomorrowDate();
		this.getBackupVoucherTypes();
	},
	methods: {
		uploadBackupVouchers: function () {
			if (this.$refs.form.validate()) {
				this.progress = true;
				axios
					.post(`${CONSTANTS.BASE_URL}/api/v1` + '/vouchers/voucher_upload', {
						country: this.country_input,
						backup_voucher_type: this.backup_voucher_type,
						backup_voucher_detail: this.backup_voucher_detail,
						number_uses_allowed: this.number_uses_allowed,
						voucher_expiry_date: this.voucher_expiry_date,
						backup_voucher_popup: this.backup_voucher_popup,
						voucher_codes: this.voucher_codes,
						leadership_codes: this.is_leadership_code
					})
					.then((response) => {
						if (response.data.msg === 'success') {
							this.progress = false;
							this.jobs.push({ id: response.data.job_id });
							this.updateJobs();
							notifications.addNotification(
								`Job to upload ${response.data.num_vouchers_for_upload} vouchers started, look above.`,
								true
							);
						} else if (response.data.msg === 'codes not unique') {
							notifications.addNotification(
								`[Error] List of codes contains duplicates. No vouchers were uploaded. Duplicates: ${response.data.duplicates}`,
								true
							);
							this.progress = false;
						}
					})
					.catch(() => {
						this.progress = false;
						notifications.addNotification(
							'Oops, something went wrong when uploadings your vouchers.',
							true
						);
					});
			}
		},
		getBackupVoucherPopupList: function () {
			this.progress = true;
			axios
				.get(`${CONSTANTS.BASE_URL}/api/v1` + '/vouchers/backup_popups', {
					params: {
						country: this.preferences.country,
						backup_voucher_type: this.backup_voucher_type,
						backup_voucher_detail: this.backup_voucher_detail,
						leadership_codes: this.is_leadership_code
					}
				})
				.then((response) => {
					if (response.data.msg === 'success') {
						this.backup_voucher_popup_list =
              response.data.backup_voucher_popups;
					}
				});
		},
		getBackupVoucherTypes: function () {
			this.progress = true;
			axios
				.get(`${CONSTANTS.BASE_URL}/api/v1` + '/vouchers/backup_types', {
					params: {
						country: this.preferences.country,
						leadership_codes: this.is_leadership_code
					}
				})
				.then((response) => {
					if (response.data.msg === 'success') {
						const voucherTypesBlacklist = [
							'September Reactivation',
							'August Reactivation'
						];
						this.backup_voucher_types = response.data.backup_voucher_types.filter(
							(voucher) => {
								return !voucherTypesBlacklist.includes(voucher);
							}
						);
					}
				});
		},
		getBackupVoucherDetails: function () {
			this.progress = true;
			this.backup_voucher_detail = '';
			axios
				.get(`${CONSTANTS.BASE_URL}/api/v1` + '/vouchers/backup_details', {
					params: {
						country: this.preferences.country,
						backup_voucher_type: this.backup_voucher_type,
						leadership_codes: this.is_leadership_code
					}
				})
				.then((response) => {
					if (response.data.msg === 'success') {
						this.backup_voucher_details = response.data.backup_voucher_details;
					}
				});
		},
		tomorrowDate: function () {
			var date = new Date();
			date.setDate(date.getDate() + 1);
			this.tomorrow_date = date.toISOString().substr(0, 10);
		},
		updateJobs() {
			this.jobs = this.jobs.reduce((jobs, job) => {
				if (job.interval === undefined) {
					this.progress = true;
					job.interval = setInterval(() => {
						axios
							.get(`${CONSTANTS.BASE_URL}/api/v1` + '/jobs/' + job.id)
							.then((response) => {
								let progress = response.data.meta.progress;
								if (progress > 0) {
									job.progress = progress;
									job.title = response.data.meta.title;
									job.errors = response.data.meta.errors;
								} else {
									job.progress = 0;
									job.title = 'Waiting for job';
								}

								job.is_failed = response.data.failed;
								if (job.is_failed) {
									job.color = 'error';
									job.title = response.data.error;
									clearInterval(job.interval);
								} else if (job.errors && job.errors.length > 0) {
									job.color = 'warning';
								} else {
									job.color = 'primary';
								}

								if (job.progress >= 100) {
									this.progress = false;
									clearInterval(job.interval);
									job.title = 'Finished';
								}

								this.updateJobs();
							});
					}, 2000);
					this.$set(job, 'progress', -1);
					this.$set(job, 'title', 'Fetching job');
				}
				jobs.push(job);

				return jobs;
			}, []);
		}
	}
};
</script>
