<template>
  <div>
    <v-container
      v-if="
        this.userProfile.admin ||
        this.userProfile.team_leader ||
        this.userProfile.qa_team
      "
      class="pa-0 ma-0"
    >
      <v-row class="ma-3 pa-3" v-for="job in jobs" :key="job.id">
        <v-col cols="2" class="job-title" v-if="updateVouchers === job.id"
          >Uploading Vouchers</v-col
        >
        <v-col cols="3" class="job-title">{{ job.title }}</v-col>
        <v-col cols="8">
          <v-progress-linear
            :indeterminate="job.progress < 0"
            :value="job.progress"
            :color="job.color"
          ></v-progress-linear>
        </v-col>
      </v-row>

      <v-card class="pa-0 ma-0">
        <v-card-title class="headline"
          >Select the voucher settings you are uploading</v-card-title
        >
        <v-card-text>
          <v-form ref="form">
            <v-container grid-list-sm>
              <v-row wrap>
                <v-col cols="2">
                  <v-combobox
                    :items="backup_voucher_types"
                    v-model="backup_voucher_type"
                    label="Voucher Type*"
                    :rules="[
                      (v) => v.length > 0 || 'Please enter a voucher type',
                    ]"
                    @change="getBackupVoucherDetails"
                  ></v-combobox>
                </v-col>
                <v-col cols="2">
                  <v-combobox
                    :items="backup_voucher_details"
                    v-model="backup_voucher_detail"
                    label="Voucher Detail*"
                    :rules="[
                      (v) => v.length > 0 || 'Please enter a voucher detail',
                    ]"
                    @change="getBackupVoucherPopupList"
                  ></v-combobox>
                </v-col>
                <v-col cols="2">
                  <v-combobox
                    :items="['single-use', 'unlimited']"
                    label="Number of uses*"
                    offset-y
                    v-model="number_uses_allowed"
                  ></v-combobox>
                </v-col>
                <v-col cols="3">
                  <v-checkbox
                    v-model="is_leadership_code"
                    label="Are these leadership codes?"
                    @change="getBackupVoucherTypes"
                  >
                  </v-checkbox>
                </v-col>
                <v-col cols="2">
                  <v-menu v-model="date_menu" offset-x>
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        v-model="voucher_expiry_date"
                        v-on="on"
                        label="Expiry date"
                        :rules="[(v) => !!v || 'Please select an expiry date']"
                        readonly
                      ></v-text-field
                    ></template>
                    <v-date-picker
                      v-model="voucher_expiry_date"
                      @input="date_menu = false"
                      :min="tomorrow_date"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12">
                  <v-combobox
                    v-model="backup_voucher_popup"
                    :items="backup_voucher_popup_list"
                    label="Popup voucher instruction*"
                    offset-y
                    :rules="[
                      (v) =>
                        v.length > 0 ||
                        'Please enter a popup voucher instruction',
                    ]"
                  ></v-combobox>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    v-model="voucher_codes"
                    label="Voucher codes* (comma, space or line separated)"
                    :rules="[
                      (v) =>
                        v.length > 0 ||
                        'Please enter one or more voucher codes',
                    ]"
                  ></v-text-field>
                </v-col>
                <v-col cols="3">
                  <v-btn color="primary" @click="uploadBackupVouchers"
                    >Upload Vouchers</v-btn
                  >
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import axios from 'axios';
import auth from '../../modules/auth';
import notifications from '@/modules/notifications';
import {CONSTANTS} from '@/modules/constants';

export default {
	name: 'VoucherUpload',
	data() {
		return {
			preferences: auth.user.preferences,
			userProfile: auth.user.profile,
			backup_voucher_type: '',
			backup_voucher_detail: '',
			backup_voucher_types: [],
			backup_voucher_details: [],
			number_uses_allowed: '',
			backup_voucher_popup: '',
			voucher_expiry_date: null,
			voucher_codes: '',
			date_menu: false,
			tomorrow_date: null,
			jobs: [],
			is_leadership_code: false,
			backup_voucher_popup_list: []
		};
	},
	computed: {
		...mapGetters(['ccAccessLevel'])
	},
	mounted() {
		this.tomorrowDate();
		this.getBackupVoucherTypes();
	},
	methods: {
		uploadBackupVouchers: function () {
			if (this.$refs.form.validate()) {
				this.progress = true;
				axios
					.post(`${CONSTANTS.BASE_URL}/api/v1` + '/vouchers/voucher_upload', {
						country: this.preferences.country,
						backup_voucher_type: this.backup_voucher_type,
						backup_voucher_detail: this.backup_voucher_detail,
						number_uses_allowed: this.number_uses_allowed,
						voucher_expiry_date: this.voucher_expiry_date,
						backup_voucher_popup: this.backup_voucher_popup,
						voucher_codes: this.voucher_codes,
						leadership_codes: this.is_leadership_code
					})
					.then((response) => {
						if (response.data.msg === 'success') {
							this.progress = false;
							this.jobs.push({ id: response.data.job_id });
							this.updateJobs();
							notifications.addNotification(
								`Job to upload ${response.data.num_vouchers_for_upload} vouchers started, look above.`,
								true
							);
						} else if (response.data.msg === 'codes not unique') {
							notifications.addNotification(
								`[Error] List of codes contains duplicates. No vouchers were uploaded. Duplicates: ${response.data.duplicates}`,
								true
							);
							this.progress = false;
						}
					})
					.catch(() => {
						this.progress = false;
						notifications.addNotification(
							'Oops, something went wrong when uploadings your vouchers.',
							true
						);
					});
			}
		},
		getBackupVoucherPopupList: function () {
			this.progress = true;
			axios
				.get(`${CONSTANTS.BASE_URL}/api/v1` + '/vouchers/backup_popups', {
					params: {
						country: this.preferences.country,
						backup_voucher_type: this.backup_voucher_type,
						backup_voucher_detail: this.backup_voucher_detail,
						leadership_codes: this.is_leadership_code
					}
				})
				.then((response) => {
					if (response.data.msg === 'success') {
						this.backup_voucher_popup_list =
              response.data.backup_voucher_popups;
					}
				});
		},
		getBackupVoucherTypes: function () {
			this.progress = true;
			axios
				.get(`${CONSTANTS.BASE_URL}/api/v1` + '/vouchers/backup_types', {
					params: {
						country: this.preferences.country,
						is_leadership_code: this.is_leadership_code
					}
				})
				.then((response) => {
					if (response.data.msg === 'success') {
						this.backup_voucher_types = response.data.backup_voucher_types
							.filter((voucherType) => voucherType[this.ccAccessLevel])
							.map((voucherType) => voucherType.type);
					}
				});
		},
		getBackupVoucherDetails: function () {
			this.progress = true;
			this.backup_voucher_detail = '';
			axios
				.get(`${CONSTANTS.BASE_URL}/api/v1` + '/vouchers/backup_details', {
					params: {
						country: this.preferences.country,
						backup_voucher_type: this.backup_voucher_type,
						leadership_codes: this.is_leadership_code
					}
				})
				.then((response) => {
					if (response.data.msg === 'success') {
						this.backup_voucher_details = response.data.backup_voucher_details;
					}
				});
		},
		tomorrowDate: function () {
			var date = new Date();
			date.setDate(date.getDate() + 1);
			this.tomorrow_date = date.toISOString().substr(0, 10);
		},
		updateJobs() {
			this.jobs = this.jobs.reduce((jobs, job) => {
				if (job.interval === undefined) {
					this.progress = true;
					job.interval = setInterval(() => {
						axios
							.get(`${CONSTANTS.BASE_URL}/api/v1` + '/jobs/' + job.id)
							.then((response) => {
								let progress = response.data.meta.progress;
								if (progress > 0) {
									job.progress = progress;
									job.title = response.data.meta.title;
									job.errors = response.data.meta.errors;
								} else {
									job.progress = 0;
									job.title = 'Waiting for job';
								}

								job.is_failed = response.data.failed;
								if (job.is_failed) {
									job.color = 'error';
									job.title = response.data.error;
									clearInterval(job.interval);
								} else if (job.errors && job.errors.length > 0) {
									job.color = 'warning';
								} else {
									job.color = 'primary';
								}

								if (job.progress >= 100) {
									this.progress = false;
									clearInterval(job.interval);
									job.title = 'Finished';
								}

								this.updateJobs();
							});
					}, 2000);
					this.$set(job, 'progress', -1);
					this.$set(job, 'title', 'Fetching job');
				}
				jobs.push(job);

				return jobs;
			}, []);
		}
	}
};
</script>
