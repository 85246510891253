<template>
<!--  <div>-->
    <div class="login">
    <v-row>
      <v-col cols="12" xs="12" sm="6" offset-sm="3">
        <img src="../../assets/logo-neutral.png" id="title">

        <v-card flat id="card" color="transparent">
          <v-card-title>
            <div class="text">
              <v-btn block color="primary" v-on:click="login" id="connect" :loading="loading" data-cy="login-button">Log in</v-btn>
            </div>
          </v-card-title>
        </v-card>
      </v-col>
    </v-row>
<!--    <vue-particles-->
<!--      color="#8bcd57"-->
<!--      :particleOpacity="0.5"-->
<!--      :particlesNumber=20-->
<!--      shapeType="edge"-->
<!--      :particleSize="20"-->
<!--      linesColor="#669933"-->
<!--      :linesWidth="4"-->
<!--      :lineLinked="true"-->
<!--      :lineOpacity="0.4"-->
<!--      :linesDistance="10"-->
<!--      :moveSpeed="3"-->
<!--      :hoverEffect="true"-->
<!--      hoverMode="grab"-->
<!--      :clickEffect="true"-->
<!--      clickMode="repulse"-->
<!--    >-->
    <Snow
      :active='true'
      :zIndex="'2'"
      :wind='1'
      :swing='1'
      :speed="'m'"
      color="#000000">
    </Snow>
  </div>
</template>

<script>
import auth from '../../modules/auth/index';
import Snow from 'vue-niege'

export default {
	name: 'Login',
	components: {Snow},
	data() {
		return {
			loading: false
		}
	},
	mounted() {
		this.loading = true;
		auth.checkAuth().then(() => {
			this.$router.replace('/home');
			this.loading = false;
		}).catch(() => {
			this.loading = false;
		});
	},
	methods: {
		async login() {
			auth.login(this);
		}
	}
}
</script>

<style scoped>
  .login {
    width: 100%;
    text-align: center;
  }

  #title {
    width: 600px;
    max-width: 90%;
    margin-top: 2em;
  }

  #card {
    margin: 4em;
  }

  .text {
    width: 500px;
    max-width: 90%;
    margin: auto;
    text-align: left;
  }

  #connect {
    margin-top: 2em;
    margin-bottom: 2em;
  }

    #particles-js {
    position: absolute;
    background-size: cover;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    overflow-y: hidden;
    z-index: 0;
  }
</style>
