<template>
  <div class="windows">
    <v-container class="pb-0">
      <v-row>
        <v-col cols="10">
          <h1 class="display-3 mb-4">Food Safety Issues</h1>
        </v-col>
        <v-col cols="2" class="mt-4">
          <v-btn
            color="primary"
            block
            :disabled="foodSafetyIssues.length === 0"
            @click="downloadFoodSafetyIssues"
          >
            <v-icon left dark>cloud_download</v-icon>
            Export
          </v-btn>
        </v-col>
        <v-col cols="12">
          <v-tabs
            v-model="tab"
            background-color="transparent"
            color="primary"
            grow
          >
            <v-tab>
              Current Issues
            </v-tab>
            <v-tab>
              Archived Issues
            </v-tab>
            <v-tab>
              Food Safety Summary
            </v-tab>
          </v-tabs>
          <v-tabs-items v-model="tab">
            <v-tab-item>
              <v-card>
                <v-card-title class="pb-0">
                  <v-row>
                    <v-col cols="3">
                      <v-text-field
                        v-model="currentIssuesSearch"
                        append-icon="search"
                        label="Search"
                        single-line
                        hide-details
                      ></v-text-field>
                      <v-subheader class="right pr-1">
                        {{ "Completion rate : " + completionRate + " %" }}
                      </v-subheader>
                    </v-col>

                    <v-col
                      cols="1"
                      class="pt-6 pr-4"
                      style="text-align: right;"
                    >
                      <v-fade-transition leave-absolute>
                        <v-progress-circular
                          v-if="progress"
                          size="24"
                          color="secondary"
                          :width="3"
                          indeterminate
                        ></v-progress-circular>
                      </v-fade-transition>
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col cols="2" class="subtitle-2 ma-0">
                      <v-switch
                        label="show completed"
                        v-model="showCompleted"
                        color="secondary"
                        hide-details
                      ></v-switch>
                    </v-col>
                    <v-col cols="2" class="subtitle-2 ma-0">
                      <v-switch
                        label="show unanswered"
                        v-model="showUnanswered"
                        color="secondary"
                        hide-details
                      ></v-switch>
                    </v-col>
                  </v-row>
                </v-card-title>
                <v-data-table
                  :headers="headers"
                  :items="filteredFoodSafetyIssues"
                  item-key="box"
                  sort-by="input_time"
                  sort-desc
                  :search="currentIssuesSearch"
                  multi-sort
                  :footer-props="{
                    itemsPerPageOptions: [50],
                    showFirstLastPage: true,
                  }"
                  :items-per-page="50"
                  fixed-header
                  :height="tableHeight"
                  :key="dataTableKey"
                  :no-data-text="'No data found'"
                >
                  <template v-slot:body="{ items }">
                    <tbody>
                      <tr
                        :class="
                          preferences.country === 'au'
                            ? 'grey lighten-3'
                            : 'grey'
                        "
                      >
                        <td>
                          <v-icon>filter_list</v-icon>
                        </td>
                        <td
                          v-for="(header, index) in headers.slice(1)"
                          :key="index"
                        >
                          <div v-if="filters.hasOwnProperty(header.value)">
                            <v-select
                              text
                              hide-details
                              small
                              multiple
                              clearable
                              :items="columnValueList(header.value)"
                              v-model="filters[header.value]"
                            ></v-select>
                          </div>
                        </td>
                      </tr>

                      <tr v-for="(item, index) in items" :key="index">
                        <td>
                          {{ item.input_time }}
                        </td>
                        <td>{{ item.week }}</td>
                        <td>
                          <v-btn
                            text
                            small
                            :to="{
                              name: 'box',
                              params: {
                                id: item.box_id,
                              },
                            }"
                            class="font-weight-medium"
                            :color="item.alert ? 'error' : 'secondary'"
                          >
                            {{ item.box_id }}
                          </v-btn>
                        </td>

                        <!--                    <v-spacer></v-spacer>-->
                        <!--                  </div>-->
                        <!--                  <span class="caption font-weight-light pl-3">-->
                        <!--                    {{props.item.week}}-->
                        <!--                  </span>-->

                        <td class="px-0">
                          <div class="pt-2">
                            <span type="html">
                              {{
                                item.food_safety_type.charAt(0).toUpperCase() +
                                item.food_safety_type
                                  .slice(1)
                                  .replace(/\_/g, " ") +
                                " - "
                              }}
                              <!--                       <b>{{props.item.input_time}}</b>-->
                            </span>
                          </div>
                          <div
                            class="pt-1"
                            style="max-width: 300px; overflow: hidden;"
                          >
                            <span class="caption font-weight-light">{{
                              item.recipe_name
                            }}</span>
                          </div>
                        </td>
                        <td class="text-center">
                          <v-icon v-if="item.answered" small color="secondary"
                            >done</v-icon
                          >
                          <v-icon v-else-if="item.alert" color="error"
                            >error_outline</v-icon
                          >
                          <v-icon v-else small color="error">clear</v-icon>
                        </td>
                        <td class="text-center">
                          <v-checkbox
                            color="secondary"
                            v-model="item.qa_reviewed"
                            @change="saveFoodSafetyComment(item, false)"
                          ></v-checkbox>
                        </td>
                        <td style="min-width: 300px;">
                          <v-textarea
                            @input="saveFoodSafetyComment(item, false)"
                            name="input-7-1"
                            :label="
                              item.qa_comment === '' ? 'Enter your comment' : ''
                            "
                            v-model="item.qa_comment"
                            auto-grow
                            rows="1"
                            :rules="[rules.length]"
                            counter="255"
                            :hint="`${
                              item.qa_agent !== null
                                ? item.qa_agent + ', ' + item.qa_update_time
                                : ''
                            }`"
                            persistent-hint
                          ></v-textarea>
                        </td>
                        <td class="text-center">
                          <v-checkbox
                            color="secondary"
                            v-model="item.cc_reviewed"
                            @change="saveFoodSafetyComment(item, true)"
                          ></v-checkbox>
                        </td>
                        <td style="min-width: 300px;">
                          <v-textarea
                            @input="saveFoodSafetyComment(item, true)"
                            name="input-7-1"
                            :label="
                              item.cc_comment === '' ? 'Enter your comment' : ''
                            "
                            v-model="item.cc_comment"
                            auto-grow
                            rows="1"
                            :rules="[rules.length]"
                            counter="255"
                            :hint="`${
                              item.cc_agent !== null
                                ? item.cc_agent + ', ' + item.cc_update_time
                                : ''
                            }`"
                            persistent-hint
                          ></v-textarea>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                  <v-alert
                    slot="no-data"
                    :value="true"
                    color="warning"
                    icon="warning"
                    >No food safety issues for this week.</v-alert
                  >
                  <v-alert
                    slot="no-results"
                    :value="true"
                    color="error"
                    icon="warning"
                    >Your search for "{{ currentIssuesSearch }}" found no
                    results.</v-alert
                  >
                </v-data-table>
              </v-card>
            </v-tab-item>

            <v-tab-item>
              <v-card>
                <v-card-title class="pb-0">
                  <v-row>
                    <v-col cols="3">
                      <v-text-field
                        v-model="archivedIssuesSearch"
                        append-icon="search"
                        label="Search"
                        single-line
                        hide-details
                      ></v-text-field>
                    </v-col>

                    <v-col
                      cols="1"
                      class="pt-6 pr-4"
                      style="text-align: right;"
                    >
                      <v-fade-transition leave-absolute>
                        <v-progress-circular
                          v-if="progress"
                          size="24"
                          color="secondary"
                          :width="3"
                          indeterminate
                        ></v-progress-circular>
                      </v-fade-transition>
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col cols="2" class="subtitle-2 ma-0">
                      <v-switch
                        label="show completed"
                        v-model="showArchiveCompleted"
                        color="secondary"
                        hide-details
                      ></v-switch>
                    </v-col>
                    <v-col cols="2" class="subtitle-2 ma-0">
                      <v-switch
                        label="show unanswered"
                        v-model="showArchiveUnanswered"
                        color="secondary"
                        hide-details
                      ></v-switch>
                    </v-col>
                  </v-row>
                </v-card-title>
                <v-data-table
                  :headers="headers"
                  :items="filteredArchivedIssues"
                  item-key="box"
                  sort-by="input_time"
                  sort-desc
                  :search="archivedIssuesSearch"
                  multi-sort
                  :footer-props="{
                    itemsPerPageOptions: [50],
                    showFirstLastPage: true,
                  }"
                  :items-per-page="50"
                  fixed-header
                  :height="tableHeight"
                  :key="dataTableKey"
                  :no-data-text="'No data found'"
                >
                  <template v-slot:body="{ items }">
                    <tbody>
                      <tr
                        :class="
                          preferences.country === 'au'
                            ? 'grey lighten-3'
                            : 'grey'
                        "
                      >
                        <td>
                          <v-icon>filter_list</v-icon>
                        </td>
                        <td
                          v-for="(header, index) in headers.slice(1)"
                          :key="index"
                        >
                          <div
                            v-if="archivedFilters.hasOwnProperty(header.value)"
                          >
                            <v-select
                              text
                              hide-details
                              small
                              multiple
                              clearable
                              :items="archivedColumnValueList(header.value)"
                              v-model="archivedFilters[header.value]"
                            ></v-select>
                          </div>
                        </td>
                      </tr>

                      <tr v-for="(item, index) in items" :key="index">
                        <td>
                          {{ item.input_time }}
                        </td>
                        <td>{{ item.week }}</td>
                        <td>
                          <v-btn
                            text
                            small
                            :to="{
                              name: 'box',
                              params: {
                                id: item.box_id,
                              },
                            }"
                            class="font-weight-medium"
                            :color="item.alert ? 'error' : 'secondary'"
                          >
                            {{ item.box_id }}
                          </v-btn>
                        </td>
                        <td class="px-0">
                          <div class="pt-2">
                            <span type="html">
                              {{
                                item.food_safety_type.charAt(0).toUpperCase() +
                                item.food_safety_type
                                  .slice(1)
                                  .replace(/\_/g, " ") +
                                " - "
                              }}
                            </span>
                          </div>
                          <div
                            class="pt-1"
                            style="max-width: 300px; overflow: hidden;"
                          >
                            <span class="caption font-weight-light">{{
                              item.recipe_name
                            }}</span>
                          </div>
                        </td>
                        <td class="text-center">
                          <v-icon v-if="item.answered" small color="secondary"
                            >done</v-icon
                          >
                          <v-icon v-else-if="item.alert" color="error"
                            >error_outline</v-icon
                          >
                          <v-icon v-else small color="error">clear</v-icon>
                        </td>
                        <td class="text-center">
                          <v-checkbox
                            color="secondary"
                            v-model="item.qa_reviewed"
                            @change="saveFoodSafetyComment(item, false)"
                          ></v-checkbox>
                        </td>
                        <td style="min-width: 300px;">
                          <v-textarea
                            @input="saveFoodSafetyComment(item, false)"
                            name="input-7-1"
                            :label="
                              item.qa_comment === '' ? 'Enter your comment' : ''
                            "
                            v-model="item.qa_comment"
                            auto-grow
                            rows="1"
                            :rules="[rules.length]"
                            counter="255"
                            :hint="`${
                              item.qa_agent !== null
                                ? item.qa_agent + ', ' + item.qa_update_time
                                : ''
                            }`"
                            persistent-hint
                          ></v-textarea>
                        </td>
                        <td class="text-center">
                          <v-checkbox
                            color="secondary"
                            v-model="item.cc_reviewed"
                            @change="saveFoodSafetyComment(item, true)"
                          ></v-checkbox>
                        </td>
                        <td style="min-width: 300px;">
                          <v-textarea
                            @input="saveFoodSafetyComment(item, true)"
                            name="input-7-1"
                            :label="
                              item.cc_comment === '' ? 'Enter your comment' : ''
                            "
                            v-model="item.cc_comment"
                            auto-grow
                            rows="1"
                            :rules="[rules.length]"
                            counter="255"
                            :hint="`${
                              item.cc_agent !== null
                                ? item.cc_agent + ', ' + item.cc_update_time
                                : ''
                            }`"
                            persistent-hint
                          ></v-textarea>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                  <v-alert
                    slot="no-data"
                    :value="true"
                    color="warning"
                    icon="warning"
                    >No food safety issues for this week.</v-alert
                  >
                  <v-alert
                    slot="no-results"
                    :value="true"
                    color="error"
                    icon="warning"
                    >Your search for "{{ archivedIssuesSearch }}" found no
                    results.</v-alert
                  >
                </v-data-table>
              </v-card>
            </v-tab-item>
            <v-tab-item>
              <food-safety-summary />
            </v-tab-item>
          </v-tabs-items>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import axios from 'axios';
import { debounce, partition } from 'lodash';
import moment from 'moment';
import auth from '../../modules/auth';
import notifications from '@/modules/notifications';
import FoodSafetySummary from '@/components/errors/FoodSafetySummary';
import {CONSTANTS} from '@/modules/constants';

export default {
	name: 'FoodSafetyIssues',
	components: { FoodSafetySummary },
	data() {
		return {
			progress: false,
			weeksProgress: true,
			preferences: auth.user.preferences,
			dataTableKey: 0,
			tab: null,
			currentIssuesSearch: '',
			archivedIssuesSearch: '',
			foodSafetyIssues: [],
			archivedFoodSafetyIssues: [],
			headers: [
				{ text: 'Input Date', align: 'left', value: 'input_time' },
				{ text: 'Week', align: 'left', value: 'week' },
				{ text: 'Box ID', align: 'left', value: 'box_id' },
				{ text: 'Issue', value: 'food_safety_type', align: 'left' },
				{ text: 'Answered', value: 'answered', align: 'left' },
				{ text: 'QA Reviewed', value: 'qa_reviewed', align: 'left' },
				{ text: 'QA Comment', value: 'qa_comment', align: 'left' },
				{ text: 'CC Reviewed', value: 'cc_reviewed', align: 'left' },
				{ text: 'CC Comment', value: 'cc_comment', align: 'left' }
			],
			rules: {
				length: (v) => (v || '').length <= 255 || `Maximum 255 characters`
			},
			// pagination: {
			//     sortBy: 'input_time',
			//     descending: true
			// },
			filters: {
				week: [],
				box_id: [],
				food_safety_type: [],
				answered: [],
				qa_reviewed: [],
				qa_comment: [],
				cc_reviewed: [],
				cc_comment: []
			},
			archivedFilters: {
				week: [],
				box_id: [],
				food_safety_type: [],
				answered: [],
				qa_reviewed: [],
				qa_comment: [],
				cc_reviewed: [],
				cc_comment: []
			},
			showCompleted: false,
			showUnanswered: false,
			showArchiveCompleted: false,
			showArchiveUnanswered: false
		};
	},
	computed: {
		completionRate: function () {
			let completionRate = 0;
			this.foodSafetyIssues.forEach((issue) => {
				if (issue.answered) {
					completionRate += 1;
				}
			});
			return this.foodSafetyIssues.length > 0
				? Math.round((completionRate * 100) / this.foodSafetyIssues.length)
				: '-';
		},
		filteredFoodSafetyIssues() {
			// this.forceReRender();
			let temp = this.foodSafetyIssues.filter((foodSafetyIssue) => {
				return Object.keys(this.filters).every((filter) => {
					return (
						this.filters[filter].length < 1 ||
            this.filters[filter].includes(foodSafetyIssue[filter])
					);
				});
			});
			if (this.showCompleted === false) {
				temp = temp.filter((tem) => {
					return tem.qa_reviewed === false || tem.cc_reviewed === false;
				});
			}
			if (this.showUnanswered) {
				return temp;
			} else {
				return temp.filter((tem) => {
					return tem.answered || tem.alert;
				});
			}
		},
		filteredArchivedIssues() {
			// this.forceReRender();
			let temp = this.archivedFoodSafetyIssues.filter((archivedIssue) => {
				return Object.keys(this.archivedFilters).every((filter) => {
					return (
						this.archivedFilters[filter].length < 1 ||
            this.archivedFilters[filter].includes(archivedIssue[filter])
					);
				});
			});
			if (this.showArchiveCompleted === false) {
				temp = temp.filter((tem) => {
					return tem.qa_reviewed === false || tem.cc_reviewed === false;
				});
			}
			if (this.showArchiveUnanswered) {
				return temp;
			} else {
				return temp.filter((tem) => {
					return tem.answered || tem.alert;
				});
			}
		},
		tableHeight() {
			let rows = this.filteredFoodSafetyIssues.length;
			return rows < (window.innerHeight * 0.7 - 142) / 38
				? (142 + rows * 38).toString() + 'px'
				: window.innerHeight * 0.7;
		}
	},
	mounted() {
		this.setTableHeaderColor();
		this.getFoodSafetyIssues();
	},
	watch: {
		filterByWeek: function () {
			this.getFoodSafetyIssues();
		},
		showCompleted: function () {
			this.filteredFoodSafetyIssues;
		},
		showArchiveCompleted: function () {
			this.filteredArchivedIssues();
		},
		showUnanswered: function () {
			this.filteredFoodSafetyIssues;
		},
		showArchiveUnanswered: function () {
			this.filteredArchivedIssues();
		}
	},
	methods: {
		changeSort(column) {
			if (this.pagination.sortBy === column) {
				this.pagination.descending = !this.pagination.descending;
			} else {
				this.pagination.sortBy = column;
				this.pagination.descending = false;
			}
		},
		columnValueList(val) {
			return this.foodSafetyIssues.map((d) => d[val]);
		},
		archivedColumnValueList(val) {
			return this.archivedFoodSafetyIssues.map((d) => d[val]);
		},
		forceReRender() {
			this.dataTableKey += 1;
		},
		setTableHeaderColor: function () {
			let d = document.getElementsByTagName('th');
			for (let element of d) {
				element.className =
          this.preferences.country === 'au' ? 'thead-au' : 'thead-nz';
			}
		},
		getFoodSafetyIssues: function () {
			this.progress = true;
			axios
				.get(`${CONSTANTS.BASE_URL}/api/v1` + '/food_safety', {
					params: {
						country: this.preferences.country
					}
				})
				.then((response) => {
					const { msg, food_safety_issues } = response.data;
					if (msg === 'success') {
						const threeMonthsAgo = moment().subtract(3, 'months');
						const [currentIssues, archivedIssues] = partition(
							food_safety_issues,
							(issue) => {
								return moment(issue.input_time) > threeMonthsAgo;
							}
						);
						this.foodSafetyIssues = currentIssues;
						this.archivedFoodSafetyIssues = archivedIssues;
						this.progress = false;
					}
				})
				.catch(() => {
					this.foodSafetyIssues = [];
					this.progress = false;
					notifications.addNotification(
						'Oups, error when getting food safety issues..',
						true
					);
				});
		},
		saveFoodSafetyComment: function (foodSafetyIssue, reviewByCC) {
			this.progress = true;
			this.updateFoodSafetyIssueComment(foodSafetyIssue, reviewByCC);
		},
		updateFoodSafetyIssueComment: debounce(function (
			foodSafetyIssue,
			reviewByCC
		) {
			if (
				reviewByCC
					? foodSafetyIssue.cc_comment.length <= 255
					: foodSafetyIssue.qa_comment.length <= 255
			) {
				axios
					.post(
						`${CONSTANTS.BASE_URL}/api/v1` +
              '/food_safety/' +
              foodSafetyIssue.box_id +
              '/comment',
						{
							comment: reviewByCC
								? foodSafetyIssue.cc_comment
								: foodSafetyIssue.qa_comment,
							reviewed: reviewByCC
								? foodSafetyIssue.cc_reviewed
								: foodSafetyIssue.qa_reviewed,
							is_reviewed_from_cc: reviewByCC
						}
					)
					.then((response) => {
						if (response.data.msg === 'success') {
							this.successMessage =
                'Food safety issue for box ' +
                foodSafetyIssue.box_id +
                ' updated !';
							this.progress = false;
							this.getFoodSafetyIssues();
						}
					});
			}
		},
		1200),
		downloadFoodSafetyIssues: function () {
			this.progress = true;
			axios({
				url: `${CONSTANTS.BASE_URL}/api/v1` + '/food_safety/download',
				method: 'GET',
				responseType: 'blob',
				params: {
					country: this.preferences.country,
					week: this.selectedWeek,
					all: !this.filterByWeek
				}
			})
				.then((response) => {
					const url = window.URL.createObjectURL(new Blob([response.data]));
					const link = document.createElement('a');
					link.href = url;
					link.setAttribute(
						'download',
						'Food safety issues - ' +
              this.preferences.country.toUpperCase() +
              ' - ' +
              (this.filterByWeek ? this.selectedWeek : 'all weeks') +
              '.csv'
					);
					document.body.appendChild(link);
					link.click();
					this.progress = false;
				})
				.catch(() => {
					this.progress = false;
				});
		}
	}
};
</script>

<style scoped>
td {
  white-space: nowrap;
}
>>> .thead-au {
  background-color: #8bcd57 !important;
}
>>> .thead-nz {
  background-color: #c180ff !important;
}
.v-checkbox {
  margin: 8px;
}
</style>
