<template>
  <div>
    <v-container>
      <v-row>
        <v-col cols="12">
          <h1 class="display-3">SuperBob Changes</h1>
        </v-col>
        <v-col cols="12" class="mt-12">
          <v-card>
            <v-card-title primary-title class="primary">
                <h3 class="headline">Refund after credit</h3>
            </v-card-title>
            <v-card-text class="pt-4">
              We credit customers when an error happened. But sometimes, those customers want a <b>refund</b> instead,
              and the credit has already been applied. In this case, we need someone with SuperBob accesses to remove
              the credit and refund the box, without error tracking again the refund, as it has already been done for
              the credit.
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn text color="primary" :href="refund_after_credit_link" target="_blank">Ask for one!</v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import auth from '../../modules/auth';

export default {
	name: 'RefundHandbook',
	data() {
		return {
			preferences: auth.user.preferences
		}
	},
	computed: {
		refund_after_credit_link() {
			const { country } = this.preferences
			if (country === 'au') {
				return 'https://docs.google.com/forms/d/e/1FAIpQLScD3V8niJteJlHdJFRttXRNiYkmwEjzxe9hnJ-J_cats5Y13Q/viewform?vc=0&c=0&w=1';
			} else if (country === 'nz') {
				return 'https://docs.google.com/forms/d/e/1FAIpQLSfQ7o9Pbz9KCVccpYjybdRom-CC0Od1mH0qzNvdD-_wgKth2A/viewform?vc=0&c=0&w=1';
			} else if (country === 'ao') {
				return 'https://docs.google.com/forms/d/10JewPkP8hJ5eLx5OJHCXxdo2Uf4WK3VfrtZrile1f2M/viewform?edit_requested=true'
			}
		}
	}
}
</script>

<style scoped>
</style>
