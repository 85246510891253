<template lang="html">
  <div class="documentation">
    <v-card>
      <img
        v-if="preferences.country === 'au'" src="../assets/australia-header.jpg" alt="australia_background"
        class="background-img"
      >
      <img v-else src="../assets/new-zealand-header.jpg" alt="new_zealand_background" class="background-img">
      <v-card-title secondary-title color="primary" class="py-2">
        <div style="display: contents">
          <div class="headline">{{documentation.title !== undefined ? documentation.title : 'Sentinel'}}</div>
          <v-spacer></v-spacer>
          <span class="grey--text pt-1" style="text-align: right">
            {{documentation.sub_title !== undefined ? documentation.sub_title : 'No documentation for this page'}}
          </span>
        </div>
      </v-card-title>
      <v-divider v-if="documentation.title !== undefined" class="mx-4 grey lighten-1"></v-divider>
      <v-card-text
        v-if="documentation.title !== undefined" v-html="documentation.description" style="text-align: justify;"
      ></v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="closePopIn()">Got it</v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import auth from '@/modules/auth';
import axios from 'axios';
import {CONSTANTS} from '@/modules/constants';

export default {
	name: 'Documentation',
	data() {
		return {
			preferences: auth.user.preferences,
			documentation: {}
		}
	},
	props: {
		closePopIn: Function
	},
	mounted() {
		this.getDocumentation()
	},
	watch: {
		'$route.name': function() {
			this.getDocumentation()
		}
	},
	methods: {
		getDocumentation: function () {
			axios.get(`${CONSTANTS.BASE_URL}/api/v1` + '/documentation?path=' + this.$route.name).then((response) => {
				if (response.data.msg === 'success') {
					this.documentation = response.data.documentation;
				}
			});
		}
	}
}
</script>

<style scoped>

  .background-img {
    width: 500px;
    height: auto;
  }
</style>
