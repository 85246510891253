<template>
  <div>
    <v-progress-linear indeterminate v-if="progress" color="secondary" class="progress"></v-progress-linear>
    <v-container grid-list-sm>
      <v-row>
        <v-col cols="12">
          <h1 class="display-3">Hot Topic Forms</h1>
        </v-col>
        <v-col cols="12" sm="6">
          <v-autocomplete
            v-model="issue_name"
            label="Select form"
            :items="issues"
            @change="getResponses">
          </v-autocomplete>
        </v-col>
        <v-col v-if="userProfile.team_leader || userProfile.admin" cols="12" sm="6" md="2">
          <v-switch
            v-model="leadership_issues_only"
            label="Hide agent forms"
            color="primary"
            @change="issue_name = ''; getIssues()"
          ></v-switch>
        </v-col>
        <v-col cols="12" sm="6" md="2">
          <v-dialog
            v-if="userProfile.team_leader || userProfile.admin"
            max-width="1200px"
            v-model="newIssueDialog">
            <template v-slot:activator="{ on }">
              <v-btn raised dark color="primary" v-on="on" class="ma-2">Add New Form</v-btn>
            </template>
            <v-card>
              <live-data-new-issue @newIssueSubmitted="getIssues(); newIssueDialog=false"></live-data-new-issue>
            </v-card>
          </v-dialog>
          <v-dialog
            v-if="(userProfile.team_leader || userProfile.admin) && issue_name != ''"
            max-width="1200px"
            v-model="editIssueDialog">
            <template v-slot:activator="{ on }">
              <v-btn raised dark color="secondary" v-on="on" class="ma-2">Edit Form</v-btn>
            </template>
            <v-card>
              <live-data-edit-issue
                :issue_name="issue_name"
                @issueUpdated="getIssues(); editIssueDialog = false; field_update = true"></live-data-edit-issue>
            </v-card>
          </v-dialog>
        </v-col>
        <v-col cols="12" sm="6" md="2">
          <v-dialog v-if="(userProfile.team_leader || userProfile.admin) && issue_name != ''" max-width="600px"
                    v-model="deleteIssueDialog">
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" raised dark color="error" class="ma-2">
                Delete Form
              </v-btn>
            </template>
            <v-card>
              <v-card-title class="heading">
                Delete {{issue_name}}
              </v-card-title>
              <v-card-text>Are you sure you want to delete this form?</v-card-text>
              <v-card-actions class="justify-center">
                <v-btn text color="error" @click="removeIssue(); deleteIssueDialog = false">Delete Form</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-col>
      </v-row>
    </v-container>
    <v-expansion-panels v-if="issue_name" popout>
      <v-expansion-panel>
        <v-expansion-panel-header class="headline pa-3" expand-icon="fas fa-plus">Submit Response
        </v-expansion-panel-header>
        <v-expansion-panel-content class="pa-0 ma-0">
          <live-data-form v-if="issue_name !== ''"
                          :issue_name="issue_name"
                          @formSubmitted="getResponses"
                          @fieldsRefreshed="field_update = false"
                          showHeader
          />
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel v-if="accessFormResponses">
        <v-expansion-panel-header class="headline pa-3" expand-icon="fas fa-plus">View Form Responses
        </v-expansion-panel-header>
        <v-expansion-panel-content class="pa-0 ma-0">
          <live-data-display :key="live_data_display_key"
                             :issue_name="issue_name"
                             :form_responses="form_responses"
                             :display_fields="display_fields"
                             :filters="filters"
                             :loading-responses="getResponsesProgress"
                             @rowUpdate="updateFrontFormResponse"
          />
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>

  </div>
</template>

<script>
import axios from 'axios';
import auth from '../../modules/auth';
import notifications from '@/modules/notifications';
import LiveDataDisplay from './LiveDataDisplay';
import LiveDataNewIssue from './LiveDataNewIssue';
import LiveDataForm from './LiveDataForm';
import LiveDataEditIssue from './LiveDataEditIssue';
import {CONSTANTS} from '@/modules/constants';

export default {
	name: 'LiveDataCollection',
	components: {
		LiveDataDisplay: LiveDataDisplay,
		LiveDataNewIssue: LiveDataNewIssue,
		LiveDataForm: LiveDataForm,
		LiveDataEditIssue: LiveDataEditIssue
	},
	data() {
		return {
			preferences: auth.user.preferences,
			userProfile: auth.user.profile,
			progress: false,
			newIssueDialog: false,
			deleteIssueDialog: false,
			editIssueDialog: false,
			issues: [],
			issue_name: '',
			responses_issue_name: '',
			issue_description: '',
			customer_id: '',
			leadership_issues_only: false,
			rules: {
				'customer id': [v => !isNaN(v) || 'A customer id is required', v => v.length > +5 || 'A customer id is required'],
				'date': [v => v.length === 10 || 'date format: yyyy-mm-dd', v => v[4] === '-' || 'date format yyyy-mm-dd', v => v[7] === '-' || 'date format yyyy-mm-dd'],
				'time': [v => v.length === 5 || '24 hr: hh:mm', v => v[2] === ':' || '24 hr: hh:mm', v => !isNaN(v.substring(0, 2)) && v.substring(0, 2) < 24 || '24 hr: hh:mm',
					v => !isNaN(v.substring(3, 5)) && v.substring(3, 5) < 60 || '24 hr: hh:mm'],
				'number': [v => !isNaN(v) || 'Enter a number', v => v.length > 0 || 'Enter a number'],
				'text': []
			},
			form_fields: [],
			form_responses: [{}],
			display_fields: [],
			field_update: false,
			live_data_display_key: 0,
			getResponsesProgress: false
		}
	},
	created() {
		this.getIssues()
		this.$store.dispatch('resetWeek')
	},
	watch: {
		form_fields: function () {
			this.form_input = {}
			for (let field of this.form_fields) {
				this.form_input[field['name']] = ''
			}
		},
		week: function () {
			this.getResponses()
		}
	},
	computed: {
		filters: function () {
			let output = {}
			for (let display_field of this.display_fields) {
				output[display_field['name']] = []
			}
			return output
		},
		accessFormResponses() {
			return this.userProfile.team_leader || this.userProfile.admin || this.userProfile.beta_test
		},
		week() {
			return this.$store.state.week
		}
	},
	methods: {
		getIssues: function () {
			this.progress = true;
			axios.get(`${CONSTANTS.BASE_URL}/api/v1` + '/issues/issue_names', {
				params: {
					country: this.preferences.country,
					leadership_only: this.leadership_issues_only,
					team_leader: this.userProfile.team_leader
				}
			}).then((response) => {
				if (response.data.msg === 'success') {
					this.issues = response.data.issues
					this.progress = false;

				}
			})
		},
		getResponses: function () {
			if (this.accessFormResponses) {
				this.getResponsesProgress = true;
				axios.get(`${CONSTANTS.BASE_URL}/api/v1` + '/issues/responses', {
					params: {
						country: this.preferences.country,
						issue_name: this.issue_name,
						week: this.week
					}
				})
					.then((response) => {
						if (response.data.msg === 'success') {
							this.getResponsesProgress = false;
							this.form_responses = response.data.form_responses
							this.display_fields = response.data.display_fields
							this.live_data_display_key++ //re-renders live-data-display component to ensure reactivity
						}
					})
					.catch(() => {
						notifications.addNotification(`Oops, there was a problem getting the form responses`, true)
					})
			}
		},
		removeIssue() {
			this.progress = true;
			axios.post(`${CONSTANTS.BASE_URL}/api/v1` + '/issues/remove', {
				issue_name: this.issue_name,
				country: this.preferences.country
			})
				.then((response) => {
					if (response.data.msg === 'success') {
						notifications.addNotification(`Form deleted successfully`, true)
						this.issue_name = ''
						this.getIssues()
					}
				})
		},
		updateFrontFormResponse(row, updatedField, updatedValue) {
			let row_number = this.form_responses.indexOf(row)
			this.form_responses[row_number][updatedField] = updatedValue
		}
	}
}
</script>

<style scoped>
  /*v-expansion-panel-content {*/
  /*  padding-left: 0px !important;*/
  /*  padding-right: 0px !important;*/
  /*  padding-bottom: 0px !important;}*/
</style>
