<template>
  <div v-if="sub_info">
    <v-dialog v-model="create_lmc" max-width="1100px">
      <v-card>
        <v-card-title class="secondary">
          <span class="headline" v-if="lmc_present">Update a LMC</span>
          <span class="headline" v-else>Create LMC</span>
        </v-card-title>
        <v-card-text>
          <v-form ref="lmc_form" lazy-validation>
            <v-container grid-list-md>
              <v-row v-for="(box, index) in lmc_info.boxes" :key="index" class="text-justify">
                <v-card-title>
                  <span class="pr-4">{{ box.id }}</span>
                  <span class="pr-4"> Type: {{ box.type }}</span>
                  <span class="pr-4"> Value: ${{ box.value }}</span>
                </v-card-title>
                <v-col cols="8" class="pa-0 pl-8 pr-8">
                  <v-select
                    :items="compensations"
                    item-value="id"
                    item-text="name"
                    v-model="new_lmc.compensation_type_id"
                    label="Compensation type"
                    :loading="progress_compensations"
                    :rules="[(v) => !!v || 'A compensation is required']"
                    required
                  />
                </v-col>
                <v-col cols="4" class="pa-0 pl-8 pr-8">
                  <v-text-field
                    prefix="$"
                    label="Amount"
                    v-model="box.compensation_sum"
                    required
                    :rules="[(v) => !!v || 'An amount is required']"
                  />
                </v-col>
                <v-col cols="8" class="pa-0 pl-8 pr-8">
                  <v-select
                    v-if="preferences.country === 'ao'"
                    :items="compensation_type_credit"
                    item-value="id"
                    item-text="name"
                    v-model="new_lmc.compensation_type_id_2"
                    label="2nd compensation type"
                    :loading="progress_compensations"
                  />
                </v-col>
                <v-col cols="4" class="pa-0 pl-8 pr-8">
                  <v-text-field
                    prefix="$"
                    v-if="preferences.country === 'ao'"
                    label="Amount"
                    v-model="box.compensation_sum_2"
                  />
                </v-col>
                <v-switch required label="Compensation applied" v-model="new_lmc.compensation_applied"></v-switch>
              </v-row>
              <v-row wrap>
                <v-select
                  :items="reasons"
                  item-value="id"
                  item-text="name"
                  v-model="new_lmc.reason_id"
                  label="Reason"
                  :loading="progress_reasons"
                  :rules="[(v) => !!v || 'A reason is required']"
                  required
                ></v-select>
              </v-row>
              <v-row>
                <v-text-field label="Comments" v-model="new_lmc.comment"></v-text-field>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn color="error" text @click.native="deleteLMC" v-if="lmc_present">Delete</v-btn>
          <v-spacer></v-spacer>
          <v-btn text @click.native="resetLMCForm">Close</v-btn>
          <v-btn color="primary" @click.native="createLMC">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-col cols="12" key="lmc" v-if="lmc_present || lmc_auth">
      <v-card class="lmc-card">
        <v-card-title primary-title class="warning">
          <h3 class="display-1 font-weight-bold" v-if="lmc_present">LMC issued</h3>
          <h3 class="display-1 font-weight-bold" v-else-if="lmc_auth">LMC allowed</h3>
          <h3 class="display-1 font-weight-bold" v-else>LMC not allowed</h3>
          <v-spacer></v-spacer>
          <v-btn color="white" @click="openLMC" :disabled="!lmc_auth" class="grey--text" v-if="lmc_present"
            >Update LMC
          </v-btn>
          <v-btn color="white" @click="openLMC" class="grey--text" v-else>Create LMC</v-btn>
        </v-card-title>
        <v-alert :value="true" type="warning" class="black--text ma-0" v-if="lmc_message">
          <div v-html="lmc_message"></div>
        </v-alert>
      </v-card>
    </v-col>

    <v-container>
      <v-card class="mb-4" elevation="15">
        <v-row class="py-0">
          <v-col cols="12" class="py-0">
            <v-card color="primary">
              <v-row>
                <v-col cols="5">
                  <v-card-title class="white--text pt-1">Subscription Number: {{ sub_id }} </v-card-title>
                  <v-card-subtitle class="white--text pb-1">Number of packages: {{ numberOfPackages }}</v-card-subtitle>
                </v-col>
                <v-spacer></v-spacer>
                <v-col cols="5" class="text-right">
                  <v-btn
                    color="secondary"
                    class="mx-1"
                    :href="beCoolPortalLink"
                    v-if="this.sub_info.courier !== 'HDS WA' && preferences.country === 'au'"
                    target="_blank"
                    >BeCool Portal V2</v-btn
                  >
                  <v-btn color="secondary" class="px-4 mx-1" :href="this.bobSubscriptionLink"> BOB Subscription</v-btn>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            <v-list three-line-line>
              <v-list-item>
                <v-list-item-action><v-icon>loyalty</v-icon></v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>{{ sub_info.customer_loyalty }} boxes</v-list-item-title>
                  <v-list-item-subtitle>Loyalty</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-action><v-icon>mail</v-icon></v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>{{ sub_info.email }}</v-list-item-title>
                  <v-list-item-subtitle>Email</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-action><v-icon>home</v-icon></v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>{{ customerAddress }}</v-list-item-title>
                  <v-list-item-subtitle>Address</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-col>
          <v-col cols="6">
            <v-list three-line-line>
              <v-list-item>
                <v-list-item-action><v-icon>departure_board</v-icon></v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>{{ sub_info.delivery_date }} / {{ sub_info.delivery_window }}</v-list-item-title>
                  <v-list-item-subtitle>Delivery Day</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-action>
                  <v-icon>mdi-pencil</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>{{ sub_info.delivery_instructions }}</v-list-item-title>
                  <v-list-item-subtitle>Delivery Instructions</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-action v-if="couponCode">
                  <v-icon>card_membership</v-icon>
                </v-list-item-action>
                <v-list-item-content v-if="couponCode">
                  <v-list-item-title>{{ couponCode }}</v-list-item-title>
                  <v-list-item-subtitle>Coupon template</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-col>
        </v-row>
        <v-row wrap>
          <v-spacer></v-spacer>
          <v-col cols="10" class="px-0">
            <div v-for="(pkg, index) in packages" :key="index">
              <PackageItem
                class="pb-5 px-0"
                :keys="pkg.id"
                :package_id="pkg.id"
                :pkg="pkg"
                :week="week"
                @appendTotalPrice="pushPackagePrice"
              >
              </PackageItem>
            </div>
          </v-col>
          <v-spacer></v-spacer>
        </v-row>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import axios from 'axios'
import auth from '@/modules/auth'
import PackageItem from './PackageItem'
import notifications from '@/modules/notifications'
import {CONSTANTS} from '@/modules/constants';

export default {
	name: 'SubscriptionSummary',
	components: { PackageItem },
	props: {
		sub_id: { type: Number, required: true },
		week: { type: String },
		packages: { type: Array }
	},
	data() {
		return {
			preferences: auth.user.preferences,
			progress: false,
			sub_info: null,
			parcel_ids: null,
			lmc_present: false,
			lmc_auth: false,
			lmc_message: null,
			lmc_info: null,
			reasons: [],
			compensations: [],
			compensation_type_credit: [{ id: 1, name: 'Credit' }],
			progress_reasons: false,
			progress_compensations: false,
			create_lmc: false,
			new_lmc: {
				compensation_applied: false,
				comment: '',
				country: auth.user.preferences.country
			},
			list_of_price: [],
			package_ids_known_price: [],
			bobLinks: {
				au: 'https://bob.hellofresh.com.au',
				nz: 'https://bob.hellofresh.co.nz',
				ao: 'https://bob.everyplate.com.au'
			}
		}
	},
	mounted() {
		this.getSubInfo()
		this.getLMCInfo()
	},
	computed: {
		customerAddress() {
			const {
				customer_street: customerStreet,
				customer_city: customerCity,
				customer_postcode: customerPostcode
			} = this.sub_info

			return `${customerStreet}, ${customerCity} ${customerPostcode}`
		},
		couponCode() {
			const { coupon_template: couponTemplate, coupon_code: couponCode } = this.sub_info

			return `${couponCode} (${couponTemplate})`
		},
		numberOfPackages() {
			return this.packages.length
		},
		beCoolPortalLink: function () {
			return 'https://portalv2.becoolcouriers.com.au/search?invoice=' + this.sub_info.subscription_id.toString()
		},
		bobSubscriptionLink: function () {
			return `${this.bobLinks[this.preferences.country]}/subscription-ext/edit_index/index/id_subscription/${
				this.sub_info.subscription_id
			}`
		}
	},
	methods: {
		getSubInfo() {
			this.progress = true

			axios
				.get(`${CONSTANTS.BASE_URL}/api/v1` + '/subscription/' + this.sub_id, {
					params: {
						country: this.preferences.country,
						week: this.week
					}
				})
				.then((response) => {
					if (response.data.msg === 'success') {
						this.sub_info = response.data.sub_info
					}
					this.progress = false
				})
		},
		pushPackagePrice(data) {
			if (!this.package_ids_known_price.includes(data.package_id)) {
				this.package_ids_known_price.push(data.package_id)
				this.list_of_price.push(data.package_price)
			}
		},
		getLMCInfo() {
			axios
				.get(`${CONSTANTS.BASE_URL}/api/v1` + '/subscription/lmc-info/' + this.sub_id, {
					params: {
						week: this.week,
						country: this.preferences.country
					}
				})
				.then((response) => {
					if (response.data.msg === 'success') {
						this.lmc_info = response.data.lmc_info
						this.lmc_auth = response.data.lmc_auth
						axios
							.get(`${CONSTANTS.BASE_URL}/api/v1` + '/boxes/lmcs/' + this.lmc_info.boxes[0].id)
							.then((response) => {
								if (response.data.msg === 'success') {
									this.new_lmc.compensation_applied = response.data.lmc.compensation_applied
									this.new_lmc.comment = response.data.lmc.comment
									this.new_lmc.compensation_type_id = response.data.lmc.compensation_type_id
									this.new_lmc.compensation_type_id_2 = response.data.lmc.compensation_type_id_2
									this.new_lmc.reason_id = response.data.lmc.reason_id
									this.lmc_present = true
								}
							})
							.catch((e) => {
								notifications.addNotification('Could not retrieve lmc info.')
							})
					}
				})
		},
		openLMC() {
			this.progress_reasons = true
			this.progress_compensations = true
			this.create_lmc = true
			axios.get(`${CONSTANTS.BASE_URL}/api/v1` + '/boxes/lmcs/reasons').then((response) => {
				if (response.data.msg === 'success') {
					this.reasons = response.data.reasons
				}
				this.progress_reasons = false
			})
			axios.get(`${CONSTANTS.BASE_URL}/api/v1` + '/boxes/lmcs/compensations').then((response) => {
				if (response.data.msg === 'success') {
					this.compensations = response.data.compensations
				}
				this.progress_compensations = false
			})
		},
		resetLMCForm() {
			this.new_lmc = {
				compensation_applied: false,
				compensation_type_id_2: null,
				reason_id: '',
				comment: '',
				country: auth.user.preferences.country
			}
			this.create_lmc = false
		},
		createLMC() {
			if (this.$refs.lmc_form.validate()) {
				this.create_lmc = false
				this.progress = true
				this.lmc_info.boxes.forEach((box) => {
					let lmc_obj = Object.assign({}, this.new_lmc)
					lmc_obj.compensation_sum = box.compensation_sum
					lmc_obj.compensation_sum_2 = box.compensation_sum_2
					axios
						.post(
							`${CONSTANTS.BASE_URL}/api/v1` + '/boxes/lmcs/' + box.id,
							{
								params: {
									lmc: lmc_obj
								}
							}
						)
						.then((response) => {
							if (response.data.msg === 'success') {
								this.lmc_present = true
								notifications.addNotification('LMC inserted successfully.')
								if (response.data.suggestion) {
									this.suggestion = true
									this.suggestion_box = response.data.suggestion
								}
								if (response.data.bob_status_code !== 200) {
									notifications.addNotification(
										'LMC failed to update on Bob and a Team Leader has been notified.\n' +
                        'Please ensure you are logged in to Bob.',
										true
									)
								}
							}
							this.progress = false
						})
						.catch((e) => notifications.addNotification('LMC insertion not authorized'))
				})
			}
		},
		deleteLMC() {
			this.create_lmc = false
			this.progress = true
			this.lmc_info.boxes.forEach((box) => {
				axios.delete(`${CONSTANTS.BASE_URL}/api/v1` + '/boxes/lmcs/' + box.id).then((response) => {
					if (response.data.msg === 'success') {
						notifications.addNotification('LMC deleted successfully.')
						if (response.data.bob_status_code !== 200) {
							notifications.addNotification(
								'LMC failed to update on Bob and a Team Leader has been notified.\n' +
                    'Please ensure you are logged in to Bob.',
								true
							)
						}
					}
				})
			})
			this.lmc_present = false
		},
		checkLMC() {
			axios.get(`${CONSTANTS.BASE_URL}/api/v1` + '/boxes/lmcs/' + this.box_id).then((response) => {
				if (response.data.lmc) {
					this.new_lmc = response.data.lmc
					this.lmc_present = true
				}
			})
		}
	}
}
</script>

<style scoped>
  .total_invoice_au {
    width: 500px;
    height: 60px;
    position: relative;
    background: #669933;
    padding: -20px 0;
    text-align: center;
    line-height: 60px;
    font-weight: normal;
    font-family: Roboto, sans-serif;
    font-size: larger;
    color: white;
  }
  .total_invoice_au:before {
    content: '';
    position: absolute;
    right: -30px;
    bottom: 0;
    width: 0;
    height: 0;
    border-right: 30px solid transparent;
    border-top: 30px solid #669933;
    border-bottom: 30px solid #669933;
  }

  .total_invoice_nz {
    width: 500px;
    height: 60px;
    position: relative;
    background: #8c988d;
    padding: -20px 0;
    text-align: center;
    line-height: 60px;
    font-weight: normal;
    font-family: Roboto, sans-serif;
    font-size: larger;
    color: white;
  }
  .total_invoice_nz:before {
    content: '';
    position: absolute;
    right: -30px;
    bottom: 0;
    width: 0;
    height: 0;
    border-right: 30px solid transparent;
    border-top: 30px solid #8c988d;
    border-bottom: 30px solid #8c988d;
  }
</style>
