<template>
  <div class="windows">

    <v-container  class="pb-0">
      <v-row>
        <v-col cols="10">
          <h1 class="display-3 mb-4">{{courierName}} Chat/Error Portal</h1>
        </v-col>
      </v-row>
    </v-container>

  </div>
</template>

<script>
import auth from '../../modules/auth';

export default {
	name: 'CourierPortal',
	props: {
		courierName: {type: String, required: true}
	},
	data() {
		return {
			preferences: auth.user.preferences
		}
	}
}
</script>

<style scoped>

</style>
