var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(
      this.userProfile.admin ||
      this.userProfile.team_leader ||
      this.userProfile.qa_team
    )?_c('v-container',{staticClass:"pa-0 ma-0"},[_vm._l((_vm.jobs),function(job){return _c('v-row',{key:job.id,staticClass:"ma-3 pa-3"},[(_vm.updateVouchers === job.id)?_c('v-col',{staticClass:"job-title",attrs:{"cols":"2"}},[_vm._v("Uploading Vouchers")]):_vm._e(),_c('v-col',{staticClass:"job-title",attrs:{"cols":"3"}},[_vm._v(_vm._s(job.title))]),_c('v-col',{attrs:{"cols":"8"}},[_c('v-progress-linear',{attrs:{"indeterminate":job.progress < 0,"value":job.progress,"color":job.color}})],1)],1)}),_c('v-card',{staticClass:"pa-0 ma-0"},[_c('v-card-title',{staticClass:"headline"},[_vm._v("Select the voucher settings you are uploading")]),_c('v-card-text',[_c('v-form',{ref:"form"},[_c('v-container',{attrs:{"grid-list-sm":""}},[_c('v-row',{attrs:{"wrap":""}},[_c('v-col',{attrs:{"cols":"2"}},[_c('v-combobox',{attrs:{"items":_vm.backup_voucher_types,"label":"Voucher Type*","rules":[
                    function (v) { return v.length > 0 || 'Please enter a voucher type'; } ]},on:{"change":_vm.getBackupVoucherDetails},model:{value:(_vm.backup_voucher_type),callback:function ($$v) {_vm.backup_voucher_type=$$v},expression:"backup_voucher_type"}})],1),_c('v-col',{attrs:{"cols":"2"}},[_c('v-combobox',{attrs:{"items":_vm.backup_voucher_details,"label":"Voucher Detail*","rules":[
                    function (v) { return v.length > 0 || 'Please enter a voucher detail'; } ]},on:{"change":_vm.getBackupVoucherPopupList},model:{value:(_vm.backup_voucher_detail),callback:function ($$v) {_vm.backup_voucher_detail=$$v},expression:"backup_voucher_detail"}})],1),_c('v-col',{attrs:{"cols":"2"}},[_c('v-combobox',{attrs:{"items":['single-use', 'unlimited'],"label":"Number of uses*","offset-y":""},model:{value:(_vm.number_uses_allowed),callback:function ($$v) {_vm.number_uses_allowed=$$v},expression:"number_uses_allowed"}})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-checkbox',{attrs:{"label":"Are these leadership codes?"},on:{"change":_vm.getBackupVoucherTypes},model:{value:(_vm.is_leadership_code),callback:function ($$v) {_vm.is_leadership_code=$$v},expression:"is_leadership_code"}})],1),_c('v-col',{attrs:{"cols":"2"}},[_c('v-menu',{attrs:{"offset-x":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
return [_c('v-text-field',_vm._g({attrs:{"label":"Expiry date","rules":[function (v) { return !!v || 'Please select an expiry date'; }],"readonly":""},model:{value:(_vm.voucher_expiry_date),callback:function ($$v) {_vm.voucher_expiry_date=$$v},expression:"voucher_expiry_date"}},on))]}}],null,false,2961448356),model:{value:(_vm.date_menu),callback:function ($$v) {_vm.date_menu=$$v},expression:"date_menu"}},[_c('v-date-picker',{attrs:{"min":_vm.tomorrow_date},on:{"input":function($event){_vm.date_menu = false}},model:{value:(_vm.voucher_expiry_date),callback:function ($$v) {_vm.voucher_expiry_date=$$v},expression:"voucher_expiry_date"}})],1)],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-combobox',{attrs:{"items":_vm.backup_voucher_popup_list,"label":"Popup voucher instruction*","offset-y":"","rules":[
                    function (v) { return v.length > 0 ||
                      'Please enter a popup voucher instruction'; } ]},model:{value:(_vm.backup_voucher_popup),callback:function ($$v) {_vm.backup_voucher_popup=$$v},expression:"backup_voucher_popup"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":"Voucher codes* (comma, space or line separated)","rules":[
                    function (v) { return v.length > 0 ||
                      'Please enter one or more voucher codes'; } ]},model:{value:(_vm.voucher_codes),callback:function ($$v) {_vm.voucher_codes=$$v},expression:"voucher_codes"}})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.uploadBackupVouchers}},[_vm._v("Upload Vouchers")])],1)],1)],1)],1)],1)],1)],2):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }