<template>
  <div>
    <v-container class="pl-12 pr-12">
      <v-row>
        <v-col cols="6">
          <v-card class="mx-auto mt-12 pa-0" elevation="0" outlined>
            <v-card style="height: 270px; position: relative" elevation="0" class="pa-4">
              <h2 class="ml-4 mt-2 font-weight-bold"> Deliver Notifications </h2>
              <h4 class=" ml-4 grey--text font-weight-light mt-2">
                Click to send out SMSes to customers with failed payment orders notifying them to resolve their payment
                to receive their upcoming box - <strong> ideally trigger at 9:00 AM </strong>
              </h4>
              <v-divider class="mb-1 mt-4"></v-divider>
              <v-col col="12">
                <v-alert v-if="triggeredToday9am" dense class="ma-0" text dismissible type="info" color="blue">
                  <strong> Success </strong> SMS notifications have been triggered and processed today
                </v-alert>
                <v-alert v-else-if="mantarayWarning9am" dense class="ma-0" text dismissible type="error">
                  <strong> Warning </strong> Check mantaray data before triggering this pipeline
                </v-alert>
                <v-alert v-else class="ma-0" text dense dismissible type="success" color="green">
                  <strong> Click </strong> to trigger failed payment notifications delivery pipeline
                </v-alert>
              </v-col>
              <v-fab-transition>
                <v-btn class="mr-6" color="pink" dark absolute top right fab large
                  @click="showConfirmation9am=true">
                  <v-icon>mdi-send</v-icon>
                </v-btn>
              </v-fab-transition>
            </v-card>
            <v-expand-transition>
              <v-card v-if="showConfirmation9am" class="transition-fast-in-fast-out v-card--reveal pa-8" style="height: 100%;">
                <v-card-text class="text-center">
                  <h1 class="text--primary"> Are you sure? </h1>
                  <h3 class="grey--text mt-4 font-weight-light">
                    This will dispatch all SMS notifications to customers with failed payment orders immediately
                  </h3>
                </v-card-text>
                <v-card-actions class="justify-center">
                  <v-col cols="6" class="pa-0">
                    <v-btn color="" dark large width="100%" @click="closeConfirmation9am()"> Nope, Never mind</v-btn>
                  </v-col>
                  <v-col cols="6">
                    <v-btn color="pink" dark large width="100%" @click="sendNotifications()"> Yep, Send Them All </v-btn>
                  </v-col>
                </v-card-actions>
              </v-card>
            </v-expand-transition>
          </v-card>
        </v-col>
        <v-col cols="6">
          <v-card class="mx-auto mt-12 pa-0" elevation="0" outlined>
            <v-card style="height: 270px; position: relative" elevation="0" class="pa-4">
              <h2 class="ml-4 mt-2 font-weight-bold"> Process Cancellations </h2>
              <h4 class=" ml-4 grey--text font-weight-light mt-2">
                Click to process customer's failed payment order cancellations and deliver relevant SMS
                notifications in response to their payment resolution - <strong> ideally trigger at 3:30 PM </strong>
              </h4>
              <v-divider class="mb-1 mt-4"></v-divider>
              <v-col col="12">
                <v-alert v-if="triggeredToday3pm" dense class="ma-0" text dismissible type="info" color="blue">
                  <strong> Success </strong> Failed payment cancellations have been triggered and processed today
                </v-alert>
                <v-alert v-else-if="mantarayWarning3pm" dense class="ma-0" text dismissible type="error">
                  <strong> Warning </strong> Check mantaray data before triggering this pipeline
                </v-alert>
                <v-alert v-else class="ma-0" text dense dismissible type="success" color="green">
                  <strong> Click </strong> to trigger failed payments cancellations pipeline
                </v-alert>
              </v-col>
              <v-fab-transition>
                <v-btn class="mr-6" color="blue" dark absolute top right fab large
                  @click="showConfirmation3pm=true">
                  <v-icon>mdi-send</v-icon>
                </v-btn>
              </v-fab-transition>
            </v-card>
            <v-expand-transition>
              <v-card v-if="showConfirmation3pm" class="transition-fast-in-fast-out v-card--reveal pa-8" style="height: 100%;">
                <v-card-text class="text-center">
                  <h1 class="text--primary"> Are you sure? </h1>
                  <h3 class="grey--text mt-4 font-weight-light">
                    This will process cancellations for customers with outstanding failed payment orders immediately
                  </h3>
                </v-card-text>
                <v-card-actions class="justify-center">
                  <v-col cols="6" class="pa-0">
                    <v-btn color="" dark large width="100%" @click="closeConfirmation3pm()"> Nope, Never mind</v-btn>
                  </v-col>
                  <v-col cols="6">
                    <v-btn color="blue" dark large width="100%" @click="processFailedPaymentCancellation()"> Yep, Send Them All </v-btn>
                  </v-col>
                </v-card-actions>
              </v-card>
            </v-expand-transition>
          </v-card>
        </v-col>
      </v-row>
      <v-row class="mt-4">
        <v-col>
          <v-card-title class="pr-0">
            <h3 class="font-weight-light"> Delivered Notifications </h3>
            <v-spacer></v-spacer>
            <v-spacer></v-spacer>
            <v-text-field
              dense
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
            ></v-text-field>
            <v-btn plain raised depressed class="ml-6 pa-0" @click="refreshSmsDeliveryTable">
              <v-icon class="pa-0">mdi-cached</v-icon>
            </v-btn>
          </v-card-title>
          <v-data-table
            :headers="headers"
            :items="smsDelivery"
            :search="search"
            :loading="isLoading"
            loading-text="Loading sms deliveries... Please wait"
            :single-expand="singleExpand"
            :expanded.sync="expanded"
            item-key="sms_delivery_id"
            show-expand
            elevation="0"
          >
            <template v-slot:expanded-item="{ headers, item }">
              <td class="pt-4 pb-4" :colspan="headers.length">
                {{ item.sms_content }}
              </td>
            </template>
          </v-data-table>
          <v-card-text class="text-center">
            <h4 class="grey--text font-weight-light"> This table displays SMS delivery records for the past 7 days only </h4>
          </v-card-text>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import axios from 'axios';
import notifications from '@/modules/notifications';
import moment from 'moment';
import auth from '../../modules/auth';
import {CONSTANTS} from '@/modules/constants';

export default {
	name: 'FailedPayments',
	data() {
		return {
			country: auth.user.preferences.country,
			expanded: [],
			singleExpand: false,
			headers: [
				{ text: 'SMS Delivery ID', align: 'start', sortable: false, value: 'sms_delivery_id'},
				{ text: 'Customer Phone', value: 'customer_phone' },
				{ text: 'Created At', value: 'created_timestamp' },
				{ text: 'Last Updated', value: 'last_updated_timestamp' },
				{ text: 'Delivery Status', value: 'status' },
				{ text: '', value: 'data-table-expand' }
			],
			smsDelivery: [],
			search: '',
			showConfirmation9am: false,
			showConfirmation3pm: false,
			triggeredToday9am: false,
			triggeredToday3pm: false,
			mantarayWarning9am: false,
			mantarayWarning3pm: false,
			isLoading: true
		}
	},
	mounted() {
		this.getDeliveredSmsRecords();
	},
	methods: {
		getDeliveredSmsRecords(){
			this.isLoading = true
			axios.get(`${CONSTANTS.BASE_URL}/api/v1` + '/failed_payment_sms_delivery/' + this.country, {timeout: 180000}).then(res => {
				if (res.data.msg === 'success') {
					this.smsDelivery = res.data.sms_delivery
						.map((item) => {
							return {
								sms_delivery_id: item.sms_delivery_id,
								customer_phone: item.customer_phone,
								sms_content: item.sms_content,
								created_timestamp: moment.utc(item.created_timestamp).local().format('DD-MM-YYYY HH:mm:ss'),
								last_updated_timestamp: moment.utc(item.last_updated_timestamp).local().format('DD-MM-YYYY HH:mm:ss'),
								status: item.status
							}
						})
					this.isLoading = false
				}
			})
				.catch(() => {
					this.isLoading = false
					notifications.addNotification('Error: Failed to load SMS deliveries data', true)
				});
		},
		refreshSmsDeliveryTable(){
			this.getDeliveredSmsRecords()
		},
		sendNotifications() {
			this.closeConfirmation9am()
			axios.post(`${CONSTANTS.BASE_URL}/api/v1` + '/send_payment_sms_notification/' + this.country,
				{timeout: 2000}).then((response) => {
				if (response.data.msg === 'success') {
					notifications.addNotification('Successfully dispatched SMS notifications to customers');
					this.triggeredToday9am = true
				}
			})
				.catch(() => {
					notifications.addNotification('Error: Failed to dispatch SMS notifications', true)
				});
		},
		processFailedPaymentCancellation() {
			this.closeConfirmation3pm()
			axios.post(`${CONSTANTS.BASE_URL}/api/v1` + '/process_failed_payment_cancellation/' + this.country,
				{timeout: 2000}).then((response) => {
				if (response.data.msg === 'success') {
					notifications.addNotification('Successfully triggered Failed Payments Cancellations to customers');
					this.triggeredToday3pm = true
				}
			})
				.catch(() => {
					notifications.addNotification('Error: Failed to trigger Failed Payments Cancellations', true)
				});
		},
		closeConfirmation9am() {
			this.showConfirmation9am = false
		},
		closeConfirmation3pm() {
			this.showConfirmation3pm = false
		}
	}
}
</script>

<style scoped>

.v-card--reveal {
  bottom: 0;
  opacity: 1 !important;
  position: absolute;
  width: 100%;
}

</style>
