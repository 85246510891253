<template>
  <v-dialog max-width="400px">
    <template v-slot:activator="{ on }">
      <v-btn
        v-on="on"
        :disabled="!trackingDetails"
        color="secondary"
        v-clipboard=""
      >
        Tracking Status Details
      </v-btn>
    </template>
    <div>
      <v-card>
        <v-card-title>
          <h3 class="headline">Tracking Status Details</h3>
        </v-card-title>
         <v-card-text class="tracking-status-details">
           <h4 v-if="nzpError"> {{ nzpError }} </h4>
           <br/>
           <span v-for="(data, header) in trackingDetails" :key="header.index">
             <h4> {{ formatHeader(header) }} </h4>
             {{ data }} <br/>
             <br/>
           </span>
         </v-card-text>
      </v-card>
    </div>
  </v-dialog>
</template>

<script>

import util from '@/modules/util'

export default {
	name: 'TrackingStatusModal',
	props: {
		trackingDetails: {type: Object, required: false},
		nzpError: {type: String, required: false}
	},
	methods: {
		formatHeader: function(header) {
			return util.snakeToTitleCase(header);
		}
	}
}
</script>

<style scoped>

</style>
