<template>
  <div>
    <v-container pa-0>
      <v-card>
        <v-col>
          <v-card-title title class="font-weight-bold">
            <v-icon aria-hidden="false" color="primary" left medium>mdi-cart-plus</v-icon> <h3> Mark Upsell Attempted </h3>
          </v-card-title>
          <v-card-subtitle class="font-weight-light mt-1">
            Are you sure you want to mark upsell attempted for this customer?
          </v-card-subtitle>
        </v-col>
        <v-container>
          <v-row align="center" class="d-flex justify-end ma-3">
            <v-btn text color="error" normal min-width="30%" large @click="closeMarkUpsellAttemptedModal()">
              Cancel
            </v-btn>
            <v-btn color="primary" normal width="35%" @click="markUpsellAttempted()">
              <v-icon small dark left> mdi-thumb-up </v-icon> Confirm
            </v-btn>
          </v-row>
        </v-container>
      </v-card>
    </v-container>
  </div>
</template>

<script>
export default {
	name: 'MarkUpsellAttemptedModal',
	props: {
		markUpsellAttempted: Function,
		closeMarkUpsellAttemptedModal: Function
	}
}
</script>

<style scoped>

</style>
