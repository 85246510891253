import axios from 'axios';
import store from '../../store/store';
import {CONSTANTS} from '@/modules/constants';
// import features from '../features';
import router from '../router/index';

let user = {
	authenticated: false,
	profile: JSON.parse(localStorage.getItem('profile')),
	preferences: {
		country: JSON.parse(localStorage.getItem('country'))
	}
};

function login(context) {
	console.log(CONSTANTS.BASE_URL)
	axios.get(CONSTANTS.BASE_URL + '/auth/login').then((response) => {
		if (response.status === 200) {
			if (response.data.access_token) {
				localStorage.setItem('access_token', response.data.access_token);
				store.dispatch('setAccessToken', response.data.access_token);
				checkAuth().then(() => {
					router.push('/country');
				});
			} else {
				window.location.replace(response.data.url);
			}
		} else {
			context.error = true;
		}
	});
}

function bobLogin(context) {
	const url = CONSTANTS.BASE_URL + '/auth/login/bob/' + user.preferences.country;
	const loginDetail = {
		username: context.username,
		password: context.password
	};
	return axios.post(url, loginDetail);
}

function authorize(context, code, state) {
	axios
		.get(CONSTANTS.BASE_URL + '/auth/authorize?code=' + code + '&state=' + state)
		.then(function (response) {
			if (response.status === 200) {
				localStorage.setItem('access_token', response.data.access_token);
				localStorage.setItem('refresh_token', response.data.refresh_token);
				checkAuth().then(() => {
					router.push('/country');
				});
			} else {
				context.error = true;
			}
		})
		.catch(function (error) {
			context.error = true;
			context.errorMessage = 'An error occured during the authentification.';
			if (error.response) {
				context.errorMessage = error.response.data.error;
			} else {
				console.log(error);
			}
		});
}

function refresh(context) {
	return new Promise((resolve, reject) => {
		axios.defaults.headers.common['Authorization'] =
      'Bearer ' + localStorage.getItem('refresh_token');
		axios.post(CONSTANTS.BASE_URL + '/auth/refresh').then(
			function (response) {
				if (response.status === 200) {
					localStorage.setItem('access_token', response.data.access_token);
					checkAuth().then(
						() => {
							resolve();
						},
						() => {
							reject();
						}
					);
				} else {
					reject();
				}
			},
			(error) => {
				reject();
			}
		);
	});
}

function logout() {
	localStorage.removeItem('access_token');
	localStorage.removeItem('profile');
	localStorage.removeItem('country');
	axios.defaults.headers.common['Authorization'] = '';
	user.authenticated = false;
	user.profile = null;
	user.preferences.country = null;
	store.dispatch('clearState');
	router.replace('/');
}

function checkAuth() {
	return new Promise((resolve, reject) => {
		const jwt = localStorage.getItem('access_token');
		if (jwt !== null) {
			user.authenticated = true;
			axios.defaults.headers.common['Authorization'] = 'Bearer ' + jwt;
			axios
				.get(`${CONSTANTS.BASE_URL}/api/v1` + '/me', {
					params: {country: user.preferences.country}
				})
				.then(function (response) {
					user.authenticated = response.status === 200;
					if (!user.authenticated) {
						throw new Error(response);
					} else {
						localStorage.setItem('profile', JSON.stringify(response.data));
						store.dispatch('setProfile', response.data);
						user.profile = response.data;
						// if (user.authenticated) {
						// 	features.configureFeatureToggles(store.dispatch, user)
						// }
						resolve();
					}
				})
				.catch(function (error) {
					if (error.response && error.response.status === 401) {
						refresh().then(
							() => {
								resolve();
							},
							() => {
								reject();
								logout();
							}
						);
					} else {
						user.authenticated = false;
						reject();
						logout();
					}
				});
		} else {
			user.authenticated = false;
			reject();
		}
		if (!user.authenticated) {
			router.replace('/');
		}
	});
}

async function setCountry(value) {
	user.preferences.country = value;
	localStorage.setItem('country', JSON.stringify(user.preferences.country));
	await store.commit('SET_COUNTRY', value);
	store.dispatch('getWeeks');
}

function loadCountry(context) {
	if (user.preferences.country === 'au') {
		const hfTheme = {
			primary: '#8bcd57',
			secondary: '#669933',
			error: '#ff7a73',
			warning: '#ffc852'
		};
		context.$vuetify.theme.themes.light = hfTheme;
		context.$vuetify.theme.dark = false;
	} else if (user.preferences.country === 'nz') {
		context.$vuetify.theme.dark = true;
	} else if (user.preferences.country === 'ao') {
		const epTheme = {
			primary: '#f99d33',
			secondary: '#16becf',
			error: '#ff7a73',
			warning: '#ffc852'
		};
		context.$vuetify.theme.themes.light = epTheme;
	} else if (user.preferences.country === 'ye') {
		const hfTheme = {
			primary: '#8bcd57',
			secondary: '#669933',
			error: '#ff7a73',
			warning: '#ffc852'
		};
		context.$vuetify.theme.themes.light = hfTheme;
		context.$vuetify.theme.dark = false;
	} else {
		context.$vuetify.theme.primary = '#626262';
		context.$vuetify.theme.secondary = '#626262';
		context.$router.replace('/country');
	}
	context.$vuetify.theme.accent = context.$vuetify.theme.secondary;
	context.$vuetify.theme.info = context.$vuetify.theme.primary;
}

export default {
	user,
	login,
	bobLogin,
	authorize,
	logout,
	checkAuth,
	setCountry,
	loadCountry
};
