<template>
  <div class="main">
    <v-progress-linear indeterminate v-if="progress" color="secondary" class="progress"></v-progress-linear>
    <v-container grid-list-sm>
      <v-row wrap>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import axios from 'axios';
import auth from '@/modules/auth';

export default {
	name: 'VoucherIssues',
	data() {
		return {
			progress: false
		}
	},
	created() {
		auth.checkBobAuth();
	}
}
</script>

<style scoped>
  .title-img {
    margin: 1rem 0;
  }

  .v-list {
    background: transparent;
  }
</style>
