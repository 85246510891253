<template lang="html">
  <div class="bob-login">
    <v-card>
      <v-card-title primary-title class="pb-0">
        <h3 class="headline ta-center">Login to BOB</h3>
      </v-card-title>
      <v-card-text>
        <v-form ref="form" v-model="valid">
          <v-text-field
            v-model="username"
            label="Username"
            required
            :rules="inputRules"
          ></v-text-field>
          <v-text-field
            v-model="password"
            label="Password"
            :append-icon="show_password ? 'visibility_off' : 'visibility'"
            :type="show_password ? 'text' : 'password'"
            @click:append="show_password ^= true"
            required
            :rules="inputRules"
          ></v-text-field>
          <v-btn color="primary" @click="submit" block :loading="progress">
            Login
          </v-btn>
        </v-form>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import auth from '@/modules/auth';
import notifications from '@/modules/notifications';

export default {
	name: 'BobLogin',
	data() {
		return {
			valid: false,
			username: '',
			password: '',
			form: null,
			progress: false,
			show_password: false,
			alert: {
				visible: false,
				message: ''
			},
			inputRules: [(v) => !!v || 'Field is required']
		};
	},
	methods: {
		submit: function () {
			if (this.$refs.form.validate()) {
				this.progress = true;
				auth
					.bobLogin(this)
					.catch((error) => {
						notifications.addNotification(
							'Error: Could not log into Bob',
							true
						);
					})
					.finally((_) => {
						this.progress = false;
						this.$emit('close');
					});
			}
		}
	}
};
</script>

<style lang="css"></style>
