<template>
  <v-dialog max-width="500px" max-height="600px">
    <template v-slot:activator="{ on }">
      <v-container>
        <v-btn v-on="on" color="secondary" outlined small>
          View Ingredient Substitutions
        </v-btn>
      </v-container>
    </template>
    <div>
      <v-container pa-0>
          <v-card width="600px">
            <v-app-bar flat color="secondary" height="100px">
              <v-col>
                <v-card-title class="font-weight-bold">
                  <span class="white--text font-weight-medium">
                    Recipe Ingredient Substitution Details
                  </span>
                </v-card-title>
                <v-card-subtitle class="font-weight-medium">
                  <span class="white--text font-weight-medium">
                    slot: {{ slot }}
                  </span>
                </v-card-subtitle>
              </v-col>
            </v-app-bar>
            <v-card-text class="tracking-status-details">
              <v-card-title class="font-weight-light"> Modularity Type(s) </v-card-title>
              <v-card-text>
                <v-chip-group active-class="primary--text" column>
                  <v-chip v-for="(types, index) in modTypes" :key="index" outlined color="primary">
                    {{ types }}
                  </v-chip>
                </v-chip-group>
              </v-card-text>
              <v-card-title class="font-weight-light"> Swapped ingredient(s) </v-card-title>
              <v-card-text>
                <v-chip-group active-class="primary--text" column>
                  <v-chip v-for="(ingredientSubstitution, index) in modIngredientSubstitutions" :key="index" outlined color="secondary">
                    {{ ingredientSubstitution }}
                  </v-chip>
                </v-chip-group>
              </v-card-text>
            </v-card-text>
          </v-card>
      </v-container>
    </div>
  </v-dialog>
</template>

<script>
export default {
	name: 'IngredientSubstitutionDetailsModal',
	props: {
		slot: {type: Number, required: true},
		modTypes: {type: String, required: true},
		modIngredientSubstitutions: {type: String, required: true}
	}
}
</script>

<style scoped>
</style>
