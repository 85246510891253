import Vue from 'vue';
import Router from 'vue-router';

import Login from '@/components/auth/Login';
import Callback from '@/components/auth/Callback';
import Country from '@/components/Country';
import Settings from '@/components/settings/Settings';
import BobLogin from '@/components/auth/BobLogin';

import Main from '@/components/Main';
import Box from '@/components/boxes/Box';
import CustomerWeekSummary from '@/components/packages/CustomerWeekSummary'
import Customer from '@/components/customers/Customer';
import PostcodeLookup from '@/components/postcodes/PostcodeLookup';
import PostcodeRequests from '@/components/postcodes/PostcodeRequests';
import VoucherIssues from '@/components/VoucherIssues';
import SlotAllocations from '@/components/SlotAllocations'

import FailedPayments from '@/components/payment/FailedPayments';
import PaymentLMCS from '@/components/payment/LMCS';
// import PaymentErrors from '@/components/payment/PaymentErrors'; // ANZOPSTECH-1010-Hide payment errors component as after analysis it is not considered as important feature
import LMCS from '@/components/LMCS';
// import LiveErrors from '@/components/errors/LiveErrors'; // ANZOPSTECH-1013
import WrongAddresses from '@/components/WrongAddresses';
import WrongAddressesSend from '@/components/WrongAddressesSend';
// import HolidayWindows from '@/components/windows/Holidays';
import Lookup from '@/components/Lookup';
import Hotwires from '../../components/hotwires/Hotwires';

// import DamageClaimDashboard from '../../components/damageclaim/DamageClaimDashboard'; // ANZOPSTECH-1005
import Upload from '@/components/Upload';
// import ErrorsReport from '@/components/errors/ErrorsReport'; // ANZOPSTECH-1307

import Refund from '@/components/handbook/Refund';

import MissedCourierDeliveries from '@/components/errors/MissedCourierDeliveries';
import MissedCourierDeliveriesReport from '@/components/errors/MissedCourierDeliveriesReport';
// import BobManagement from '@/components/payment/BobManagement'; // ANZOPSTECH-1011
import FoodSafetyIssues from '@/components/errors/FoodSafetyIssues';
// import UploadPostcodeDeliveryList from '../../components/UploadPostcodeDeliveryList';
import VoucherLookup from '@/components/vouchers/VoucherLookup';
import VoucherTools from '@/components/vouchers/VoucherTools';
import LiveDataCollection from '@/components/issues/LiveDataCollection';
// import Jobs from '../../components/Jobs'; // ANZOPSTECH-1019
import CourierContact from '@/components/courier/CourierContact';
import store from '@/store/store';
import {COUNTRYCODE} from '@/modules/constants';

Vue.use(Router);

let routerObject = {
	routes: [
		{
			path: '/',
			name: 'login',
			component: Login
		},
		{
			path: '/auth/callback',
			name: 'callback',
			component: Callback
		},
		{
			path: '/country',
			name: 'country',
			component: Country
		},
		{
			path: '/settings',
			name: 'settings',
			component: Settings
		},
		{
			path: '/home',
			name: 'home',
			component: Main
		},
		{
			path: '/upload',
			name: 'upload',
			component: Upload
		},
		// {
		//   path: "/upload_postcode_delivery_list",
		//   name: "upload_postcode_delivery_list",
		//   component: UploadPostcodeDeliveryList
		// },
		{
			path: '/customer/:id',
			name: 'customer',
			component: Customer
		},

		{
			path: '/customer/:id/week_summary',
			name: 'CustomerWeekSummary',
			component: CustomerWeekSummary
		},

		{
			path: '/box/:id',
			name: 'box',
			component: Box
		},
		{
			path: '/lmcs',
			name: 'lmcs',
			component: LMCS
		},
		{
			path: '/payment/failed_payments',
			name: 'failed_payments',
			component: FailedPayments
		},
		{
			path: '/payment/lmcs',
			name: 'payment_lmcs',
			component: PaymentLMCS
		},
		// ANZOPSTECH-1010-Hide payment errors component as after analysis it is not considered as important feature
		// {
		// 	path: '/payment/errors',
		// 	name: 'payment_errors',
		// 	component: PaymentErrors
		// },

		// ANZOPSTECH-1011 Hide component
		// {
		// 	path: '/payment/bob_management',
		// 	name: 'bob_management',
		// 	component: BobManagement
		// },

		// ANZOPSTECH-1013
		// {
		// 	path: '/errors/live',
		// 	name: 'live_errors',
		// 	component: LiveErrors
		// },

		// ANZOPSTECH-1005
		// {
		// 	path: '/damageclaim_board',
		// 	name: 'damageclaim_board',
		// 	component: DamageClaimDashboard
		// },
		{
			path: '/postcode_lookup',
			name: 'postcode_lookup',
			component: PostcodeLookup
		},
		{
			path: '/postcode_requests',
			name: 'postcode_requests',
			component: PostcodeRequests
		},
		// ANZOPSTECH-1018 - Hide component for all brands
		// {
		// 	path: '/wrong_addresses',
		// 	name: 'wrong_addresses',
		// 	component: WrongAddresses
		// },
		{
			path: '/wrong_addresses/send/:type/:week',
			name: 'wrong_addresses_send',
			component: WrongAddressesSend
		},
		// ANZOPSTECH-1307 - Hide component for all brands
		// {
		// 	path: '/errors/report',
		// 	name: 'errors_report',
		// 	component: ErrorsReport
		// },
		{
			path: '/voucher_issue',
			name: 'voucher_issue',
			component: VoucherIssues
		},
		{
			path: '/auth/bob',
			name: 'bob_login',
			component: BobLogin
		},
		{
			path: '/super_bob_changes',
			name: 'super_bob_changes',
			component: Refund
		},

		// ANZOPSTECH-1017 hide component as it is no longer considered as important
		// {
		// 	path: '/holiday_windows',
		// 	name: 'holiday_windows',
		// 	component: HolidayWindows
		// },

		{
			path: '/lookup',
			name: 'lookup',
			component: Lookup
		},
		{
			path: '/hotwires',
			name: 'hotwires',
			component: Hotwires
		},
		{
			path: '/mcd',
			name: 'mcd',
			component: MissedCourierDeliveries
		},
		{
			path: '/mcd/report/:week',
			name: 'mcd_report',
			component: MissedCourierDeliveriesReport
		},
		{
			path: '/food_safety',
			name: 'food_safety',
			component: FoodSafetyIssues
		},
		{
			path: '/slot_allocations',
			name: 'slot_allocations',
			component: SlotAllocations
		},
		{
			path: '/voucher_lookup',
			name: 'voucher_lookup',
			component: VoucherLookup
		},
		{
			path: '/voucher_tools',
			name: 'voucher_tools',
			component: VoucherTools
		},
		{
			path: '/issues',
			name: 'issues',
			component: LiveDataCollection
		},
		{
			path: '/courier/contact',
			name: 'courier_contact',
			component: CourierContact
		},

		// ANZOPSTECH-1019 Hide jobs_overview
		// {
		// 	path: '/jobs',
		// 	name: 'jobs',
		// 	component: Jobs
		// },

		{
			path: '*',
			redirect: '/'
		}
	]
};


let router = new Router(routerObject);

router.beforeEach((to, from, next) => {
	// ANZOPSTECH-1015 Hide hotwires for Youfoodz brand
	// ANZOPSTECH-1016 Hide food_safety for Youfoodz brand
	// ANZOPSTECH-1022 Hide super_bob_changes for Youfoodz brand
	// ANZOPSTECH-1008 Hide failed_payments for Youfoodz brand
	// ANZOPSTECH-1328 Hide slot_allocations for Youfoodz brand
	const hideRoutesYE = ['hotwires', 'food_safety', 'super_bob_changes', 'failed_payments', 'slot_allocations'];
	const hideRotesEP = ['failed_payments']
	if (store.state.country === COUNTRYCODE.YE && hideRoutesYE.includes(to.name)) {
		next('/home')
	} else if (store.state.country === COUNTRYCODE.AO && hideRotesEP.includes(to.name)) {
		next('/home')
	} else {
		next()
	}
});
export default router;
