<template>
  <div>
    <v-container>
      <v-row wrap>
        <v-col cols="12">
          <v-card class="pa-3">
            <v-col cols="12">
              <h1 class="display-3">Postcode Lookup</h1>
            </v-col>
            <v-scroll-y-transition tag="jobs">
              <v-col cols="12" v-if="jobs.length > 0" class="pb-2">
                <v-divider class="mx-2"></v-divider>
                <v-subheader class="pt-4" style="height: 40px;"
                >Jobs
                </v-subheader
                >
                <v-scroll-y-transition group>
                  <v-row class="mr-4 pl-4" v-for="job in jobs" :key="job.id">
                    <v-col cols="3" class="job-title">{{ job.title }}</v-col>
                    <v-col cols="8">
                      <v-progress-linear
                        :indeterminate="job.progress < 0"
                        :value="job.progress"
                        :color="job.color"
                      >
                      </v-progress-linear>
                    </v-col>
                  </v-row>
                </v-scroll-y-transition>
              </v-col>
            </v-scroll-y-transition>
            <v-col cols="12" sm="6" md="3">
              <v-form
                ref="postcode_form"
                @submit.prevent="getPostcode(search_postcode)"
              >
                <v-text-field
                  v-model="search_postcode"
                  label="Search Postcode"
                  :rules="postcode_form_rules"
                  solo
                ></v-text-field>
                <v-btn
                  small
                  light
                  color="primary"
                  @click.prevent="getPostcode(search_postcode)"
                >
                  Submit
                </v-btn>
              </v-form>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    small
                    light
                    color="primary"
                    v-on="on"
                    @click="updatePostcodes()"
                    class="mt-2"
                  >
                    Update Postcodes
                  </v-btn>
                </template>
                <span
                >This will sync postcodes from the CC Postcode google
                  sheet</span
                >
              </v-tooltip>
            </v-col>
          </v-card>
        </v-col>

        <v-col cols="12" v-if="postcode_status !== null">
          <v-card class="pa-3">
            <v-card-title class="headline">
              <span v-if="postcode_name" class="pr-3"
              >{{ postcode_name }}:</span
              >
              <span>{{ postcode }}</span>
            </v-card-title>
            <div v-if="postcode_status">
              <v-card-text class="headline">We deliver here :)</v-card-text>
              <div v-if="preferences.country === 'au'">
                <v-card-text v-if="delivery_fee"
                >*Delivery fee applies
                </v-card-text
                >
                <v-card-text v-else>*Delivery fee does not apply</v-card-text>
              </div>
            </div>
            <div v-else>
              <v-card-text class="headline"
              >No delivery for this postcode yet :(
              </v-card-text
              >
              <v-card-text>Nearby delivery areas coming soon....</v-card-text>
            </div>
            <!--            <v-card-text v-if="xmas_box_day">Christmas box window(s): {{xmas_box_day}}, {{xmas_delivery_window}} </v-card-text>-->
            <v-card-text v-if="postcode_names.length > 1">
              <span>Full suburb list: </span><br/>

              <span
                v-for="name in postcode_names.slice(
                  0,
                  postcode_names.length - 1
                )"
              >{{ name }},
              </span>
              <span v-for="name in postcode_names[postcode_names.length - 1]">{{
                  name
                }}</span
              ><br/>
              <span>
                Note: In rural areas, we don't always service all suburbs within
                a postcode</span
              ><br/>
            </v-card-text>
            <v-card-text v-if="preferences.country === 'nz'"
            >If necessary, please manually check validity of address/postcode
              combination with
              <a href="http://verifyaddress.courierpost.co.nz/">this website</a
              ><span>.</span><br/>
              <span>Note: We only deliver to rural addresses on Tuesdays</span>
            </v-card-text>

            <!--            Delivery window code - Don't remove!-->
            <!--                <v-col v-for="day in delivery_windows" cols="6" sm="4" md="3" lg="2">-->
            <!--                  <v-card-text class="headline text-capitalize">{{day['day']}}</v-card-text>-->
            <!--                  <ul>-->
            <!--                    <li v-for="window in day['windows']">{{window}}</li>-->
            <!--                  </ul>-->
            <!--                </v-col>-->

            <v-col
              v-for="(item, index) in nearby_delivery_areas"
              key="'index"
              xs="6"
              sm="4"
              md="3"
              lg="2"
            >
              <v-card-text>{{ item }}</v-card-text>
            </v-col>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import axios from 'axios';
import auth from '../../modules/auth';
import notifications from '@/modules/notifications';
import {CONSTANTS} from '@/modules/constants';

export default {
	name: 'PostcodeLookup',
	data() {
		return {
			preferences: auth.user.preferences,
			progress: false,
			jobs: [],
			// requests: [],
			// creation: false,
			// new_postcode: {
			//   postcode: "",
			//   suburb: "",
			//   comment: "",
			country: auth.user.preferences.country,
			postcode: '',
			search_postcode: '',
			postcode_status: null,
			postcode_name: '',
			delivery_windows: null,
			nearby_delivery_areas: null,
			delivery_fee: null,
			postcode_valid: null,
			xmas_box_day: null,
			xmas_delivery_window: null,
			postcode_names: [],
			postcode_form_rules: [
				(v) => !isNaN(v) || 'A number is required',
				(v) =>
					v.length === 3 ||
          v.length === 4 ||
          'Please enter a 3 or 4 digit postcode'
			]
		};
	},
	methods: {
		getPostcode: function () {
			if (this.$refs.postcode_form.validate()) {
				this.progress = true;
				axios
					.get(`${CONSTANTS.BASE_URL}/api/v1` + '/delivery_postcodes/lookup', {
						params: {
							postcode: this.search_postcode.trim(),
							country: this.country
						}
					})
					.then(({data}) => {
						if (data.msg === 'success') {
							const {postcode_info} = data;
							this.postcode = this.search_postcode;
							this.postcode_status = postcode_info.postcode_status;
							this.postcode_name = postcode_info.postcode_names[0];
							this.delivery_fee = postcode_info.delivery_fee;
							this.postcode_valid = postcode_info.postcode_valid;
							this.xmas_box_day = postcode_info.xmas_box_day;
							this.xmas_delivery_window = postcode_info.xmas_delivery_window;
							this.postcode_names = postcode_info.postcode_names;
							this.progress = false;
						} else if (data.msg === 'postcode not found') {
							this.progress = false;
							notifications.addNotification(
								`No record found for this postcode.`,
								true
							);
						}
					})
					.catch(() => {
						this.progress = false;
						notifications.addNotification(
							`No record found for this postcode.`,
							true
						);
					});
			}
		},
		updatePostcodes: function () {
			this.progress = true;
			axios
				.get(`${CONSTANTS.BASE_URL}/api/v1/delivery_postcodes/sync`)
				.then((response) => {
					this.progress = false;
					notifications.addNotification('Syncing postcodes from google sheet');
					this.jobs.push({id: response.data.job_id});
					this.updateJobs();
				})
				.catch((error) => {
					this.progress = false;
					notifications.addNotification('Failed to sync postcodes', true);
				});
		},
		updateJobs() {
			this.jobs = this.jobs.reduce((jobs, job) => {
				if (job.interval === undefined) {
					this.progress = true;
					job.interval = setInterval(() => {
						axios
							.get(`${CONSTANTS.BASE_URL}/api/v1` + '/jobs/' + job.id)
							.then((response) => {
								let progress = response.data.meta.progress;
								if (progress > 0) {
									job.progress = progress;
									job.title = response.data.meta.title;
									job.errors = response.data.meta.errors;
								} else {
									job.progress = 0;
									job.title = 'Waiting for job';
								}

								job.is_failed = response.data.failed;
								if (job.is_failed) {
									job.color = 'error';
									job.title = response.data.error;
									clearInterval(job.interval);
								} else if (job.errors && job.errors.length > 0) {
									job.color = 'warning';
								} else {
									job.color = 'primary';
								}

								if (job.progress >= 100) {
									this.progress = false;
									clearInterval(job.interval);
									job.title = 'Finished';
								}

								this.updateJobs();
							});
					}, 2000);
					this.$set(job, 'progress', -1);
					this.$set(job, 'title', 'Fetching job');
				}
				jobs.push(job);

				return jobs;
			}, []);
		}
	}
};
</script>

<style scoped></style>
