<template>
  <div>
    <v-progress-linear indeterminate v-if="progress" color="secondary" class="progress"></v-progress-linear>
    <v-form ref="new_issue_form">
      <v-card class="pa-6">
        <v-card-title class="display-3">Add New Form</v-card-title>
        <v-card-text>
        <v-row>
          <v-col xs="12" sm="6">
            <v-text-field
              v-model="new_issue_name"
              label="Form Name">
            </v-text-field>
          </v-col>
          <v-col xs="12" sm="6">
            <v-checkbox
              v-model="team_leader_issue"
              :label="'Form for leadership use only'"
              class="ma-0 pa-0"
              hide-details>
            </v-checkbox>
            <v-checkbox
              v-model="add_aunz_duplicate"
              :label="duplicateFormLabel"
              class="ma-0 pa-0"
              hide-details>
            </v-checkbox>
          </v-col>
          <v-col xs="12" sm="6">
            <v-text-field
              v-model="new_issue_description"
              label="Description (optional)">
            </v-text-field>
          </v-col>
          <v-col xs="12" sm="6">
            <v-text-field
              v-model="new_issue_email"
              label="Notification email (optional)">
            </v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col xs="12" sm="6" md="3">
            <v-checkbox
              v-model="get_customer_id"
              label="Get customer id">
            </v-checkbox>
          </v-col>
          <v-col v-if="get_customer_id" xs="12" sm="6" md="3">
            <v-checkbox
              v-model="display_customer_address"
              label="Display customer address">
            </v-checkbox>
          </v-col>
          <v-col v-if="get_customer_id" xs="12" sm="6" md="3">
            <v-checkbox
              v-model="display_customer_contact"
              label="Display customer contact">
            </v-checkbox>
          </v-col>
          <v-col v-if="get_customer_id" xs="12" sm="6" md="3">
            <v-checkbox
              v-model="display_customer_loyalty"
              label="Display customer loyalty">
            </v-checkbox>
          </v-col>
        </v-row>
        </v-card-text>
        <v-card-text class="headline pa-2">Entry field names</v-card-text>
        <v-card-text>
        <v-container fluid>
          <v-row
                    v-for="(field, index) in new_issue_fields"
                    :key="index">
            <v-col cols="4">
              <v-text-field
                :label="field['label']"
                v-model="field['name']"
              ></v-text-field>
            </v-col><v-col cols="3">
            <v-autocomplete
              :label="field['type'] === 'Email Notification' ? 'Type* (notification on field edit)': 'Type*'"
              v-model="field['type']"
              :items="data_types"
              @change="setDefaults(field)"
            ></v-autocomplete>
          </v-col>
            <v-col cols="3">
              <v-text-field v-if="field['type'] === 'Text' || field['type'] === 'Email Notification'"
                            :label="field['type'] === 'Email Notification' ? 'Potential emails (comma separated)': 'Dropdown items (comma separated)'"
                            v-model="field['dropdown']"
              ></v-text-field>
            </v-col>
            <v-col v-if="field['type'] !== 'Email Notification'"
                    cols="2"
              ><v-checkbox
                label="Hide on form"
                v-model="field['hidden']"
                class="ma-0 pa-0"
                hide-details
              ></v-checkbox>
              <v-checkbox v-if="field['type'] !== 'Checkbox' && !field['hidden']"
                          label="Required field"
                          v-model="field['required']"
                          class="ma-0 pa-0"
                          hide-details
              ></v-checkbox>
            </v-col>
          </v-row>
        </v-container>
        </v-card-text>
        <v-card-actions class="justify-center">
          <v-btn text
                 block
                 color="primary"
                 @click="addNewIssue(preferences.country)"
          >Add Form
          </v-btn>
        </v-card-actions>
        <v-progress-circular indeterminate v-if="progress" color="secondary" class="progress"></v-progress-circular>
      </v-card>
    </v-form>

  </div>
</template>




<script>
import axios from 'axios';
import auth from '../../modules/auth';
import notifications from '@/modules/notifications';
import {CONSTANTS} from '@/modules/constants';

export default {
	name: 'LiveDataNewIssue',
	data() {
		return {
			preferences: auth.user.preferences,
			userProfile: auth.user.profile,
			progress: false,
			new_issue_name: '',
			new_issue_description: '',
			new_issue_email: '',
			get_customer_id: false,
			display_customer_address: false,
			display_customer_contact: false,
			display_customer_loyalty: false,
			add_aunz_duplicate: true,
			team_leader_issue: false,
			data_types: [
				'Text',
				'Number',
				'Decimal',
				'Checkbox',
				'Date',
				'Time',
				'Email Notification'],
			new_issue_fields: [
				{
					'label': 'Name 1',
					'db_label': '0',
					'name': '',
					'type': 'Text',
					'dropdown': '',
					'required': true,
					'hidden': false
				},
				{
					'label': 'Name 2',
					'db_label': '1',
					'name': '',
					'type': 'Text',
					'dropdown': '',
					'required': true,
					'hidden': false
				},
				{
					'label': 'Name 3',
					'db_label': '2',
					'name': '',
					'type': 'Text',
					'dropdown': '',
					'required': true,
					'hidden': false
				},
				{
					'label': 'Name 4',
					'db_label': '3',
					'name': '',
					'type': 'Text',
					'dropdown': '',
					'required': true,
					'hidden': false
				},
				{
					'label': 'Name 5',
					'db_label': '4',
					'name': '',
					'type': 'Text',
					'dropdown': '',
					'required': true,
					'hidden': false
				},
				{
					'label': 'Name 6',
					'db_label': '5',
					'name': '',
					'type': 'Text',
					'dropdown': '',
					'required': true,
					'hidden': false
				},
				{
					'label': 'Name 7',
					'db_label': '6',
					'name': '',
					'type': 'Text',
					'dropdown': '',
					'required': true,
					'hidden': false
				},
				{
					'label': 'Name 8',
					'db_label': '7',
					'name': '',
					'type': 'Text',
					'dropdown': '',
					'required': true,
					'hidden': false
				},
				{
					'label': 'Name 9',
					'db_label': '8',
					'name': '',
					'type': 'Text',
					'dropdown': '',
					'required': true,
					'hidden': false
				},
				{
					'label': 'Name 10',
					'db_label': '9',
					'name': '',
					'type': 'Text',
					'dropdown': '',
					'required': true,
					'hidden': false
				},
				{
					'label': 'Name 11',
					'db_label': '10',
					'name': '',
					'type': 'Text',
					'dropdown': '',
					'required': true,
					'hidden': false
				},
				{
					'label': 'Name 12',
					'db_label': '11',
					'name': '',
					'type': 'Text',
					'dropdown': '',
					'required': true,
					'hidden': false
				},
				{
					'label': 'Name 13',
					'db_label': '12',
					'name': '',
					'type': 'Text',
					'dropdown': '',
					'required': true,
					'hidden': false
				},
				{
					'label': 'Name 14',
					'db_label': '13',
					'name': '',
					'type': 'Text',
					'dropdown': '',
					'required': true,
					'hidden': false
				},
				{
					'label': 'Name 15',
					'db_label': '14',
					'name': '',
					'type': 'Text',
					'dropdown': '',
					'required': true,
					'hidden': false
				}
			]
		}
	},
	computed: {
		duplicateFormLabel: function() {
			if (this.preferences.country === 'au') {
				return 'Add duplicate form in NZ'
			}
			else {
				return 'Add duplicate form in AU'
			}
		}
	},
	methods: {
		addNewIssue(country) {
			if (this.$refs.new_issue_form.validate()) {
				this.progress = true;
				axios.post(`${CONSTANTS.BASE_URL}/api/v1` + '/issues/new_issue', {
					agent_id: this.userProfile.id,
					issue_name: this.new_issue_name,
					issue_description: this.new_issue_description || '',
					country: country,
					leadership_only: this.team_leader_issue,
					new_issue_fields: this.new_issue_fields,
					get_customer_id: this.get_customer_id,
					display_customer_address: this.display_customer_address,
					display_customer_contact: this.display_customer_contact,
					display_customer_loyalty: this.display_customer_loyalty,
					email: this.new_issue_email || ''
				})
					.then((response) => {
						if (response.data.msg === 'success') {
							this.issues = response.data.issues
							notifications.addNotification(country.toUpperCase() + ` - New issue added successfully`, true)
							if (this.add_aunz_duplicate) {
								this.add_aunz_duplicate = false
								this.addNewIssue(country === 'au' ? 'nz' : 'au')
							}
						}
						else if (response.data.msg === 'name error') {
							notifications.addNotification(`[Error] ` + country.toUpperCase() + ` - Form name already taken.`, true)
							this.progress = false;
						}
					})
					.finally(() => {
						this.$emit('newIssueSubmitted')
						this.progress = false
						this.$refs.new_issue_form.reset()
					})
			}
		},
		setDefaults(field) {
			if (field['type'] === 'Email Notification') {
				field['hidden'] = true
				field['required'] = false
			}
		}
	}
}
</script>
