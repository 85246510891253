<template>
  <div>
    <v-container class="pb-0">
      <v-row>
        <v-col cols="12">
          <h1 class="display-3">Hotwires</h1>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <h1 class="subtitle-1">Export</h1>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="3">
          <v-select :items="weeks" v-model="weekSelected" label="Choose a week to export" solo :loading="progress">
          </v-select>
        </v-col>
        <v-col cols="4" v-if="weekSelected" class="d-flex">
          <v-btn class="mx-2" color="primary" @click="launchExportPipeline()">
            <v-icon left dark>cloud_download</v-icon>
            Download Data Pipeline
          </v-btn>

          <v-btn class="mx-2" color="primary" @click="launchWeekSummaryExport()">
            <v-icon left dark>cloud_download</v-icon>
            Download Week Summary
          </v-btn>

          <v-btn class="mx-2" color="primary" @click="launchFullHotwireBoxesExport()">
            <v-icon left dark>cloud_download</v-icon>
            Full Hotwire Boxes
          </v-btn>
        </v-col>
      </v-row>
    </v-container>

    <v-container class="pb-0">
      <v-row>
        <v-col cols="12">
          <v-tabs v-model="tab" background-color="transparent" color="primary" grow>
            <v-tab>
              Waiting Hotwires
            </v-tab>
            <v-tab>
              Checked Hotwires
            </v-tab>
            <v-tab>
              Hotwires Summary
            </v-tab>
          </v-tabs>
          <div width="100%" class="text-center my-10" v-if="loadingWaitingHotwires || loadingWaitingHotwires">
            <v-progress-circular indeterminate size="100" color="primary"/>
          </div>
          <v-tabs-items v-model="tab" v-else>
            <v-tab-item v-for="(hotwireData, index) in Object.values(hotwireTypes)" :key="index">
              <v-row>
                <v-col cols="3" class="pl-6">
                  <v-text-field
                    v-model="hotwireData.search"
                    append-icon="search"
                    label="Search"
                    single-line
                    hide-details
                  ></v-text-field>
                </v-col>
                <v-spacer></v-spacer>
                <v-col cols="3">
                  <v-switch
                    :disabled="hotwireData.loading"
                    color="primary"
                    v-model="showAllCountries"
                    label="Show all countries"
                    class="text-xs-right"
                  ></v-switch>
                </v-col>
              </v-row>
              <v-data-table
                :headers="headers"
                :items="hotwireData.filteredHotwireList"
                :search="hotwireData.search"
                :loading="hotwireData.loading"
                :loading-text="'Loading hotwires'"
                :no-data-text="'No hotwires found'"
                sort-desc
                multi-sort
                :footer-props="{
                  itemsPerPageOptions: [50],
                  showFirstLastPage: true,
                }"
                fixed-header
                :height="hotwireData.hotwireList.length < 10 ? '' : '600px'"
              >
                <template v-slot:body.prepend>
                  <tr>
                    <th :class="tableHeaderColourClass">
                      <v-icon>filter_list</v-icon>
                    </th>
                    <th v-for="(header, index) in headers.slice(1)" :key="index" :class="tableHeaderColourClass">
                      <div v-if="filters.hasOwnProperty(header.value)">
                        <v-select
                          text
                          hide-details
                          small
                          multiple
                          clearable
                          :items="hotwireData.columnValueList(header.value)"
                          v-model="filters[header.value]"
                        >
                        </v-select>
                      </div>
                    </th>
                  </tr>
                </template>

                <template slot="item" slot-scope="props">
                  <tr>
                    <td>{{ props.item.id }}</td>
                    <td>{{ props.item.week }}</td>
                    <td>{{ props.item.distribution_center }}</td>
                    <td>{{ props.item.production_day }}</td>
                    <td>{{ props.item.number_customers }}</td>
                    <td>
                      <v-autocomplete
                        :items="type"
                        v-model="props.item.type"
                        @change="saveUpdate(props.item)"
                        style="width: 220px;"
                      ></v-autocomplete>
                    </td>
                    <td>
                      <v-autocomplete
                        :items="complaintCategories"
                        v-model="props.item.complaint_category"
                        @change="saveUpdate(props.item)"
                        style="width: 220px;"
                      ></v-autocomplete>
                    </td>
                    <td>
                      <v-autocomplete
                        :items="detail1Categories"
                        v-model="props.item.detail_1_category"
                        @change="saveUpdate(props.item)"
                        clearable
                        style="width: 220px;"
                      ></v-autocomplete>
                    </td>
                    <td>
                      <v-autocomplete
                        :items="detail2Categories"
                        v-model="props.item.detail_2_category"
                        @change="saveUpdate(props.item)"
                        style="width: 220px;"
                        clearable
                      ></v-autocomplete>
                    </td>
                    <td>
                      <v-autocomplete
                        :items="additionalComplaintCategories"
                        v-model="props.item.additional_complaint_category"
                        @change="saveUpdate(props.item)"
                        clearable
                        style="width: 220px;"
                      ></v-autocomplete>
                    </td>
                    <td>
                      <v-checkbox
                        color="secondary"
                        v-model="props.item.complaint_category_checked"
                        @change="saveUpdate(props.item)"
                      ></v-checkbox>
                    </td>
                    <td>{{ props.item.recipe_slot }}</td>
                    <td>{{ props.item.recipe_code }}</td>
                    <td>{{ props.item.recipe_name }}</td>
                    <td>{{ props.item.missing_ingredient }}</td>
                    <td>{{ props.item.added_ingredient }}</td>
                    <td>{{ props.item.added_ingredient_picks }}</td>
                    <td>{{ props.item.reason }}</td>
                    <td>
                      <v-checkbox
                        v-if="userIsCulinary"
                        color="secondary"
                        v-model="props.item.culinary_reviewed"
                        @change="saveUpdate(props.item)"
                      ></v-checkbox>
                      <v-checkbox v-else color="secondary" v-model="props.item.culinary_reviewed" disabled></v-checkbox>
                    </td>
                    <td>
                      <v-textarea v-model="props.item.culinary_modification" disabled></v-textarea>
                      <v-btn
                        v-if="userIsCulinary || userIsQA"
                        class="v-btn--round"
                        color="primary"
                        @click="updatePopUp(props.item, 'culinary_modification')"
                      >
                        <v-icon>mdi-square-edit-outline</v-icon>
                      </v-btn>
                    </td>

                    <td>
                      <v-checkbox
                        v-if="userIsQA"
                        color="secondary"
                        v-model="props.item.qa_reviewed"
                        @change="saveUpdate(props.item)"
                      ></v-checkbox>
                      <v-checkbox v-else color="secondary" v-model="props.item.qa_reviewed" disabled></v-checkbox>
                    </td>

                    <td>
                      <v-textarea v-model="props.item.qa_comment" disabled></v-textarea>
                      <v-btn
                        v-if="userIsQA"
                        class="v-btn--round"
                        color="primary"
                        @click="updatePopUp(props.item, 'qa_comment')"
                      >
                        <v-icon>mdi-plus-circle</v-icon>
                      </v-btn>
                    </td>

                    <td>
                      <v-checkbox
                        v-if="userIsTL"
                        color="secondary"
                        v-model="props.item.cc_reviewed"
                        @change="saveUpdate(props.item)"
                      >
                      </v-checkbox>
                      <v-checkbox v-else color="secondary" v-model="props.item.cc_reviewed" disabled></v-checkbox>
                    </td>

                    <td>
                      <v-textarea v-model="props.item.compensation" prefix="$"></v-textarea>
                    </td>

                    <td>
                      <v-btn color="primary" @click="downloadHotwire(props.item.id)">
                        <v-icon dark>cloud_download</v-icon>
                      </v-btn>
                    </td>
                  </tr>
                </template>
                <v-alert slot="no-data" :value="true" color="warning" icon="warning">
                  No hotwires for this week.
                </v-alert>
                <v-alert slot="no-results" :value="true" color="error" icon="warning">
                  Your search for "{{ hotwireData.search }}" found no results.
                </v-alert>
              </v-data-table>
            </v-tab-item>
            <v-tab-item>
              <hotwires-dashboard-summary></hotwires-dashboard-summary>
            </v-tab-item>
          </v-tabs-items>
        </v-col>
      </v-row>
    </v-container>

    <v-dialog v-model="isPopUp" max-width="1000px">
      <v-card>
        <v-container>
          <v-card-title> Edit Comment</v-card-title>
          <v-card-text>
            <div v-if="popUpAttribute == 'qa_comment'">
              <v-textarea
                :label="popUpItem.qa_comment === null ? 'Enter QA comment' : ''"
                v-model="popUpItem.qa_comment"
              >
              </v-textarea>
            </div>
            <div v-if="popUpAttribute == 'culinary_modification'">
              <v-textarea
                outlined
                auto-grow
                :label="
                  popUpItem.culinary_modification === null
                    ? 'Enter culinary instruction (Use <b>bold text</b> to highlight bold text)'
                    : ''
                "
                v-model="popUpItem.culinary_modification"
              >
              </v-textarea>
            </div>
          </v-card-text>
          <v-card-actions class="justify-center">
            <v-btn v-if="popUpAttribute == 'culinary_modification'" @click="saveUpdate(popUpItem)" color="primary">
              Save Culinary Modification
            </v-btn>
            <v-btn v-else @click="saveUpdate(popUpItem)" color="primary">
              Save QA comment
            </v-btn>
          </v-card-actions>
        </v-container>
      </v-card>
    </v-dialog>
  </div>
</template>

<style scoped></style>

<script>
import auth from '../../modules/auth'
import axios from 'axios'
import notifications from '@/modules/notifications'
import _ from 'lodash'
import HotwiresDashboardSummary from './HotwiresDashboardSummary'
import {CONSTANTS} from '@/modules/constants';

export default {
	name: 'Hotwires',
	components: {
		'hotwires-dashboard-summary': HotwiresDashboardSummary
	},
	data() {
		return {
			progress: false,
			showAllCountries: false,
			tab: 0,
			isPopUp: false,
			popUpItem: null,
			popUpAttribute: '',
			loadingWaitingHotwires: false,
			loadingCheckedHotwires: false,
			searchWaitingHotwires: '',
			searchCheckedHotwires: '',
			waitingHotwires: [],
			checkedHotwires: [],
			preferences: auth.user.preferences,
			dataTableKey: 0,
			noHotwireData: false,
			hotwires: [],
			headers: [
				{text: 'Id', value: 'id'},
				// {text: 'Country', value: 'country'},
				{text: 'Week', value: 'week'},
				{text: 'Distribution Center', value: 'distribution_center'},
				{text: 'Prod Day', value: 'production_day'},
				{text: 'Number Customers', value: 'number_customers'},
				{text: 'Type', value: 'type'},
				{text: 'Complaint Category', value: 'complaint_category'},
				{text: 'Detail 1 Category', value: 'detail_1_category'},
				{text: 'Detail 2 Category', value: 'detail_2_category'},
				{text: 'Additional Complaint Category', value: 'additional_complaint_category'},
				{text: 'Complaint Category Checked', value: 'complaint_category_checked'},
				{text: 'Recipe Slot', value: 'recipe_slot'},
				{text: 'Recipe Code', value: 'recipe_code'},
				{text: 'Recipe Name', value: 'recipe_name'},
				{text: 'Missing Ingredient', value: 'missing_ingredient'},
				// {text: 'Missing Ingredient Picks', value: 'missing_ingredient_picks'},
				{text: 'Added Ingredient', value: 'added_ingredient'},
				{text: 'Added SKU Picks', value: 'added_ingredient_picks'},
				// {text: 'Added Ingredient 2', value: 'added_ingredient_2'},
				// {text: 'Added Ingredient 2 Picks', value: 'added_ingredient_2_picks'},
				{text: 'Reason', value: 'reason'},
				{text: 'Culinary Reviewed', value: 'culinary_reviewed', type: 'checkbox', visible: this.userIsCulinary},
				{text: 'Culinary Modification', value: 'culinary_modification', type: 'textarea'},
				{text: 'QA Reviewed', value: 'qa_reviewed', type: 'checkbox', visible: this.userIsQA},
				{text: 'QA Comment', value: 'qa_comment', type: 'textarea'},
				{text: 'CC Reviewed', value: 'cc_reviewed', type: 'checkbox', visible: this.userIsTL},
				{text: 'Compensation', value: 'compensation', type: 'textfield'},
				{text: 'Actions', value: ''}
			],
			rules: {
				length: (v) => (v || '').length <= 255 || `Maximum 255 characters`,
				float: (v) => !isNaN(+v) || 'Enter a float please'
			},
			type: ['Missing Ingredient',
				'Substitution',
				'General Communication'
			],
			complaintCategories: [
				'Internal Production Error Goods In',
				'Internal Production Error Inventory',
				'Supplier Advanced Shortage',
				'Internal OpsTech Issue',
				'Internal Ordering Error',
				'Internal Procurement Decision',
				'Supplier Quality',
				'Supplier Incorrect SKU',
				'Supplier Short Delivery',
				'Internal Food Safety',
				'Internal Forecasting Issue',
				'Supplier Early Late Delivery',
				'Supplier No Delivery',
				'Internal Pick Pack'
			],
			detail1Categories: ['Missing', 'SKU change', 'Wrong amount not enough', 'Wrong amount too much', 'Incorrect'],
			detail2Categories: ['Low impact', 'High impact'],
			additionalComplaintCategories: ['Cooking instructions are incorrect'],
			filters: {
				recipe_slot: [],
				recipe_name: [],
				missing_ingredient: [],
				added_ingredient: [],
				culinary_reviewed: [],
				qa_reviewed: [],
				cc_reviewed: []
			},
			showCompleted: false,
			showUnanswered: false
		}
	},
	watch: {
		showAllCountries: function () {
			this.getWaitingHotwires()
			this.getCheckedHotwires()
		},
		weekSelected: function () {
			this.getWaitingHotwires()
			this.getCheckedHotwires()
		}
	},
	computed: {
		hotwireTypes() {
			return {
				waiting: {
					hotwireList: this.waitingHotwires,
					search: this.searchWaitingHotwires,
					loading: this.loadingWaitingHotwires,
					filteredHotwireList: this.filteredWaitingHotwires,
					columnValueList: this.columnValueListWaiting
				},
				checked: {
					hotwireList: this.checkedHotwires,
					search: this.searchCheckedHotwires,
					loading: false,
					filteredHotwireList: this.filteredCheckedHotwires,
					columnValueList: this.columnValueListChecked
				}
			}
		},
		filteredCheckedHotwires() {
			let filteredArray = this.checkedHotwires.filter((hotwire) => {
				return Object.keys(this.filters).every((filter) => {
					return this.filters[filter].length < 1 || this.filters[filter].includes(hotwire[filter])
				})
			})
			return filteredArray
		},
		filteredWaitingHotwires() {
			let filteredArray = this.waitingHotwires.filter((hotwire) => {
				return Object.keys(this.filters).every((filter) => {
					return this.filters[filter].length < 1 || this.filters[filter].includes(hotwire[filter])
				})
			})
			return filteredArray
		},
		completionRate() {
			let completionRate = 0
			this.hotwires.forEach((hotwire) => {
				if (hotwire.answered) {
					completionRate += 1
				}
			})
			return this.hotwires.length > 0 ? Math.round((completionRate * 100) / this.hotwires.length) : '-'
		},
		userIsCulinary() {
			return auth.user.profile.culinary
		},
		userIsQA() {
			return auth.user.profile.food_safety
		},
		userIsTL() {
			return auth.user.profile.team_leader
		},
		tableHeaderColourClass() {
			return this.preferences.country === 'au' ? 'thead-au' : 'thead-nz'
		},
		weekSelected: {
			set(week) {
				this.$store.dispatch('updateWeek', week)
			},
			get() {
				return this.$store.state.week
			}
		},
		weeks() {
			return this.$store.state.weeks
		}
	},
	mounted() {
		this.getComplaintCategories()
		this.getWaitingHotwires()
		this.getCheckedHotwires()
	},
	methods: {
		getComplaintCategories() {
			axios
				.get(process.env.VUE_APP_YAKKA_API_URL + '/hotwires/complaint-categories')
				.then((response) => {
					this.complaintCategories = response.data.complaint_categories
				})
				.catch((error) => {
					notifications.addNotification('Oops! There was a problem in retrieving the complaint categories.', true)
				})
		},
		getWaitingHotwires() {
			this.loadingWaitingHotwires = true
			axios
				.get(`${CONSTANTS.BASE_URL}/api/v1` + '/hotwires/waiting', {
					params: {
						is_tl: auth.user.profile.team_leader,
						is_qa: auth.user.profile.food_safety,
						is_culinary: auth.user.profile.culinary,
						week: this.weekSelected,
						country: this.showAllCountries ? null : this.preferences.country
					}
				})
				.then((response) => {
					this.waitingHotwires = response.data.waiting_hotwires
					this.loadingWaitingHotwires = false
				})
		},
		getCheckedHotwires() {
			this.loadingCheckedHotwires = true
			axios
				.get(`${CONSTANTS.BASE_URL}/api/v1` + '/hotwires/checked', {
					params: {
						is_tl: auth.user.profile.team_leader,
						is_qa: auth.user.profile.food_safety,
						is_culinary: auth.user.profile.culinary,
						week: this.weekSelected,
						country: this.showAllCountries ? null : this.preferences.country
					}
				})
				.then((response) => {
					this.checkedHotwires = response.data.checked_hotwires
					this.loadingCheckedHotwires = false
				})
		},
		downloadHotwire(hotwireID) {
			this.progress = true
			axios({
				url: `${CONSTANTS.BASE_URL}/api/v1/hotwire/${hotwireID}/download`,
				method: 'GET',
				responseType: 'blob',
				params: {
					country: this.preferences.country
				}
			})
				.then((response) => {
					const url = window.URL.createObjectURL(new Blob([response.data]))
					const link = document.createElement('a')
					link.href = url
					link.setAttribute('download', `"Hotwire - ${hotwireID}.csv`)
					document.body.appendChild(link)
					link.click()
					this.progress = false
				})
				.catch(() => {
					this.progress = false
				})
		},
		saveUpdate(hotwire) {
			this.progress = true
			if (!hotwire.qa_reviewed) {
				hotwire.qa_reviewed = hotwire.complaint_category && hotwire.detail_1_category == 'Missing' ? true : false
			}

			axios
				.post(`${CONSTANTS.BASE_URL}/api/v1/hotwire/update`, {
					params: {
						hotwire: hotwire
					}
				})
				.then((response) => {
					notifications.addNotification('Response updated successfully!', true)
					this.isPopUp = false
					this.progress = false
				})
				.catch((error) => {
					notifications.addNotification('Oops! There was a problem updating your comment', true)
				})
		},

		launchExportPipeline() {
			this.progress = true
			axios({
				url: `${CONSTANTS.BASE_URL}/api/v1/hotwires/${this.weekSelected}/data/download`,
				method: 'GET',
				responseType: 'blob',
				params: {
					country: this.preferences.country
				}
			})
				.then((response) => {
					const url = window.URL.createObjectURL(new Blob([response.data]))
					const link = document.createElement('a')
					link.href = url
					link.setAttribute('download', `Hotwire Boxes - ${this.weekSelected}.csv`)
					document.body.appendChild(link)
					link.click()
					this.progress = false
				})
				.catch((error) => {
					notifications.addNotification('Oops, there was an error exporting the data', true)
					this.progress = false
				})
		},
		launchWeekSummaryExport() {
			this.progress = true
			axios({
				url: `${CONSTANTS.BASE_URL}/api/v1/hotwires/${this.weekSelected}/download`,
				method: 'GET',
				responseType: 'blob',
				params: {
					country: this.preferences.country
				}
			})
				.then((response) => {
					const url = window.URL.createObjectURL(new Blob([response.data]))
					const link = document.createElement('a')
					link.href = url
					link.setAttribute('download', `Hotwires - ${this.weekSelected}.csv`)
					document.body.appendChild(link)
					link.click()
					this.progress = false
				})
				.catch((error) => {
					notifications.addNotification('Oops, there was an error exporting the data', true)
					this.progress = false
				})
		},
		launchFullHotwireBoxesExport() {
			this.progress = true
			axios({
				url: `${CONSTANTS.BASE_URL}/api/v1/hotwire/boxes/${this.weekSelected}/download`,
				method: 'GET',
				responseType: 'blob',
				params: {
					country: this.preferences.country
				}
			})
				.then((response) => {
					const url = window.URL.createObjectURL(new Blob([response.data]))
					const link = document.createElement('a')
					link.href = url
					link.setAttribute('download', `HotwireBoxes - ${this.weekSelected}.csv`)
					document.body.appendChild(link)
					link.click()
					this.progress = false
				})
				.catch((error) => {
					notifications.addNotification('Error: could not download hotwires', true)
					this.progress = false
				})
		},
		updatePopUp(popUpItem, popUpAttribute) {
			this.isPopUp = true
			this.popUpItem = popUpItem
			this.popUpAttribute = popUpAttribute
		},
		changeSort(column) {
			if (this.pagination.sortBy === column) {
				this.pagination.descending = !this.pagination.descending
			} else {
				this.pagination.sortBy = column
				this.pagination.descending = false
			}
		},
		forceReRender() {
			this.dataTableKey += 1
		},
		columnValueListChecked(val) {
			return this.checkedHotwires.map((d) => d[val])
		},
		columnValueListWaiting(val) {
			return this.waitingHotwires.map((d) => d[val])
		}
	}
}
</script>
