var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('h1',{staticClass:"display-3"},[_vm._v("Voucher Code Lookup")])]),_c('v-col',{attrs:{"cols":"12"}},[_c('h1',{staticClass:"display-1 ma-3"},[_vm._v("Backup Vouchers")]),_c('span',{staticClass:"headline"}),_c('v-card',[_c('v-card-title',{staticClass:"headline"},[_vm._v("Select the voucher settings you require")]),_c('v-card-text',[_c('v-form',{ref:"voucherLookupForm"},[_c('v-container',{attrs:{"grid-list-sm":""}},[_c('v-row',[_c('v-col',{attrs:{"xs":"12","sm":"6","md":"3"}},[_c('v-checkbox',{staticClass:"shrink mr-2",attrs:{"label":"Is this a new customer?","hide-details":"","color":"secondary"},model:{value:(_vm.is_new_customer),callback:function ($$v) {_vm.is_new_customer=$$v},expression:"is_new_customer"}}),(_vm.is_new_customer)?_c('v-text-field',{attrs:{"label":"New Customer Email / ID","rules":[
                        function (v) { return v.length >= 4 ||
                          'A customer email or ID is required'; } ]},model:{value:(_vm.customer_email),callback:function ($$v) {_vm.customer_email=$$v},expression:"customer_email"}}):_c('v-text-field',{attrs:{"label":"Customer ID","rules":[
                        function (v) { return !isNaN(v) || 'A customer ID is required'; },
                        function (v) { return v.length >= 4 || 'A customer ID is required'; } ]},model:{value:(_vm.customer_id),callback:function ($$v) {_vm.customer_id=$$v},expression:"customer_id"}}),(!_vm.is_new_customer)?_c('v-btn',{staticClass:"mt-2",attrs:{"color":"primary"},on:{"click":_vm.getCustomerLoyalty}},[_vm._v("Check customer loyalty")]):_vm._e()],1),(_vm.userProfile.team_leader || _vm.userProfile.admin)?_c('v-col',{attrs:{"xs":"12","sm":"6","md":"3"}},[_c('v-checkbox',{attrs:{"label":"Is this a leadership code"},on:{"change":_vm.refreshDropdowns},model:{value:(_vm.show_leadership_codes),callback:function ($$v) {_vm.show_leadership_codes=$$v},expression:"show_leadership_codes"}}),(_vm.loadingCustomerLoyalty)?_c('v-progress-circular',{attrs:{"indeterminate":""}}):_vm._e(),(_vm.customerLoyalty !== null && !_vm.is_new_customer)?_c('h3',[_vm._v(" Customer Loyalty: "+_vm._s(_vm.customerLoyalty)+" ")]):_vm._e(),(_vm.customerLoyaltyError && !_vm.is_new_customer)?_c('h3',[_vm._v(" "+_vm._s(_vm.customerLoyaltyError)+" ")]):_vm._e()],1):_vm._e()],1),_c('v-row',{attrs:{"wrap":""}},[(_vm.show_leadership_codes)?_c('v-col',{attrs:{"xs":"12","sm":"6","md":"3"}},[_c('v-autocomplete',{attrs:{"items":_vm.agent_names,"label":"Agent requesting code","rules":[
                        function (v) { return v.length > 0 || 'Please type an agent name'; } ]},model:{value:(_vm.requesting_agent_name),callback:function ($$v) {_vm.requesting_agent_name=$$v},expression:"requesting_agent_name"}})],1):_vm._e(),_c('v-col',{attrs:{"xs":"12","sm":"6","md":"3"}},[_c('v-select',{attrs:{"items":_vm.backup_voucher_reasons,"label":"Reason*","rules":[
                        function (v) { return v.length > 0 ||
                          'Please select a reason for supplying this voucher'; } ]},on:{"change":_vm.getBackUpVoucherReasonDetails},model:{value:(_vm.backup_voucher_reason),callback:function ($$v) {_vm.backup_voucher_reason=$$v},expression:"backup_voucher_reason"}})],1),(_vm.backup_voucher_reason_details.length != 0)?_c('v-col',{attrs:{"xs":"12","sm":"6","md":"3"}},[_c('v-select',{attrs:{"items":_vm.backup_voucher_reason_details,"label":"Reason Detail*","rules":[
                        function (v) { return v.length > 0 ||
                          'Please select a reason for supplying this voucher'; } ]},model:{value:(_vm.backup_voucher_reason_detail),callback:function ($$v) {_vm.backup_voucher_reason_detail=$$v},expression:"backup_voucher_reason_detail"}})],1):_vm._e()],1),_c('v-row',{attrs:{"wrap":""}},[_c('v-col',{attrs:{"xs":"12","sm":"6","md":"3"}},[_c('v-select',{attrs:{"items":_vm.backup_voucher_types,"label":"Voucher Type* (select first)","rules":[
                        function (v) { return v.length > 0 || 'Please select a voucher type'; } ]},on:{"change":_vm.getBackupVoucherDetails},model:{value:(_vm.backup_voucher_type),callback:function ($$v) {_vm.backup_voucher_type=$$v},expression:"backup_voucher_type"}})],1),_c('v-col',{attrs:{"xs":"12","sm":"6","md":"3"}},[_c('v-select',{attrs:{"items":_vm.filteredBackupVoucherDetails,"rules":[
                        function (v) { return v.length > 0 || 'Please select a voucher detail'; } ],"label":"Voucher Detail*"},model:{value:(_vm.backup_voucher_detail),callback:function ($$v) {_vm.backup_voucher_detail=$$v},expression:"backup_voucher_detail"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"3"}},[(_vm.oldCodePolicy == 'optional')?_c('v-text-field',{attrs:{"label":"Old Code"},model:{value:(_vm.old_code),callback:function ($$v) {_vm.old_code=$$v},expression:"old_code"}}):_vm._e(),(_vm.oldCodePolicy == 'compulsory')?_c('v-text-field',{attrs:{"label":"Old Code*","rules":[function (v) { return v.length >= 1 || 'Old Code needed'; }]},model:{value:(_vm.old_code),callback:function ($$v) {_vm.old_code=$$v},expression:"old_code"}}):_vm._e(),(
                        _vm.backup_voucher_reason_detail ===
                        'Other - please specify'
                      )?_c('v-text-field',{attrs:{"label":"Other Reason*","rules":[function (v) { return v.length >= 1 || 'Reason needed'; }]},model:{value:(_vm.otherReason),callback:function ($$v) {_vm.otherReason=$$v},expression:"otherReason"}}):_vm._e()],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"3"}},[_c('v-btn',{attrs:{"color":"primary","loading":_vm.loadingGenerateBackUpVoucher,"disabled":_vm.loadingGenerateBackUpVoucher},on:{"click":_vm.getBackupVoucher}},[_vm._v("Get Backup Voucher Code ")])],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"3"}},[(_vm.backup_voucher_code !== '')?_c('v-card-text',{staticClass:"headline"},[_vm._v(" "+_vm._s(_vm.backup_voucher_code)+" ")]):_vm._e()],1),_c('v-col',{attrs:{"cols":"12"}},[(_vm.backup_voucher_code !== '')?_c('div',{staticClass:"pl-3",attrs:{"cols":"3"}},[_c('span',{staticClass:"headline"},[_vm._v(_vm._s(_vm.backup_voucher_message))])]):_vm._e()])],1)],1)],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }