<template>
  <v-card>
    <v-card-title>
      <span class="headline">Add Template</span>
    </v-card-title>
    <v-card-text>
      <v-alert
        border="top"
        colored-border
        type="error"
        elevation="2"
        class="caption"
      >
        <p v-pre>
          Note: If you want your template to fill in the customer name or CC phone number automatically, use either
          {{ name }} or {{ phone }} in the template
        </p>
        <p v-pre>
          eg. Hi {{ name }}, please call us at {{ phone }}
        </p>
      </v-alert>
      <v-container>
        <v-form
          ref="form"
          v-model="valid"
        >
          <v-row>
            <v-col cols="12">
              <v-text-field
                label="Name*"
                required
                v-model="name"
                :rules="inputRules"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-textarea
                auto-grow
                label="Body*"
                required
                v-model="body"
                :rules="inputRules"
              ></v-textarea>
            </v-col>
            <template v-if="country !== 'ao'">
              <v-col cols="12">
                <span>Select Country</span>
              </v-col>
              <v-col cols="6" sm="6">
                <v-checkbox v-model="isAu" label="Australia"></v-checkbox>
              </v-col>
              <v-col cols="6" sm="6">
                <v-checkbox v-model="isNz" label="New Zealand"></v-checkbox>
              </v-col>
            </template>
          </v-row>
        </v-form>
      </v-container>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="black" text @click="toggleDialog">Close</v-btn>
      <v-btn color="primary" @click="addTemplate" :loading="loading">Save</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import axios from 'axios';
import notifications from '@/modules/notifications';
import auth from '../../modules/auth';
import {CONSTANTS} from '@/modules/constants';

export default {
	name: 'AddTextTemplate',
	props: {
		dialog: Boolean,
		toggleDialog: Function,
		getTextTemplates: Function
	},
	watch: {
		dialog: {
			handler: function(dialog) {
				// this clears all form fields
				// when someone makes template edits and closes the dialog
				if (dialog) {
					this.resetFields();
				}
			}
		}
	},
	mounted() {
		this.resetFields();
	},
	data() {
		return {
			country: auth.user.preferences.country,
			valid: false,
			loading: false,
			name: '',
			body: '',
			isAu: false,
			isNz: false,
			inputRules: [v => !!v || 'Required field']
		}
	},
	methods: {
		addTemplate() {
			const template = {
				name: this.name,
				body: this.body,
				au: this.isAu,
				nz: this.isNz
			}

			if (this.$refs.form.validate()) {
				this.loading = true;
				axios
					.post(`${CONSTANTS.BASE_URL}/api/v1/customers/text-templates`, {
						template,
						country: this.country
					})
					.then(response => {
						this.loading = false;
						this.toggleDialog();
						this.getTextTemplates();
						notifications.addNotification('Successfully added template!');
					})
					.catch(error => {
						notifications.addNotification('Error: Could not update template', true);
						this.loading = false;
					})
			}
		},
		resetFields() {
			this.name = '';
			this.body = '';

			if (this.country === 'au' || this.country === 'ao') {
				this.isAu = true;
			} else if (this.country === 'nz') {
				this.isNz = true;
			}
		}
	}
}
</script>

<style scoped>

</style>
